import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from "@mui/icons-material/Close";
import { Divider, Stack, Typography , useMediaQuery } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "../notification/notification.style.css";
import CardItems from "./CardItems";
import { view_notification_details } from "../../pages/Axiosfetch/Axiosfetch";
import { useTheme } from '@mui/material/styles';

export default function NotificationHeading({
  deleteid,
  activeJobId,

  isVisiable,

  displayCount,
  counts,
  initialDisplayCount,
  setActiveJobId,
  loading,
  setDeleteid,
  handleToggle,
  setFilteredJobs,
  filteredJobs,
  handleRemoveMessage,
}) {
  useEffect(() => {
    console.log("filteredJobs: ", filteredJobs);
  }, [filteredJobs]);

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));


  console.log("isXs:", isXs, "isSm:", isSm, "isMd:", isMd);

  let className = "closeIcon";

  if (isXs) {
    console.log("isXs is active");
    className = "closeIcon1";
  } else if (isSm) {
    console.log("isSm is active");
    className = "closeIcon";
  } else if (isMd) {
    console.log("isMd is active");
    className = "closeIcon";
  }

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: 340, md: 340 },
        // marginTop: "25%",
        position: "relative",
      }}
      role="presentation"
      // onClick={toggleDrawer(false)}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          className="align-icon"
          fontWeight={500}
          variant="h4"
          sx={{
            textTransform: "capitalize",
            color: "white",
            padding: "20px",
          }}
          // sx={{ textTransform: "capitalize", color: "#017BFD",  padding: "20px" }}
        >
          {/* Notification {counts} */}

          Notification 
        </Typography>

        <Stack direction="row" sx={{ display: "flex", alignItems: "center" , cursor:"pointer" }}>
          <TaskAltIcon sx={{ color: "white", width: "15px" }} />
          <Typography
            className="align-icon"
            fontWeight={500}
            variant="h4"
            onClick={() => {setFilteredJobs([]);}}
            sx={{
              // color: "#017BFD",
              color: "white",
              fontSize: "12px",
              marginLeft: "5px",
              paddingleft: "15px !important",
              paddingRight: "20px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            Clear all
          </Typography>
        </Stack>
      </Stack>

      <Divider color={"white"} />

      {loading ? (
        <>
          <Box className="cardContainer">
            {filteredJobs.length > 0
              ? filteredJobs.slice(0, displayCount).map((job) => {
                  console.log("Slicedjob", job);
                  console.log(job.id, "===", deleteid);

                  return (
                    <CardItems
                      key={job.id}
                      job={job}
                      filteredJobs={filteredJobs}
                      deleteid={deleteid}
                      initialDisplayCount={initialDisplayCount}
                      handleToggle={handleToggle}
                      displayCount={displayCount}
                      counts={counts}
                      isActive={job.id === activeJobId}
                      isActive2={job.id === deleteid}
                      className={className}
                      // isActive2={job.id === activeJobId2}

                      isVisiable={isVisiable}
                      handleRemoveMessage={handleRemoveMessage}
                      onClick={() => {
                        setActiveJobId(job.id);
                        console.log(job.id, "job.id");
                      }}
                    />
                  );
                })
              : null}

            {/* <JobBox /> */}
          </Box>

          <Box>
            {filteredJobs.length > initialDisplayCount && (
              <Box>
                <Button
                  onClick={handleToggle}
                  sx={{
                    width: "100%",
                    backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
                    color: "white",
                  }}
                >
                  {displayCount === initialDisplayCount
                    ? "Show More"
                    : "Show Less"}
                </Button>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <lottie-player
          src="https://lottie.host/8ef3bac3-3ca1-4f3e-b26f-ce8e81aad3cb/y1R698LOQA.json"
          background="##ffffff"
          speed="1"
          style={{ width: "325px", height: "400px" }}
          loop
          autoplay
          direction="1"
          mode="normal"
        ></lottie-player>
      )}
    </Box>
  );
}
