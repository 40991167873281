import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from "@mui/icons-material/Close";
// import "../components/notification.style.css";

import {
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";


export default function CardItems({
  job,
  isActive,
  isActive2,
  onClick,
  handleRemoveMessage,
  isVisiable,
  filteredJobs,
  deleteid,
  Counts,

  
 
}) {
  const navigate = useNavigate();
  console.log(isActive2, "isActive2");
  console.log(deleteid, "deleteid");

  const [isCloseIconActive, setIsCloseIconActive] = useState(false);

 
  
  useEffect(() => {
    console.log({ isCloseIconActive });
    console.log({ job });
    console.log("filterdJObs", filteredJobs?.includes(job.id));
    console.log("deleteid", deleteid);
  }, [filteredJobs]);
  console.log("deleteid", deleteid);
  return (
    <>
      <Box
       

        key={job.id}
        component={Paper}
        paddingInline={2}
        paddingBlock={2}
        onClick={onClick}
        // onMouseEnter={() => {
        //   setIsCloseIconActive(true);
        // }}
        // onMouseLeave={() => {
        //   setIsCloseIconActive(false);
        // }}

        className={`card-container ${
          isActive2 ? "fade-out" : ""
        } cardItems`}
        

     
     

        sx={{
          cursor: "pointer",
          mb: 2,
          background: isActive ? "#00000080" : "#00000050",
          // background: isActive ? "#D1E8FF" : "#ECF5FF",
          border: isActive ? "1px solid #49A3F1" : "none",

          marginRight: "15px",
          position: "relative",

          margin: "10px",

          // borderRadius: "20px",
          boxShadow: "0px -1px 10px #1e1e1e5c",

          "::before": isActive
            ? {
                content: 'url("./../images/forborder.png")', // Path to your image file
                //   content: '"hello"', // Path to your image file
                width: "100%", // Adjust to your image dimensions
                height: "20px", // Adjust to your image dimensions
                position: "absolute",
                top: 0,
                right: 0,
                width: 0,
                height: 0,

                // borderTop: "10px solid #49A3F1",
                // borderRight: "10px solid transparent"
              }
            : {},
        }}
      >
        {/* {isCloseIconActive ? (
        
        ) : null} */}
          <Box>
            <CloseIcon
              onClick={() => handleRemoveMessage(job.id)}
              className="closeIcon"

              // className={ }

              sx={{
                width: "20px",
                color: "#ffffff90",
                position: "absolute",
                top: "0px",
                right: "0px",
             
              }}
            />
          </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
          gap={2}
        >
          {/* <img width={48} alt="dummy" /> */}

          <img
            width={48}
            src={job.logo}
            // src={profile}
            alt="dummy"
          />

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{ color: "white" }}
            flexItem
          />

          <Box>
            <Typography
              className="align-icon"
              fontWeight={500}
              variant="h4"
              sx={{ textTransform: "capitalize", color: "white" }}
            >
              {job.title}
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                fontSize={12}
                sx={{ textTransform: "capitalize", color: "#ffffff90" }}
              >
                From HR
              </Typography>

              <Link
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {/* <LaunchIcon
                      color="primary"
                      sx={{ fontSize: 17, marginLeft: 1 }}
                    /> */}
              </Link>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "flex" }}>
            <div
              style={{
                background: "#00000030",
                paddingRight: "1px",
                borderRadius: "15px",
                display: "flex",
                marginTop: "15px",
                border: "1px solid #00000010",
              }}
            >
              {/* <CheckCircleIcon sx={{ marginRight: "2px", color: "green" }} /> */}
              <Typography
                sx={{
                  color: "#ffffff90",
                  fontSize: 11,
                  fontWeight: "medium",
                  textAlign: "center",
                  marginTop: "2px",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                {job.body}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
