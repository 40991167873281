
import { useContext, useEffect, useState  } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import DirectionsIcon from "@mui/icons-material/Directions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AlertToast, FullScreenContext,alertState } from "../divine-constants";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { forgotpass, verify } from "./Axiosfetch/Axiosfetch";
import { emailValidation } from "../utils";
const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [expire, setExpire] = useState(5);
  const navigate = useNavigate();
  const [timer, setTimer]  = useState(false);
  const [otp, setOtp] = useState(false);
  const [email, setemail] = useState();
  const { setAlert } = useContext(AlertToast);
  const [otpValue, setOtpValue] = useState('');


 
  const handleSubmit = async(event) => {
    event.preventDefault();
    const val = { OTP : otpValue }
    try {
      const response = await verify(val)
  
      if(response.status===200){
     
        setAlert(alertState({
          message: "Login successfuly",
          severity: "success",
          show: true
        }));
        navigate("/reset-password")

      }else {
        setAlert(alertState({
          message: response.data.message,
          severity: "error",
          show: true
        }));
      }
  
      }catch (err){
        setAlert(alertState({
          message: "Something went wrong! Please try again",
          severity: "error",
          show: true
        }));
    }
  }

  const handlechange = async(event) => {

    setemail(event.target.value)
  }

  function isValidEmail(email) {
    // Regular expression for validating email addresses
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}


  const HandleOtp = async () => {

    
    if (email && isValidEmail(email)  ) {
   const emails = {"email_id" : email}
    try {
      const { status , data  } = await forgotpass(emails);
      if (status === 200) {
        localStorage.setItem("accesstoken",data.token)		
      setAlert(alertState({
        message: "OTP sent successfully",
        severity: "success",
        show: true
      }));
     
      }else {
        setAlert(alertState({
					message: "Email Already Exists! Go to Login",
					severity: "error",
					show: false
				}));
      }
    } catch (error) {
      setAlert(alertState({
        message: "Something went wrong! Please try again",
        severity: "error",
        show: true
      }));
    }

    setOtp(true);
    setTimer(true);
    setExpire(60);

  }
  else{
    setAlert(alertState({

      message: "invalid Email id",
      severity: "error",
      show: true

    }));
  }

  }

  useEffect(()=>{
    let countdownTimer;
    if(timer && expire>0){
      countdownTimer = setInterval(() => {
        setExpire((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }else{
      setTimer(false);
      clearInterval(countdownTimer);
    }

    return () => {
      clearInterval(countdownTimer);
    };
  },[timer, expire]);


  return (
    <Card>
      <CardContent>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box  
              sx={{
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockResetIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                sx={{ fontFamily: "Vollkorn" }}
              >
                FORGOT PASSWORD
              </Typography>

              <Box sx={{ mt: 5 }}>
                <FormControl
                  sx={{ mt: 0,  }}
                  size="small"
                  variant="outlined"

                >
                  <InputLabel >
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value = {email}
                    name = "email_id"
                    type="Email"
                    onChange = {handlechange}
                    
                  
                    endAdornment={
                      <InputAdornment position="end">
                        <Divider  
                          sx={{ height: 28, m: 0.5, ml: 2 }}
                          orientation="vertical"
                        />
                        <IconButton
                          name = "email_id"
                          onClick={HandleOtp}
                          color="primary"
                          
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="email"
                  />
                </FormControl>

                {otp === true ? (
                  <Box>
                    <form onSubmit={handleSubmit}>

                      <TextField
                        size="small"
                        sx={{ mt: 3 }}
                        margin="normal"
                        fullWidth
                        name="OTP"
                        label="OTP"
                        type="text"
                        id="OTP"
                        onChange={(e) => setOtpValue(e.target.value)}
                      />

                      <Grid container>
                        <Grid item>
                          {timer===true?<Link
                            underline="none"
                            sx={{ color: "black" }}
                            href="/registration"
                            variant="body2"
                          >
                            OTP Expires in : {expire} seconds
                          </Link>:
                          <Link
                          onClick={HandleOtp}
                          sx={{ color: "black" }}
                          underline="none"
                          href="#"
                          variant="body2"
                        >
                          Resend otp
                        </Link>}
                        </Grid>
                      </Grid>

                      <Button
                       
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit
      
                      </Button>
                    </form>
                  </Box>
                ) : null}
              </Box>
            </Box>

          </Container>
        </ThemeProvider>
      </CardContent>
    </Card>
  );
}
