import { useState } from "react";
import { emailRegex } from "./divine-constants";


export function emailValidation() {
	return {
		required: "Email is required",
		pattern: {
			value: emailRegex,
			message: "Invalid email address"
		}
	}
}

export function textValidation(title) {
return {

		required: title + " is required"
	}
}





export function check_box_valid() {
return {

	required: " is required"
	}
}

export function Percentages(title) {
	return {
		required: "Percentage is required",
		minLength: {
		  value: 2,
		  message: "Percentage must be at least 2 digits long"
		},
		pattern: {
		  value: /^[1-9]\d*$/, // Allows positive numeric digits greater than 0
		  message: "Percentage can only contain positive numeric digits"
		}
	  };
	
  }


  let variable1 = 0;
  let variable2 = 0;

  export function Workexpre(title) {

	return {
	  required: "TotalExperience is required",
	  validate: {
		nonZero: value => {

		  const isValid = parseFloat(value) > 0;
		  if (title === "Years"){
			variable1 = value
			
		  }
		  if (title === "Months"){
			variable2 = value

		  }
		if (variable1 == 0 && variable2 == 0 ){

	
			return isValid || `${title} cannot be zero`
		}
	
		}
	  }
	};
  }
  

//   export function Workexpre(title) {

// 	// const [work, setWork] = useState("");
// 	// console.log(work,"work")

	


// 	return {

// 	  	required: "TotalExperience is required"

// 	//   validate: {
		
// 	// 	nonZero: value =>{
// 	// 		const tryme = parseFloat(value) > 0 || "Percentage cannot be zero"
// 	// 	  console.log(value,"valueof")
// 	// 	  return tryme

// 	// 	}
	  
	  

// 	  	validate: {
		
// 		nonZero: 	 value => {
// 			const tryme = parseFloat(value) > 0 || "Percentage cannot be zero"
// 			console.log("nonZero", value)

// 			return tryme
// 		 }

// 		};

//   }
// }

  

export function Percentagesss(title) {
	return {
		required: "Percentage is required",
		minLength: {
		  value: 2,
		  message: "Percentage must be at least 2 digits long"
		},
		pattern: {
		  value: /^[1-9]\d*$/, // Allows positive numeric digits greater than 0
		  message: "Percentage can only contain positive numeric digits"
		}
	  };
	
  }





// export function passwordValidation() {
// 	return {
// 		required: "Password is required",
// 		minLength: {
// 			value: 8, message: "Password must be atleast 8 characters"
// 		},
// 		maxLength: {
// 			value: 25, message: "Password can be a maximum of 25 characters"
// 		},

// 		pattern: {
// 			value: /\W/,
// 			message: "Password has to be atleast 1 special character"
// 		}

// 	}
// }


export function passwordValidation() {
	return {
		required: "Password is required",
		minLength: {
			value: 12,
			message: "Password must be at least 12 characters"
		},
		maxLength: {
			value: 64,
			message: "Password can be a maximum of 64 characters"
		},

		pattern: {
			value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
			message: "Password must contain at least one uppercase, one lowercase, one number, one special character, and be at least 12 characters long."
		}

	}
}


export function mobileValidation() {
	return {
		required: "Mobile number is required",
		minLength: {
			value: 10, message: "Mobile number has to be atleast 6 digits"
		},
		maxLength: {
			value: 12, message: "Mobile number can only be 10 digits"
		},
		pattern: {
			value: /\d{6,10}/g,
			message: "Mobile can only contain numeric digits"
		}
	}
}

export const dummyLanguages = new Set(["Tamil", "English", "Korean", "Japanese", "Swedish", "Mandarin", "Spanish", "Telugu", "Malayalam", "Kannada", "Hindi", "Urdu", "Afrikaans", "Arabic", "Bengali", "Marathi", "Russian", "German", "Polish", "French", "Portuguese", "Irish", "Italian", "Greek", "Turkish", "Croatian", "Hungarian", "Belarusian", "Danish", "Czech", "Maltese", "Romanian", "Latin"]);
export const skills = [
	'Algorithms',
	'Analytical Skills',
	'Big Data',
	'Calculating',
	'Compiling Statistics',
	'Data Analytics',
	'Data Mining',
	'Database Design',
	'Database Management',
	'Documentation',
	'Modeling',
	'Modification',
	'Needs Analysis',
	'Quantitative Research',
	'Quantitative Reports',
	'Statistical Analysis',
	'Applications',
	'Certifications',
	'Coding',
	'Computing',
	'Configuration',
	'Customer Support',
	'Debugging',
	'Design',
	'Development',
	'Hardware',
	'Implementation',
	'Information Technology',
	'Infrastructure',
	'Languages',
	'Maintenance',
	'Network Architecture',
	'Network Security',
	'Networking',
	'New Technologies',
	'Operating Systems',
	'Programming',
	'Restoration',
	'Security',
	'Servers',
	'Software',
	'Solution Delivery',
	'Storage',
	'Structures',
	'Systems Analysis',
	'Technical Support',
	'Technology',
	'Testing',
	'Tools',
	'Training',
	'Troubleshooting',
	'Usability',
	'Benchmarking',
	'Budget Planning',
	'Engineering',
	'Fabrication',
	'Following Specifications',
	'Operations',
	'Performance Review',
	'Project Planning',
	'Quality Assurance',
	'Quality Control',
	'Scheduling',
	'Task Delegation',
	'Task Management',
	'Blogging',
	'Digital Photography',
	'Digital Media',
	'Facebook',
	'Instagram',
	'Pinterest',
	'SEO',
	'Social Media Platforms',
	'Twitter',
	'Web Analytics',
	'Client Relations',
	'Email',
	'Requirements Gathering',
	'Research',
	'Subject Matter Experts (SMEs)',
	'Technical Documentation'
];