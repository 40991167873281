import {
  Box,
  Container,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import Grid from "@mui/material/Grid";

const OverView = () => {
  return (
    <Container maxWidth="lg">
      <Paper elevation={3}>

      <Box sx={{py:"10px" , backgroundColor:'#cdcdcd'}}>
                <Typography variant="h5" component="h2" textAlign={"center"} >
                This is the resume companies will see when you apply
              </Typography>

                </Box>

          

              <Divider orientation={"horizontal"} variant={"fullWidth"} />
        <Container maxWidth="lg">
          <Grid container spacing={2}>
           
            <Grid item xs={6} md={6}>
                <Box sx={{my:"20px" }}>

                   
              <Typography variant="h3" component="h2">
                Akash G
              </Typography>
              <Typography variant="h5" component="h2">
                akash.seltam@gmail.com
              </Typography>
              <Typography variant="h5" component="h2">
                +91 9566085526
              </Typography>
              <Typography variant="h5" component="h2">
                Chennai
              </Typography>


              </Box>


            </Grid>
            
            

            <Grid item xs={6} md={12}>
               

            <Divider orientation={"horizontal"} variant={"fullWidth"} />

            </Grid>


            <Grid item xs={6} md={4}>
                
              {/* <Divider orientation={"horizontal"} variant={"fullWidth"} /> */}

            <Typography variant="h3" component="h2">
            EDUCATION
              </Typography>

               

            </Grid>


            <Divider />
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};

export default OverView;
