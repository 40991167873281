import { Typography } from "@mui/material";
import CountUp from "react-countup";


// const NumberCount = ({ value }) => {

//     const commonStyle = {
//         fontSize: '3em', // Adjust the font size as needed
//         fontWeight: 'bold', // Optional: make the font bold
//         color: '#333', // Optional: set the text color
//         };

//   return Number(value) === 0 ? (
//     value
//   ) : value.toString().length === 1 ? (
    
//     <CountUp start={0} end={value} delay={0} prefix="0">

//       {({ countUpRef }) => (

//         <div  >
//           <span ref={countUpRef}  style={commonStyle}  />
//           {/* <label style={commonStyle} > M </label> */}
//           <lable color={'#000000'} fontSize={15}> M </lable>
//         </div>
//       )}

//     </CountUp>

//   ) : (
//     <CountUp    start={0} end={value} delay={0}>

//       {({ countUpRef }) => (

//         <div style={{display : "flex"}}> 
//           <span ref={countUpRef}  style={commonStyle}  />

//         <lable color={'#000000'} fontSize={15}> M </lable>
                    
    


//         </div>
      



//       )}

//     </CountUp>
//   );
// };

// export default NumberCount;


// const NumberCount = ({ value }) => {

    

//   return (

//     <CountUp start={0} end={1000} delay={0.27}>
//     {({ countUpRef }) => (
//       <div>
//         <span ref={countUpRef} />
//         <label> M </label>
//       </div>
//     )}
//   </CountUp>




//   );
// };

// export default NumberCount;







export const NumberCount = ({ value }) => {
    const commonStyle = {
      fontSize: '3em', // Adjust the font size as needed
  
      color: '#333', // Optional: set the text color
      fontFamily: 'Jost !important', // Optional: set the text color
    };
  
    const labelStyle = {
      fontSize: '4em', // Adjust the label font size as needed
      fontFamily: 'Jost  !important ',
      color: '#000', // Set the label text color
      marginLeft: '5px', // Add some space between the number and the label
      alignSelf: 'flex-end' // Align the label vertically with the number
    };
  
    return Number(value) === 0 ? (
      value
    ) : value.toString().length === 1 ? (
      <CountUp start={0} end={value} delay={0} >
        {({ countUpRef }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span ref={countUpRef} style={commonStyle} />
            <label style={{fontFamily  : "Jost' !important", fontSize:"3em"}}>M</label>
          </div>
        )}
      </CountUp>
    ) : (
      <CountUp start={0} end={value} delay={0}>
        {({ countUpRef }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span ref={countUpRef} style={commonStyle} />
            <label style={{fontFamily  : "Jost' !important" , fontSize : "3em"}}>M</label>
          </div>
        )}
      </CountUp>
    );
  };

//   export default NumberCount;



export const NumberCount2 = ({ value }) => {
    const commonStyle = {
      fontSize: '3em', // Adjust the font size as needed
      fontFamily: 'Jost  !important',
      color: '#333', // Optional: set the text color
    };
  
    const labelStyle = {
      fontSize: '4em', // Adjust the label font size as needed
      color: '#000', // Set the label text color
      marginLeft: '5px', // Add some space between the number and the label
      alignSelf: 'flex-end', // Align the label vertically with the number
      fontFamily: 'Jost  !important'

    };
  
    return Number(value) === 0 ? (
      value
    ) : value.toString().length === 1 ? (
      <CountUp start={0} end={value} delay={0} >
        {({ countUpRef }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span ref={countUpRef} style={commonStyle} />
            <label  style={{fontFamily  : "Jost' !important" , fontSize:"3em"}}>k</label>
          </div>
        )}
      </CountUp>

    ) : (
      <CountUp start={0} end={value} delay={0}>
        {({ countUpRef }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span ref={countUpRef} style={commonStyle} />
            <label style={{fontFamily  : "Jost' !important" , fontSize:"3em"}}>k</label>
          </div>
        )}
      </CountUp>
    );
  };
  