import './App.css';
import { Alert, AlertTitle, Box, CssBaseline, IconButton, ThemeProvider, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import Sidenav from './Sidenav';
import {theme} from './theme';
import { AlertToast, FullScreenContext, alertState } from './divine-constants';
import Router from './Router';
import Header from './Header';
import MainLayout  from './MainLayout';
import { Close } from '@mui/icons-material';
import { autoPlay } from 'react-swipeable-views-utils';
import Aos from "aos";
import "aos/dist/aos.css";
import Location from './components/Location';
import Notificationfire from "./components/Firebase/Notificationfire";






export default function App() {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    severity: "",
    show: false,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (alert.show) {
        setAlert({
          title: "",
          message: "",
          severity: "",
          show: false,
        });
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [alert.show]);

  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  // useEffect(() => {
  //   if (alert.show)
  //     setAlert({
  //       title: "",
  //       message: "",
  //       severity: "",
  //       show: false,
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);




  // useEffect(() => {
  //   <Location />
  // }, [])


  return (
    <ThemeProvider theme={theme}>

      <FullScreenContext.Provider value={{isFullScreen, setIsFullScreen}}>
        
        <Box className="App">
        <AlertToast.Provider value={{ alert, setAlert }}>

        {alert.show && (
              <Alert
                style={{
                  position: "sticky",
                  // marginInline: "auto",
                  zIndex: 10000,
                  top: 75,
                  maxWidth : 300,
                  marginLeft : "auto", 
                  marginRight : "auto", 
                  

                  // top: "4.5rem",
                  // left: "50%",
                  // transform: "translate(-50%, 0)",
                }}
                action={
                  <IconButton
                    size="small"
                    onClick={() => setAlert(alertState({}))}
                  >
                    <Close />
                  </IconButton>
                }
                severity={alert.severity}
              >
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.message}
              </Alert>
            )}
          <CssBaseline />
          {/* {!isFullScreen && <Header  state={[drawerIsOpen, setDrawerIsOpen]} />} */}
          {!isFullScreen && <Toolbar />}
          <Sidenav state={[drawerIsOpen, setDrawerIsOpen]} />
          
          <Router />


        </AlertToast.Provider>
        </Box>
       
      </FullScreenContext.Provider>
    </ThemeProvider>
  );

}


