// Footer.js
import React from 'react';
import { Box, Typography, Link, Grid, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#C5DCFF',
        
        // backgroundImage: 'linear-gradient(#49A3F1 , #2E373C)',
    
        color: 'white',
        padding: '20px 0',
        mt: 4,
      }}
    >
      <Container  >
        <Grid container spacing={4}>

                
        <Grid item xs={12} sm={3}>

<div>
        <img loading="" src="logo.svg" alt="flower logo" />
        <img
          style={{ marginBottom: 5, height: 25 }}
          src="logo_text.png"
          alt="text logo"
        />

   
      </div>
    
    {/* <Typography variant="h6" gutterBottom>
    Follow Us
    </Typography> */}

    <Box sx={{ display: 'flex', gap: '10px'  }}>
    <Link href="https://www.facebook.com" color="#017BFD" target="_blank">
        <FacebookIcon />
    </Link>
    <Link href="https://www.twitter.com" color="#017BFD" target="_blank">
        <TwitterIcon />
    </Link>
    <Link href="https://www.linkedin.com" color="#017BFD" target="_blank">
        <LinkedInIcon />
    </Link>
    <Link href="https://www.github.com" color="#017BFD" target="_blank">
        <GitHubIcon />
    </Link>
    </Box>
</Grid>

<Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom>
              Resources
            </Typography>
            <Link href="/about" color="inherit" underline="none">
              <Typography variant="body2">About Us</Typography>
            </Link>
            <Link href="/faq" color="inherit" underline="none">
              <Typography variant="body2">FAQ</Typography>
            </Link>
            <Link href="/contact" color="inherit" underline="none">
              <Typography variant="body2">Contact Us</Typography>
            </Link>
          </Grid>

          
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" sx={{letterSpacing : "1px"}} gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body2" sx={{letterSpacing : "1px"}} >
              Email: support@jobseeker.com
            </Typography>
            <Typography variant="body2" sx={{letterSpacing : "1px"}}>
              Phone: +1 (555) 123-4567
            </Typography>
            <Typography variant="body2" sx={{letterSpacing : "1px"}} >
              Address: 123 Job St, Job City, JS 12345
            </Typography>
          </Grid>



          <Grid item xs={12} sm={3}>

              <div>

<Typography variant="h6" gutterBottom>
About Us
</Typography>

<p style={{color:"black"}}>
At [Job Portal Name], we believe that finding the right job should be a seamless and rewarding experience. Our platform is dedicated to connecting job seekers with their dream careers by offering a vast array of opportunities across various industries and locations. Whether you're a seasoned professional or just starting out, we provide the tools and resources you need to take the next step in your career journey.
</p>
</div>
          
          </Grid>

 



       

    
        </Grid>

        



        <Box sx={{ mt: 4, textAlign: 'center', }}>
          <Typography variant="body2" fontWeight={"bold"} color="#017BFD">
            &copy; {new Date().getFullYear()} Job Seeker. All rights reserved.
          </Typography>
        </Box>
        
      </Container>
    </Box>
  );
};

export default Footer;
