import { Paper, Toolbar, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, FormControlLabel, Chip, Grid, Radio, Typography, Stack, TextField, Autocomplete, Container, MenuItem, Divider } from "@mui/material";
import Checkbox from '@mui/material/Checkbox'
import { AlertToast,alertState } from "../../divine-constants";

import { Fragment, useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { add_certificate, basicdetails, edit_certificate, edit_user_prefer, get_profile } from "../Axiosfetch/Axiosfetch";
import{useContext} from "react"
import { textValidation } from "../../utils";
import { DotSeparator, LibDateField, LibTextField } from "../../components/LibComponents";
import { theme } from "../../theme";
import Deletemodel from "../../Deletemodel";
import download from "../../images/download.png";
import edit from "../../images/edit.png";
import file from "../../images/file.png";
import bin from "../../images/delete.png";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@mui/icons-material";

export default function Certification({ formData, control , setValue }) {
    console.log(formData,"formDataceritification")
	const [dialogOpen, setDialogOpen] = useState(false);
	const theme = useTheme();
    const [modalDatas,setModalDatas] = useState()

    // const [datas, setDatas] = useState(formData);
    // console.log(datas,"datas1")

    // const [formdatas, setFormdata] = useState( formData.filter((item) => item.certification_name != "" ));
    
    // const [janavalues, setJanaValues] = useState(formData);
    // console.log(formdatas,"PPPPPP")


   
    // const ak = Object.values(formdatas)
    // console.log(ak,"fssssssss")

    // const filteredArray = formData.filter(obj => {
    //     // Check if any property of the object has a non-empty value
    //     return Object.values(obj).some(value => value !== '');
    // });
    // console.log(filteredArray,"filteredArray")

    // const tryMe = () => {
    //     formData.filter((item) => item.certification_name != "" ) 
    // }

    // const result = tryMe()
    // useEffect(() => {
    //     console.log( formdatas,"formDatassss")

    //     // console.log( formData.filter((item) => item.certification_name != "" ),"result")

    // },[setFormdata]) 






	const [operation, setOperation] = useState();
	const [clickedRow, setClickedRow] = useState(); 

	const dialogClose = () => {
		setDialogOpen(false);
	}

    function HandleDelete() {
        setOperation("delete");
        setDialogOpen(true);
      }

    function HandleAdd (){
		setDialogOpen(true);
		setOperation('add');
	}
    // function HandleEdit (arg){
	// 	setDialogOpen(true);
	// 	setOperation('edit');
	// }

    function HandleEdit(arg) {
        console.log(HandleEdit)
        setDialogOpen(true);
        setOperation("edit");
        setModalDatas(arg)
      }

    const handleDelete = async () => {
		try {
			console.log('Project deleted successfully');
			setDialogOpen(false);
		} catch (error) {
			console.error('Error deleting project:', error);
		}
	};
	

	return(
        <>
		 {/* <Deletemodel
            dialogDisplay={dialogOpen}
            setFormdatas = {setFormdatas}
            setDialogDisplay={setDialogOpen}
            candidate_percentage={candidate_persen}
            url={"candidate/delete_certificate/"}
            data={{ id: clickedRow ? clickedRow.id : null }}
            handleDelete={handleDelete} // Pass the handleDelete function   
            
        /> */}

        
        {/* <PersonalDetailsDialog state={[dialogOpen, setDialogOpen]} operation={operation} datas={formData} control={control} setFormdatas = {setFormdatas} dialogClose={dialogClose} dialogOpen={dialogOpen} /> */}
        <Paper sx={{p:2}}>
                <Box sx={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}}>
                    <Button onClick={HandleAdd} variant="text">Add Certification </Button>
                </Box>
            <Container>
                {formData.length  ? (formData.map((Certification, index) => (
                    
                    <section key={index}>
                    <Grid container>
                                <Grid item>
                                
                                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                                    <Typography variant="subtitle1" fontWeight={800} >{Certification.certification_name} </Typography>
                                    
                                        <Toolbar>
                                            <div style={{ marginLeft: 'auto' }}> 
                                                <Tooltip title="Edit this section">
                                                    <IconButton onClick={() => { HandleEdit(Certification) }}>
                                                        <img style={{width:'14px',height:'14px'}} src={edit}/>
                                                    </IconButton >
                                                </Tooltip>
                                                <Tooltip title="Delete this section">
                                                    <IconButton onClick={() =>{ setClickedRow(Certification); HandleDelete() }}>
                                                    <img style={{width:'14px', height:'14px'}} src={bin}/> 
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Toolbar>

                                    </Box>

                                    <Box display={"flex"} alignItems={"center"}>
                                    <Typography variant="subtitle2"> Client :</Typography>
                                    <Typography variant="body1"> {Certification.Certificate_Provider}</Typography>
                                    </Box>
                                    
                                    

                                    {/* <Box display={"flex"} alignItems={"center"}>
                                    <Typography variant="subtitle2"> Project Status :</Typography>
                                    <Typography variant="body1"> {Certification.Project_status}</Typography>
                                    
                                
                                    </Box> */}

                                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                                        <Typography variant="subtitle2" fontWeight={500} > Date </Typography>
                                        <DotSeparator />

                                        <Typography variant="body1">
                                            {Certification.start_date} To {Certification.end_date}
                                        </Typography>


							        </Box>
                                    
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography variant="subtitle2"> Certificate ID : </Typography>
                                        <Typography variant="body1"> { Certification.certification_id }</Typography>
                                    </Box>
                                 
                                  
                                </Grid>
                            </Grid>
                            <Box>
                                <Chip
                                icon={<LinkOutlined />}
                                label={<a href={Certification.certification_url} aria-label="Link to project">{Certification.certification_url}</a>}
                            />
                            </Box>
                </section>
                ))):
                
                (
					<Typography variant="h3" textAlign="center">
						No Certification specified!
					</Typography>
				// )(
                //     <Grid sx={{p:2}} container spacing={2}>
                //         <Grid item xs={12} sm={12} md={12} >
                //             <Paper sx={{padding:3, backgroundColor:'#f5f6f7',  mt:2}}>
                //             <Typography sx={{mb:2}} fontSize={30} fontWeight={'bold'}> No Certification specified </Typography>
                            
                //             </Paper>
                //         </Grid>
                // </Grid>)
                )}
                    <Deletemodel
                        dialogDisplay={dialogOpen}
                        setDialogDisplay={setDialogOpen}
                        url={"candidate/delete_certificate/"}
                        data={{ id: clickedRow ? clickedRow.id : null }}
                        handleDelete={handleDelete} // Pass the handleDelete function
                        setValue={setValue}
                        
                    />

                    <PersonalDetailsDialogss 
                        state={[dialogOpen, setDialogOpen]} 
                        operation={operation} 
                        datas={modalDatas} 
                        control={control}
                        dialogClose={dialogClose}
                        dialogOpen={dialogOpen}
                        setValue={setValue}
                         />
                
            </Container>
        
        </Paper>
	    </>
    ) 
}

function PersonalDetailsDialogss({state, dialogClose, dialogOpen ,setValue, datas , operation}) {
	const { setAlert } = useContext(AlertToast);
    console.log(datas,"datas123")
      
    const { reset ,  control, handleSubmit, setValue: setFormValue } = useForm();

    const [formDatas, setFormDatas] = useState({
        id : "",
        certification_name : "",
        certification_id : "",
        certification_url : "",
        Certificate_Provider : "" ,
        start_date : "" ,
        end_date : "",
      });
    
  
    useEffect(() => {
        if (datas) {
            setFormDatas({
                id : datas.id || "",
                certification_name: datas.certification_name || '',
                certification_id: datas.certification_id || "",
                certification_url: datas.certification_url || "",
                Certificate_Provider: datas.Certificate_Provider || "",
                start_date : datas.start_date || "",
                end_date : datas.end_date || "",
          
            })


        }
    }, [datas]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
	
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };
  
   
    const handleEdits = async (e) => {
        e.preventDefault();
  
      const workdata = {
        id : datas.id,
        certification_name : formDatas.certification_name,
        certification_id : formDatas.certification_id,
        certification_url : formDatas.certification_url,
        Certificate_Provider : formDatas.Certificate_Provider,
        start_date : formDatas.start_date,
        end_date :formDatas.end_date,
        };
  
        try {
            const response = await edit_certificate(workdata);
            if (response.status === 200) {
                dialogClose();
                const profileResponse = await get_profile();
                if (profileResponse.status === 200) {
                    setValue("Certifications",profileResponse.data.certification);
                }
                setAlert({
                    message: "Work Experience updated successfully",
                    severity: "success",
                    show: true
                });
                reset()
            } else {
                throw new Error('Failed to update work experience');
            }
        } catch (error) {
            setAlert({
                message: error.message,
                severity: "error",
                show: true
            });
        }
    };

    const handleAddWorkExperience = async (adddatas) => {
        try {
          const { status , data  } = await add_certificate(adddatas);

          if (status === 200) {
            const response = await get_profile(); // Call your API function to fetch profile data

            if (response.status === 200) {
                setValue("Certifications",response.data.certification);
                // setValue(response.data.certification)
                setAlert(alertState({
                    message: "Certification added successfully",
                    severity: "success",
                    show: true
                }));
                reset()
                dialogClose();

            }

            }else if (status === 400) {
    
                setAlert(alertState({
                    message: "data already added ",
                    severity: "error",
                    show: true
                }));
                reset()
                dialogClose();
            }else {
                throw new Error('Invalid input: The value must be a positive number');
                
            }
            } catch (error) {
          
                setAlert(alertState({
                    message: error.message,
                    severity: "error",
                    show: true
                }));
              }
        
    }



    
//   const handleEdits = async (e) => {
//     e.preventDefault();

//     const formData = {

//         id : datas.id,
//         certification_name : formDatas.certification_name,
//         certification_id : formDatas.certification_id,
//         certification_url : formDatas.certification_url,
//         Certificate_Provider : formDatas.Certificate_Provider,
//         start_date : formDatas.start_date,
//         end_date :formDatas.end_date,  
//     };
// }



if (operation === 'add'){
    return (
        <Dialog fullWidth={true}
        maxWidth={'sm'} open={dialogOpen} onClose={dialogClose}>
            <DialogTitle>
                <Box display={"flex"} alignItems={"center"}>
                    Certifications <img style={{width:15, height:15, marginLeft:'auto'}} src={edit}/>
                </Box>
                <Typography color={'#00000080'} >Add information about the certifications you have earned or completed.</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleAddWorkExperience)} >
                <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                      gap={0 + ' ' + theme.spacing(3)} sx={{
                          [theme.breakpoints.up("sm")]: {
                              gridTemplateColumns: "repeat(2, 1fr)",
                          }
                      }}>
                        <LibTextField
                          control={control}
                          name={"Certification_name"}
                          rules={textValidation("Certification Name")}
                          textfieldProps={{
                              label: "Certification Name"
                          }}
                      />
                        <LibTextField
                          control={control}
                          name={"Certificate_Provider"}
                          rules={textValidation("Certificate Provider")}
                          textfieldProps={{
                              label: "Certificate Provider"
                          }}
                      />

                    <LibTextField
                          control={control}
                          name={"certification_url"}
                  
                          rules={textValidation("Certification_URL")}
                          textfieldProps={{
                              label: "Certification URL"
                          }}
                      />

                    <LibTextField
                          control={control}
                          name={"certification_id"}
                          
                          rules={textValidation("Certification_completion_ID")}
                          textfieldProps={{
                              label: "Certification Completion ID"
                          }}
                      />

 
                    <Typography sx={{mb:1}} color="#000000">Course Duration</Typography>
                    <Box></Box>

                      <LibDateField
                          control={control}
                          name={"start_date"}
                   
                          textfieldProps={{
                              label: "Start Date",
                              fullWidth: true,
                          }}
                      />
                      <LibDateField
                          control={control}
                          name={"end_date"}
                 
                          textfieldProps={{
                              label: "End Date",
                              fullWidth: true,
                          }}
                      />
                  </Box>
                    
                    <DialogActions>
                        <Button onClick={ () =>{ dialogClose(); reset(); } } variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Add </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>  
	)
}
else if (operation === 'edit'){
    return (
        <Dialog fullWidth={true}
        maxWidth={'sm'} open={dialogOpen} onClose={dialogClose}>
            <DialogTitle>
                <Box display={"flex"} alignItems={"center"}>
                    Certifications <img style={{width:15, height:15, marginLeft:'auto'}} src={edit}/>
                </Box>
                <Typography color={'#00000080'} >Add information about the certifications you have earned or completed.</Typography>
            </DialogTitle>
            <DialogContent>
           
                  <form onSubmit={handleEdits}  > 
                  <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                      gap={0 + ' ' + theme.spacing(3)} sx={{
                          [theme.breakpoints.up("sm")]: {
                              gridTemplateColumns: "repeat(2, 1fr)",
                          }
                      }}>                           
                            <TextField
                                name="certification_name"
                                value={formDatas.certification_name }
                                onChange={handleInputChange}
                                label="Certification Name"
                                fullWidth
                            />
    
                        
                            <TextField
                                name="Certificate_Provider"
                                value={formDatas.Certificate_Provider}
                                onChange={handleInputChange}
                                label="Certificate Provider"
                                fullWidth
                            
                            />
                
                            <TextField
                                name="certification_url"
                                value={formDatas.certification_url}
                                onChange={handleInputChange}
                                label="Certification Url"
                                fullWidth
                        
                            />
                         

                            <TextField
                                name="certification_id"
                                value={formDatas.certification_id}
                                onChange={handleInputChange}
                                label="Certification Id"
                                fullWidth
                            
                            />
                            <Typography sx={{mb:1}} color="#000000">Course Duration</Typography>
                            <Box></Box>

                            <TextField
                                type="date"
                                name="start_date"
                                value={formDatas.start_date || ''}
                                onChange={handleInputChange}
                                label="Start Date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                type="date"
                                name="end_date"
                                value={formDatas.end_date || ''}
                                onChange={handleInputChange}
                                label="End Date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
    
                            {/* <LibDateField
                                control={control}
                                name={"start_date"}
                                defaultValue={datas.start_date || ''}
                                textfieldProps={{
                                    label: "Start Date",
                                    fullWidth: true,
                                }}
                            /> */}
{/*                                                         
                            <LibDateField
                                control={control}
                                name={"end_date"}
                                defaultValue={datas.end_date || ''}
                                textfieldProps={{
                                    label: "End Date",
                                    fullWidth: true,
                                }}
                
                /> */}
                </Box>
                                
                    <DialogActions>
                        <Button onClick={() =>{ dialogClose(); reset(); }} variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Update</Button>
                    </DialogActions>
                
                </form>
            </DialogContent>
            
        </Dialog>  
	)
}
	
}


 {/* <form onSubmit={handleSubmit(handleUpdate)} >
                <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                      gap={0 + ' ' + theme.spacing(3)} sx={{
                          [theme.breakpoints.up("sm")]: {
                              gridTemplateColumns: "repeat(2, 1fr)",
                          }
                      }}>
                        <LibTextField
                          control={control}
                          name={"Certification_name"}
                          value={formDatas.Certification_name || ''}
                          onChange={handleInputChange}
                          rules={textValidation("Certification Name")}
                          textfieldProps={{
                              label: "Certification Name"
                          }}
                      />
                        <LibTextField
                          control={control}
                          name={"Certificate_Provider"}
                   
                          value={formDatas.Certificate_Provider || ''}
                          rules={textValidation("Certificate Provider")}
                          textfieldProps={{
                              label: "Certificate Provider"
                          }}
                      />

                    <LibTextField
                          control={control}
                          name={"certification_url"}
                          value={formDatas.certification_url || ''}
                          rules={textValidation("Certification_URL")}
                          textfieldProps={{
                              label: "Certification URL"
                          }}
                      />

                    <LibTextField
                          control={control}
                          name={"certification_id"}
                          value={formDatas.certification_id || ''}
                          rules={textValidation("Certification_completion_ID")}
                          textfieldProps={{
                              label: "Certification completion ID"
                          }}
                      />

                    <Typography sx={{mb:1}} color="#000000">Course Duration</Typography>
                    <Box></Box>

                      <LibDateField
                          control={control}
                          name={"start_date"}
                          value={formDatas.start_date || ''}
                          textfieldProps={{
                              label: "Start Date",
                              fullWidth: true,
                          }}
                      />
                      <LibDateField
                          control={control}
                          name={"end_date"}
                          value={formDatas.end_date || ''}
                          textfieldProps={{
                              label: "End Date",
                              fullWidth: true,
                          }}
                      />
                  </Box> */}



{/* <input
                                    type="date"
                                    name="end_date"
                                    value={formDatas.end_date || ''}
                                    onChange={(e) => {
                                        // Handle change if necessary
                                    }}
                                    style={{ width: "100%" }}
                                    required
                                /> */}


                        {/* <DialogActions>
                            <Button onClick={dialogClose} variant="text">Cancel</Button>
                            <Button type="submit"  variant="text">Update</Button>
                        </DialogActions>
                    </form> */}


                        {/* <input
                                    type="date"
                                    name="start_date"
                                    value={formDatas.start_date || ''}
                                    onChange={(e) => {
                                        // Handle change if necessary
                                    }}
                                    style={{ width: "100%" }}
                                    required
                                /> */}