import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { add_notification } from "../../pages/Axiosfetch/Axiosfetch";

const firebaseConfig = {
  apiKey: "AIzaSyD2W4KyDo11pjOwllE9-e9qXREnUKCHdGQ",
  authDomain: "job-portal-7adf5.firebaseapp.com",
  projectId: "job-portal-7adf5",
  storageBucket: "job-portal-7adf5.appspot.com",
  messagingSenderId: "300968442740",
  appId: "1:300968442740:web:e3b02778c766ab2f858a21",
  measurementId: "G-TMW4HW10NJ",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    const permission = Notification.permission;

    // if (permission === "denied") {
    //   const permissionResult = await Notification.requestPermission();
    //   console.log("permissionResult", permissionResult)

    //   // alert(
    //   //   "Notifications have been blocked. Please enable them in your browser settings."
    //   // );
    //   // return;

    // }

    const local_get_item = localStorage.getItem("notify");

    // if (!local_get_item) {
      try {
        const permissionResult = await Notification.requestPermission();

        if (permissionResult === "granted") {
          localStorage.setItem("notify", "granted");

          console.log("User permission granted");
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BKZwyooUmJRacehzoLPhftT6ghWWMuwlQpoHbUliTDlCrxXU6jWC42UetCszy7nIFuVSPGAHCCtrKaZHRDUT2hY",
          });
          console.log(currentToken);
          if (currentToken) {
            try {
              // Make API call here using fetch or axios
              const payloaddata = { token: currentToken };

              const response = await add_notification(payloaddata);

              if (response.status === 200) {
              }
            } catch (error) {
              // Handle error condition
            }

            console.log("FCM Token:", currentToken);

            // Send this token to your backend to save it
          } else {
            console.error("Failed to generate the app registered token");
          }
        } else {
          localStorage.setItem("notify", "denied");
          console.log("User permission denied");
        }
      } catch (error) {
        console.error("An error occurred while requesting permission", error);
      }
    // }
  } catch (error) {
    console.log(error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    onMessage(
      messaging,
      (payload) => {
        console.log(payload);
        resolve(payload);
      },
      (error) => {
        reject(error);
      }
    );
  });

console.log(onMessageListener());
