import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Container, Divider, Grid, Stack } from "@mui/material";
import { Margin } from "@mui/icons-material";
import placementbanner from "../images/placementbanner.png";
import placementbanner2 from "../images/placementbanner2.png";
import partnersbanner1 from "../images/partnersbanner1.png";
import partnersbanner2 from "../images/partnersbanner2.png";
import placementbanner3 from "../images/placementbanner3.png";
import placementtabbanner from "../images/placementtabbanner.png";
import placementmobilebanner from "../images/placementmobilebanner.png";
import placementbannermobile from "../images/placementbannermobile.png";
import LaunchIcon from "@mui/icons-material/Launch";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import "./PlacementStyle.css";

import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import LazyLoad from "react-lazyload";
import { useFormState } from "react-hook-form";
import { partner_list } from "./Axiosfetch/Axiosfetch";
import { useNavigate } from "react-router-dom";





const maxLength = 20;

const TruncatedInstituteNames = ({ institute }) => {


    const maxLength = 20;
    const truncatedName = institute.institute_name.length > maxLength 
      ? `${institute.institute_name.slice(0, maxLength)}...` 
      : institute.institute_name;
  
    return (
      <Typography
        component="div"
        variant="p"
        sx={{
          fontSize: { md: 20, sm: 17.5, xs: 15 },
          textAlign: "start",
          fontWeight: "bold",
          ml: 2,
          color: "var(--primary)",
        }}
      >
        {truncatedName}
      </Typography>
    );
  };
  






const TruncatedInstituteNames1 = ({ institute1 }) => {


    console.log(institute1.about,"institute.about.")

    const maxLength1 = 200;

    const truncatedName1 = institute1.our_services ?.length > maxLength1 
      ? `${institute1.our_services.slice(0, maxLength1)}...` 
      : institute1.our_services;
  
    return (
        <Typography
        component="div"
        variant="p"
        sx={{
          fontSize: { md: 13, sm: 12.5, xs: 10 },
          fontWeight: "400",
          my: 1,
          textAlign: "start",
        }}
      >
        {truncatedName1}
      </Typography>
    );
  };
  




  





 

export default function Placementcom() {
  const [institutes, setInstitutes] = useState([]);
  const navigate = useNavigate();
  const maxLength = 20;

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await partner_list();

        if (response && response.data && Array.isArray(response.data)) {
          setInstitutes(response.data);
        } else {
          throw new Error(`Something went wrong ${response.data.message}`);
        }
      } catch (error) {}
    };

    fetchdata();
  }, []);


  

  return (
    // , position: "relative"
    // position: "absolute" ,

    <>

  
      <div className="banner-container">
     
        <img
          src={placementbanner}
          alt="banner"
          className="lap"  
          loading="lazy"      
        />
   
        <img
          src={placementtabbanner}
          alt="banner"
          className="tab"
          loading="lazy"    
        

        />


        <img
          src={placementbannermobile}
          alt="banner"
          className="mobile"
          loading="lazy"    
          style={{ filter: "brightness(50%)" }}

        />





        <Box
          sx={{
            position: "absolute",
            top: {md : "50px"  ,  sm : "50px" , xs: "30px" }, 

            px : { md : 5 , xs:"20px"},

            width: { md: "60%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={2}
            direction={{ md: "row", sm: "row", xs: "column" }}
            alignItems={{ md: "center" }}
            mt={1}
          >
            <Button
              variant="contained"
              className="btn-alt"
              sx={{
                fontSize: {md : "0.65rem" , sm: "0.60rem", xs: "0.50rem" },
                borderRadius: "10px",
                border: "1px soild #cdcdcd !important",
                marginBottom: "20px !important",
                paddingLeft : "5px",
                paddingRight : "5px",
            
                backgroundColor: "transparent",

                textTransform: "none",
                width : { md : "30%" , sm: "40%",  xs : "50%" , }
              }}
            >
              Guaranteed placement
              <span style={{ marginLeft: "0.5rem" }}>
                <AutoAwesomeIcon />
              </span>
            </Button>
          </Stack>

          <Typography
            component="div"
            variant="h3"
            sx={{
              fontSize: { md: "2.5rem", sm: "1.25rem", xs: "1.75rem" },
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            <span style={{ color: "#FBD119" }}>Unlimited</span> Interviews until
            you get Placed!
          </Typography>

          <Typography
            component="div"
            variant="h3"
            sx={{
              fontSize: { md: "1.5rem", sm: "1rem", xs: "1rem" },
              mt: "25px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            Get confirmed ₹40,000 total stipend
          </Typography>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <Stack
              direction="row"
              sx={{
                border: "1px solid #cdcdcd85",
                alignItems: "center",
                borderRadius: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginRight: "15px",
              }}
            >
              <WorkspacePremiumIcon sx={{ color: "#FBD119", ml: "5px" }} />

              <Button
                variant="contained"
                sx={{
                  background: "transparent",
                  my: "5px",
                  paddingLeft: "1px",
                }}
              >
                {" "}
                4.5/5
              </Button>
            </Stack>

            <Stack
              direction="row"
              sx={{
                border: "1px solid #cdcdcd90",
                alignItems: "center",
                borderRadius: "20px",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginRight: "15px",
               
              }}
            >
              <LocalLibraryIcon sx={{ color: "#FBD119" }} />

              <Button variant="contained" sx={{ background: "transparent" }}>
                {" "}
                Learn More
              </Button>
            </Stack>
          </div>
        </Box>
      </div>

      <Box>
        <Typography
          component="div"
          variant="h3"
          className="btn btn-anim"
          sx={{
            fontSize: { md: "2.5rem", sm: "2.25rem", xs: "1.75rem" },
            fontWeight: "bold",
            textAlign: "center",
            mt: 3,
            marginBottom: "20px !important",
          }}
        >
          {/* Get <span className='highlight-pharse'>NitoPhone</span> for all platforms . */}
          Choose for{" "}
          <span style={{ color: "#017BFD" }}>Career Advancement </span>
        </Typography>

        <Stack
          direction={{ md: "row", sm: "row", xs: "column" }}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          sx={{flexWrap : "wrap"}}
         
        >

            
      

          {institutes.map((institute, index) => (

         
            <Box
              key={index}
              onClick={() =>{navigate("/View_placement", { state: { ...institute } })}}

              sx={{
                width: { md: "30%", sm: "30%", xs: "90%" },
               
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "var(--primary-fade)",
                textAlign: "center",
                mt: 3,
                mb: 6,
                px: 1,

                ":hover": {
                    boxShadow: "0px 0px 10px #cdcdcd",
                    transform: "scale(1.010)",
                  },
                  cursor: "pointer",
                    transition: "0.2s ease-in-out",
              
             
                  border: "0.5px solid #cdcdcd",
                
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  mt: "10px",
                  justifyContent: "flex-start",
                }}
              >

            <img width={48} src={institute.institute_logo} alt="dummy" />

                       
            <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{marginLeft : "10px" , marginTop : "0px" , marginBottom : "0px"}}
                      />

                <div>

       

     


                <TruncatedInstituteNames institute={institute} />



                 
                <Stack  direction="row"  >
                  <Typography
                    component="div"
                    variant="p"
                    sx={{
                      fontSize: { md: 13, sm: 12.5, xs: 10 },
                      textAlign: "start",
                      ml: 2,
                      mt: "0px !important",
                      fontWeight: "400",
                    }}
                  >

                    {institute.partner_name}
               
                  </Typography>

                  <a href={institute.company_website_link}  target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: 'inherit' }}>
                        <LaunchIcon color="primary" sx={{ fontSize: 17, marginLeft: 1 }} />
                      </a>

                   </Stack>

             

                </div>
           
              </Box>

              <Divider sx={{ marginTop: "10px", mb: "10px" }} />

              <Typography
                component="div"
                variant="p"
                sx={{
                  fontSize: { md: 15, sm: 12.5, xs: 10 },
                  fontWeight: "500",
                  my: 1,
                  textAlign: "start",
                }}
              >
                Our services
              </Typography>

       

                <TruncatedInstituteNames1  institute1={institute}/>

             
   

              {/* <img src={windows} alt='desktop' className='desktop-image' /> */}

              <Divider sx={{ marginTop: "10px"}} />

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack>
                  <Typography
                    component="div"
                    variant="p"
                    sx={{
                      fontSize: { md: 15, sm: 12.5, xs: 10 },
                      fontWeight: "500",
                      mt: 1,
                    }}
                  >
                    Subscription starts from
                  </Typography>
                  <Stack direction="row">
                    <CurrencyRupeeIcon sx={{ width: "10%" }} />
                    <Typography
                      component="div"
                      variant="p"
                      sx={{
                        fontSize: { md: 13, sm: 12.5, xs: 10 },
                        textAlign: "start",
                        color: " black",
                        fontWeight: "500",
                      }}
                    >

                    {Number(institute.price).toLocaleString("en-IN")}
                     
                    </Typography>

                    {/* <Typography
                      component="div"
                      variant="p"
                      sx={{
                        fontSize: { md: 13, sm: 12.5, xs: 10 },
                        textAlign: "start",
                        color: " black",
                        marginLeft: "5px",
                        fontWeight: "500",
                      }}
                    >
         
                    </Typography> */}

                  </Stack>
                </Stack>

                <Button variant="contained" sx={{ my: 1 ,  backgroundImage : "linear-gradient(#24282A97 , #0A79C9)" }}>
                  {" "}
                  
                  More Details

                </Button>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
}

// <div style={{ marginBottom: "20px" }}>
//   <img
//     src={placementbanner}
//     alt="placementbanner"
//     style={{ width: "100%", height: "80%" }}
//   />

//   <Grid
//     item
//     xs={12}
//     sx={{
//       top: 100,
//       left: 55,
//       position: "absolute",
//       textAlign: "center",
//       width: "auto",
//     }}
//   >
//     <div style={{ display: "flex" }}>
//       <Button
//         variant="contained"
//         className="btn-alt"
//         sx={{
//           borderRadius: "10px",
//           border: "1px soild #cdcdcd !important",
//           marginBottom: "20px !important",

//           backgroundColor: "transparent",

//           textTransform: "none",
//         }}
//       >
//         Guaranteed placement
//         <span style={{ marginLeft: "0.5rem" }}>
//           <AutoAwesomeIcon />
//         </span>
//       </Button>

//       <Button
//         variant="contained"
//         sx={{
//           borderRadius: "10px",
//           border: "1px soild #cdcdcd !important",
//           height: "100%",
//           paddingLeft: "15px",
//           paddingRight: "15px",
//           paddingTop: "8px",
//           paddingBottom: "8px",
//           marginLeft: "20px",
//           backgroundColor: "#ffffff30 !important",

//           //   backgroundImage : "linear-gradient(#24282A , #0A79C9)"
//         }}
//       >
//         placement
//       </Button>
//     </div>

//     <Typography
//       fontWeight="bold"
//       sx={{
//         fontSize: 30,
//         fontFamily: "Satoshi",
//         color: "white",
//       }}
//     >
//       {/* <span style={{ color: '#FBD119' }}> Data Science </span> Course with Guaranteed Placement  */}
//       <span style={{ color: "#FBD119" }}>Unlimited</span> Interviews until you
//       get Placed!
//       {/* Unlimited Interviews until you get Placed! */}
//     </Typography>

//     <Typography
//       fontWeight="bold"
//       sx={{
//         fontSize: 30,
//         fontFamily: "Satoshi",
//         color: "white",
//         textAlign: "left",
//       }}
//     >
//       Placement
//     </Typography>

//     <div style={{ display: "flex" }}>
//       <Typography variant="body2" color="text.secondary">
//         At Veranda, we believe that world-class education and learning should
//         ... Support (Veranda RACE). USEFUL LINKS. All Courses · Government Exams
//         · UPSC Exams.
//       </Typography>
//     </div>
//   </Grid>

//   <div style={{ top: "-7px", left: "240px", top: "20%" }}>
//     <Container maxWidth="lg">
//       <Box sx={{ marginTop: "40px", textAlign: "center" }}>
//         <Typography
//           fontWeight="bold"
//           sx={{
//             fontSize: 25,

//             fontFamily: "Satoshi",
//             color: "Black",
//           }}
//         >
//           {/* Find the Ideal Institute to Propel Your Career   */}
//           Choose for{" "}
//           <span style={{ color: "#017BFD" }}>Career Advancement </span>
//         </Typography>
//       </Box>

//       <Grid container spacing={2} sx={{ marginTop: "10px" }}>
//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 20px #cdcdcd",
//                 transform: "scale(1.010)",
//               },

//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             <CardContent sx={{ padding: "10px" }}>
//               <div style={{ display: "flex", marginBottom: "10px" }}>
//                 <img
//                   width={50}
//                   // height = {50}

//                   src={partnersbanner1}
//                   alt="dummy"
//                   style={{ marginRight: "10px" }}
//                 />

//                 <Divider
//                   orientation="vertical"
//                   variant="middle"
//                   flexItem
//                   sx={{
//                     marginRight: "10px",
//                     marginBottom: "0px",
//                     marginTop: "0px",
//                   }}
//                 />

//                 <Typography gutterBottom variant="h4" sx={{ margin: "0px" }}>
//                   Veranda
//                 </Typography>
//               </div>

//               <Divider />

//               <br />

//               <Typography variant="body2" color="text.secondary">
//                 At Veranda, we believe that world-class education and learning
//                 should ... Support (Veranda RACE). USEFUL LINKS. All Courses ·
//                 Government Exams · UPSC Exams.
//               </Typography>
//             </CardContent>

//             <Divider />

//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="Black">
//                   $20,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Learn More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 25px #cdcdcd",
//                 transform: "scale(1.010)",
//               },
//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             {/* <img src={partnersbanner1} alt="Hlo" style={{ width: "100%" }} /> */}

//             <CardContent>
//               <Typography gutterBottom variant="h4" component="div">
//                 FITA Academy
//               </Typography>
//               <Divider />

//               <br />
//               <Typography variant="body2" color="text.secondary">
//                 FITA (Focus’d IT Academy) is a leading Training and Placement
//                 company managed by IT veterans with more than a decade of
//                 experience in leading MNC companies.
//               </Typography>
//             </CardContent>
//             <Divider />

//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   $10,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Learn More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 25px #cdcdcd",
//                 transform: "scale(1.010)",
//               },
//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             {/* <img src={partnersbanner1} alt="Hlo" style={{ width: "100%" }} />  */}

//             <CardContent>
//               <Typography gutterBottom variant="h4" component="div">
//                 SLA Institute
//               </Typography>

//               <Divider />

//               <br />

//               <Typography variant="body2" color="text.secondary">
//                 SLA is the Best Software Training Institute in Chennai for its
//                 Prominent IT Training and Placement Institute steered by IT
//                 experts with 100% placement guidance.
//               </Typography>
//             </CardContent>

//             <Divider />

//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   $40,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Know More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>

//       <Grid container spacing={2} sx={{ marginTop: "10px" }}>
//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 20px #cdcdcd",
//                 transform: "scale(1.010)",
//               },

//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             {/* <img src={partnersbanner2} alt="Hlo" style={{ width: "100%" }} /> */}

//             <CardContent>
//               <Typography gutterBottom variant="h5" component="div">
//                 Veranda
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 At Veranda, we believe that world-class education and learning
//                 should ... Support (Veranda RACE). USEFUL LINKS. All Courses ·
//                 Government Exams · UPSC Exams.
//               </Typography>
//             </CardContent>
//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack spacing={1}>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   $20,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Learn More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 25px #cdcdcd",
//                 transform: "scale(1.010)",
//               },
//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             {/* <img src={partnersbanner1} alt="Hlo" style={{ width: "100%" }} /> */}

//             <CardContent>
//               <Typography gutterBottom variant="h5" component="div">
//                 FITA Academy
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 FITA (Focus’d IT Academy) is a leading Training and Placement
//                 company managed by IT veterans with more than a decade of
//                 experience in leading MNC companies.
//               </Typography>
//             </CardContent>
//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack spacing={1}>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   $10,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Learn More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Card
//             sx={{
//               maxWidth: 345,
//               ":hover": {
//                 boxShadow: "0px 0px 25px #cdcdcd",
//                 transform: "scale(1.010)",
//               },
//               cursor: "pointer",
//               mb: 2,
//               transition: "0.2s ease-in-out",
//               // width : "650px",

//               border: "0.5px solid #cdcdcd",
//             }}
//           >
//             {/* <img src={partnersbanner1} alt="Hlo" style={{ width: "100%" }} />  */}

//             <CardContent>
//               <Typography gutterBottom variant="h5" component="div">
//                 SLA Institute
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 SLA is the Best Software Training Institute in Chennai for its
//                 Prominent IT Training and Placement Institute steered by IT
//                 experts with 100% placement guidance.
//               </Typography>
//             </CardContent>
//             <CardActions
//               sx={{ display: "flex", justifyContent: "space-between" }}
//             >
//               <Stack spacing={1}>
//                 <Typography variant="h5" color="text.secondary">
//                   Subscription starts from
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   $40,999 For 1 Month
//                 </Typography>
//               </Stack>

//               <Button size="small" variant="text">
//                 Know More
//               </Button>
//             </CardActions>
//           </Card>
//         </Grid>
//       </Grid>
//     </Container>
//   </div>
// </div>;
