import { Route, Routes } from "react-router-dom";
import Registration from "./pages/Registration";
import Jobs from "./pages/Jobs";
import Internships from "./pages/Internships";
import Details from "./pages/Details";
import UserProfile from "./pages/user_profile/UserProfile";
import FormDialog  from "./pages/OTPverify";
import UserLogin from "./pages/UserLoginPage";
import HomePage from "./HomePage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Header from "./Header";
import ViewJobDetail from "./pages/ViewJobDetails";
import { ApplyJob } from "./pages/ApplyJob";
import AppliedJobHistory from "./pages/AppliedJobHistory";
import RecomendedJobs from "./pages/RecomendedJobs";
import BookMarks from "./pages/BookMarks";
import MyPreferences from "./pages/MyPreferences";
import PageNotFound from "./pages/PageNotFound";
import Placement from "./pages/Placement";
import ViewPlacement from "./pages/ViewPlacement";
import ViewForm from "./pages/user_profile/ApplyForm/ViewForm";
import OverView from "./pages/user_profile/ApplyForm/OverView";

import MainLayout from './MainLayout';



export default function Router() {

	return (
		<Routes>
			<Route path="/ViewForm" element={<ViewForm />} />
			<Route path="/OverView" element={<OverView />} />
			
			<Route path="/" element={<MainLayout />}>
				<Route index element={<HomePage/>} />
				<Route path="/jobs" element={<Jobs />} />
				<Route path="/view-job-details" element={<ViewJobDetail />} />
				<Route path="/apply-job" element={<ApplyJob />} />
				<Route path="/bookmarks" element={<BookMarks />} />
				<Route path="/applied-job-history" element={<AppliedJobHistory />} />
				<Route path="/recomended-job" element={<RecomendedJobs/>} />
				<Route path="/userlogin" element={<UserLogin />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/registration" element={<Registration />} />
				<Route path="/verify" element={<FormDialog />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/internships" element={<Internships />} />
				<Route path="/details" element={<Details />} />
				<Route path="/my-preferences" element={<MyPreferences />} />
				<Route path="/user_profile" element={<UserProfile />} />
				<Route path="/placement" element={<Placement />} />
				<Route path="/View_placement" element={<ViewPlacement />} />
		
				<Route path="*" element={<PageNotFound />} />				
			</Route>
			


		</Routes>
	)
}



