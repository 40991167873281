import {
    Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import name from "../images/name.png";
import email from "../images/email.png";
import file from "../images/file.png";
import cross from "../images/cross.png";
import phone from "../images/phone.png";


import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import {
  Submit_application,
  addresume,
  get_profile,
} from "./Axiosfetch/Axiosfetch";
import { AlertToast } from "../divine-constants";
import Noticepopup from "../Noticepopup";

import DatePicker from "react-datepicker";
import "./dateRange.style.css";
import "react-datepicker/dist/react-datepicker.css";

import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Padding } from "@mui/icons-material";
import Warring from "./Warring";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(customParseFormat)

// import styles from "./Candidates.module.css";

export function ApplyJob() {

  const matches = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const data = location.state;
  const [resumename, setResumename] = useState("");

  const [coverLetter, setCoverLetter] = useState("");

  const [resume, setResume] = useState();
  const [warringstatus, setWarringstatus] = useState(false);

  const [value, setValue] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");

  const [experience, setExperience] = useState(data.experience);
  const [certificate, setCertificate] = useState(data.certificate);
  const [CustomQuestion, setCustomQuestion] = useState(data.CustomQuestions);

  const [StartTime, setStartTime] = useState(null);
  const [EndTime, setEndTime] = useState(null);

  const [for_radio_value, setFor_radio_value] = useState("female");

  const handleChange = (event) => {
    setFor_radio_value(event.target.value);
    setFormData({ ...formData, Avaliable_time: event.target.value });

  };

  const dataAdds = (item) => {
    let newArr = {}; // Initialize newArr outside of the if-else block

    if (item.question != null) {
      newArr = { ...item, Answer: "yes" }; // Assign the modified object to newArr
    } else {
      newArr = { ...item }; // Assign the original object to newArr
    }

    return newArr;
  };

  const [relocate, setRelocate] = useState(data.relocate);
  const [minEducation, setMinEducation] = useState(data.minEducation);

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    setEndDate(end);
    console.log(start, "datesdatesdates");
    console.log(end, "datesdatesdates2");

    setFormData({ ...formData, startDate: start, endDate: end });
  };

  const dataAdd = (item) => {
    const newArr = [];

    item.forEach((item) => {
      newArr.push({ ...item, Answer: "yes" });
    });

    return newArr;
  };

  const [language, setLanguage] = useState(dataAdd(data.language));
  const [shiftTime, setShiftTime] = useState(data.ShiftTime);
  const [notice_period, setNotice_period] = useState(false);

  const { setAlert } = useContext(AlertToast);

  console.log(data, "datadata");

  const [formData, setFormData] = useState({
    // Initialize form data with default values
    relocated: dataAdds(data.relocate),
    minEducations: {},
    languages: dataAdd(data.language),
    ShiftTimes: {},
    CustomQuestions: [],
    certificates: [],
    experiences: [],
    CoverLetter: "",
    resumename: "",
    resumes: "",
    startDate: "",
    endDate: "",
    starttime: "",
    endtime: "",
    Avaliable_time: "",
    day: "",
    id: data.id,
  });

  const days_of_week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  
]

  const handleCustomQuestion = (e, id) => {
    const { value } = e.target;

    setCustomQuestion((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const handlelanguageChange = (e, id) => {
    const { value } = e.target;

    setLanguage((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const handleExperience = (e, id) => {
    const { value } = e.target;

    setExperience((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const handleCertificate = (e, id) => {
    const { value } = e.target;

    setCertificate((prevData) => {
      return prevData.map((item) =>
        item.id === id ? { ...item, Answer: value } : item
      );
    });
  };

  useEffect(() => {
    setFormData({ ...formData, CustomQuestions: CustomQuestion });
  }, [CustomQuestion]);

  useEffect(() => {
    setFormData({ ...formData, certificates: certificate });
  }, [certificate]);

  useEffect(() => {
    setFormData({ ...formData, experiences: experience });
  }, [experience]);

  useEffect(() => {
    setFormData({ ...formData, relocated: relocate });
  }, [relocate]);

  useEffect(() => {
    setFormData({ ...formData, minEducations: minEducation });
  }, [minEducation]);

  useEffect(() => {
    console.log("languagelanguage", language);
    setFormData({ ...formData, languages: language });
  }, [language]);

  useEffect(() => {
    setFormData({ ...formData, ShiftTimes: shiftTime });
  }, [shiftTime]);

  useEffect(() => {
    setFormData({ ...formData, CoverLetter: coverLetter });
  }, [coverLetter]);

  useEffect(() => {
    setFormData({ ...formData, resumes: resume });
    // console.log()
  }, []);

  useEffect(() => {
    console.log(coverLetter, "coverLetter");
  }, [coverLetter]);

  const handleRelocateChange = (event) => {
    setRelocate({ ...relocate, Answer: event.target.value });
    // setFormData({ ...formData, relocate: event.target.value });
  };

  const handleEducationChange = (event) => {
    setMinEducation({ ...minEducation, Answer: event.target.value });
  };

  const handleShiftTimeChange = (event) => {
    setShiftTime({ ...shiftTime, Answer: event.target.value });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleSkillsChange = (event, newValue) => {
    setFormData({
        ...formData,
        day: newValue
    });
};

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await get_profile(); // Call your API function to fetch profile data
        if (response.status === 200) {
          setUser(response.data);

          // setResume(response.data.resume)
          setResumename(response.data.resumename);
          if(response.data.notice_period){
            setNotice_period(false);
          }else{
            setNotice_period(true);
          }
       
          setFormData({
            ...formData,
            resumes: response.data.resume,
            resumename: response.data.resumename,
          });
        }
      } catch (err) {}
    };
    fetchProfileData();
  }, []);


  console.log(warringstatus, "warringstatus");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWarringstatus(true) 


  };


  
  const submitapi = async () => {

    try {
    
        const data = new FormData();
    
        for (const [key, value] of Object.entries(formData)) {
          if (key == "resumes") {
            if (value) {
              data.append(key, value);
            } else {
              throw new Error();
            }
          } else {
            data.append(key, JSON.stringify(value));
          }
        }
    
        const response = await Submit_application(data);
    
        if (response.status === 200) {
    
           navigate("/jobs");
    
          // console.log('Cover letter submitted successfully!');

        } else {
          // Handle error, maybe show an error message
          console.error("Failed to submit cover letter");
        }
    
      } catch (error) {
        setAlert({
          message: "Upload Resume",
          severity: "error",
          show: true,
        });

      }
    

  };


  


  const handleresumeChange = async (event) => {
    const selectedFile = event.target.files[0];
    setResumename(selectedFile.name);
    setFormData({
      ...formData,
      resumes: selectedFile,
      resumename: selectedFile.name,
    });
  };

  

  return (
    <>
      <Grid sx={{ p: 5 }} container>
        <Grid item xs={12} md={2} sm={2}></Grid>
        <Grid justifyContent={"center"} item xs={12} md={8} sm={8}>
          <Typography
            color={"#000000"}
            variant="h2"
            fontWeight={"bold"}
            sx={{ textTransform: "capitalize" }}
          >
            {data.job_title} ({data.work_mode ? data.work_mode : null})
          </Typography>
          <Typography color={"#00000090"} fontSize={16} variant="overline">
            {data.company_name ? data.company_name : null}
          </Typography>
          <Divider />
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            sx={{ mt: 2 }}
          >
            <img style={{ width: 16, height: 16 }} src={name} />

            {user ? (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              >
                {user.firstName}
              </Typography>
            ) : (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              ></Typography>
            )}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            sx={{ mt: 1 }}
          >
            <img style={{ width: 16, height: 16 }} src={phone} />
            {user ? (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              >
                {user.mobile}
              </Typography>
            ) : (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              ></Typography>
            )}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            sx={{ mt: 1 }}
          >
            <img style={{ width: 16, height: 16 }} src={email} />
            {user ? (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              >
                {user.email}
              </Typography>
            ) : (
              <Typography
                fontFamily={"Poppins"}
                fontSize={13}
                color={"#000000"}
              ></Typography>
            )}
          </Stack>{" "}
          <br />
          <Divider />
          {/* <form autoComplete="off" onSubmit={handleSubmit}> */}
          <form onSubmit={handleSubmit}>
            {data ? (
              <Box sx={{ mt: 2 }}>
                <Typography
                  fontSize={15}
                  color={"#000000"}
                  fontFamily={"Poppins"}
                >
                  Application Questionnaire
                </Typography>
                {data?.relocate?.question ? (
                  <Stack
                    sx={{ mt: 2 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <FormControl>
                      <Typography color={"#000000"} fontSize={13}>
                        Are you willing to relocate to any of the following
                        locations -
                        {data.relocate.Showdata.map((city, index) => (
                          <span key={index}>
                            {index > 0 && ","} {city}
                          </span>
                        ))}{" "}
                        ?{" "}
                      </Typography>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        defaultValue={"yes"}
                        // value={value}
                        // onChange={handleRadio}
                        // value={"yes"}

                        onChange={handleRelocateChange}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, I am available to relocate"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                ) : null}

                {data.minEducation.question ? (
                  <FormControl sx={{ mt: 2, maxWidth: 500 }}>
                    {/* <Stack sx={{mt:2, maxWidth:300}} direction={{ xs: 'column', sm: 'column', md:'column'}} spacing={{ xs: 1, sm: 1, md: 1 }}> */}
                    <Typography color={"#000000"} fontSize={13}>
                      {" "}
                      {data.minEducation.question} ({data.minEducation.Showdata}
                      )
                    </Typography>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={formData.education}
                        onChange={handleEducationChange}
                        // required
                      >
                        {data.minEducation.dropdown.map((education, index) => (
                          <MenuItem key={index} value={education}>
                            {education}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* </Stack> */}
                  </FormControl>
                ) : null}

                {data.experience.length > 0 ? (
                  <Stack
                    sx={{ mt: 2 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <div>
                      {data.experience.map((exp, index) => (
                        <div key={index}>
                          <Typography color={"#000000"} fontSize={13}>
                            {exp.question} ?
                          </Typography>
                          <TextField
                            id={`experience-${index}`}
                            // value={exp.Showdata}
                            // required

                            onChange={(e) => handleExperience(e, exp.id)}
                            type="number"
                          />
                        </div>
                      ))}
                    </div>
                  </Stack>
                ) : null}

                {data.language.length > 0 ? (
                  <Stack
                    sx={{ mt: 2 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <div>
                      {data.language.map((lang, index) => (
                        <div key={index}>
                          <Typography color={"#000000"} fontSize={13}>
                            {lang.question}({lang.Showdata}){" "}
                          </Typography>
                          <FormControl component="fieldset">
                            {/* <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    // value={formData.ShiftTimes} 
                                                    onChange={handlelanguageChange}
                                                >
                                                    {data.lang.dropdown.map((shift, index) => (
                                                        <MenuItem key={index} value={shift}>
                                                            {shift}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}

                            <RadioGroup
                              aria-label={lang.question}
                              name={lang.question}
                              defaultValue={"Yes"}
                              // value={lang.Showdata}
                              // onChange={handleRadioLanguage}
                              // value={formData.language}
                              onChange={(e) => handlelanguageChange(e, lang.id)}
                              row
                            >
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      ))}
                    </div>
                  </Stack>
                ) : null}

                {data.certificate.length > 0 ? (
                  <Stack
                    sx={{ mt: 2 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <div>
                      {data.certificate.map((cer, index) => (
                        <div key={index}>
                          <Typography color={"#000000"} fontSize={13}>
                            {cer.question} ({cer.Showdata})
                          </Typography>
                          <TextField
                            id={`experience-${index}`}
                            // value={cer.Showdata}
                            onChange={(e) => handleCertificate(e, cer.id)}
                            // required
                            type="text"
                          />
                        </div>
                      ))}
                    </div>
                  </Stack>
                ) : null}

                {data.ShiftTime.question ? (
                  <Stack
                    sx={{ mt: 2, maxWidth: 300 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <Typography color={"#000000"} fontSize={13}>
                      {data.ShiftTime.question}
                    </Typography>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        // value={formData.ShiftTimes}
                        onChange={handleShiftTimeChange}
                        // required
                      >
                        {data.ShiftTime.dropdown.map((shift, index) => (
                          <MenuItem key={index} value={shift}>
                            {shift}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                ) : null}

                {data.CustomQuestions.length > 0 ? (
                  <Stack
                    sx={{ mt: 2 }}
                    direction={{ xs: "column", sm: "column", md: "column" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    <div>
                      {data.CustomQuestions.map((cus, index) => (
                        <div key={index}>
                          <Typography color={"#000000"} fontSize={13}>
                            {cus.question}
                          </Typography>
                          <TextField
                            id={`experience-${index}`}
                            // name = {cus.question}
                            // value = {cus.Showdata}
                            // onChange={(e) => handleCustomQuestion(cus, e.target.value)}
                            onChange={(e) => handleCustomQuestion(e, cus.id)}
                            type="text"
                            // required
                          />
                        </div>
                      ))}
                    </div>
                  </Stack>
                ) : null}
              </Box>
            ) : null}

            {/* <Box sx={{mt:2}}> 

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateRangePicker']}>
        <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
      </DemoContainer>
    </LocalizationProvider>


                    </Box> */}
<Box sx={{mt:2}}> 

            <FormControl  >

              <FormLabel id="demo-row-radio-buttons-group-label">
                Confirm your availability{" "}
              </FormLabel>

              <RadioGroup
                row ={true}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={for_radio_value}
                onChange={handleChange}
                sx={{mt:1}}
              >
               <Stack direction="row" sx={{marginLeft:"5px"}}>


         
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Flexible Schedule"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="other"
                  />
                     </Stack>
              
              </RadioGroup>
            </FormControl>

    </Box>

            {for_radio_value === "male" ? (

              <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ width: { md: "40%", xs: "100%" } }}>

                  {/* <Autocomplete
							multiple
							value={formData.Skills}
							onChange={handleSkillsChange}
							options={days_of_week}
							getOptionLabel={(option) => option}
							freeSolo
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="select the days"
									label="Select"
                                    helperText={false}
                                   

								/>
							)}
						/>
						 */}
                    <DatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      isClearable={true}
                      withPortal
                      required
                      placeholderText="  From dd/mm/yyyy To dd/mm/yyyy "
                      className="dateRange"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>

                  <Box sx={{width:"60%"}} >
                    {" "}
                    <LocalizationProvider dateFormats={"fulltime12h"} dateAdapter={AdapterDayjs}>
                      {" "}
                      {/* Use AdapterMoment */}
                      
                      <MobileTimePicker
                        sx={{
                          "& .css-uu33k-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              height: "40px",
                             
                            },
                        }}
                        label="Start Time"
                        value={formData.starttime || dayjs()}

                        onChange={(newValue) =>
                          setFormData({ ...formData, starttime: newValue } , console.log(newValue,"newValue"))
                        }
                        // maxTime={dayjs(EndTime?.$d) || ""}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={{width:"60%"}}>
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {" "}
                      {/* Use AdapterMoment */}

                      <MobileTimePicker
                        sx={{
                          "& .css-uu33k-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              height: "40px",
                          
                            },
                        }}
                        label="End Time"
                        value={formData.endtime || dayjs()}
                        onChange={(newValue) =>
                          setFormData({ ...formData, endtime: newValue } , console.log(newValue,"newValue"))
                        }

                        // onChange={(newValue) => { console.log("newValuesd32", new Date(newValue).toLocaleTimeString(undefined, {hour: "numeric", minute: "numeric"}))}
                        // onChange={(newValue) => { console.log("newValuesd32", new Date(newValue).toLocaleTimeString(undefined, {hour: "numeric", minute: "numeric"}))}

                      
                        //   setFormData({ ...formData, endtime: dayjs(newValue).format("DD MM YYYY, hh:mm A") })
                        // }
                        // maxTime={dayjs(EndTime?.$d) || ""}

                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
            ) : null}

            <Box sx={{ mt: 2 }}>
              <Typography variant="h3">Cover Letter</Typography>
              <Typography fontSize={13} color={"#00000090"}>
                Why should we hire you for this position
              </Typography>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                rows={6}
                value={coverLetter}
                required={false}
                onChange={(e) => setCoverLetter(e.target.value)}
              />
            </Box>
            <Box>
              <Typography variant="h3">Resume</Typography>
              <Typography fontSize={13} color={"#00000090"}>
                The employer is able to view and download this CV.
              </Typography>
              {resumename ? (
                <Stack
                  sx={{ mt: 2 }}
                  direction={"row"}
                  spacing={2}
                  alignItems="center"
                >
                  <img style={{ width: 21, height: 21 }} src={file} />
                  <Typography fontSize={16}> {resumename}</Typography>
                  <Box
                    display={"flex"}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    {/* <IconButton >
                            <img style={{width:14, height:14}} src={cross}/>
                        </IconButton> */}
                  </Box>
                </Stack>
              ) : (
                <Stack
                  sx={{ mt: 2 }}
                  direction={"row"}
                  spacing={2}
                  alignItems="center"
                >
                  <img style={{ width: 21, height: 21 }} src={file} />
                  <Typography fontSize={16}> Please Upload Resume</Typography>
                  <Box
                    display={"flex"}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    {/*                             
                        <IconButton >
                            <img style={{width:14, height:14}} src={cross}/>
                        </IconButton> */}
                  </Box>
                </Stack>
              )}

              <Paper sx={{ p: 2, mt: 2 }}>
                <Stack alignContent={"center"} alignItems={"center"}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleresumeChange}
                      accept=".docx,.pdf"
                    />
                  </Button>
                  <Typography sx={{ mt: 1 }} fontSize={12} color={"#00000090"}>
                    File size limit: 10 MB. File type: DOC, PDF, DOCX
                  </Typography>
                </Stack>
              </Paper>
            </Box>

            <Box textAlign={"center"} sx={{ mt: 2 }}>
              <Button type="submit" value="submit" sx={{backgroundImage : "linear-gradient(#24282A97 , #0A79C9)" , color : "white" }}>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} md={2} sm={2}></Grid>
      </Grid>

    {notice_period ?  <Noticepopup /> : null }

<Warring open={warringstatus} onClose = {setWarringstatus} submitapi = {submitapi}/> 


    </>
  );
}

//   const [isClosing, setIsClosing] = React.useState(false);

//   const handleDrawerClose = () => {
//     setIsClosing(true);
//     setMobileOpen(false);
//   };

//   const handleDrawerTransitionEnd = () => {
//     setIsClosing(false);
//   };

//   const handleDrawerToggle = () => {
//     if (!isClosing) {
//       setMobileOpen(!mobileOpen);
//     }
//   };

//   const drawer = (
//     <div>
//       <Toolbar />
//       <Divider />
//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {['All mail', 'Trash', 'Spam'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </div>
//   );

//   // Remove this const when copying and pasting into your project.
//   const container = window !== undefined ? () => window().document.body : undefined;

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         sx={{
//           width: { sm: `calc(100% - ${drawerWidth}px)` },
//           ml: { sm: `${drawerWidth}px` },
//         }}
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { sm: 'none' } }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap component="div">
//             Responsive drawer
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Box
//         component="nav"
//         sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
//         aria-label="mailbox folders"
//       >
//         {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//         <Drawer
//           container={container}
//           variant="temporary"
//           open={mobileOpen}
//           onTransitionEnd={handleDrawerTransitionEnd}
//           onClose={handleDrawerClose}
//           ModalProps={{
//             keepMounted: true, // Better open performance on mobile.
//           }}
//           sx={{
//             display: { xs: 'block', sm: 'none' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//           }}
//         >
//           {drawer}
//         </Drawer>
//         <Drawer
//           variant="permanent"
//           sx={{
//             display: { xs: 'none', sm: 'block' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//           }}
//           open
//         >
//           {drawer}
//         </Drawer>
//       </Box>
//       <Box
//         component="main"
//         sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
//       >
//         <Toolbar />
//         <Typography paragraph>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//           tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//           enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//           imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//           Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//           Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//           adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//           nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//           leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//           feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//           consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//           sapien faucibus et molestie ac.
//         </Typography>
//         <Typography paragraph>
//           Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//           eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//           neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//           tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//           sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//           tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//           gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//           et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//           tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//           eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//           posuere sollicitudin aliquam ultrices sagittis orci a.
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * Remove this when copying and pasting into your project.
//    */
//   window: PropTypes.func,
// };

// export default ResponsiveDrawer;
