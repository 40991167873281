import { Container,Toolbar,Tooltip, Typography , Divider ,useTheme,Box, Stack ,IconButton, Button, Chip, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Autocomplete } from "@mui/material";
import {  AddOutlined, LinkOutlined ,  } from "@mui/icons-material";
import { LibTextField, LibAutocomplete } from "../../components/LibComponents";
import { DotSeparator } from "../../components/LibComponents";
import { useForm } from "react-hook-form";
import { useState ,useContext  ,useEffect} from "react";
import { addproject, edit_projects, get_profile } from "../Axiosfetch/Axiosfetch";
import { AlertToast,alertState } from "../../divine-constants";
import {textValidation } from "../../utils";
import Deletemodel from "../../Deletemodel";
import edit from "../../images/edit.png";
import bin from "../../images/delete.png";


export default function Projects({ formData, setValue , control , getValues , handleSubmit , candidate_persen}) {

	const [modalDatas,setModalDatas] = useState()
	const [clickedRow, setClickedRow] = useState(); 
	const [operation, setOperation] = useState();
	const theme = useTheme();
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const [projectsData, setProjectsData] = useState([]);
	
	useEffect(() => {
        if (formData && formData.projectdetails) {
            setProjectsData(formData.projectdetails);
        }
    }, [formData]);

	const dialogClose = () => {
		setDialogOpen(false);
	}
	const dialogOpened = async() =>{
		setDialogOpen(true);
		setOperation('edit');

	}
	function HandleEdit (arg){
		setDialogOpen(true);
		setOperation('edit');
		setModalDatas(arg)

		// return(
			// <EducationDialog dialogClose={dialogClose} dialogOpen={dialogOpen} project ={arg}  
			// handleSubmit={handleSubmit} form = {formData} getValues={getValues} operation={operation} control={control}/>

		// )
	

		// setId(arg)
		// setDialogOpen(true);
		// setOperation('edit');
	}
	function HandleAdd (){
		setDialogOpen(true);
		setOperation('add');
		console.log(operation)
	}
	// function HandleDelete (project){		
	// 	setDialogOpen(true);
	// 	setOperation('delete');
	// }
	const handleDelete = async () => {
		try {
			
			// Perform the delete operation using your API
			// Example:
			// const response = await deleteProject(projectId);
			// Handle the response or any errors accordingly
			// Update the state or perform any necessary actions after successful deletion
			// For example:
			// setProjectsData(updatedProjects);

			console.log('Project deleted successfully');
			setDialogOpen(false);
		} catch (error) {
			console.error('Error deleting project:', error);
			// Handle any error, show a message, or perform appropriate actions
		}
	};

	return (
		<Paper  sx={{p:2}}>
			<Box sx={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}}>
			<Button onClick={() => HandleAdd()} variant="text"> Add Project
				</Button>
			</Box>
			<Container sx={{ pb: 2 }}>
				{formData.length > 0 ? (
					formData.map((project, index) => (
						<section key={index}>
							{/* <Stack direction = {{ sm : "column" , md:"row"}} spacing={1} > */}
							<Box display={"flex"} columnGap={1} alignItems={"center"}>

							<Typography variant="subtitle1" fontWeight={800}>
									{project.project_title}
								</Typography>
					
								<Toolbar>
									<div style={{ marginLeft: 'auto' }}>
										{/* Use a div to align items to the right */}
										<Tooltip title="Edit this section">
											<IconButton onClick={() => HandleEdit(project) }>
											<img style={{width:15, height:15}} src={edit}/>
											</IconButton>
										</Tooltip>
										<Tooltip title="Delete this section">
											<IconButton onClick={() =>{ setClickedRow(project); setDialogOpen(true); setOperation('delete'); }}>
											<img style={{width:15, height:15}} src={bin}/>
											</IconButton>
										</Tooltip>
									</div>
								</Toolbar>

							</Box>
							
						
							<Box display={"flex"}columnGap={1} alignItems={"center"}>

								{/* <Typography variant="subtitle2"> Client : </Typography> */}
								<Typography variant="body1">
									{project.Client}
								</Typography>
								<Divider sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
									<Box display={"flex"} columnGap={1} alignItems={"center"}>
										{/* <Typography variant="subtitle2" fontWeight={500} > Date  : </Typography> */}
										{/* <DotSeparator /> */}
										<Typography variant="body1">
											{project.Worked_from} To {project.Worked_till}
										</Typography>
									</Box>
							
						
							</Box>
							
							<Box>
							<Typography variant="subtitle2"> Description</Typography>
							
								<Typography variant="body1">{project.Details_of_project}</Typography>
								<Chip
									icon={<LinkOutlined />}
									label={<a href={project.link} aria-label="Link to project">{project.link}</a>}
								/>
							</Box>
						</section>
					))
				) : (
					<Typography variant="h3" textAlign="center">
						No projects to show!
					</Typography>
				)}
	
				<Deletemodel
					dialogDisplay={dialogOpen}
					setDialogDisplay={setDialogOpen}
					candidate_percentage={candidate_persen}
					url={"candidate/delete_project/"}
					data={{ id: clickedRow ? clickedRow.id : null }}
					handleDelete={handleDelete} // Pass the handleDelete function
					setValue={setValue}
				/>
	
				<EducationDialog
					dialogClose={dialogClose}
					dialogOpen={dialogOpen}
					project={modalDatas}
					candidate_percentage={candidate_persen}
					handleSubmit={handleSubmit}
					form={formData}
					getValues={getValues}
					operation={operation}
					control={control}
					setValue={setValue}
				/>
			</Container>
		</Paper>
	);
	
}


function EducationDialog({state, dialogClose ,form, operation, setValue , dialogOpen , project , candidate_percentage}) {

	const { setAlert } = useContext(AlertToast);
	 
	const theme = useTheme();
	const [userData,setUserData] = useState([])
	// console.log(project,"project")
	const [userPerferData] = "";
	// const skills = ['JavaScript', 'React', 'Node.js', 'HTML', 'CSS'];
	// const skills = useMemo(() =>  [
	const skills = [
		"Java Programming",
		"Object-Oriented Programming (OOP)",
		"Java Standard Edition (Java SE)",
		"Java Enterprise Edition (Java EE)",
		"JavaServer Faces (JSF)",
		"Spring Framework",
		"Hibernate",
		"Servlets",
		"JavaServer Pages (JSP)",
		"RESTful Web Services",
		"Microservices Architecture",
		"Spring Boot",
		"Maven",
		"Gradle",
		"JUnit",
		"TestNG",
		"Version Control (Git, SVN)",
		"Database Management (SQL, JDBC)",
		"HTML, CSS, JavaScript",
		"Threading and Concurrency",
		"Design Patterns",
		"Dependency Injection",
		"Logging (SLF4J, Log4j)",
		"Build Tools (Ant)",
		"Continuous Integration/Continuous Deployment (CI/CD)",
		"Web Application Security",
		"Agile/Scrum Methodology",
		"Problem-Solving Skills",
		"Debugging and Profiling Tools",
		"Eclipse IDE or IntelliJ IDEA",
		"Understanding of JVM Internals",
		"Knowledge of Containers (Docker)",
		"Understanding of REST and SOAP",
		"Web Development Frameworks (Spring MVC)",
		"Web Application Servers (Tomcat, Jetty)",
		"JSON and XML Processing",
		"Unit Testing and Integration Testing",
		"Performance Optimization",
		"Cloud Technologies (AWS, Azure, GCP)",
		"Linux/Unix Basics"]

	const [formDatas, setFormDatas] = useState({
		project_title: '',
		Details_of_project: '',
		Worked_from: '',
		Worked_till: '',
		Skills_used: [],
		link: '',
		Client: ''
	});

	useEffect(() => {
		if (project) {
			setFormDatas({
				id : project.id || '',
				project_title: project.project_title || '',
				Details_of_project: project.Details_of_project || '',
				Worked_from: project.Worked_from || '',
				Worked_till: project.Worked_till || '',
				Skills_used: project.Skills_used || [],
				link: project.link || '',
				Client: project.Client || ''
			});
		}
	}, [project]);
	
	
    const handleInputChange = (e) => {
        const { name, value } = e.target;
	
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };

	
    const handleSkillsChange = (event, newValue) => {
        setFormDatas({
            ...formDatas,
            Skills_used: newValue
        });
    };	

	const {
		reset,
		control,
		handleSubmit,
	} = useForm({
		defaultValues: {
			// projectdetails : {
			// 	project_title: '',
			// 	Details_of_project: '',
			// 	Skills_used: [],
			// 	Worked_from: '',
			// 	Worked_till: '',
			// 	link: '',
			// 	Client: '',
			// },
		}
	});


	
  
  
	
  
	const handleAddProject = async (datas) => {

		console.log("comein")
	  try {
		console.log("comein")
		const { status , data  } = await addproject(datas);
		if (status === 200) {
			const response = await get_profile(); // Call your API function to fetch profile data
			
			if (response.status === 200) {
				setValue('projectdetails', response.data.candidate_projects);
				setValue('candidate_percentage', response.data.candidate_percentage);
			}
		setAlert(alertState({
		  message: "Project added successfully ",
		  severity: "success",
		  show: true
		}));
		reset()
	  
  
		}else {
			throw new Error('Invalid input: The value must be a positive number Something went wrong! Please try again');
			
		}
		} catch (error) {
		// console.log(error)
			setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
			}));
	  	}

	  
	  dialogClose();
  
	}


	// 	const handleAddProject = async (datas) => {
	// 		console.log(datas);
	// 	try {
	// 		// Send formData to the addproject function
	// 		const response = await addproject(datas);	
	// 		if (response.status === 200) {
	// 		await fetchSync()
	// 		setAlert(alertState({
	// 		message: "project added successfuly",
	// 		severity: "success",
	// 		show: true
	// 		}));
	
	// 		}else {
	// 		setAlert(alertState({
	// 					message: response.data.message,
	// 					severity: "error",
	// 					show: true
	// 				}));
	// 		}
	// 	} catch (error) {
	// 		setAlert(alertState({
	// 		message: "Something went wrong! Please try again",
	// 		severity: "error",
	// 		show: true
	// 		}));
	// 	}
	// 			// window.location.reload()
	// 	dialogClose();
	// };

	const handleEdits = async (e) => {
		e.preventDefault();

		const prodata = {
			id : formDatas.id,
			project_title: formDatas.project_title,
			Details_of_project: formDatas.Details_of_project,
			Worked_from: formDatas.Worked_from,
			Worked_till: formDatas.Worked_till,
			Skills_used: formDatas.Skills_used,
			link: formDatas.link,
			Client: formDatas.Client

		};
		if (!formDatas.Skills_used || formDatas.Skills_used.length === 0) {
			setAlert({
				message: "Please fill in all inputs.",
				severity: "error",
				show: true
			});
		  }else{
	

		try {
			console.log("project added successfuly111")
			const response = await edit_projects(prodata);	
			if (response.status === 200) {

				const response = await get_profile(); // Call your API function to fetch profile data
				if (response.status === 200) {
					console.log(response.data.candidate_projects,"response.data.candidate_projects")

			
				setValue('projectdetails', response.data.candidate_projects);
			}

			
				console.log("project added successfuly3333")
				setAlert(alertState({
				message: "project added successfuly",
				severity: "success",
				show: true
				}));
			}else {
				throw new Error('Invalid input: The value must be a positive number');
			}
					
		}catch (error) {
			// console.log(error)
			setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
			  }));
				// window.location.reload()

		}

		dialogClose();
	}
	};


	if (operation === 'add'){
		return (
			// <Dialog open={state[0]} onClose={dialogClose}>
			<Dialog open={dialogOpen} onClose={dialogClose} >
				<DialogTitle>
				<Box display="flex" alignItems="center">
                        Add Project <AddOutlined style={{ marginLeft: "auto" }} />
                    </Box>
					<Typography color={'#00000080'} >Stand out for employers by adding details about projects you have done in college, internships, or at work.</Typography>
					
					
				</DialogTitle>
					
				<DialogContent>
					{/* <form onSubmit={handleSubmit(handleAddProject)} > */}
						<Grid component={"form"} noValidate container spacing={2}>
							<Grid item xs={12}>
								<LibTextField 
									control={control}
									name={"project_title"}
									
									rules={textValidation("project_title")}
									textfieldProps={{
										label: "Project name",
										fullWidth: true
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<LibTextField 
									control={control}
									name={"Details_of_project"}
									rules={textValidation("Current Job")}
									textfieldProps={{
										label: "Description",
										fullWidth: true,
										multiline: true
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<LibTextField 
									control={control}
									name={"Worked_from"}
									rules={textValidation("Current Job")}
									textfieldProps={{
										label: "Start date",
										fullWidth: true,
										type: "date"
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<LibTextField 
									control={control}
									name={"Worked_till"}
									rules={textValidation("Current Job")}
									textfieldProps={{
										label: "End date",
										fullWidth: true,
										helperText: <small>Ignore if you are still working on this project</small>,
										required: false,
										type: "date",
										FormHelperTextProps: {
											error: false,
											classes: {
												sizeSmall: "0.5em"
											},
											variant: "outlined"
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<LibAutocomplete 
									control={control}
									options={skills}
									name={"Skills_used"}
									autocompleteProps={{
										multiple: true,
										getOptionLabel: option => option,
										freeSolo: true,
										renderTags: (value, getTagProps) =>
											value.map((option, index) => (
												<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
												)),
											}}
									textfieldProps={{
										placeholder: "Enter the skills you used in this project",
										label: "Skills",
										
									}}
								/>
							</Grid>
						
							<Grid item xs={12} sm={6}>
								<LibTextField 
									control={control}
									name={"link"}
									rules={textValidation("Current Job")}
									textfieldProps={{
										label: "Link",
										fullWidth: true,
										required: false,
										type: "url",
										placeholder: "Share a URL if your project if hosted"
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<LibTextField 
									control={control}
									name={"Client"}
									rules={textValidation("Current Job")}
									textfieldProps={{
										label: "Company",
										fullWidth: true,
										required: false,
										type: "text",
										placeholder: "Client/Company"
									}}
								/>
							</Grid>
						</Grid>
						
					{/* </form> */}
				</DialogContent>
				{/* <DialogActions>
					<Button onClick={dialogClose} variant="text">Cancel</Button>
					<Button type="submit" variant="text">Add</Button>
				</DialogActions> */}
				<DialogActions>
					<Button onClick={() =>{ dialogClose(); reset(); }} variant="text">Cancel</Button>
					<Button type="submit" onClick={handleSubmit(handleAddProject)} variant="text">Add</Button>
				</DialogActions>
		
			
			</Dialog>
		)
	}
	if (operation === 'edit'){

		return (
			<>
			<Dialog maxWidth={"sm"} fullWidth={true} open={dialogOpen} onClose={dialogClose} >
				<DialogTitle>
				<Box display="flex" alignItems="center">
                        project <img style={{width:25, height:25, marginLeft:'auto'}} src={edit}/>
                    </Box>
				</DialogTitle>
				<DialogContent>
				
				<form onSubmit={handleEdits}  > 
			
				
				
						<Grid item xs={12}>
						<TextField
								name="project_title"
								value={formDatas.project_title}
								onChange={handleInputChange}
								label="Project name"
								fullWidth
								required 
							/>
					
							
						</Grid>
						<Grid item xs={12}>
						<TextField
								name="Details_of_project"
								value={formDatas.Details_of_project}
								onChange={handleInputChange}
								label="Description"
								fullWidth
								multiline
								required
							/>
						
						</Grid>
					<Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
							gap={0 + ' ' + theme.spacing(3)} sx={{ 
								
								[theme.breakpoints.up("sm")]: {
									gridTemplateColumns: "repeat(2, 1fr)",
								} , mt:1

							}}>
			
						<TextField
							name="Worked_from"
							value={formDatas.Worked_from}
							onChange={handleInputChange}
							label="Start date"
							fullWidth
							type="date"
							required
						/>
			
						<TextField
							name="Worked_till"
							value={formDatas.Worked_till}
							onChange={handleInputChange}
							label="End date"
							fullWidth
							type="date"
							helperText={<small>Ignore if you are still working on this project</small>}
							required
						/>
					
							<TextField
								name="link"
								value={formDatas.link}
								onChange={handleInputChange}
								label="Link"
								fullWidth
								type="url"
								placeholder="Share a URL if your project if hosted"
								required
							/>
						
							<TextField
								name="Client"
								value={formDatas.Client}
								onChange={handleInputChange}
								label="Company"
								fullWidth
								type="text"
								placeholder="Client/Company"
								required
							/>
						
		
					</Box>
					
			
						<Grid item xs={12}>
							<Autocomplete
								multiple
								value={formDatas.Skills_used}
								onChange={handleSkillsChange}
								options={skills}
								getOptionLabel={(option) => option}
								freeSolo
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
									))
								}

								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Enter the skills you used in this project"
										label="Skills"
										required = {false}
										
									/>
								)}
							/>
						</Grid>

						
				
				
			
			
					<DialogActions>
						<Button onClick={dialogClose} variant="text">Cancel</Button>
						<Button type="submit"  variant="text">Update</Button>
					</DialogActions>
				
					</form>
			
				
			
				</DialogContent>

				
			</Dialog>
			</>
			
			
		
		)
		
	}
	

}




