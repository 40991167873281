import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import edit from "../../images/edit.png";
import yes from "../../images/yes.png";
import no from "../../images/no.png";
import file from "../../images/file.png";
import cross from "../../images/cross.png";
import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { LibAutocomplete, LibCheckbox, LibDateField, LibTextField } from "../../components/LibComponents";
import { dummyLanguages, mobileValidation, textValidation } from "../../utils";
import { useForm,  useFieldArray } from "react-hook-form";
import { AlertToast, alertState } from "../../divine-constants";

import { useTheme } from "@emotion/react";
import { AddOutlined, FilterSharp, RemoveOutlined } from "@mui/icons-material";
import { edit_profile, get_profile } from "../Axiosfetch/Axiosfetch";
import bin from "../../images/delete.png";
import Deletemodel from "../../Deletemodel";


export default function PersonalDetails ({formData, control , setValue , candidate_persen} ){
  
    const [dialogOpen, setDialogOpen] = useState(false);
    const [languagesdata, setLanguages] = useState(formData.Languages);

    const handleClickOpen = () => {
        setDialogOpen(true);
      };
    
      const handleClose = () => {
        setDialogOpen(false);
      };
    return (
        <>
     
      <EditPersonalDetails state={[dialogOpen, setDialogOpen]}  lang = {formData.Languages}  setValue={setValue}   datas={formData}  controls={control}  dialogClose={handleClose} dialogOpen={dialogOpen} />
        <Paper>
        <Toolbar>
			<Tooltip title="Edit this section">
				<IconButton sx={{ml:"auto"}} onClick={handleClickOpen}>
					<img style={{width:15, height:15}} src={edit}/>
				</IconButton>
			</Tooltip>
		</Toolbar>
        <Box>
        </Box>
    <Grid sx={{p:3}} container spacing={2} >
            <Grid item xs={6} sm={6} md={4}>
            <Stack direction={'column'} spacing={.5}>

                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}} >Gender</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>Marital Status</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>Date of Birth</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>Career Break</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>Address</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>City</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>State</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{height:40}}>Country</Typography>

                </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
            <Stack  direction={'column'} spacing={.5} justifyContent={'space-between'}>
            {formData.Gender ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption"  sx={{textTransform : 'capitalize',height:40}}> {formData.Gender} </Typography>):

                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}> No My preferences specified </Typography>}

            {formData.Marital_status ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}> {formData.Marital_status} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'} sx={{textTransform : 'capitalize',height:40}} variant="caption"> No Marital status specified  </Typography>}
            {formData.Date_of_birth ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}> {formData.Date_of_birth} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'} sx={{textTransform : 'capitalize',height:40}} variant="caption"> No Date of birth specified  </Typography>}
            {formData.Have_you_taken_career_break ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}} > {formData.Have_you_taken_career_break} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'}sx={{textTransform : 'capitalize',height:40}}  variant="caption">No career break specified  </Typography>}

            {formData.Permanent_address ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}} > {formData.Permanent_address} {formData.Home_town} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'}sx={{textTransform : 'capitalize',height:40}}  variant="caption"> No Address specified  </Typography>}
            {formData.city ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}> {formData.city} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'} sx={{textTransform : 'capitalize',height:40}}  variant="caption"> No city specified  </Typography>}
            {formData.state ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}>  {formData.state} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'} sx={{textTransform : 'capitalize',height:40}}  variant="caption"> No state specified  </Typography>}
            {formData.country ? (<Typography fontWeight={'bold'} color={'#000000'} variant="caption" sx={{textTransform : 'capitalize',height:40}}>  {formData.country} </Typography>):
                    <Typography fontWeight={'bold'} color={'#000000'} sx={{textTransform : 'capitalize',height:40}} variant="caption"> No country specified  </Typography>}

                    {/* // <Typography fontWeight={'bold'} color={'#000000'} variant="caption">Single/ Unmarried</Typography>
                    // <Typography fontWeight={'bold'} color={'#000000'} variant="caption">22 May 2000</Typography>
                    // <Typography fontWeight={'bold'} color={'#000000'} variant="caption">No</Typography>
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption">1A,, throwpathy amman koil street,chidambaram, cuddalore, 608001</Typography> */}
                </Stack>
            </Grid>
    </Grid>
    
    <Grid sx={{p:2}} container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
        <Divider/>
            <Stack sx={{mt:1}} direction={'column'} spacing={.5} >
                    <Typography fontWeight={'bold'} color={'#000000'} variant="caption">Languages</Typography>
                    {formData.Languages ? (<LanguageTable  lang = {formData.Languages} setValue = {setValue} />):(null)}
                </Stack>
            </Grid>
    </Grid>
    </Paper>
        </>
    )
}



function LanguageTable({lang , setValue}) {

  const [clickedRow, setClickedRow] = useState(); 
	const [operation, setOperation] = useState();
	
	const [dialogOpen, setDialogOpen] = useState(false);


  return (
    <TableContainer component={Paper}>
      <Table width="100%" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Languages</TableCell>
            <TableCell align="center">Read</TableCell>
            <TableCell align="center">Write</TableCell>
            <TableCell align="center">Speak</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lang.length !== 0 ? lang.map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.lan}
          
              </TableCell>
              <TableCell align="center">{row.reading === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>
              <TableCell align="center">{row.writting === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>
              <TableCell align="center">{row.Speaking === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>

              {/* <Tooltip title="Delete this section">
                <IconButton onClick={() =>{ setClickedRow(row); setDialogOpen(true); setOperation('delete'); }}>
                <img style={{width:15, height:15}} src={bin}/>
                </IconButton>
              </Tooltip> */}

            </TableRow>
   


            
          )):(null)}


        <Deletemodel 
					dialogDisplay={dialogOpen}
					setDialogDisplay={setDialogOpen}
					// candidate_percentage={candidate_persen}
					url={"candidate/delete_language/"}
					data={{ id: clickedRow ? clickedRow.Language_id : null }}
          type="Language"
					// handleDelete={handleDelete} // Pass the handleDelete function
					setValue={setValue}
				/> 

        </TableBody>
      </Table>
    </TableContainer>
  );}

function LanguageTables({lang}) {
  return (
    <TableContainer component={Paper}>
      <Table width="100%" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Languages</TableCell>
            <TableCell align="center">Read</TableCell>
            <TableCell align="center">Write</TableCell>
            <TableCell align="center">Speak</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lang.length !== 0 ? lang.map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">

                {row.lan}
              </TableCell>
              <TableCell align="center">{row.reading === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>
              <TableCell align="center">{row.writting === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>
              <TableCell align="center">{row.Speaking === true ? <img style={{width:16,height:16}} src={yes}/>:
                      <img style={{width:16,height:16}} src={no}/>}</TableCell>
            </TableRow>
          )):(null)}

        </TableBody>
      </Table>
    </TableContainer>
  );}


function EditPersonalDetails({operation , setValue , state, dialogClose,lang, datas , dialogOpen  , candidate_percentage}) {


 
  const theme = useTheme();
 
  const { setAlert } = useContext(AlertToast);
  console.log(datas.audioFile,"aufio")
  console.log(datas,"audio2")
  

  const { control, handleSubmit, getValues ,  setValue: setFormValue  } = useForm({
    defaultValues: {
			languages: [],
    }}
  )


  const [audioFile, setAudioFile] = useState(null);
  const [audioFile_name, SetAudioFile_name] = useState(null);

  const handleFileChange = (e) => {
      
      setAudioFile(e.target.files[0]);
      const filename = e.target.files[0]
      console.log(filename.name,"audioFileaudioFile")
      SetAudioFile_name(filename.name)

  };

 

  const handleUpload = async () => {
      const formData = new FormData();
      formData.append('audio', audioFile);
  }


  const { fields, append , remove } = useFieldArray({
    name: "languages",
    control: control
  });

  // const [lan,setLan] = useState(fields )
  // const [languagesdata, setLanguages] = useState([]);

  // const [languages, setLanguages ] = useState([datas.Languages]);

  // const handleAddLanguage = () => {
  //   setLanguages([...languages, { lan: '', Speaking: false, reading: false, writting: false }]);
  // };

  // const handleRemoveLanguage = (indexToRemove) => {
  //   setLanguages(languages.filter((_, index) => index !== indexToRemove));
  // };

 
 
  const [formDatas, setFormDatas] = useState({
      Gender: '',
      Marital_status: '',
      Date_of_birth: "",
      Have_you_taken_career_break: '',
      Permanent_address: '',
      Home_town: '',
      city: '',
      state: '',
      pincode: '',
      Languages: []
  });

  
  useEffect(() => { console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@") }, [])


  useEffect(() => {
    if (datas) {
      console.table(datas)
        // setFormDatas(datas); // Set initial form data
        const count = datas.Languages.length
        console.log(count,"count")
        Object.keys(datas).forEach(key => {
          if (key === "Languages" && Array.isArray(datas[key])) {
            datas[key].forEach((language, index) => {
          console.log("language language",language)
          

          if (fields.length === count ) {
            console.log("if")
            clearFieldsData()
            append({id: index, ...language})
          
          }else{
            console.log("else")
            append({id: index, ...language})
          
          }
          
      
          // const  alreadyExists = fields.findIndex((item)=>item.Language_id === language.Language_id)

          // const  alreadyNullExists = fields.findIndex((item)=>item.Language_id === null)

          // console.log("language alreadyExists",alreadyExists)

          // if (alreadyExists === -1 && alreadyNullExists === -1  ) {
          // append({id: index, ...language})
          // }

              // setLan(prev => {

              //   if (typeof prev === "array") {
              //   console.log("prev",prev)
              //   const alreadyData = prev.find((item)=>item.Language_id === language.Language_id)
              //   const alreadyDataIndex = prev.findIndex((item)=>item.Language_id === language.Language_id)
              //   if (alreadyData){
              //     return prev[alreadyDataIndex] = {id: index, ...language}
              //   }else{
              //     return [...prev, {id: index, ...language} ]
              //   }
              //   }
              // });
            });


          } else {
            setFormValue(key, datas[key]);
          }
        });
    
        // Object.keys(datas).forEach(key => {
        //     setFormValue(key, datas[key]);
        // });

    }
}, [datas]);



useEffect(() => { 
  console.log(fields,"fields")
},[fields])



const clearFieldsData = () => {
  if (fields.length > 0) {
    remove(0); // Remove the first item until the array is empty
  }
};




// console.log(getValues("languages"),"languages12345")


function handleAddLanguage() {

append({lang: null, read: false, speak: false, write: false,Language_id : null});

}



function handleRemoveLanguage(index) {
remove(index)
}


console.log(datas.Languages,"datas")


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormDatas({
          ...formDatas,
          [name]: value
      });
  };

  

  const handleUpdate = async (formDatas) => {

    const formData = {
			id : formDatas.id,
			Gender: formDatas.Gender,
			Marital_status: formDatas.Marital_status,
			Date_of_birth: formDatas.Date_of_birth,
			Have_you_taken_career_break: formDatas.Have_you_taken_career_break,
			Permanent_address: formDatas.Permanent_address,
			Home_town: formDatas.Home_town,
			pincode: formDatas.pincode,
			city: formDatas.city,
			state: formDatas.state,
      Languages : getValues("languages"),
      audioFile : audioFile,
      audioFile_name : audioFile_name,
		};
    
      console.log("payload ",formData )

      const data  = new FormData()
        

        for (const [key, value] of Object.entries(formData)) {

           if ( key == "audioFile") {
            data.append(key, value); 

        }

        else {
                data.append(key, JSON.stringify(value))
            }
        }
    
      try {
          const response = await edit_profile(data);
          if (response.status === 200) {
            dialogClose()
          
              const profileResponse = await get_profile();
              if (profileResponse.status === 200) {
                  // Do something after updating profile
                setValue('personalDetails', profileResponse.data);
              }
            
             
              setAlert({
                  message: "Added successfully",
                  severity: "success",
                  show: true
              });

          } else {
              throw new Error('Invalid input: The value must be a positive number');
          }
      } catch (error) {
          setAlert({
              message: error.message,
              severity: "error",
              show: true
          });
      }
      
  };

  return (
      <Dialog  maxWidth={"sm"} fullWidth={true} open={dialogOpen} onClose={dialogClose} >
          <DialogTitle>
              <Box display={"flex"} alignItems={"center"}>
                  Personal Details
              </Box>
          </DialogTitle>
          <DialogContent > 
              <form onSubmit={handleSubmit(handleUpdate)} >
                <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                  gap={0 + ' ' + theme.spacing(3)} sx={{
                    
                      [theme.breakpoints.up("sm")]: {
                          gridTemplateColumns: "repeat(2, 1fr)",
                      }
                  }}>
                      <LibTextField        
                          control={control}
                          rules={textValidation("Gender")}
                          name={"Gender"}
                          // value={formDatas.Gender || ''}
                          value={setValue | ''}
                          textfieldProps={{
                              label: "Gender",
                              select: true
                          }}>

                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                    
                          <MenuItem value="others">Others</MenuItem>

                      </LibTextField>

                  
             
                      <LibTextField
                          control={control}
                          rules={textValidation("Marital Status")}
                          name={"Marital_status"}
                          // value={formDatas.Marital_status || ''}
                          value={setValue || ''}
                          textfieldProps={{

                              label: "Marital Status",
                              select: true
                          }}>
                          <MenuItem value="single">Single/unmarried</MenuItem>
                          <MenuItem value="married">Married</MenuItem>
                          <MenuItem value="divorced">Divorced</MenuItem>
                          <MenuItem value="widowed">Widowed</MenuItem>
                      </LibTextField>

                      <LibDateField
                          control={control}
                          name={"Date_of_birth"}
                          value={formDatas.Date_of_birth || ''}
                          textfieldProps={{
                              label: "Date Of Birth",
                              fullWidth: true,
                          }}
                      />

                      <LibTextField
                          control={control}
                          rules={textValidation("career Break")}
                          name={"Have_you_taken_career_break"}
                          value={formDatas.Have_you_taken_career_break || ''}
                          textfieldProps={{
                              label: "Career Break",
                              select: true
                          }}>
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                      </LibTextField>

                      <LibTextField
                          control={control}
                          name={"Permanent_address"}
                          value={formDatas.Permanent_address || ''}
                          rules={textValidation("address")}
                          textfieldProps={{
                              label: "Address"
                          }}
                      />

                      <LibTextField
                          control={control}
                          name={"Home_town"}
                          value={formDatas.Home_town || ''}
                          rules={textValidation("hometown")}
                          textfieldProps={{
                              label: "Home Town"
                          }}
                      />

                      {/* <LibTextField
                          control={control}
                          name={"city"}
                          value={formDatas.city || ''}
                          rules={textValidation("City")}
                          textfieldProps={{
                              label: "City"
                          }}
                      />
                      <LibTextField
                          control={control}
                          name={"state"}
                          value={formDatas.state || ''}
                          rules={textValidation("State")}
                          textfieldProps={{
                              label: "State"
                          }}
                      /> */}

                      <LibTextField
                          name="pincode"
                          control={control}
                          value={formDatas.pincode || ''}
                          rules={textValidation("Pincode")}
                          textfieldProps={{
                              label: "Pincode",
                              type: "tel",
                          }}
                      />

                    

                    <Box sx={{gridColumn: "1 / -1"}}>
                      <h4>Languages</h4>
                        <br />
                        {fields && fields.map((field, index) => (
                        
                          <Paper  key={field?.Language_id} style={{display: "flex", paddingBlock: theme.spacing(0.5), paddingInline: theme.spacing(1), marginBottom: theme.spacing(1), alignItems: "center"}}>
                           
                            <Stack flexWrap="wrap" direction="row" flexGrow={1}>
                              <LibAutocomplete 
                               
                                control={control}
                                name={`languages.${index}.lan`}
                                rules={textValidation("Language")}
                                defaultValue={field.lan} 
                                options={[...dummyLanguages]}
                                textfieldProps={{
                                  label: "Language",
                                  helperText: "",
                                  fullWidth: true
                                }}
                                autocompleteProps={{
                                  style: {flexGrow: '1'}
                                }}
                              />


                              <FormGroup style={{display: "flex", marginLeft: theme.spacing(4), columnGap: theme.spacing(2), flexDirection: "row"}}>
                                <LibCheckbox 
                                  control={control}
                                  name={`languages.${index}.Speaking`}
                                  defaultValue={field.Speaking} 
                                  // checked={getValues("languages")[index].speak}
                                  FormControlLabelProps={{
                                    label: "Speak"
                                  }}
                                />
                                
                                <LibCheckbox 
                                  control={control}
                                  name={`languages.${index}.reading`}
                                  defaultValue={field.reading} 
                                  // checked={getValues("languages")[index].read}
                                  FormControlLabelProps={{
                                    label: "Read"
                                  }}
                                />
                                <LibCheckbox 
                                  control={control}
                                  name={`languages.${index}.writting`}
                                  defaultValue={field.writting} 
                                  // checked={getValues("languages")[index].write}
                                  FormControlLabelProps={{
                                    label: "Write"
                                  }}
                                />
                              </FormGroup>
                            </Stack>
                            <Tooltip title="Remove language">
                              <IconButton variant="contained" size="small" onClick={handleRemoveLanguage.bind(this, index)}><RemoveOutlined /> </IconButton>
                            </Tooltip>
                          </Paper>
                        ))}
                     
                        <Button variant="text" size="small" onClick={handleAddLanguage}><AddOutlined /> Add language</Button>
                    </Box> 
                  </Box> 

                  <DialogActions>
						<Button onClick={dialogClose} colour = "primary" >Cancel</Button>
            <Button type="submit">Submit</Button>
						{/* <Button type="submit" value="submit" >Submit</Button> */}
					</DialogActions>

            </form>
          </DialogContent>
       

      </Dialog>
  );
  































































  
  //   console.log(datas,"formData")
  //   const theme = useTheme();
  //   const { setAlert } = useContext(AlertToast);
  //   const { control, handleSubmit, getValues } = useForm({
  //     })

  //     const { fields, append, remove} = useFieldArray({
  //       name: "languages",
  //       control: control
  //     });

  //     function handleAddLanguage() {
  //       append({lang: null, read: false, speak: false, write: false});
  //     }
  //     function handleRemoveLanguage(index) {
  //       remove(index)
  //     }


  // const [userPerferData] = datas;

	// const [formDatas, setFormDatas] = useState({
	// 	Gender: false,
	// 	Marital_status: '',
	// 	Date_of_birth: "",
	// 	Have_you_taken_career_break: '',
	// });

	// useEffect(() => {
	// 	if (userPerferData) {
	// 		setFormDatas({
	// 			id : userPerferData.id || '',
	// 			emailUpdates: userPerferData.Gender || false,
	// 			work_mode: userPerferData.Marital_status || '',
	// 			job_titles: userPerferData.Date_of_birth || "",
	// 			location: userPerferData.Have_you_taken_career_break || '',
		
	// 		});
	// 	}
	// }, [userPerferData]);
	
	
  //   const handleInputChange = (e) => {
  //       const { name, value } = e.target;
	
  //       setFormDatas({
  //           ...formDatas,
  //           [name]: value
  //       });
  //   };

	




	// const handleUpdate =async(e) => {
	// 	e.preventDefault();
	// 	const formData = {

	// 	};
	
	// 	try {
	// 		const response = await edit_profile(formData); // Call your API function to api data
	// 		if (response.status === 200) {
	// 			const response = await get_profile(); // Call your API function to fetch profile data
			
	// 			if (response.status === 200) {

	// 			}
	// 		  setAlert(alertState({
	// 			message: "Work Experience added successfully",
	// 			severity: "success",
	// 			show: true
	// 		  }));
	// 		} else {
	// 			throw new Error('Invalid input: The value must be a positive number');
				
	// 		}
	// 	} catch (error) {
	// 		// console.log(error)
	// 		setAlert(alertState({
	// 			message: error.message,
	// 			severity: "error",
	// 			show: true
	// 		  }));	
	// 	}
	// 	dialogClose();
  //   };
  
  //     return (
  //       <Dialog fullWidth={true} open={dialogOpen} onClose={dialogClose}>
  //         <DialogTitle>
  //           <Box display={"flex"} alignItems={"center"}>
  //             Personal Details 
  //           </Box>
  //         </DialogTitle>
  //         <DialogContent>

  //           <form> 
  //             <Box display="grid"  gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
	// 		gap={0 + ' ' + theme.spacing(3)} sx={{
	// 			[theme.breakpoints.up("sm")]: {
	// 				gridTemplateColumns: "repeat(2, 1fr)",
	// 			}}
	// 		}>

  //           <LibTextField
	// 			  control={control}
	// 			  rules={textValidation("Gender")}
	// 				name={"Gender"}
	// 				textfieldProps={{
	// 					label: "Gender",
	// 					select: true
	// 				}}
	// 			>

	// 				<MenuItem value="male">Male</MenuItem>
	// 				<MenuItem value="female">Fenale</MenuItem>
	// 				<MenuItem value="transgender">Transgender</MenuItem>
	// 				<MenuItem value="others">Others</MenuItem>
	// 			</LibTextField>

  //           <LibTextField
	// 			control={control}
	// 			rules={textValidation("Marital Status")}
	// 				name={"marital_status"}
	// 				textfieldProps={{ 
	// 					label: "Marital Status",
	// 					select: true
	// 				}}
	// 			>
	// 				<MenuItem value="single">Single/unmarried</MenuItem>
	// 				<MenuItem value="female">Fenale</MenuItem>
	// 				<MenuItem value="transgender">Transgender</MenuItem>
	// 				<MenuItem value="others">Others</MenuItem>
	// 			</LibTextField>
	
	// 			<LibDateField
  //           control={control}
  //           name={"date_of_birth"}
  //           textfieldProps={{
  //             label: "Date Of Birth",
  //             fullWidth: true,
  //           }}
  //         />
	// 			<LibTextField
	// 			control={control}
	// 			rules={textValidation("career Break")}
	// 				name={".career_break"}
	// 				textfieldProps={{
	// 					label: "career Break",
	// 					select: true
	// 				}}
	// 			>
	// 				<MenuItem value="yes">Yes</MenuItem>
	// 				<MenuItem value="no">No</MenuItem>
	// 			</LibTextField>

	// 			<LibTextField 
	// 				control={control}
	// 				name={".address"}
	// 				rules={textValidation("address")}
	// 				textfieldProps={{
	// 					label: "Address"
	// 				}}
	// 			/>
	// 			<LibTextField 
	// 				control={control}
	// 				name={".hometown"}
	// 				rules={textValidation("hometown")}
	// 				textfieldProps={{
	// 					label: "Home Town"
	// 				}}
	// 			/>
	// 			<LibTextField 
	// 				control={control}
	// 				name={".district"}
	// 				rules={textValidation("district")}
	// 				textfieldProps={{
	// 					label: "District"
	// 				}}
	// 			/>
  //       <LibTextField
  //           name="pincode"
  //           control={control}
  //           rules={mobileValidation()}
  //           textfieldProps={{
  //             label: "Pincode",
  //             type: "tel",
  //           }}
  //         />
	// 		</Box>

  //     <Box sx={{gridColumn: "1 / -1"}}>
	// 					<h4>Languages</h4>
	// 					<br />

	// 					{fields.map((field, index) => (
	// 						<Paper  key={field.id} style={{display: "flex", paddingBlock: theme.spacing(0.5), paddingInline: theme.spacing(1), marginBottom: theme.spacing(1), alignItems: "center"}}>
	// 							<Stack flexWrap="wrap" direction="row" flexGrow={1}>
	// 								<LibAutocomplete 
	// 									control={control}
	// 									name={"lang"}
	// 									rules={textValidation("Language")}
	// 									options={[...dummyLanguages]}
	// 									textfieldProps={{
	// 										label: "Language",
	// 										helperText: "",
	// 										fullWidth: true
	// 									}}
	// 									autocompleteProps={{
	// 										style: {flexGrow: '1'}
	// 									}}
	// 								/>
	// 								<FormGroup style={{display: "flex", marginLeft: theme.spacing(2), columnGap: theme.spacing(1), flexDirection: "row"}}>
	// 									<LibCheckbox 
	// 										control={control}
	// 										name={"speak"}
	// 										checked={getValues("languages")[index].speak}
	// 										FormControlLabelProps={{
	// 											label: "Speak"
	// 										}}
	// 									/>
	// 									<LibCheckbox 
	// 										control={control}
	// 										name={"read"}
	// 										checked={getValues("languages")[index].read}
	// 										FormControlLabelProps={{
	// 											label: "Read"
	// 										}}
	// 									/>
	// 									<LibCheckbox 
	// 										control={control}
	// 										name={"write"}
	// 										checked={getValues("languages")[index].write}
	// 										FormControlLabelProps={{
	// 											label: "Write"
	// 										}}
	// 									/>
	// 								</FormGroup>
  //                 <Tooltip title="Remove language">
	// 								<IconButton variant="contained" size="small" onClick={handleRemoveLanguage.bind(this, index)}><RemoveOutlined /> </IconButton>
	// 							</Tooltip>
	// 							</Stack>
								
	// 						</Paper>
	// 					))}
	// 					<Button variant="text" size="small" onClick={handleAddLanguage}><AddOutlined /> Add language</Button>
	// 				</Box>
  //           </form>
  //         </DialogContent>
  //         <DialogActions>
  //                 <Button onClick={dialogClose} variant="outlined">
  //                   Cancel
  //                 </Button>
  //                 <Button  variant="outlined">Update </Button>
  //             </DialogActions>
            
  //       </Dialog>
  //     );

}

