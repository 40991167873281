import { Paper, RadioGroup,FormControlLabel,Radio ,Toolbar,Container, Dialog,MenuItem, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Grid, IconButton, Tooltip, Stack, Divider, Chip, TextField, Autocomplete } from "@mui/material";
import { LibAutocomplete, LibTextField ,} from "../../components/LibComponents";
import { useForm } from "react-hook-form";
import { EditOutlined, AddOutlined, ContactSupportOutlined} from "@mui/icons-material";
import { useState, useEffect ,useContext } from "react";
import { useTheme } from "@emotion/react";
import { Tenth, Twelfth, Undergraduate, Postgraduate, Doctorate } from "../Details";
import { AlertToast, alertState } from "../../divine-constants";
import edit from "../../images/edit.png";
import bin from "../../images/delete.png";
import Deletemodel from "../../Deletemodel";
import { Add_Graduate_qualification, Edit_Graduate_qualification, Edit_School_qualification, Edit_doctorate_qualification, Edit_post_Graduate_qualification, add_doctorate_qualification, add_post_graduate_qualification, add_school_qualification, get_profile } from "../Axiosfetch/Axiosfetch";
import React, { useMemo } from 'react';
import { textValidation } from "../../utils";

export default function Education({ fetchProfileDatas , formData, control , setValue }) {

	const [dialogOpen, setDialogOpen] = useState(false);
    const [formdata, setFormdata] = useState(formData);
    console.log(formdata,'1')
	const [operation, setOperation] = useState();
    const [education,setEducation] = useState();
    const [educations,setEducations] = useState();
    const [dropdowndata,setDropdowndata] = useState();
	const theme = useTheme();

    const [clickedRow, setClickedRow] = useState(); 

    const fetchProfileData = async () => {
        console.log("testing1")
        try {
            const response = await get_profile(); // Call your API function to fetch profile data
            if (response.status === 200) {
                setFormdata(response.data.candidates_qualification)
                fetchProfileDatas()
                setDialogOpen(false)
            } else {
                console.error('Invalid data structure:', response);
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };


	const dialogClose = () => {
		setDialogOpen(false);
	}

    function HandleEdit (item){
		setDialogOpen(true);
		setOperation('edit');
        console.log("item",item)
        setEducation(item.Education_qualification)
        setEducations(item)
        
	}

	function HandleAdd (item){
		setDialogOpen(true);
        console.log("item",item)
        setEducation(item.Education_qualification)
		setOperation('add');
        setDropdowndata(item)
	}

    const handleDelete = async () => {
		try {
			setDialogOpen(false);
		} catch (error) {
			console.error('Error deleting project:', error);
			// Handle any error, show a message, or perform appropriate actions
		}
	};
	return (
	<Paper sx={{p:2}}>
    <Container  sx={{ pb: 1 }} >
        <Box sx={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}}>

            <Button  variant="text" onClick={() => { HandleAdd(formdata); }}>Add Education</Button>
        </Box>
    {/* <EducationDialog state={[dialogOpen, setDialogOpen]} control={control} dialogClose={dialogClose} dialogOpen={dialogOpen} operation={operation} /> */}
    
        {formdata.map((item)=>{
            if (item.Education_qualification == '10th' ){
                return(
                    <Grid   container>
                <Grid item>
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                       <Typography fontWeight={800} variant="subtitle1">Class X</Typography>
                        <Toolbar>
                            <div style={{ marginLeft: 'auto' }}> 
                                <Tooltip title="Edit this section">
                                    <IconButton onClick={() => { HandleEdit(item); }}>
                                        <img style={{width:'14px',height:'14px'}} src={edit}/>
                                    </IconButton >
                                </Tooltip>
                                {formdata.length > 1 ? (
                                <Tooltip title="Delete this section">
                                    <IconButton onClick={() =>{ setClickedRow(item); setDialogOpen(true); setOperation('delete'); }}>
                                    <img style={{width:'14px', height:'14px'}} src={bin}/> 
                                    </IconButton>
                                </Tooltip>) : null }
                            </div>
                        </Toolbar>
                    </Box>
                    <Typography  variant="body2">{item.University}</Typography>
                    <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="body2">{item.Board}</Typography>
                    
                    <Divider  sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
                    <Typography variant="body2">{item.passOutYear ? item.passOutYear  : null }</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="body2">{item.persentage}%</Typography>
                    </Box>
                </Grid>
            </Grid>
                )
        }}) }

        {formdata.map((item)=>{
            if (item.Education_qualification == '12th'){
                return(
                    <Grid container>
                        <Grid item>
                            <Box display={"flex"} columnGap={1} alignItems={"center"}>
                            <Typography fontWeight={800} variant="subtitle1">Class XII </Typography>
                                <Toolbar>
                                    <div style={{ marginLeft: 'auto' }}> 
                                        <Tooltip title="Edit this section">
                                            <IconButton onClick={() => { HandleEdit(item) }}>
                                                <img style={{width:'14px',height:'14px'}} src={edit}/>
                                            </IconButton >
                                        </Tooltip>
                                        {formdata.length > 1 ? (
                                        <Tooltip title="Delete this section">
                                            <IconButton onClick={() =>{ setClickedRow(item); setDialogOpen(true); setOperation('delete'); }}>
                                            <img style={{width:'14px', height:'14px'}} src={bin}/> 
                                            </IconButton>
                                        </Tooltip>) : null }
                                    </div>
                                </Toolbar>
                            </Box>
                            <Typography variant="body2">{item.University}</Typography>
                            <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="body2">{item.Board}</Typography>
                            
                            <Divider sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
                            <Typography variant="body2">{item.passOutYear ? item.passOutYear  : null }</Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="body2">{item.persentage}%</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }}) }

{formdata.map((item)=>{

    if (item.Education_qualification == 'undergraduate'){

    return(
        <Grid    container>
        <Grid item>
            <Box display={"flex"} columnGap={1} alignItems={"center"}>
                <Typography fontWeight={800} variant="subtitle1">{item.Course} </Typography>
                {/* <Typography variant="subtitle2">{item.stream}</Typography> */}
                <Typography variant="body2"> ( {item.Specialization} )</Typography>
                <Toolbar>
                    <div style={{ marginLeft: 'auto' }}> {/* Use a div to align items to the right */}
                        <Tooltip title="Edit this section">
                            <IconButton  onClick={() => { HandleEdit(item) }}>
                                <img style={{width:'14px',height:'14px'}} src={edit}/>
                            </IconButton >
                        </Tooltip>
                        {formdata.length > 1 ? (
                        <Tooltip title="Delete this section">
                            <IconButton onClick={() =>{ setClickedRow(item); setDialogOpen(true); setOperation('delete'); }}>
                            <img style={{width:'14px', height:'14px'}} src={bin}/> 
                            </IconButton> 
                        </Tooltip>) : null }
                    </div>
                </Toolbar>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Typography variant="subtitle2">{item.University}</Typography>
                <Typography variant="subtitle2">{item.percentage}</Typography>
                
            </Box>
            <Box display={"flex"} columnGap={1} alignItems={"center"}>
                <Typography variant="body2"> {item.year_of_joing}</Typography>
                <Typography variant="body2"> {item.Year_of_passing}</Typography>
                <Divider sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
                <Typography variant="body2">{item.course_type}</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
               
                <Typography variant="subtitle2">{item.persentage}%</Typography>
                
            </Box>
        </Grid>
    </Grid>
 
    )
}}) }

{formdata.map((item)=>{

if (item.Education_qualification == 'postgraduate'){
    return(
        <Grid  container>
        <Grid item>
            <Box display={"flex"} columnGap={1} alignItems={"center"}>
                        <Typography  fontWeight={800} variant="subtitle1">{item.Course} </Typography>
                        <Typography variant="body2"> ( {item.Specialization} )</Typography>
                        <Toolbar>
                            <div style={{ marginLeft: 'auto' }}> 
                                <Tooltip title="Edit this section">
                                    <IconButton onClick={() => { HandleEdit(item) }}>
                                        <img style={{width:'14px',height:'14px'}} src={edit}/>
                                    </IconButton >
                                </Tooltip>
                                {formdata.length > 1 ? (
                                <Tooltip title="Delete this section">
                                    <IconButton onClick={() =>{ setClickedRow(item); setDialogOpen(true); setOperation('delete'); }}>
                                    <img style={{width:'14px', height:'14px'}} src={bin}/> 
                                    </IconButton>
                                </Tooltip>) : null }
                            </div>
                        </Toolbar>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography variant="subtitle2">{item.University}</Typography>
                    </Box>
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                        <Typography variant="body2"> {item.year_of_joing}</Typography>
                        <Typography variant="body2"> {item.Year_of_passing}</Typography>
                        
                        <Divider sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
                        <Typography variant="body2">{item.course_type}</Typography>
                    </Box>
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                    <Typography variant="subtitle2">{item.persentage}%</Typography>
                      
                    </Box>
        </Grid>
    </Grid>
    )
}}) }

{formdata.map((item)=>{

if (item.Education_qualification == 'doctorate'){

    return(
        <Grid  container>
        <Grid item>
            <Box display={"flex"} columnGap={1} alignItems={"center"}>
                        <Typography fontWeight={800} variant="subtitle1">{item.Course} </Typography>
                        <Typography variant="body2"> ( {item.Specialization} )</Typography>
                        <Toolbar>
                            <div style={{ marginLeft: 'auto' }}> 
                                <Tooltip title="Edit this section">
                                    <IconButton onClick={() => { HandleEdit(item) }}>
                                        <img style={{width:'14px',height:'14px'}} src={edit}/>
                                    </IconButton >
                                </Tooltip>
                                {formdata.length > 1 ? (
                                <Tooltip title="Delete this section">
                                    <IconButton onClick={() =>{ setClickedRow(item); setDialogOpen(true); setOperation('delete'); }}>
                                    <img style={{width:'14px', height:'14px'}} src={bin}/> 
                                    </IconButton>
                                </Tooltip>) : null}
                            </div>
                        </Toolbar>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography variant="subtitle2">{item.University}</Typography>
                    </Box>
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                        <Typography variant="body2">{item.year_of_joing} - {item.Year_of_passing}</Typography>
                        <Divider sx={{ height: 15, m: 0.5, ml:1 }} orientation="vertical" />
                        <Typography variant="body2">{item.course_type}</Typography>

                        <Typography variant="body2"></Typography>

                    </Box>
                    
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography variant="subtitle2">{item.persentage}%</Typography>
                    </Box>
                    
        </Grid>
    </Grid>
    )
}}) }
    {formdata.length > 1 ? (<Deletemodel
            dialogDisplay={dialogOpen}
            setDialogDisplay={setDialogOpen}
            fetchProfileData={fetchProfileData}
            url={"candidate/delete_education/"}
            data={{ id: clickedRow ? clickedRow.id : null }}
            handleDelete={handleDelete} // Pass the handleDelete function
            type="education"
        />) : null }

        <EducationDialog
                dialogClose={dialogClose}
                dialogOpen={dialogOpen}
                form={formData}
                operation={operation}
                control={control}
                education = {education}
                educations = {educations}
                dropdowndata = {dropdowndata}
                setValue={setValue}
                fetchProfileData={fetchProfileData}
       

            />

    </Container>
</Paper>
)}

function EducationDialog({ education,educations, dialogClose ,dropdowndata , fetchProfileData , operation, dialogOpen , form}) {
    const theme = useTheme();
    const [selectedQualification, setSelectedQualification] = useState(null);
    const [tryset, setTryset] = useState([]);



    const {reset, getValues , watch,control,handleSubmit, setValue: setFormValue} = useForm({
        
            defaultValues: {
                tryme : true,
                resume: '',
                highest_qualification: '',
                Gender: '',
                country : "",
                state : "",
                city : "",
                primary_graducation : false,
    
                candidates_qualification : {
                  


                    candidates_qualifications : []
                    },
            
            }


        });

    console.log(getValues("candidates_qualification.year_of_joing"),"candidates_qualification_candidates_qualification")

    const {tenth , setTenth} =  useState(false);
   

    const [userPerferData] = "";

  const [formDatas, setFormDatas] = useState({
    Board : "",
    passOutYear :"",
    School_medium : "",
    percentage:"",
    Education_qualification :"",
    Specialization :"",
    Total_marks :"",
    University :"",
    Year_of_passing :"",
    course_type :"",
    persentage :"",
    primary_graducation :"",
    year_of_joing :"",
    Course : "",
  });



  
	

	useEffect(() => {
		if (educations) {
			setFormDatas({

            id : educations.id || '',
            Board: educations.Board || '',
            passOutYear: educations.passOutYear || "",
            School_medium: educations.School_medium || "",
            percentage: educations.persentage || "",
            Education_qualification : educations.Education_qualification || "",
            Course : educations.Course || "",
            Specialization : educations.Specialization || "",
            Total_marks : educations.Total_marks || "",
            University : educations.University || "",
            Year_of_passing : educations.Year_of_passing || "",
            course_type : educations.course_type || "",
            primary_graducation : educations.primary_graducation || "",
            year_of_joing : educations.year_of_joing || "",

			});
		}
	}, [educations]);
	
	
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name,value,".....")
	
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };


    const handleBoardChange = (event, value) => {
        // Update formDatas with the selected board value
        setFormDatas({ ...formDatas, Board: value });
      };
      

    const handleBoardChanges = (event, value) => {
        // Update formDatas with the selected board value
        setFormDatas({ ...formDatas, University: value });
      };
      


  const handleEdits = async (e) => {
    e.preventDefault();

    const formData = {
        id : educations.id,
        Board: formDatas.Board,
        passOutYear: formDatas.passOutYear,
        School_medium: formDatas.School_medium,
        percentage: formDatas.percentage,

        Education_qualification : formDatas.Education_qualification,
        Course : formDatas.Course,
        Specialization : formDatas.Specialization,
        Total_marks : formDatas.Total_marks,
        University : formDatas.University,
        Year_of_passing : formDatas.Year_of_passing,
        course_type : formDatas.course_type,
        primary_graducation : formDatas.primary_graducation,
        year_of_joing : formDatas.year_of_joing,

  
    };
    try {  
        let editresponse;
        // Determine which qualification type is being submitted and call the appropriate API function
        switch (education) {
            case "10th":
                editresponse = await Edit_School_qualification(formData);
                break;
            case "12th":
                editresponse = await Edit_School_qualification(formData);
                break;
            case "undergraduate":
                editresponse = await Edit_Graduate_qualification(formData);
                break;
            case "postgraduate":
                editresponse = await Edit_post_Graduate_qualification(formData);
                break;
            case "doctorate":
                editresponse = await Edit_doctorate_qualification(formData);
                break;
            default:
                // Handle unknown qualification types if necessary
                break;
        }
        // const editresponse = await Edit_School_qualification(formData);	
        if (editresponse.status === 200) {
            const response = await get_profile();
            if (response.status === 200) {
                fetchProfileData()
                setAlert(alertState({
                message: "Added successfuly",
                severity: "success",
                show: true
                }));
            }

        }else {
            throw new Error('Invalid input: The value must be a positive number');
        }
                
    }catch (error) {
        setAlert(alertState({
            message: error.message,
            severity: "error",
            show: true
          }));
            // window.location.reload()

    }
    dialogClose();
};




   
const { setAlert } = useContext(AlertToast);

const onSubmit = async (resdata) => {
    const myJSON = JSON.stringify(resdata);
    console.log(resdata, "highest_qualification_details1");
    


    try {
        let response;
        // Determine which qualification type is being submitted and call the appropriate API function
        switch (resdata.highest_qualification) {
            case "10th":
                response = await add_school_qualification(resdata);
                break;
            case "12th":
                response = await add_school_qualification(resdata);
                break;
            case "undergraduate":
                response = await Add_Graduate_qualification(resdata);
                break;
            case "postgraduate":
                response = await add_post_graduate_qualification(resdata);
                break;
            case "doctorate":
                response = await add_doctorate_qualification(resdata);
                break;
            default:
                // Handle unknown qualification types if necessary
                break;
        }

        if (response.status === 200) {
            
            fetchProfileData()
            dialogClose();
            reset()


        }else{
            setAlert(alertState({
                title: "",
                message: "Added successfully",
                severity: "error",
                show: true
            }));
        }
    } catch (error) {
        console.error("Error:", error);
    }
};

    const w_qualification = watch("highest_qualification");

    useEffect(() => {
        switch (w_qualification) {
            case "10th":
                setSelectedQualification(<Tenth control={control} name="tenth" />);
                break;
            case "12th":
                setSelectedQualification(<Twelfth control={control} name="twelfth" />);
                break;
            case "undergraduate":
                setSelectedQualification(<Undergraduate control={control} name="undergraduate" />);
                break;
            case "postgraduate":
                setSelectedQualification(<Postgraduate control={control} name="postgraduate" />);
                break;
            case "doctorate":
                setSelectedQualification(<Doctorate control={control} name="doctorate" />);
                break;
            default:
                setSelectedQualification(null);
        }

    }, [w_qualification] , );

    
  



    

    const eduTopic = [
        {
            name : "10th Pass" ,
            value : "10th"
        } ,

        {
            name : "12th Pass" ,
            value : "12th"
        } ,

        {
            name : "undergraduate" ,
            value : "undergraduate"
        } ,

        {
            name : "postgraduate" ,
            value : "postgraduate"
        } ,

        {
            name : "doctorate" ,
            value : "doctorate"
        } ,
    ]

    
    
    console.log("educationswwwwkkkkkkkkkkkkk",dropdowndata)
    const filteredEduTopic = []
    useEffect(()=> {
        
        dropdowndata?.forEach((item)=>{
            if(item.Education_qualification == "12th" || item.Education_qualification == "10th" ) {
        filteredEduTopic.push(item.Education_qualification) }
        })
        // if (educationss) {
        // }
    },[dropdowndata])

    
    
    
    console.log(filteredEduTopic,"filteredEduTopic")
    

    // const finalEduList = eduTopic.filter((item)=>!filteredEduTopic.includes(item.value))
    
    useEffect(()=> {
        setTryset(eduTopic.filter((item)=>!filteredEduTopic.includes(item.value)))
        
        console.log("test11111111111111111111111")
    
    },[dropdowndata])
    
    
    useEffect(()=> {
        
        console.log("educationswwww1",tryset)
        
    },[tryset])
//     const jobTitles = useMemo(() =>  ['Software Engineer',
// 	'Web Developer',
// 	'Data Scientist',
// 	'Product Manager',
// 	'UX/UI Designer',
// 	'Network Administrator',
// 	'Marketing Specialist',
// 	'Financial Analyst',
// 	'Human Resources Manager',
// 	'Customer Support Representative',
	
//   ])


const boardOptions = [
    'Tamil Nadu Board',
    'State Board',
    'CBSE Board',
    'ICSE Board',
    'UP Board',
    'Maharashtra State Board',
    'Rajasthan Board',
    'Karnataka State Board',
    'West Bengal Board',
    'Gujarat Board',
    'Bihar Board',
    'Madhya Pradesh Board',
    'Punjab State Board',
    'Haryana Board',
    'Kerala State Board',
    'Telangana State Board',
    'Odisha Board',
    'Assam Board',
    'Jharkhand Board',
    'Chhattisgarh Board',
    'Uttarakhand Board',
    'Himachal Pradesh Board',
    'Goa Board',
    'Nagaland Board',
    'Manipur Board',
    'Tripura Board',
    'Meghalaya Board',
    'Arunachal Pradesh Board',
    'Mizoram Board',
    'Sikkim Board',
    // Add more board options as needed
  ];


  const chennaiColleges = [
    "Anna University",
    "Indian Institute of Technology Madras (IIT Madras)",
    "Madras Institute of Technology (MIT)",
    "SRM Institute of Science and Technology",
    "Vellore Institute of Technology (VIT) Chennai Campus",
    "Stella Maris College",
    "Loyola College, Chennai",
    "Madras Christian College (MCC)",
    "Presidency College, Chennai",
    "Ethiraj College for Women",
    "DG Vaishnav College",
    "MOP Vaishnav College for Women",
    "Women's Christian College (WCC)",
    "The New College, Chennai",
    "Madras Medical College (MMC)",
    "Stanley Medical College (SMC)",
    "Kilpauk Medical College (KMC)",
    "Madras Veterinary College",
    "Madras Dental College",
    "Government College of Fine Arts, Chennai",
    "Government College of Technology, Coimbatore - Chennai Campus",
    "Madras School of Economics",
    "Madras School of Social Work",
    "Central Institute of Plastics Engineering & Technology (CIPET), Chennai",
    "Central Leather Research Institute (CLRI), Chennai",
    "National Institute of Fashion Technology (NIFT), Chennai",
    "National Institute of Technology (NIT), Tiruchirappalli - Chennai Campus",
    "Vivekananda College",
    "Pachaiyappa's College",
    "Queen Mary's College, Chennai",
    "Bharath Institute of Higher Education and Research",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Jeppiaar Engineering College",
    "Panimalar Engineering College",
    "Sri Sairam Engineering College",
    "Sri Sai Ram Institute of Technology",
    "Sathyabama Institute of Science and Technology",
    "Saveetha Engineering College",
    "Hindustan Institute of Technology and Science (HITS)",
    "Meenakshi College of Engineering",
    "St. Joseph's College of Engineering",
    "Easwari Engineering College",
    "Sri Venkateswara College of Engineering",
    "Rajalakshmi Engineering College",
    "Velammal Engineering College",
    "SRM Easwari Engineering College",
    "KCG College of Technology",
    "Sri Sai Ram Engineering College",
    "Apollo Engineering College",
    "Alpha College of Engineering",
    "Anand Institute of Higher Technology",
    "Asan Memorial College of Engineering and Technology",
    "Dhaanish Ahmed College of Engineering",
    "Jerusalem College of Engineering",
    "Kings Engineering College",
    "Prince Shri Venkateshwara Padmavathy Engineering College",
    "R.M.K. Engineering College",
    "Rajiv Gandhi College of Engineering",
    "Rajalakshmi Institute of Technology",
    "St. Peter's Engineering College",
    "Sri Krishna Engineering College",
    "Sri Muthukumaran Institute of Technology",
    "Sriperumbudur Institute of Technology",
    "Thangavelu Engineering College",
    "Thirumalai Engineering College",
    "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Apollo Arts and Science College",
    "Bharathi Women's College",
    "Guru Nanak College",
    "Justice Basheer Ahmed Sayeed College for Women",
    "Queen Mary's College",
    "Shri Krishnaswamy College for Women",
    "SIET College",
    "Womens Christian College",
    "MGR Educational and Research Institute",
    "Madha Engineering College",
    "Sakthi Mariamman Engineering College",
    "Sree Sastha Institute of Engineering and Technology",
    "SRM Valliammai Engineering College",
    "St. Joseph's Institute of Technology",
    "Thangavelu Engineering College",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Valliammai Engineering College",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "C. Abdul Hakeem College",
    "Anna Adarsh College for Women",
    "Bhaktavatsalam Memorial College for Women",
    "Dr. Ambedkar Government Law College",
    "The School of the Photographic Society of Madras",
    "D.G. Vaishnav College",
    "Don Bosco College, Chennai",
    "Government College of Fine Arts, Chennai",
    "Hindustan College of Arts and Science",
    "Madras School of Social Work",
    "Madras Christian College",
    "Pachaiyappa's College",
    "Quaid-e-Millath Government College for Women",
    "Quaid-E-Millath Government College for Women",
    "Sri Ramachandra Medical College and Research Institute",
    "Sathyabama Institute of Science and Technology",
    "Sri Ramachandra Institute of Higher Education and Research",
    "Madras Medical College and Government General Hospital",
    "Madras Institute of Fashion Technology",
    "National Institute of Fashion Technology",
    "Tamil Nadu Dr. Ambedkar Law University",
    "University of Madras",
    "Apollo College of Nursing",
    "BS Abdur Rahman Crescent Institute of Science and Technology",
    "Chennai Medical College Hospital and Research Centre",
    "Chennai School of Ship Management",
    "Chennai School of Business Excellence",
    "Dhaanish Ahmed College of Engineering",
    "Ethiraj College for Women",
    "Government College of Fine Arts, Chennai",
    "Government Kilpauk Medical College",
    "KCG College of Technology",
    "Loyola College, Chennai",
    "Madras Christian College",
    "Madras Institute of Fashion Technology",
    "Madras School of Social Work",
    "MGR Educational and Research Institute",
    "National Institute of Fashion Technology",
    "Rajiv Gandhi College of Engineering",
    "Sathyabama Institute of Science and Technology",
    "Sri Ramachandra Institute of Higher Education and Research",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Institute of Science and Technology",
    "St. Joseph's College of Engineering",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Velammal College of Engineering and Technology",
    "Vellore Institute of Technology Chennai Campus",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "St. Joseph's College of Engineering",
    "Easwari Engineering College",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Valliammai Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Meenakshi College of Engineering",
    "Velammal Engineering College",
    "Sri Sairam Engineering College",
    "St. Peter's Engineering College",
    "D.G. Vaishnav College",
    "Women's Christian College",
    "Stella Maris College",
    "Ethiraj College for Women",
    "Loyola College, Chennai",
    "Presidency College, Chennai",
    "DG Vaishnav College",
    "MOP Vaishnav College for Women",
    "Queen Mary's College, Chennai",
    "Bharathi Women's College",
    "SIET College",
    "Sri Ramachandra Medical College and Research Institute",
    "Sri Ramachandra Institute of Higher Education and Research",
    "Madras Medical College and Government General Hospital",
    "Madras Institute of Fashion Technology",
    "National Institute of Fashion Technology",
    "Tamil Nadu Dr. Ambedkar Law University",
    "University of Madras",
    "Chennai Medical College Hospital and Research Centre",
    "Chennai School of Ship Management",
    "Chennai School of Business Excellence",
    "BS Abdur Rahman Crescent Institute of Science and Technology",
    "Apollo College of Nursing",
    "Apollo Arts and Science College",
    "Bharath Institute of Higher Education and Research",
    "Chennai Institute of Technology",
    "Dr. M.G.R. Educational and Research Institute University",
    "Indian Institute of Information Technology, Design and Manufacturing (IIITDM), Kancheepuram - Chennai Campus",
    "Saveetha Institute of Medical and Technical Sciences",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Velammal College of Engineering and Technology",
    "Velammal Institute of Technology",
    "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Sree Sastha Institute of Engineering and Technology",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology",
    "Vellore Institute of Technology Chennai Campus",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Velammal College of Engineering and Technology",
    "Velammal Institute of Technology",
    "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Sree Sastha Institute of Engineering and Technology",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Chennai Institute of Technology",
    "Dr. M.G.R. Educational and Research Institute University",
    "Indian Institute of Information Technology, Design and Manufacturing (IIITDM), Kancheepuram - Chennai Campus",
    "Saveetha Institute of Medical and Technical Sciences",
    "BS Abdur Rahman Crescent Institute of Science and Technology",
    "Apollo College of Nursing",
    "Apollo Arts and Science College",
    "Bharath Institute of Higher Education and Research",
    "Chennai Institute of Technology",
    "Dr. M.G.R. Educational and Research Institute University",
    "Indian Institute of Information Technology, Design and Manufacturing (IIITDM), Kancheepuram - Chennai Campus",
    "Saveetha Institute of Medical and Technical Sciences",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Velammal College of Engineering and Technology",
    "Velammal Institute of Technology",
    "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Sree Sastha Institute of Engineering and Technology",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Apollo College of Nursing",
    "Apollo Arts and Science College",
    "Bharath Institute of Higher Education and Research",
    "BS Abdur Rahman Crescent Institute of Science and Technology",
    "Chennai Institute of Technology",
    "Dr. M.G.R. Educational and Research Institute University",
    "Indian Institute of Information Technology, Design and Manufacturing (IIITDM), Kancheepuram - Chennai Campus",
    "Saveetha Institute of Medical and Technical Sciences",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Velammal College of Engineering and Technology",
    "Velammal Institute of Technology",
    "Vel Tech High Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    "Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College",
    "Vels Institute of Science, Technology & Advanced Studies (VISTAS)",
    "Sree Sastha Institute of Engineering and Technology",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras",
    "Apollo College of Nursing",
    "Apollo Arts and Science College",
    "Bharath Institute of Higher Education and Research",
    "BS Abdur Rahman Crescent Institute of Science and Technology",
    "Chennai Institute of Technology",
    "Dr. M.G.R. Educational and Research Institute University",
    "Indian Institute of Information Technology, Design and Manufacturing (IIITDM), Kancheepuram - Chennai Campus",
    "Saveetha Institute of Medical and Technical Sciences",
    "Sri Sivasubramaniya Nadar College of Engineering",
    "SRM Valliammai Engineering College",
    "Tamil Nadu Physical Education and Sports University",
    "Tamil Nadu Veterinary and Animal Sciences University",
    "Tamil Nadu Open University",
    "Tamil Nadu Music and Fine Arts University",
    "Tamil Nadu Dr. Ambedkar Law University",
    "The Tamil Nadu Dr. M.G.R. Medical University",
    "The Tamil Nadu Dr. J. Jayalalithaa Fisheries University",
    "University of Madras"
  ];
  

  

const uniqueChennaiCollegesSet = new Set(chennaiColleges);

// Convert set back to array
const uniqueChennaiColleges = Array.from(uniqueChennaiCollegesSet);

    
    

    
    if (operation === 'edit'){
        return (
            <>
            <Dialog fullWidth={true} maxWidth={'sm'} open={dialogOpen} onClose={dialogClose}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        Education <EditOutlined style={{ marginLeft: "auto" }} />
                    </Box>
                </DialogTitle>
                <DialogContent>
                <Stack>
                { education ===  "10th" ? (
                    <form onSubmit={handleEdits}  > 
                    	 <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                  gap={0 + ' ' + theme.spacing(3)} sx={{ 
                    
                      [theme.breakpoints.up("sm")]: {
                          gridTemplateColumns: "repeat(2, 1fr)",
                      } , mt:3 

                  }}>


                            <Autocomplete
                                value={formDatas.Board}
                                onChange={handleBoardChange}
                                options={boardOptions}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    name="Board"
                                    label="Board"
                                    fullWidth
                                    required
                                    />
                                )}
                                />

                                
                            {/* </Grid> */}
                            {/* <Grid item xs={12} sm={6}> */}
                                <TextField
                                    name="passOutYear"
                                    value={formDatas.passOutYear}
                                    onChange={handleInputChange}
                                    label="passOutYear"
                                    fullWidth
                                    
                                    
                                
                                />
                            {/* </Grid> */}
                            {/* <Grid item xs={12} sm={6}> */}
                            <TextField
                                    name="University"
                                    value={formDatas.University}
                                    onChange={handleInputChange}
                                    label="School Name"
                                    fullWidth
                                />

                                
                            {/* </Grid> */}
                           
                            {/* <Grid item xs={12} sm={6}> */}
                                <TextField
                                    name="School_medium"
                                    value={formDatas.School_medium}
                                    onChange={handleInputChange}
                                    label="School Medium"
                                    fullWidth
                            
                                />
                            {/* </Grid> */}
                            {/* <Grid item xs={12} sm={6}> */}
                                <TextField
                                    name="percentage"
                                    value={formDatas.percentage}
                                    onChange={handleInputChange}
                                    label="percentage"
                                    fullWidth
                     
                               
                                />
                          
                        </Box>
                        <DialogActions>
                            <Button onClick={dialogClose} variant="text">Cancel</Button>
                            <Button type="submit"  variant="text">Update</Button>
                        </DialogActions>
                    </form>

                    ) :  education === '12th' ? <form onSubmit={handleEdits}  > 

                <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                  gap={0 + ' ' + theme.spacing(3)} sx={{ 
                    
                      [theme.breakpoints.up("sm")]: {
                          gridTemplateColumns: "repeat(2, 1fr)",
                      } , mt:3 

                  }}>

                       
                        <Autocomplete
                                value={formDatas.Board}
                                onChange={handleBoardChange}
                                options={boardOptions}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    name="Board"
                                    label="Board"
                                    fullWidth
                                    required
                                    />
                                )}
                                />

                                 <TextField
                                    name="University"
                                    value={formDatas.University}
                                    onChange={handleInputChange}
                                    label="School Name"
                                    fullWidth
                                />



     
                            <TextField
                                name="passOutYear"
                                value={formDatas.passOutYear}
                                onChange={handleInputChange}
                                label="passOutYear"
                                fullWidth
                            
                            />
                   
                            <TextField
                                name="School_medium"
                                value={formDatas.School_medium}
                                onChange={handleInputChange}
                                label="School_medium"
                                fullWidth
                        
                            />
             
                            <TextField
                                name="percentage"
                                value={formDatas.percentage}
                                onChange={handleInputChange}
                                label="percentage"
                                fullWidth
                            />
                      
            
                    
                </Box>
                <DialogActions>
                        <Button onClick={dialogClose} variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Update</Button>
                </DialogActions>
                </form>
                : education === 'undergraduate' ? <form onSubmit={handleEdits}  > 

                    <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                                    gap={0 + ' ' + theme.spacing(3)} sx={{ 
                                        
                                        [theme.breakpoints.up("sm")]: {
                                            gridTemplateColumns: "repeat(2, 1fr)",
                                        } , mt:3 

                                    }}>

           
                            {/* <TextField
                                name="University"
                                value={formDatas.University}
                                onChange={handleInputChange}
                                label="University"
                                fullWidth
                            />
            */}
                        <Autocomplete
                            value={formDatas.University}
                            onChange={handleBoardChanges}
                            options={uniqueChennaiColleges}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                name="Board"
                                label="University"
                                fullWidth
                                required
                                />
                            )}
                                />
       
                    
                            <TextField
                                name="Course"
                                value={formDatas.Course}
                                onChange={handleInputChange}
                                label="Course"
                                fullWidth
                            />

            
                    
                            <TextField
                                name="Specialization"
                                value={formDatas.Specialization}
                                onChange={handleInputChange}
                                label="Specialization"
                                fullWidth
                            />

                 
                            <TextField
                                name="year_of_joing"
                                value={formDatas.year_of_joing}
                                onChange={handleInputChange}
                                label="Year Of Joing"
                                fullWidth
                            
                            />
                 
                            <TextField
                                name="Year_of_passing"
                                value={formDatas.Year_of_passing}
                                onChange={handleInputChange}
                                label="Year Of Passing"
                                fullWidth
                        
                            />
                     
                            <TextField
                                name="percentage"
                                value={formDatas.percentage}
                                onChange={handleInputChange}
                                label="Percentage"
                                fullWidth
                            />

                        <Grid item xs={12} sm={12} md={6}>
								<h4>Course Type</h4>
                        </Grid>
                        <Box></Box>
								<RadioGroup 
                                    row
									name="course_type" 
									value={formDatas.course_type}
									onChange={handleInputChange}
								>
									<FormControlLabel value="Full Time" control={<Radio />} label="FullTime" />
									<FormControlLabel value="Part Time" control={<Radio />} label="PartTime" />
									<FormControlLabel value="Distance Learning" control={<Radio />} label="DistanceLearning" />
								</RadioGroup>
							
                    </Box>
                    <DialogActions>
                        <Button onClick={dialogClose} variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Update</Button>
                    </DialogActions>
                </form> 
                :  education === 'postgraduate' ? <form onSubmit={handleEdits}  > 
                
                <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                                    gap={0 + ' ' + theme.spacing(3)} sx={{ 
                                        
                                        [theme.breakpoints.up("sm")]: {
                                            gridTemplateColumns: "repeat(2, 1fr)",
                                        } , mt:3 

                                    }}> 

     
                        <Autocomplete
                            value={formDatas.University}
                            onChange={handleBoardChanges}
                            options={uniqueChennaiColleges}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                name="Board"
                                label="University"
                                fullWidth
                                required
                                />
                            )}
                                />

                    
                    
                            <TextField
                                name="Course"
                                value={formDatas.Course}
                                onChange={handleInputChange}
                                label="Course"
                                fullWidth
                            />

        
                    
                            <TextField
                                name="Specialization"
                                value={formDatas.Specialization}
                                onChange={handleInputChange}
                                label="Specialization"
                                fullWidth
                            />

                            <TextField
                                name="year_of_joing"
                                value={formDatas.year_of_joing}
                                onChange={handleInputChange}
                                label="Year Of Joing"
                                fullWidth
                            
                            />
   
                            <TextField
                                name="Year_of_passing"
                                value={formDatas.Year_of_passing}
                                onChange={handleInputChange}
                                label="Year Of Passing"
                                fullWidth
                        
                            />
                   
                        {/* <Grid item xs={12} sm={6}> */}
                            {/* <TextField
                                name="course_type"
                                value={formDatas.course_type}
                                onChange={handleInputChange}
                                label="course_type"
                                fullWidth
                
                        
                            /> */}
                        
                        {/* </Grid> */}
                     
                            <TextField
                                name="percentage"
                                value={formDatas.percentage}
                                onChange={handleInputChange}
                                label="Percentage"
                                fullWidth
                            />
                   
                        <Grid item xs={12} sm={12} md={6}>
								<h4>Course Type</h4>
								<RadioGroup 
									name="course_type" 
									value={formDatas.course_type}
									onChange={handleInputChange}
								>
									<FormControlLabel value="Full Time" control={<Radio />} label="Full Time" />
									<FormControlLabel value="Part Time" control={<Radio />} label="Part Time" />
									<FormControlLabel value="Distance Learning" control={<Radio />} label="Distance Learning" />

								</RadioGroup>
							</Grid>
                    </Box>
                    <DialogActions>
                        <Button onClick={dialogClose} variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Update</Button>
                    </DialogActions>
                </form> 
                : education === 'doctorate' ? <form onSubmit={handleEdits}  > 
                <Grid component="form" noValidate container spacing={2}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                name="University"
                                value={formDatas.University}
                                onChange={handleInputChange}
                                label="University"
                                fullWidth
                            />


                        </Grid> */}
                        <Autocomplete
							multiple
							value={formDatas.University}
							onChange={handleInputChange}
							options={uniqueChennaiColleges}
							getOptionLabel={(option) => option}
							freeSolo
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Enter the skills you used in this project"
									label="Job position"
								/>
							)}
						/>	
                        <Grid item xs={12} sm={6}>
                    
                            <TextField
                                name="Course"
                                value={formDatas.Course}
                                onChange={handleInputChange}
                                label="Course"
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                    
                            <TextField
                                name="Specialization"
                                value={formDatas.Specialization}
                                onChange={handleInputChange}
                                label="Specialization"
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="year_of_joing"
                                value={formDatas.year_of_joing}
                                onChange={handleInputChange}
                                label="Year Of Joing"
                                fullWidth
                            
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="Year_of_passing"
                                value={formDatas.Year_of_passing}
                                onChange={handleInputChange}
                                label="Year Of Passing"
                                fullWidth
                        
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                name="course_type"
                                value={formDatas.course_type}
                                onChange={handleInputChange}
                                label="course_type"
                                fullWidth
                
                        
                            />
                        </Grid> */}
                         <Grid item xs={12} sm={6}>
                            <TextField
                                name="percentage"
                                value={formDatas.percentage}
                                onChange={handleInputChange}
                                label="Percentage"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
								<h4>Course Type </h4>
								<RadioGroup 
									name="course_type" 
									value={formDatas.course_type}
									onChange={handleInputChange}
								>
									<FormControlLabel value="Full Time" control={<Radio />} label="Full Time" />
									<FormControlLabel value="Part Time" control={<Radio />} label="Part Time" />
									<FormControlLabel value="Distance Learning" control={<Radio />} label="Distance Learning" />

								</RadioGroup>
							</Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={dialogClose} variant="text">Cancel</Button>
                        <Button type="submit"  variant="text">Update</Button>
                    </DialogActions>
                </form> 
                : null }
   
                </Stack>
                </DialogContent>
            </Dialog>
            </>
        );
    }

   else if(operation === 'add'){
     return (
        <>
        <Dialog fullWidth={true} maxWidth={'sm'} open={dialogOpen} onClose={dialogClose}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        Education <AddOutlined style={{ marginLeft: "auto" }} />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack>
                    <LibTextField
                            control={control}
                            name={"highest_qualification"}
                            textfieldProps={{
                                label: "Qualification",
                                select: true
                            }}
                        >
                              {/* {form.map((item)=>{item.Education_qualification == '10th' ? null :  <MenuItem value="10th">10th pass</MenuItem> })} */}
                            {/* {form.map((item) => (item.Education_qualification === '10th' ? null : <MenuItem value="10th">10th pass</MenuItem>))} */}
                             {tryset.map((item,index)=>{
                                return  <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                             })}
                           

                        </LibTextField>

                        {selectedQualification}

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() =>{ dialogClose();  setSelectedQualification(null); reset(); }} variant="text">Cancel</Button>
                    <Button onClick={handleSubmit(onSubmit) } variant="text">Add</Button>
                </DialogActions>
            </Dialog>
        </> 
        );
   }
}




