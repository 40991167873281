import { Paper, Toolbar, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, InputAdornment, DialogActions, Button, Box, FormControlLabel, Chip, Grid, Radio, Typography, Stack, TextField, Autocomplete } from "@mui/material";
import Checkbox from '@mui/material/Checkbox'
import { Alert, AlertTitle, CssBaseline,  ThemeProvider } from '@mui/material';
import { AlertToast,alertState } from "../../divine-constants";

import { Fragment, useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { Job_title_skills_dropdown, basicdetails, edit_user_prefer, get_profile } from "../Axiosfetch/Axiosfetch";
import{useContext} from "react"
import RadioGroup from '@mui/material/RadioGroup';
import edit from "../../images/edit.png";
import { textValidation, textValidationss } from "../../utils";
import { Close } from '@mui/icons-material';

export default function EditPreference({formData, control ,key_skills , setValue , candidate_persen}) {
	// const [formState, setFormState] = formData;
	const [dialogOpen, setDialogOpen] = useState(false);
	const theme = useTheme();
	// console.log(Object.values(formData[0]),"Edit Preference Form Data out side ")
	console.log((formData),"Edit Preference Form Data out side ")
	console.log(key_skills)
	const dialogClose = () => {
		setDialogOpen(false);
	}
	useEffect(() => {
		console.log(formData,"Edit Preference Form Data")
	}, [])

// 	const jobTitles = useMemo(() =>  [
// 	'Software Engineer',
// 	'Web Developer',
// 	'Data Scientist',
// 	'Product Manager',
// 	'UX/UI Designer',
// 	'Network Administrator',
// 	'Marketing Specialist',
// 	'Financial Analyst',
// 	'Human Resources Manager',
// 	'Customer Support Representative',
//   ])

//   const jobTitles = useMemo(() => {
//     return formData.map(item => item.Preferred_job_titles);
//   }, [formData]);
// console.log(Object.values(formData[0].Preferred_job_titles))
  const jobTitles = Object.values(formData[0].Preferred_job_titles)
  const locations = Object.values(formData[0].location)


//   const key_skills  = useMemo(() => {
//     return formData.map(item => item.Preferred_job_titles);
//   }, [formData]);

	// const jobTitles = useMemo(() =>{ return formData.Preferred_job_titles});

	const onSubmit = async (resdata) => {
		try {
		  const { status , data  } = await basicdetails(resdata);	
		  if (status === 200) {
		
		  }else if (status === 403) { 
	
		  }else {
			// throw new Error(`Something went wrong ${status}`);	
		  }
		} catch (error) {
		  console.error("Error:", error);
		}
	  };

	return <>
		<PersonalDetailsDialog state={[dialogOpen, setDialogOpen]} key_skills ={key_skills} setValue={setValue} candidate_percentage={candidate_persen} datas={formData} control={control} dialogClose={dialogClose} dialogOpen={dialogOpen} />
		<Toolbar>
			<Tooltip title="Edit this section">
				<IconButton sx={{ml:"auto"}} onClick={() => setDialogOpen(true)}>
					<img style={{width:15, height:15}} src={edit}/>
				</IconButton>
			</Tooltip>
		</Toolbar>
	<Paper>

	{
		
	
	formData[0].id !== "" ? 
	<>
	
		<Grid sx={{p:2}} container spacing={2}>
			<Grid item xs={12} sm={12} md={12} >
				<Paper sx={{backgroundColor:'#f5f6f7', padding:2}}>
					<Typography sx={{mb:1}} fontSize={15} fontWeight={'bold'}>Job Positions</Typography>
					<Stack direction={"row"} flexWrap={"wrap"} spacing={1} rowGap={1}>
						{jobTitles.map((skill, index)=> ( 
								<Fragment key={index}>
								<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={skill} key={index} />
								</Fragment>
							))}	
					</Stack>
				</Paper>
			</Grid>	

			<Grid item xs={12} sm={12} md={12} >
				<Paper sx={{padding:2, backgroundColor:'#f5f6f7'}}>
					<Typography sx={{mb:1}} fontSize={15} fontWeight={'bold'}>Key Skills</Typography>
						<Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={1} rowGap={1}>
							{key_skills.map((skill, index)=> (
									<Fragment key={index}>
									<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={skill} key={index} />
									</Fragment>
								))}	
						</Stack>
				</Paper>

				<Paper sx={{padding:2, backgroundColor:'#f5f6f7',mt:2}}>
					<Typography sx={{mb:2}} fontSize={15} fontWeight={'bold'}>Prefered Locations</Typography>
						<Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={1} rowGap={1}>
							{locations.map((skill, index)=> (
									<Fragment key={index}>
									<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={skill} key={index} />
									</Fragment>
								))}	
						</Stack>
				</Paper>
			</Grid>

			{formData[0].max_salary ?

					<Grid item xs={12} sm={12} md ={4}>
					<Paper sx={{padding:3, backgroundColor:'#f5f6f7'}}>
					<Typography fontSize={15} fontWeight={'bold'}>Expected Salary</Typography>
			
					<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={`RS ${formData[0].max_salary.toLocaleString("en-IN")}`}  />
					</Paper>
					</Grid>: null}
			{formData[0].Preferred_shift ?
					<Grid item xs={12} sm={12} md ={4}>
					<Paper sx={{padding:3, backgroundColor:'#f5f6f7',}}>
					<Typography fontSize={15} fontWeight={'bold'}>Prefered Shift</Typography>
					<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={formData[0].Preferred_shift} />
					</Paper>
					</Grid> : null}
					{formData[0].work_mode ?
					<Grid item xs={12} sm={12} md ={4}>
					<Paper sx={{padding:3, backgroundColor:'#f5f6f7',}}>
					<Typography fontSize={15} fontWeight={'bold'}>Work Mode</Typography>
					<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={formData[0].work_mode}  />
					</Paper>
					</Grid> : null}
		</Grid>
		

		
	</>  
	: key_skills.length > 0 ? 
		<Paper sx={{padding:2, backgroundColor:'#f5f6f7'}}>
		<Typography sx={{mb:1}} fontSize={15} fontWeight={'bold'}>Key Skills</Typography>
			<Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={1} rowGap={1}>
				{key_skills.map((skill, index)=> (
						<Fragment key={index}>
						<Chip size = {'medium'} sx={{backgroundColor:'white'}} label={skill} key={index} />
						</Fragment>
					))}	
			</Stack>
		</Paper> 
		:(
			<Grid sx={{p:2}} container spacing={2}>
				<Grid item xs={12} sm={12} md={12} >
					<Paper sx={{padding:3, backgroundColor:'#f5f6f7',  mt:2}}>
					<Typography sx={{mb:2}} fontSize={30} fontWeight={'bold'}> No preferences specified </Typography>
					
					</Paper>
				</Grid>
		  </Grid>)

		  } 	
	</Paper>
	</>
}

function PersonalDetailsDialog({state, dialogClose, control, dialogOpen , setValue , datas , key_skills , candidate_percentage}) {

	const[alertShow,setAlertShow]  = useState(false)
	const[AlertMessage,setAlertMessage] = useState("error")
	const[AlertSeverity,setAlertSeverity] = useState("success")
	const[AlertTitle,setAlertTitle] = useState(" ")

	const { setAlert } = useContext(AlertToast);
	const java_skills = useMemo(() =>  [
		"Java Programming",
		"Object-Oriented Programming (OOP)",
		"Java Standard Edition (Java SE)",
		"Java Enterprise Edition (Java EE)",
		"JavaServer Faces (JSF)",
		"Spring Framework",
		"Hibernate",
		"Servlets",
		"JavaServer Pages (JSP)",
		"RESTful Web Services",
		"Microservices Architecture",
		"Spring Boot",
		"Maven",
		"Gradle",
		"JUnit",
		"TestNG",
		"Version Control (Git, SVN)",
		"Database Management (SQL, JDBC)",
		"HTML, CSS, JavaScript",
		"Threading and Concurrency",
		"Design Patterns",
		"Dependency Injection",
		"Logging (SLF4J, Log4j)",
		"Build Tools (Ant)",
		"Continuous Integration/Continuous Deployment (CI/CD)",
		"Web Application Security",
		"Agile/Scrum Methodology",
		"Problem-Solving Skills",
		"Debugging and Profiling Tools",
		"Eclipse IDE or IntelliJ IDEA",
		"Understanding of JVM Internals",
		"Knowledge of Containers (Docker)",
		"Understanding of REST and SOAP",
		"Web Development Frameworks (Spring MVC)",
		"Web Application Servers (Tomcat, Jetty)",
		"JSON and XML Processing",
		"Unit Testing and Integration Testing",
		"Performance Optimization",
		"Cloud Technologies (AWS, Azure, GCP)",
		"Linux/Unix Basics"])

	const [userPerferData] = datas;
	const [formDatas, setFormDatas] = useState({
		emailUpdates: false,
		job_titles: '',
		location: [],
		max_salary: '',
		Skills: [],
		Preferred_shift: '',
		work_mode: '',
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
		  if (alertShow) {

			setAlertMessage("")
			setAlertSeverity("")
			setAlertShow(false)
		
		  }
		}, 4000);
		return () => clearTimeout(timeout);
	  }, [alertShow]);
	  

	useEffect(() => {
		if (userPerferData) {
			setFormDatas({
				id : userPerferData.id || '',
				emailUpdates: userPerferData.emailUpdates || false,
				work_mode: userPerferData.work_mode || '',
				job_titles: userPerferData.Preferred_job_titles || "",
				location: userPerferData.location || '',
				max_salary: userPerferData.max_salary || 0,
				Skills: key_skills || '',
				Preferred_shift: userPerferData.Preferred_shift || ''
			});
		}
	}, [userPerferData]);
	
	
    const handleInputChange = (e) => {
        const { name, value } = e.target;
	
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };

	
    const handleSkillsChange = (event, newValue) => {
        setFormDatas({
            ...formDatas,
            Skills: newValue
        });
    };
    const handlejobtitlesChange = (event, newValue) => {
        setFormDatas({
            ...formDatas,
            job_titles: newValue
        });
    };
    const handlelocationChange = (event, newValue) => {
        setFormDatas({
            ...formDatas,
            location: newValue
        });
    };

	const handleUpdate =async(e) => {
		e.preventDefault();
		const formData = {
			id : formDatas.id,
			emailUpdates: formDatas.emailUpdates,
			job_titles: formDatas.job_titles,
			work_mode: formDatas.work_mode,
			location: formDatas.location,
			max_salary: formDatas.max_salary,
			Preferred_shift: formDatas.Preferred_shift,
			key_skills: formDatas.Skills
		};

		if (formData.job_titles.length > 0 && formData.location.length > 0 && formData.key_skills.length > 0  ) {
	
		try {
			const response = await edit_user_prefer(formData); // Call your API function to api data
			
			if (response.status === 200) {
				const response = await get_profile(); // Call your API function to fetch profile data
			
				if (response.status === 200) {
				console.log(response.data.key_skills,"response.data.key_skills")
				setValue('Skills', response.data.key_skills);
				setValue('candidate_percentage', response.data.candidate_percentage);
				setValue('user_prefers', response.data.user_prefer); 
				   
				}
			  setAlert(alertState({
				message: "Added successfully",
				severity: "success",
				show: true
			  }));

			  
			  dialogClose();
			} else {
				throw new Error('Invalid input: The value must be a positive number');
				
			}
		} catch (error) {
			setAlertShow(true)

			// console.log(error)
			// setAlert(alertState({
			// 	message: error.message,
			// 	severity: "error",
			// 	show: true
			//   }));	
		}
	}else{

		setAlertShow(true)
		setAlertSeverity("error")
		setAlertMessage("Please fill in all inputs.")

		// setAlert(alertState({
		// 	message: "error",
		// 	severity: "error",
		// 	show: true
		//   }));

	}


		
    };

	
const [options, setOptions] = useState([]); // State to hold the fetched options
const [location, setLocation] = useState([]); // State to hold the fetched options

const fetchOptions = async() => {
  // Execute API call to fetch options

    try {
      const responce = await Job_title_skills_dropdown() 
      setOptions(responce.data)
      
    }
    catch{
  
    }


};
	
// 	const theme = useTheme();
	const jobTitles = useMemo(() =>  ['Software Engineer',
	'Web Developer',
	'Data Scientist',
	'Product Manager',
	'UX/UI Designer',
	'Network Administrator',
	'Marketing Specialist',
	'Financial Analyst',
	'Human Resources Manager',
	'Customer Support Representative',
	
  ])
	const skils = useMemo(() =>  ["Critical thinking", "Analytical skills", "Python", "Java", "Python", "HTML5", "FIgma", "Research skills", "Pandas", "React", "Angular", "NextJS", "Front end", "Back end",'Software Engineer'])
  const locations = [
	'Mumbai, Maharashtra',
	'Delhi',
	'Bangalore, Karnataka',
	'Chennai, Tamil Nadu',
	'Hyderabad, Telangana',
	'Kolkata, West Bengal',
	'Pune, Maharashtra',
	'Ahmedabad, Gujarat',
	'Jaipur, Rajasthan',
	'Lucknow, Uttar Pradesh',
	
  ];

	return (
		<Dialog fullWidth={true}
		maxWidth={'sm'} open={dialogOpen} onClose={dialogClose} sx={{position : "relative"}}>
			<DialogTitle>
				<Box display={"flex"} alignItems={"center"}>
					Edit preferences <img style={{width:15, height:15, marginLeft:'auto'}} src={edit}/>
				</Box>
			</DialogTitle>
			<DialogContent>
				{/* <form   onSubmit={handleUpdate} > */}
				<form   >

					<Box maxWidth="90%" marginInline="auto">
						{/* <Checkbox
						name={"emailUpdates"}
						value={formDatas.emailUpdates}
						onChange={handleInputChange}
						label="Receive job and internship updates to your  "
						fullWidth
						 /> */}

						<Autocomplete
							multiple
							value={formDatas.job_titles}
							onChange={handlejobtitlesChange}
							// options={jobTitles}

							// value={searchInput.location}
							// onChange={(event, value) => handleInputChange({ target: { name: "options", value } })}
							options={options} // Provide options for autocomplete
							onFocus={fetchOptions} 

							getOptionLabel={(option) => option}
							freeSolo
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Enter the Job position"
									label="Job position"
								/>
							)}
						/>	
						
						<Autocomplete
							multiple
							value={formDatas.Skills}
							onChange={handleSkillsChange}
							options={java_skills}
							getOptionLabel={(option) => option}
							freeSolo
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Enter the skills"
									label="Skills"
								/>
							)}
						/>
						
						<Autocomplete
							multiple
							value={formDatas.location}
							onChange={handlelocationChange}
							options={locations}
							getOptionLabel={(option) => option}
							freeSolo
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Enter the Location"
									label="Location"
								/>
							)}
						/>
						
						<TextField
							name="max_salary"
							value={formDatas.max_salary
							}

							onChange={handleInputChange}
							label="Expected Salary"
							fullWidth
							required
						/>
			
						
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={6}>
								<h4>Preferred Shift</h4>
								<RadioGroup 
									name="Preferred_shift" 
									value={formDatas.Preferred_shift} 
									onChange={handleInputChange}
								>
									<FormControlLabel value="Day Shift" control={<Radio />} label="Day Shift" />
									<FormControlLabel value="Night Shift" control={<Radio />} label="Night Shift" />
									<FormControlLabel value="Flexible" control={<Radio />} label="Flexible" />

								</RadioGroup>
							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								<h4>Work mode</h4>
								<RadioGroup 
									name="work_mode" 
									value={formDatas.work_mode} 
									onChange={handleInputChange}
								>
									<FormControlLabel value="Work from home " control={<Radio />} label="Work From Home" />
									<FormControlLabel value="Work from office" control={<Radio />} label="Work From Office" />
									<FormControlLabel value="Hybrid" control={<Radio />} label="Hybrid" />
								</RadioGroup>
							</Grid>
						</Grid>
					</Box>
					
					<DialogActions>
						<Button onClick={dialogClose} colour = "primary" >Cancel</Button>
						<Button onClick={handleUpdate}  type ="submit" colour = "primary" >Update</Button>
						{/* <Button type="submit" value="submit" >Submit</Button> */}
					</DialogActions>
				</form>

			</DialogContent>
			{/* <DialogContent>
				<form onSubmit={handleSubmit(handleUpdate)}>
					<Box  maxWidth="90%" marginInline="auto">
						<LibCheckbox 
							control={control}
							name={"emailUpdates"}
							checked={getValues("emailUpdates")}
							FormControlLabelProps={{
								label: "Receive job and internship updates to your email"
							}}
							/>
							<br />
							<br />
							<LibAutocomplete 
								control={control}
								options={jobTitles}
								name={"job_titles"}
								defaultValue={userPerferData.Preferred_job_titles}
								checked={getValues("job_titles")} 
								autocompleteProps={{
									multiple: true,
									getOptionLabel: option => option,
									freeSolo: true,
									renderTags: (value, getTagProps) =>
										value.map((option, index) => (
											<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
										)),
								}}
								textfieldProps={{
									placeholder: "Enter your preferred job titles",
									label: "Job position",
								}}
							/>
							<LibAutocomplete 
								control={control}
								options={["Critical thinking", "Analytical skills", "Python", "Java", "Python", "HTML5", "FIgma", "Research skills", "Pandas", "React", "Angular", "NextJS", "Front end", "Back end"]}
								name={"Skills"}
								defaultValue={key_skills.key_skills}
								checked={getValues("Skills")} 
								autocompleteProps={{
									multiple: true,
									getOptionLabel: option => option,
									freeSolo: true,
									renderTags: (value, getTagProps) =>
										value.map((option, index) => (
										<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
										)),
								}}
								textfieldProps={{
									placeholder: "Add your skills",
									label: "Skills"
								}}
							/>
							<Grid container columnSpacing={2}>
								<Grid item md={6} xs={12}>
									<LibAutocomplete
										control={control}
										name={"location"}
										options={locations}
										defaultValue={userPerferData.location} 
										checked={getValues("location")}
										
										autocompleteProps={{
											multiple: true,
											getOptionLabel: option => option,
											freeSolo: true,
											renderTags: (value, getTagProps) =>
											value.map((option, index) => (
												<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
											)),
										}}
										textfieldProps={{
											label: "Preffered work location",
											fullWidth: true
										}}
									/>
								</Grid>
								<Grid item md={6} xs={12}  >
								<Controller
									name="max_salary"
									control={control}
									
									rules={{ required: 'Expected Salary is required' }} // Add the required rule
									render={({ field }) => (
									<LibTextField 
										control={control}
										name={"max_salary"}
										defaultValue={ userPerferData.max_salary}
										checked={getValues("max_salary")}
										textfieldProps={{
											label: "Expected Salary",
											fullWidth: true,
											type: "number",
											InputProps: {startAdornment: <InputAdornment position="start"> <CurrencyRupeeOutlined /> </InputAdornment>, endAdornment: <InputAdornment position="end"> LPA </InputAdornment> }
										}}
									/>
									)}
								/>
								</Grid>
								</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={12} md={6}>
								<LibRadioGroup control={control} defaultValue={ userPerferData.Preferred_shift } 	checked={getValues("emailUpdates")} label={<h4>Preffered Shift</h4>} name={"Preferred_shift"} >
								<FormControlLabel  value= "Day_Shift" control={<Radio />} label="Day Shift" />
								<FormControlLabel  value="Night_Shift" control={<Radio />} label="Night Shift" />
								<FormControlLabel  value="Flexible" control={<Radio />} label="Flexible" />
							</LibRadioGroup>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
								<LibRadioGroup control={control} defaultValue={userPerferData.work_mode} 	checked={getValues("emailUpdates")} label={<h4>Work mode</h4>} name={"work_mode"}>
							<FormControlLabel value="work from home " control={<Radio />} label="Work from home" />
							<FormControlLabel value="work from office" control={<Radio />} label="Work from office" />
							<FormControlLabel value="hybrid" control={<Radio />} label="Hybrid" />
						</LibRadioGroup>
								</Grid>
							</Grid>					
						</Box>
				</form>
					
			</DialogContent> */}

{alertShow && (
              <Alert
                style={{
                  position: "absolute",
                  marginInline: "auto",
                  zIndex: 10000,
                  top: "4.5rem",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
                action={
                  <IconButton
                    size="small"
                    onClick={() => setAlertShow(false)}
                  >
                    <Close />
                  </IconButton>
                }
                severity={AlertSeverity}

              >
                {AlertMessage}
              </Alert>
            )} 
			
		</Dialog>
	)
}


//   const {handleSubmit,getValues} = useForm({
// 	defaultValues: {
// 		emailUpdates: false,
// 		work_mode: '',
// 		job_titles: [],
// 		location: '',
// 		max_salary: 0,
// 		Skills: "",
// 		Preferred_shift: ""
// 		// Preferred_shift: {day_shift: false, night_shift: false, us_shift: false, uk_shift: false}
// 	}
// });

// const [formDatas, setFormDatas] = useState({
// 	emailUpdates: '',
// 	work_mode: '',
// 	job_titles: '',
// 	location: '',
// 	max_salary: [],
// 	Skills: '',
// 	Preferred_shift: ''
// });

// useEffect(() => {
// 	if (datas[0]) {
// 		setFormDatas({
// 			id : datas[0].id,
// 			emailUpdates: datas[0].emailUpdates || '',
// 			work_mode: datas[0].work_mode || '',
// 			job_titles: datas[0].job_titles || '',
// 			location: datas[0].location || '',
// 			max_salary: datas[0].max_salary || [],
// 			Skills: datas[0].Skills || '',
// 			Preferred_shift: datas[0].Preferred_shift || ''
// 		});
// 	}
// }, [datas]);


// const handleInputChange = (e) => {
// 	const { name, value } = e.target;

// 	setFormDatas({
// 		...datas,
// 		[name]: value
// 	});
// };

// const handleSkillsChange = (event, newValue) => {
// 	setFormDatas({
// 		...datas,
// 		Skills_used: newValue
// 	});
// };

// const handleEdits = (event) => {
//     event.preventDefault(); // Prevents the default form submission behavior
    
//     // Here, you can access the form data from your state management library
//     // For example, if you're using React Hook Form, you can get the form values using getValues()

//     const formData = getValues();
// }