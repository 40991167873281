import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Attention from "../images/Attention.png";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, onClose, submitapi }) {
  // const [animation, setAnimation] = React.useState(false);

  const handleClose = () => {
    if (open) onClose(false); // Call onClose prop if provided
  };

  const handleSubmit = () => {
    submitapi();
    // You can add additional logic here if needed
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose} // Close the modal when clicking outside
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
     
   
        <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
             
              <img style={{ width: "50%" }} src={Attention}/>
            </div>


{/* <img style={{ width: "25%", height: "25%", objectFit: "contain" }} src={Attention}/> */}


            <Typography id="modal-modal-title" variant="h6" component="h2">
        Attention Please 
        </Typography>
    

      
      
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Note: If you miss the call three times, your candidacy will no longer
          be considered.
        </Typography>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            sx={{
              backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
              color: "white",
            }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <Button
            sx={{
              backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
              color: "white",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
