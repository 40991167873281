import {
  Paper,
  Toolbar,
  IconButton,
  Container,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  MenuItem,
  Stack,
  Divider,
} from "@mui/material";
import {
  LibDateField,
  LibSelect,
  LibTextField,
} from "../../components/LibComponents";
import { CurrencyRupeeOutlined, AddOutlined } from "@mui/icons-material";
import React, { useState, useContext, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { textValidation } from "../../utils";
import {
  Edit_employment_details,
  addworkExperience,
  get_profile,
} from "../Axiosfetch/Axiosfetch";
import { AlertToast, alertState } from "../../divine-constants";
import { useForm } from "react-hook-form";
import Deletemodel from "../../Deletemodel";
import edit from "../../images/edit.png";
import bin from "../../images/delete.png";
import { WorkExperience } from "../Details";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

export default function WorkExperiences({ formData, control, setValue }) {
  console.log(formData, "formdata");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [operation, setOperation] = useState();
  const { setAlert } = useContext(AlertToast);
  const [clickedRow, setClickedRow] = useState();
  const [modalDatas, setModalDatas] = useState();
  const [isChecked, setIsChecked] = useState(false);

  console.log(formData, "formDataex");

  const theme = useTheme();

  const dialogClose = () => {
    setDialogOpen(false);
  };

  function HandleEdit(arg) {
    setDialogOpen(true);
    setOperation("edit");
    setModalDatas(arg);
  }
  const HandleAdd = async (resdata) => {
    setDialogOpen(true);
    setOperation("add");
  };

  function HandleDelete() {
    setOperation("delete");
    setDialogOpen(true);
  }

  const handleDelete = async () => {
    try {
      setDialogOpen(false);
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  //   {formData.length ? (formData.map((project, index) => (

  //   const card = (
  //     <React.Fragment>
  //       {/* <CardContent key={index} sx={{padding : "0px"}}> */}

  //       <Box display={"flex"}  columnGap={1} alignItems={"center"}
  //        borderBottom={"0.5px solid #cdcdcd"} // Set border style here
  //        justifyContent={'space-between'}
  //        sx={{padding : "0 12px" }}

  //       >
  //         <Box  display={"flex"}  columnGap={1} alignItems={"center"} >

  //                   {project.company_name ? <Typography variant="subtitle1"  fontWeight={800}>{project.company_name}</Typography> : null}

  //                   <Toolbar>
  //                       <div style={{ marginLeft: 'auto' }}> {/* Use a div to align items to the right */}
  //                           <Tooltip title="Edit this section">
  //                               <IconButton onClick={() => { HandleEdit(project) }}>
  //                               <img style={{width:15, height:15,}} src={edit}/>
  //                               </IconButton>
  //                           </Tooltip>
  //                           <Tooltip title="Delete this section">
  //                               <IconButton onClick={() => { setClickedRow(project); HandleDelete()}} >
  //                               <img style={{width:15, height:15,}} src={bin}/>
  //                               </IconButton>
  //                           </Tooltip>
  //                       </div>
  //                   </Toolbar>

  //         </Box  >
  //               <Box sx={{display:'flex', justifyContent:'spacebetween',alignItems:'flex-end'}}>

  //                   {project.current_job_status ? <Typography variant="body1" > <strong>  Currently working </strong> </Typography> : null}
  //                   </Box>

  //         </Box>

  //         <Box sx={{padding : "12px"}} display={"flex"}  justifyContent={'space-between'}   columnGap={1} alignItems={"center"} >

  //                   {project.Total_experience ? <Typography variant="body1"><strong>Total Experience:</strong> {project.Total_experience}</Typography> : null}

  //                   {project.Current_designation ?
  //                   <div>

  //                   <Typography variant="body1"><strong> {project.current_job_status ? "Current" : 'Previous'} Designation</strong></Typography>

  //                   <Typography variant="body1"> {project.Current_designation}</Typography>

  //                   </div> : null}

  //                   {project.current_job ?
  //                   <div>
  //                   <Typography variant="body1"><strong> {project.current_job_status ? "Current" : 'Previous'} Job:</strong></Typography>
  //                   <Typography variant="body1">{project.current_job}</Typography>
  //                   </div> : null}

  //                   {project.Job_profile?
  //                       <div>
  //                   <Typography variant="body1"><strong>linkedin_profile:</strong></Typography>
  //                   <Typography variant="body1"> {project.linkedin_profile}</Typography>

  //                   </div> : null }

  //                 {project.Skills_used?

  //                   <div>

  //                 <Typography variant="body1"><strong>Skills Used:</strong></Typography>
  //                 <Typography variant="body1">{project.Skills_used}</Typography>

  //                                   </div> : null }

  //                 {project.Current_annaul_salary?

  //                   <div>

  //                   <Typography variant="body1"><strong>{project.current_job_status ? "Current" : 'Previous'} Annual Salary:</strong> </Typography>
  //                   <Typography variant="body1">{project.Current_annaul_salary}</Typography>

  //                   </div> : null }

  //               </Box>

  //               <Box display={"flex"} sx={{padding : "12px"}}   columnGap={1} alignItems={"center"} borderTop={"0.5px solid #cdcdcd"} >

  //               {project.Joining_date ? <Typography variant="body1"><strong>Joining Date:</strong> {project.Joining_date}</Typography> : null}
  //                   {project.Leaving_data ? <Typography variant="body1"><strong>Leaving Date:</strong> {project.Leaving_data }</Typography> : null}
  //                   {project.Notice_period ?<Typography variant="body1"><strong>Notice Period:</strong> {project.Notice_period}</Typography> : null}

  //               </Box>

  //       {/* </CardContent> */}

  //       {/* <CardActions>
  //         <Button size="small">Learn More</Button>
  //       </CardActions> */}

  //     </React.Fragment>

  //   )

  // ))) : (
  //   <Grid sx={{p:2}} container spacing={2}>
  //     <Grid item xs={12} sm={12} md={12} >
  //       <Paper sx={{padding:3, backgroundColor:'#f5f6f7',  mt:2}}>
  //       <Typography sx={{mb:2}} fontSize={30} fontWeight={'bold'}> No Work Experience specified </Typography>

  //       </Paper>
  //     </Grid>
  //   </Grid>)}

  console.log("formData", formData);

  return (
    <>
      <Paper sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button variant="text" onClick={HandleAdd}>
            Add work experience
          </Button>
        </Box>
        <WorkExperienceDialog
          formData={formData}
          state={[dialogOpen, setDialogOpen]}
          control={control}
          works={modalDatas}
          dialogClose={dialogClose}
          dialogOpen={dialogOpen}
          operation={operation}
          setValue={setValue}
        />

        {formData.length ? (
          formData.map((project, index) => (
            <Container sx={{ pb: 2 }}>
              <Box sx={{ minWidth: 275 }}>
                <div
                  style={{
                    padding: "0px",
                    borderRadius: "5px",
                    border: "1px solid #cdcdcd",
                  }}
                  variant="outlined"
                >
                  <Box
                    display={"flex"}
                    columnGap={1}
                    alignItems={"center"}
                    borderBottom={"0.5px solid #cdcdcd"} // Set border style here
                    justifyContent={"space-between"}
                    sx={{ padding: "0 12px", backgroundColor: "#cdcdcd30" }}
                  >

                    <Box display={"flex"} columnGap={1} alignItems={"center"}>

                      {project.company_name ? (
                        <Typography variant="subtitle1" fontWeight={800}>
                          {project.company_name}
                        </Typography>
                      ) : null}

                      <Toolbar>
                        <div style={{ marginLeft: "auto" }}>
                          {" "}
                          {/* Use a div to align items to the right */}
                          <Tooltip title="Edit this section">
                            <IconButton
                              onClick={() => {
                                HandleEdit(project);
                              }}
                            >
                              <img
                                style={{ width: 15, height: 15 }}
                                src={edit}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete this section">
                            <IconButton
                              onClick={() => {
                                setClickedRow(project);
                                HandleDelete();
                              }}
                            >
                              <img
                                style={{ width: 15, height: 15 }}
                                src={bin}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Toolbar>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "spacebetween",
                        alignItems: "flex-end",
                      }}
                    >
                      {project.current_job_status ? (
                        <Typography variant="body1">
                          {" "}
                          <strong> Currently working </strong>{" "}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>

                  <Box
                    sx={{ padding: "12px" }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    columnGap={1}
                  >

                    {project.Total_experience ? (
                      <Typography variant="body1">
                        <strong>Total Experience:</strong>{" "}
                        {project.Total_experience}
                      </Typography>
                    ) : null}

                    {project.Current_designation ? (
                      <div>
                        <Typography variant="body1">
                          <strong>
                            {" "}
                            {project.current_job_status
                              ? "Current"
                              : "Previous"}{" "}
                            Designation
                          </strong>
                        </Typography>
                        {/* <div style={{display:"flex" ,}} > */}

                        <Typography sx={{ paddingTop: "8px" }} variant="body1">
                          {" "}
                          {project.Current_designation}
                        </Typography>

                        {/* <Typography  sx={{paddingLeft: "12px"}} variant="body1">{project.Skills_used}</Typography>  */}
                        {/* </div> */}
                      </div>
                    ) : null}

                    

                    <div style={{ display: "flex" }}>
                      {project.Current_annaul_salary ? (
                        <div>
                          {/* <Typography variant="body1"><strong>{project.current_job_status ? "Current" : 'Previous'} Salary:</strong> </Typography>  */}
                          {/* <Typography  variant="body1"><strong> Salary</strong> </Typography>  */}
                          <Typography variant="body1">
                            {parseInt(project.Current_annaul_salary).toLocaleString("en-IN")}
                          </Typography>

                          {/* <div style={{width : "2px" ,height : "2px", backgroundColor : "#999"}}> </div> */}
                        </div>
                      ) : null}

                      <div>
                        <Divider
                          sx={{ height: 13, m: 0.5, ml: 1 }}
                          orientation="vertical"
                        />
                      </div>

                      {project.Skills_used ? (
                        <div>
                          {/* <Typography  sx={{paddingLeft: "12px"}} variant="body1"><strong>Skills Used</strong></Typography>   */}
                          <Typography
                            sx={{ paddingLeft: "8px" }}
                            variant="body1"
                          >
                            {project.Skills_used}
                          </Typography>
                        </div>
                      ) : null}
                    </div>

                    {project.current_job ? (
                      <div>
                        <Typography variant="body1">
                          <strong>
                            {" "}
                            {project.current_job_status
                              ? "Current"
                              : "Previous"}{" "}
                            Job:
                          </strong>
                        </Typography>
                        <Typography variant="body1">
                          {project.current_job}
                        </Typography>
                      </div>
                    ) : null}

                    {project.Job_profile ? (
                      <div>
                        <Typography variant="body1">
                          <strong>linkedin_profile:</strong>
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          {project.linkedin_profile}
                        </Typography>
                      </div>
                      
                    ) : null}
                  </Box>

                  <Box
                    display={"flex"}
                    sx={{ padding: "12px", backgroundColor: "#cdcdcd30" }}
                    justifyContent={"space-between"}
                    columnGap={1}
                    alignItems={"center"}
                    borderTop={"0.5px solid #cdcdcd"}
                  >
                    {project.Joining_date ? (
                      <Typography variant="body1">
                        <strong>Joining Date:</strong> {project.Joining_date}
                      </Typography>
                    ) : null}
                    {project.Leaving_data ? (
                      <Typography variant="body1">
                        <strong>Leaving Date:</strong> {project.Leaving_data}
                      </Typography>
                    ) : null}
                    {project.Notice_period ? (
                      <Typography variant="body1">
                        <strong>Notice Period:</strong> {project.Notice_period}
                      </Typography>
                    ) : null}
                  </Box>

                  {/* {card} */}
                </div>
              </Box>

              <Deletemodel
                dialogDisplay={dialogOpen}
                setDialogDisplay={setDialogOpen}
                url={"candidate/delete_workexprence/"}
                data={{ id: clickedRow ? clickedRow.id : null }}
                handleDelete={handleDelete} // Pass the handleDelete function
                setValue={setValue}
              />
            </Container>
          ))
        ) : (
          <Grid sx={{ p: 2 }} container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper sx={{ padding: 3, backgroundColor: "#f5f6f7", mt: 2 }}>
                <Typography sx={{ mb: 2 }} fontSize={30} fontWeight={"bold"}>
                  {" "}
                  No Work Experience specified{" "}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}

function WorkExperienceDialog({
  formData,
  dialogClose,
  dialogOpen,
  operation,
  works,
  setValue,
}) {
  console.log(works, "works111");

  const theme = useTheme();
  const { setAlert } = useContext(AlertToast);
  const [isChecked, setIsChecked] = useState(true);
  const [selectedQualification, setSelectedQualification] = useState(null);

  const {
    reset,
    watch,
    control,
    getValues,
    handleSubmit,
    setValue: setFormValue,
  } = useForm({
    defaultValues: {
      resume: "",
      highest_qualification: "",
      Gender: "",
      country: "",
      state: "",
      city: "",

      languages: [
        { id: 1, lang: null, speak: false, write: false, read: false },
      ],
      primary_graducation: false,

      candidates_qualification: {
        candidates_qualifications: [],
      },

      workExperience: {
        isEmployed: false,
        recent_company: "",
        designation: "",
        recent_salary: "",
        dates: {
          joinDate: "",
          leavingDate: "",
        },
        months: "",
        years: "",
        notice_period: "",
      },
    },
  });

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    console.log("getValues", getValues("workExperience.isEmployed"));
    console.log("watch()", watch("workExperience.isEmployed"));
  }, [getValues("workExperience.isEmployed")]);

  useEffect(() => {
    if (works) {
      setFormValues(works);
    }
  }, [works]);

  const setFormValues = (formData) => {
    Object.keys(formData).forEach((key) => {
      setFormValue(key, formData[key]);
    });
  };

  const handleUpdate = async (formDatas) => {
    const workdata = {
      id: works.id,
      current_annaul_salary: formDatas.current_annaul_salary,
      Current_designation: formDatas.Current_designation,
      Job_profile: formDatas.Job_profile,
      Joining_date: formDatas.Joining_date,
      Leaving_data: formDatas.Leaving_data,
      Notice_period: formDatas.Notice_period,
      Skills_used: formDatas.Skills_used,
      Total_experience: formDatas.Total_experience,
      company_name: formDatas.company_name,
      current_job: formDatas.current_job,
      current_job_status: formDatas.current_job_status,
      linkedin_profile: formDatas.linkedin_profile,
      Current_annaul_salary: formDatas.Current_annaul_salary,
      // linkedin_profile : formDatas.linkedin_profile,
    };

    try {
      const response = await Edit_employment_details(workdata);
      if (response.status === 200) {
        dialogClose();
        const profileResponse = await get_profile();
        if (profileResponse.status === 200) {
          setValue("workExperience", profileResponse.data.work_experience);
        }
        setAlert({
          message: "Work Experience updated successfully",
          severity: "success",
          show: true,
        });
      } else {
        throw new Error("Failed to update work experience");
      }
    } catch (error) {
      setAlert({
        message: error.message,
        severity: "error",
        show: true,
      });
    }
  };

  const handleAddWorkExperience = async (datas) => {
    try {
      const { status, data } = await addworkExperience(datas);
      if (status === 200) {
        const response = await get_profile(); // Call your API function to fetch profile data
        if (response.status === 200) {
          setValue("workExperience", response.data.work_experience);
        }
        setAlert(
          alertState({
            message: "Work Experience added successfully",
            severity: "success",
            show: true,
          })
        );
        reset();
      } else {
        throw new Error("Invalid input: The value must be a positive number");
      }
    } catch (error) {
      // console.log(error)
      setAlert(
        alertState({
          message: error.message,
          severity: "error",
          show: true,
        })
      );
    }
    dialogClose();
  };
  const work_experience = watch([
    "workExperience.months",
    "workExperience.years",
  ]);

  if (operation === "edit") {
    return (
      <Dialog open={dialogOpen} onClose={dialogClose}>
        <DialogTitle>
          <Box display={"flex"} alignItems={"center"}>
            Work experience{" "}
            <img
              style={{ width: 25, height: 25, marginLeft: "auto" }}
              src={edit}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(handleUpdate)}>
            <Grid component={"form"} noValidate container spacing={2}>
              {/* <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"current_job"}
                  rules={textValidation("Current Job")}
                  textfieldProps={{
                    label: "Job",
                    fullWidth: true,
                  }}
                />
              </Grid>
               */}

              <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"company_name"}
                  rules={textValidation("Current Company")}
                  textfieldProps={{
                    label: "Company Name",
                    fullWidth: true,
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"Total_experience"}
                  rules={textValidation("Experience")}
                  textfieldProps={{
                    label: "Experience",
                    type: "number",
                    fullWidth: true,
                  }}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"Current_designation"}
                  rules={textValidation("Designation")}
                  textfieldProps={{
                    label: "Designation",
                    fullWidth: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"Current_annaul_salary"}
                  textfieldProps={{
                    label: "Current Annual Salary",
                    type: "number",
                    fullWidth: true,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeOutlined />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LibTextField
                  control={control}
                  name={"Skills_used"}
                  rules={textValidation("Skills")}
                  textfieldProps={{
                    label: "Skills",
                    fullWidth: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LibDateField
                  control={control}
                  name={"Joining_date"}
                  textfieldProps={{
                    label: "Date Of Joining",
                    fullWidth: true,
                  }}
                />
              </Grid>

              {works.current_job_status === false && (
                <Grid item xs={12} sm={6}>
                  <LibDateField
                    control={control}
                    name={"Leaving_data"}
                    textfieldProps={{
                      label: "Leaving Date",
                      fullWidth: true,
                    }}
                  />
                </Grid>
              )}

              {works.current_job_status && (
                <Grid item xs={12} sm={6}>
                  <LibSelect
                    control={control}
                    name={"Notice_period"}
                    rules={textValidation("Notice period")}
                    label="Notice period"
                  >
                    <MenuItem value="immediate">Immediate joiner</MenuItem>
                    <MenuItem value="1+ weeks">1+ weeks</MenuItem>
                    <MenuItem value="2+ weeks">2+ weeks</MenuItem>
                    <MenuItem value="3+ weeks">3+ weeks</MenuItem>
                    <MenuItem value="1+ months">1+ months</MenuItem>
                    <MenuItem value="3+ months">3+ months</MenuItem>
                  </LibSelect>
                </Grid>
                // <Grid item xs={12} sm={6}>
                //   <LibTextField
                //     control={control}
                //     name={"Notice_period"}
                //     type="date"
                //     rules={textValidation("Notice Period")}
                //     textfieldProps={{
                //       label: "Notice Period",
                //       fullWidth: true,
                //     }}
                //   />

                // </Grid>
              )}
            </Grid>
            <DialogActions>
              <Button
                onClick={() => {
                  dialogClose();
                  reset();
                }}
                colour="primary"
                variant="text"
              >
                Cancel
              </Button>
              <Button type="submit" colour="primary" variant="text">
                Update
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
        {/* <DialogActions>
       
          
        </DialogActions> */}
      </Dialog>
    );
  } else if (operation === "add") {
    return (
      <Dialog open={dialogOpen} onClose={dialogClose}>
        {/* <DialogTitle>
          <Box display={"flex"} alignItems={"center"}>
            Work experience 
            {/* <AddOutlined style={{ marginLeft: "auto" }} /> */}
        {/* </Box> */}
        {/* </DialogTitle> */}
        <DialogContent>
          <form onSubmit={handleSubmit(handleAddWorkExperience)}>
            {/* <Grid component={"form"} noValidate container spacing={2}>
              <Grid item xs={12} sm={6}> */}

            {/* {isChecked ? (
              <Box sx={{mt:2}}>
                <section >
                  <h4 >Total Work Experience</h4>
                  <br />
                  <Stack direction="row" spacing={2}>
                    <LibTextField
                      control={control}
                      name={"workExperience.years"}
                      rules={textValidation("workExperience")}
                      textfieldProps={{
                        label: "Years",
                        type: "number",
                        style: {flexGrow: '1'}
                      }}
                    />
                    <LibTextField
                      control={control}
                      rules={textValidation("workExperience")}
                      name={"workExperience.months"}
                      textfieldProps={{
                        label: "Months",
                        type: "number",
                        style: {flexGrow: '1'}
                      }}
                    />
                  </Stack>
                </section>

              </Box>
              ) : null } */}
            <WorkExperience
              control={control}
              name="workExperience"
              setFormValue={setFormValue}
              data={formData}
              isEmployed={watch("workExperience.isEmployed")}
            />

            {/* {(parseInt(work_experience[0]) > 0 || parseInt(work_experience[1]) > 0) && <WorkExperience control={control} name="workExperience" isEmployed={watch("workExperience.isEmployed")} />} */}

            {/* </Grid>
          </Grid> */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dialogClose();
              reset();
            }}
            colour="primary"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(handleAddWorkExperience)}
            colour="primary"
            variant="text"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
