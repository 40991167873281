import { PropaneSharp } from "@mui/icons-material";
import { Autocomplete, TextField, Checkbox, FormControlLabel, FormControl, Box, RadioGroup, FormLabel, FormHelperText, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useController } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear'; // Import ClearIcon

/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues>, 
 * 	name: import("react-hook-form").FieldValues,
 * 	rules?: Omit<
 * 		import("react-hook-form").RegisterOptions<
 * 			import("react-hook-form").FieldValues, string
 * 		>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
 * 	>
 * 	textfieldProps: import("@mui/material").TextFieldProps
 * }} props 
 * @returns {JSX.Element}
 */
export function LibTextField(props) {
	console.log(props.name,"props_name")
	console.log(props,"name111111")
	
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			render={({
				field,
				fieldState: { error },
				formState
			}) => (
				<TextField
					helperText={error?.message ?? " "}
					error={!!error}
					{...field}
				
					defaultValue={props.defaultValue}
					{...props.textfieldProps}
				>{props.children}</TextField>
			)}
		/>
	)
}
export function LibDateField(props) {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			render={({
				field,
				fieldState: { error },
				formState
			}) => (
				<TextField
					helperText={error?.message ?? " "}
					type = "date"
					error={!!error}
					defaultValue={props.defaultValue}
					{...field}
					{...props.textfieldProps}
				>{props.children}</TextField>
			)}
		/>
	)
}






/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues>, 
 * 	name: import("react-hook-form").FieldValues,
 * 	rules?: Omit<
 * 		import("react-hook-form").RegisterOptions<
 * 			import("react-hook-form").FieldValues, string
 * 		>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
 * 	>
 * 	FormControlLabelProps: import("@mui/material").FormControlLabelProps,
 * 	checked?: boolean
 * }} props 
 * @returns {JSX.Element}
 */


export function LibCheckbox(props) {


	console.log(props.checked,"props.defaultValue")
	
	return (
	  <FormControlLabel
		control={
		  <Controller
			name={props.name}
			control={props.control}
			defaultValue={props.defaultValue} // Pass defaultValue to Controller

			render={({ field }) => (
			  <Checkbox
				checked={field.value} // Use field.value to set the checked state
				onChange={(e) =>{ console.log(e.target.checked);return field.onChange(e.target.checked)}} // Update field value
			  />
			)}
		  />
		}
		{...props.FormControlLabelProps}
	  />
	);
  }



// export function LibCheckbox(props) {
// 	console.log(props.defaultValue,"defaultValue")

// 	// const handleChecked = () => {
// 	// 	console.log("handleChecked")
	
// 	// 	props.setChecked(!props.defaultValue)
// 	// }

// 	// useEffect(() => {
// 	// 	console.log("props. defaultValue:", props.defaultValue)

// 	// },[props.defaultValue])
// 	return (
// 		<FormControlLabel
// 			control={
// 				<Controller
// 					name={props.name}
// 					control={props.control}
// 					render={({field, fieldState, formState}) => (
// 						// <Checkbox checked={props.defaultValue}  onChange={handleChecked} />
						
// 						<Checkbox checked={field.value} onChange={(_, checked) => field.onChange(checked)} />
// 						)}
				
// 				/>
// 			}
// 			{...props.FormControlLabelProps}
// 		/>
// 	)
// }







/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues>, 
 * 	name: import("react-hook-form").FieldValues,
 * 	options?: readonly any[]
 * 	rules?: Omit<
 * 		import("react-hook-form").RegisterOptions<
 * 			import("react-hook-form").FieldValues, string
 * 		>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
 * 	>,
 * autocompleteProps: import("@mui/material").AutocompleteProps
 * textfieldProps: import("@mui/material").TextFieldProps
 * }} props 
 * @returns {JSX.Element}
 * 
 * 
 */

// export function LibAutocomplete(props) {
// 	const [selectedValue, setSelectedValue] = useState(null);
  
// 	const handleAutocompleteChange = (value) => {
// 	  setSelectedValue(value);
// 	  props.onAutocompleteChange(value);
// 	};
  
// 	const handleBlur = () => {
// 		const clearOnBlur = props.clearOnBlur || false;
// 		console.log("clearOnBlur:", props.clearOnBlur);
// 		console.log("selectedValue:", selectedValue);
// 		console.log("selectedStartingYear:", props.selectedStartingYear);
// 		console.log("endingYear:", props.endingYear);
	  
// 		if (props.clearOnBlur && selectedValue && props.selectedStartingYear >= props.endingYear) {
// 		  console.log("Condition is true. Clearing selected value...");
// 		  setSelectedValue(null);
// 		} else {
// 		  console.log("Condition is false.");
// 		}
// 	  };
  
// 	return (

// 		<Controller
// 			name={props.name}
// 			control={props.control}	
// 			rules={props.rules}
// 			render={({
// 				field: {onChange, ...fieldProps},
// 				fieldState: { error },
// 				formState
// 			}) => (
// 				<Autocomplete
// 					options={props.options}
// 					value={selectedValue}
// 					onChange={(_, value) => handleAutocompleteChange(value)}
// 					getOptionLabel={(option) => option || ''}
// 					renderInput={(params) => (
// 					<TextField
// 						{...params}
// 						{...props.textfieldProps}
// 						required={true}
// 						onBlur={handleBlur} // Add onBlur event handler
// 					/>
// 		)}
// 	  />
// 			)}
// 		/>
// 	)
	  
	
//   }




// export function LibAutocomplete(props) {
//   const [clearOnBlur, setClearOnBlur] = useState(false);

//   const handleBlur = () => {
//     // Check your condition here
//     if (props.selectedStartingYear >= props.endingYear) {
//       // Update state to trigger clearing the value
//       setClearOnBlur(true);
//     } else {
//       setClearOnBlur(false);
//     }
//   };

//   return (
//     <Controller
//       name={props.name}
//       control={props.control}
//       rules={props.rules}
//       render={({ field: { onChange, ...fieldProps }, fieldState: { error }, formState }) => (
//         <Autocomplete
//           options={props.options}
//           onChange={(_, values) => {
//             props.onAutocompleteChange(values);
//             // Reset clearOnBlur state after value changes
//             setClearOnBlur(false);
//           }}
//           defaultValue={props.defaultValue}
//           getOptionLabel={(option) => option || ''}
//           {...props.autocompleteProps}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               {...props.textfieldProps}
//               required={false}
//               onBlur={handleBlur} // Add onBlur event handler
//             />
//           )}
//           clearOnEscape={props.clearOnEscape}
//           clearText={props.clearText}
//           onClear={props.onClear}
//           // Pass clearOnBlur state as a prop
//           clearOnBlur={clearOnBlur}
//         />
//       )}
//     />
//   );
// }



export function LibAutocomplete(props) {
	console.log("props.textfieldProps.field",props.textfieldProps.field)

	return (
		<Controller
			name={props.name}

			control={props.control}	
			rules={props.rules}
			render={({
				field: {onChange, ...fieldProps},
				fieldState: { error },
				formState
			}) => (
				<Autocomplete 
					options={props.options}
					// value ={value}
					onChange={(_, values) => onChange(values)}
					defaultValue={props.defaultValue} 
				
					// Set the defaultValue here
					// isOptionEqualToValue={(option, value) => value === undefined || value === "" || option === value}
					getOptionLabel={option => option || ""}
					{...props.autocompleteProps}
					renderInput={params => (
						<TextField
							helperText={error?.message ?? " "}
							error={!!error}
							{...params} 
							{...props.textfieldProps} 
							required={false}
						
						>{props.children}</TextField>
						// <TextField {...params} error={!!error} {...props.textfieldProps} required={false} />
					)}
				/>
			)}
		/>
	)
}

// export function LibAutocomplete(props) {
//     const [selectedValue, setSelectedValue] = useState(props.defaultValue || null);

//     const handleChange = (event, values) => {
//         setSelectedValue(values);
//         if (props.onChange) {

//             props.onChange(values);
//         }
//     };
// 	console.log("selectedValue",selectedValue)

//     return (
//         <Controller
//             name={props.name}
//             control={props.control}
//             rules={props.rules}
//             render={({ field: { onChange, ...fieldProps }, fieldState: { error }, formState }) => (
//                 <Autocomplete
//                     options={props.options}
//                     onChange={(event, values) => {
						                  
// 						                        onChange(values);
// 						                    }}
//                     defaultValue={props.defaultValue}
//                     getOptionLabel={option => option || ""}
//                     value={selectedValue}
//                     {...props.autocompleteProps}
//                     renderInput={params => (
//                         <TextField {...params} {...props.textfieldProps} required={false} />
//                     )}
//                 />
//             )}
//         />
//     );
// }

// export function LibAutocomplete(props) {
//     const [selectedStartingYear, setSelectedStartingYear] = useState(props.selectedStartingYear || null);
//     const [selectedEndingYear, setSelectedEndingYear] = useState(props.endingYear || null);

//     console.log("props.textfieldProps.field", props.textfieldProps.field);
//     console.log("selectedStartingYear",selectedStartingYear);
//     console.log("selectedEndingYear",selectedEndingYear);


//     return (
//         <Controller
//             name={props.name}
//             control={props.control}
//             rules={props.rules}
//             render={({ field: { onChange, ...fieldProps }, fieldState: { error }, formState }) => (
//                 <Autocomplete
//                     options={props.options}
//                     onChange={(event, values) => {
//                         if (props.name.endsWith("Starting_Year")) {
//                             setSelectedStartingYear(values);
//                         } else if (props.name.endsWith("Ending_Year")) {
//                             setSelectedEndingYear(values);
//                         }
//                         onChange(values);
//                     }}
//                     defaultValue={props.defaultValue}
//                     getOptionLabel={option => option || ""}
//                     value={props.name.endsWith("Starting_Year") ? selectedStartingYear : selectedEndingYear}
//                     {...props.autocompleteProps}
//                     renderInput={params => (
//                         <TextField {...params} {...props.textfieldProps} required={false} />
//                     )}
//                 />
//             )}
//         />
//     );
// }






// export function LibAutocomplete(props) {
// 	console.log("props.textfieldProps.field",props.textfieldProps.field)

// 	return (
// 		<Controller
// 			name={props.name}

// 			control={props.control}	
// 			rules={props.rules}
// 			render={({
// 				field: {onChange, ...fieldProps},
// 				fieldState: { error },
// 				formState
// 			}) => (
// 				<Autocomplete 
// 					options={props.options}
// 					// value ={value}
// 					onChange={(_, values) => onChange(values)}
// 					defaultValue={props.defaultValue} 

// 					// Set the defaultValue here
// 					// isOptionEqualToValue={(option, value) => value === undefined || value === "" || option === value}
// 					getOptionLabel={option => option || ""}
// 					{...props.autocompleteProps}
// 					renderInput={params => (
// 						<TextField {...params} {...props.textfieldProps} required={false} />
// 					)}
// 				/>
// 			)}
// 		/>
// 	)
// }







/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues>, 
 * 	name: import("react-hook-form").FieldValues,
 * 	label?: React.ReactNode,
 * 	rules?: Omit<import("react-hook-form").RegisterOptions<import("react-hook-form").FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">,
 * 	selectProps?: import("@mui/material").SelectProps<unknown>
 * 	children?: React.ReactNode
 * }} props 
 * @returns {JSX.Element}
 */
export function LibSelect(props) {
	const {
		field,
		fieldState: { invalid, isTouched, isDirty, error },
		formState: { touchedFields, dirtyFields },
	} = useController({
		name: props.name,
		control: props.control,
		rules: props.rules,
	})
	return (
		<TextField
			select
			onChange={field.onChange} 
			onBlur={field.onBlur} 
			value={field.value} 
			name={field.name} 
			inputRef={field.ref}
			SelectProps={props.selectProps}
			label={props.label}
		>
			{props.children}
		</TextField>
	)
}







/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues>, 
 * 	name: import("react-hook-form").FieldValues,
 * 	rules?: Omit<import("react-hook-form").RegisterOptions<import("react-hook-form").FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">,
 * 	radioGroupProps?: import("@mui/material").RadioGroupProps
 * 	label: React.ReactNode
 * 	children?: React.ReactNode
 * }} props 
 * @returns {JSX.Element}
 */
export function LibRadioGroup(props) {
	return (
		<Controller 
			control={props.control}
			name={props.name}
			rules={props.rules}
			render={({field, fieldState, formState}) => (
				<FormControl required={false} >
					<FormLabel>{props.label}</FormLabel>
					<RadioGroup row name="radio-buttons-group" value={field.value} onChange={(_, value) => field.onChange(value)  }   defaultValue={props.defaultValue}>
						{props.children}
					</RadioGroup>
					<FormHelperText>{fieldState.error ? fieldState.error?.message : ' '}</FormHelperText>
				</FormControl>
		)}/>
	)
}





/**
 * 
 * @param {{
 * 	children?: React.ReactNode,
 * 	value: number,
 * 	index: number
 * }} props
 * @returns {JSX.Element}
 */
export function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && children}
	  </div>
	);
}






export const DotSeparator = styled(Box)(({theme}) => ({
	width: 4,
	height: 4,
	backgroundColor: theme.palette.text.disabled,
	borderRadius: 10
}));

