import {useContext , useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { login } from "./Axiosfetch/Axiosfetch";
import { AlertToast, FullScreenContext,TokenContext,alertState } from "../divine-constants";
import Notificationfire from "../components/Firebase/Notificationfire";

const defaultTheme = createTheme();

export default function UserLogin() {

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // Step 1: State for Remember Me
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertToast);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Step 3: Retrieve Remember Me state from local storage
    const rememberMeFlag = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeFlag);

    // Step 3: If Remember Me was checked, pre-fill email and password fields
    if (rememberMeFlag) {
      const savedEmail = localStorage.getItem('email') || '';
      const savedPassword = localStorage.getItem('password') || '';
      document.getElementById('email').value = savedEmail;
      document.getElementById('outlined-adornment-password').value = savedPassword;
    }
  }, []);

  const tokenContext  = useContext(TokenContext)

  const handleSubmit = async(event) => {

    event.preventDefault();
    const datas = new FormData(event.currentTarget);

    try {
      const response = await login(datas)

      if(response.status===200){

        // localStorage.setItem("access_token",response.data.token.access);
        // localStorage.setItem("refresh_token",response.data.token.refresh);
 
        localStorage.setItem("accesstoken",response.data.token)

        // console.log("accesstoken1234",response.data.token.access)

        
        navigate("/jobs");
   
        // Step 2: Save email and password to local storage if Remember Me is checked
        if (rememberMe) {
          localStorage.setItem('email', datas.get('email'));
          localStorage.setItem('password', datas.get('password'));
          localStorage.setItem('rememberMe', true);

        } else {
          
          // Clear saved credentials if Remember Me is unchecked
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          localStorage.removeItem('rememberMe');
        }
      } else {
        setAlert(alertState({
          message: response.data.message,
          severity: "error",
          show: true
        }));
      }

    } catch (err) {
      console.log(err);
    }
  };


  // const [showPassword, setShowPassword] = useState(false);
  // const navigate = useNavigate();
  // const { setAlert } = useContext(AlertToast);
  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };


  // const handleSubmit = async(event) => {
  //   event.preventDefault();
  //   const datas = new FormData(event.currentTarget);

  //   try {
  //   const response = await login(datas)

  //   if(response.status===200){
  //     localStorage.setItem("accesstoken",response.data.token)
  //     setAlert(alertState({
  //       message: "Login successfuly",
  //       severity: "success",
  //       show: true
  //     }));
  //     navigate("/jobs");
   
      
  //     // toast("Wow so easy!")
  //     console.log(response.data.message,"@@@@@@@@@@@")
  //   }else {
  //     setAlert(alertState({
  //       message: response.data.message,
  //       severity: "error",
  //       show: true
  //     }));
  //     console.log(response.data.message,"@@@@@@@@@@@")
  //   }

  //   }catch (err){

  //   }
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  // };
  return (
    <>
    <Card>
      <CardContent>
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{fontFamily:'Vollkorn'}}>
            SIGN IN
          </Typography>
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1}}>
            <TextField
              margin="normal"
              size ="small"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <FormControl sx={{ mt: 3}} fullWidth size ="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name = "password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}


                        </IconButton>
                      </InputAdornment>
                    }
                    label="Passwords"
                  />
                </FormControl>

            <FormControlLabel
              control={<Checkbox value="remember" color="primary"  checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}/>}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link 
                      // underline="none"
                      href="/forgot-password"
                      variant="body2"

                      >
                  Forgot password ?
                </Link>
              </Grid>
              <Grid item>
                <Link underline="none" sx={{color:"black"}} href="/registration" variant="body2">
                      {"Don't have an account? "}<span style={{color:"green"}}>{"Sign Up"}</span>
                    </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
      </CardContent>
    </Card>
    </>
  );
}
