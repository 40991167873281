import {
  Job_title_dropdown,
  datefilter,
  get_jobs,
  intern_work_mode,
  salary_filter,
  user_preferred_jobs,
  work_mode,
  year_of_filter,
} from "./Axiosfetch/Axiosfetch";

import { Fragment, forwardRef, useContext, useEffect, useState } from "react";
import { AlertToast, alertState, FullScreenContext } from "../divine-constants";
import {
  Box,
  Paper,
  MenuItem,
  TextField,
  Grid,
  InputAdornment,
  useTheme,
  Stack,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Chip,
  Tooltip,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
  FormGroup,
  Autocomplete,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  CircularProgress,
} from "@mui/material";
import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  History,
  Scale,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import openings from "./../images/openings.png";
import worker from "./../images/worker.png";
import sidebanner2 from "./../images/sidebanner2.png";
// import { AlertToast,alertState } from "../../divine-constants";
import { Link, useNavigate } from "react-router-dom";
import { set } from "react-hook-form";
import LaunchIcon from "@mui/icons-material/Launch";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Marquee from "react-fast-marquee";
import sutherlandlogo from "./../images/sutherlandlogo.png";
import tcslogo from "./../images/tcslogo.png";
import concentrixlogo from "./../images/concentrixlogo.png";
import hexawarelogo from "./../images/hexawarelogo.png";
import seltamlogo from "./../images/seltamlogo.png";
import metalogo from "./../images/metalogo.png";
import sideimage from "./../images/sideimage.png";
import sidebanner from "./../images/sidebanner.png";
import tcsbanner from "./../images/tcsbanner.png";
import hexawarebanner from "./../images/hexawarebanner.png";
import sutherlandbanner from "./../images/sutherlandbanner.png";
import jobsidebanner2 from "./../images/jobsidebanner2.png";
import "./Pages.style.css";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import LazyLoad from "react-lazyload";
import AnimatedHeading from "./Animi";

import NumberCount from "./Countup";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Jobs() {
  const { setIsFullScreen } = useContext(FullScreenContext);
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:768px)");
  const [itemCountSelection, setItemCountSelection] = useState(25);
  const [selectedValue, setSelectedValue] = useState();
  const [post_date, setPost_date] = useState();
  const [selectedValue1, setSelectedValue1] = useState("all");
  const [filarray, setFilarray] = useState([]);
  const { setAlert } = useContext(AlertToast);
  const token = localStorage.getItem("accesstoken");

  const [loading, setLoading] = useState(true);

  const [option, setOption] = useState([]);
  const [workMode, setWorkMode] = useState({
    wfh: false,
    wfo: false,
    hybrid: false,
  });

  const [checked, setChecked] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [salary_data, setSalary_data] = useState("0");
  const [year_of_expre, setYear_of_expre] = useState("0");

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchJobs = async () => {
    const jobtypes = "internships";

    try {
      const response = await get_jobs(jobtypes);
      if (response && response.data && Array.isArray(response.data)) {
        setJobs(response.data);
        setLoading(false);
        setPost_date(response.post_date);
      } else {
        throw new Error(`Something went wrong ${response.data.message}`);
      }
    } catch (error) {
      setAlert(
        alertState({
          message: error.message,
          severity: "error",
          show: true,
        })
      );
    }
  };

  useEffect(()=>{

    window.scrollTo(0, 0) 

  },[])

  useEffect(() => {
    if (checked === true) {
      const fetchdata = async () => {
        try {
          const response = await user_preferred_jobs();
          setLoading(false);
          if (response && response.data && Array.isArray(response.data)) {
            setJobs(response.data);
            setPost_date(response.post_date);
          } else {
            throw new Error(`Something went wrong ${response.data.message}`);
          }
        } catch (error) {
          setAlert(
            alertState({
              message: error.message,
              severity: "error",
              show: true,
            })
          );
        }
      };

      fetchdata();
    } else {
      fetchJobs();
    }
  }, [checked]);

  const handleCheckboxChanges = (event) => {
    setChecked(event.target.checked);
  };

  const handleYearOfsalaryChange = async (event) => {
    console.log(event.target.value, "event.target.value111");
    const data = event.target.value;
    setSalary_data(event.target.value);
    updateWorkModeAPI(
      { ...workMode },
      selectedValue,
      event.target.value,
      year_of_expre
    );
    // const responce = await salary_filter(data)
    // setFilteredJobs(responce.data)
  };
  // const handle_year_of_expre = async(event) => {
  const handleYearOfExpreChange = async (event, value) => {
    setYear_of_expre(value);

    updateWorkModeAPI({ ...workMode }, selectedValue, salary_data, value);
  };

  useEffect(() => {
    setFilteredJobs(
      filteredJobs.filter((j) =>
        j.job_title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);

  const handleCheckboxChange = (event) => {
    setLoading(true);
    const { value, name, checked } = event.target;

    if (checked) {
      setFilarray((prev) => [...prev, value]);
    } else {
      setFilarray((prev) => prev.filter((item) => item != value));
    }

    setWorkMode((prevWorkMode) => ({ ...prevWorkMode, [name]: checked }));
    updateWorkModeAPI(
      { ...workMode, [name]: checked },
      selectedValue,
      salary_data,
      year_of_expre
    );
  };

  const updateWorkModeAPI = async (
    updatedWorkMode,
    date_type,
    salary_type,
    y_of_e
  ) => {
    try {
      const response = await intern_work_mode(
        updatedWorkMode,
        date_type,
        salary_type,
        y_of_e
      );
      setLoading(false);
      if (response.status === 200) {
        setFilteredJobs(response.data);
      }
    } catch (error) {}
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleChanges(event);
    }
  };

  const handleRadioChange = (event) => {
    setLoading(true);
    setSelectedValue(event.target.value);
    setSelectedValue1(event.target.name);
    updateWorkModeAPI(
      { ...workMode },
      event.target.value,
      salary_data,
      year_of_expre
    );
  };

  const handleChanges = async (e) => {
    try {
      const responce = await Job_title_dropdown();
      setOption(responce.data);
    } catch {}

    if (e.target.value.length >= 1) {
      setSearchTerm(e.target.value);
    } else {
      setSearchTerm("");
      setFilteredJobs(jobs);
    }
  };

  const handleSearch = () => {
    if (selectedOption) {
      setSearchTerm(selectedOption);
    } else {
      setSearchTerm("");

      setFilteredJobs(jobs);
    }
  };

  console.log(option, "########");

  const handleOptionChange = async (event, value) => {
    setSelectedOption(value); // Update selected option when changed
    setSearchTerm("");
    setFilteredJobs(jobs);
  };

  const handleViewDetailsClick = (job) => {
    const queryParams = new URLSearchParams();
    Object.entries(job).forEach(([key, value]) => {
      queryParams.append(key, value);
    });
    const queryString = queryParams.toString();
    window.open(`/view-job-details?${queryString}`, "_blank");
  };

  const HandleFilterOpen = () => {
    setDialogOpen(true);
  };

  const HandleFilterClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = (data) => {
    if (data == selectedValue1) {
      setSelectedValue1("all");
      setSelectedValue();
    } else {
      setFilarray((prev) => prev.filter((item) => item != data));
      setWorkMode((prev) => {
        if (data === "Work from home") {
          return { ...prev, wfh: false };
        } else if (data === "Work from office") {
          return { ...prev, wfo: false };
        } else if (data === "Hybrid") {
          return { ...prev, hybrid: false };
        }
      });
    }
  };

  return (
    <>
      <Grid
        display="flex"
        sx={{ padding: 3, height: "fitContent" }}
        container
        spacing={3}
      >
        {/* <Grid display="flex"  sx={{height:"fitContent"}} container spacing={3}>  */}
        {/* item md={3} xs={12} sm={4} > */}

        <Grid item md={2.5} xs={12} sm={4}>
          {matches === true ? (
            <Stack
              position="sticky"
              top="12%"
              component={Paper}
              paddingInline={2}
              paddingBlock={1}
              spacing={1}
              sx={{ borderRadius: "10px", boxShadow: "0px 0px 10px #cdcdcd" }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <FilterAltIcon variant="outlined" sx={{ display: "inline" }} />{" "}
                <Typography variant="h4" fontWeight="800">
                  Filters
                </Typography>
              </Box>
              <Divider />

              {token ? (
                <Box>
                  <FormControl>
                    <FormControlLabel
                      label="My Preferences"
                      // control={<Checkbox />}
                      // onChange={handleCheckboxChanges}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChanges}
                        />
                      }
                    />
                  </FormControl>
                </Box>
              ) : null}
              <Box>
                <FormControl required={false}>
                  <FormLabel style={{ fontWeight: "bold" }}>
                    Date posted
                  </FormLabel>
                  <RadioGroup
                    value={selectedValue}
                    onChange={handleRadioChange}
                    style={{ marginLeft: theme.spacing(2) }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Today"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Last 3 Days"
                    />
                    <FormControlLabel
                      value="7"
                      control={<Radio />}
                      label="This week"
                    />
                    <FormControlLabel
                      value="31"
                      control={<Radio />}
                      label="This Month"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControl required={false}>
                  <FormLabel style={{ fontWeight: "bold" }}>
                    Work mode
                  </FormLabel>
                  <FormGroup style={{ marginLeft: theme.spacing(2) }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="wfh"
                          checked={workMode.wfh}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Work From Home"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          name="wfo"
                          checked={workMode.wfo}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Work From Office"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="hybrid"
                          checked={workMode.hybrid}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Hybrid"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box sx={{ width: "auto" }}>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Annual salary (in lakhs)
                </FormLabel>
                <br />
                <br />
                <br />

                <Slider
                  aria-label="Always visible"
                  defaultValue={0}
                  onChange={handleYearOfsalaryChange}
                  // getAriaValueText={valuetext}
                  valueLabelDisplay="on"
                  shiftStep={30}
                  step={2}
                  marks
                  min={0}
                  max={10}
                />
              </Box>

              <Box sx={{ width: "auto" }}>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Year of Experince
                </FormLabel>

                <Autocomplete
                  value={null} // Set initial value to null or omit this prop
                  onChange={handleYearOfExpreChange}
                  options={[
                    "Freasher",
                    "1 Year",
                    "2 Year",
                    "3 Year",
                    "4 Year",
                    "5 Year",
                  ]}
                  getOptionLabel={(option) => option}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Minimum year of Experience"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="blue" />
                          </InputAdornment>
                        ),
                      }}
                      required={false}
                    />
                  )}
                />
              </Box>
            </Stack>
          ) : (
            <>
              <Button
                color="inherit"
                variant="outlined"
                onClick={HandleFilterOpen}
              >
                <FilterAltIcon />
                Filter
              </Button>
              <Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"}>
                {[...filarray, selectedValue1].map((skill, index) => {
                  if (skill == "all") {
                    return;
                  } else {
                    return (
                      <Fragment key={index}>
                        <Chip
                          sx={{ mb: 0.5 }}
                          onDelete={() => handleDelete(skill)}
                          varient="outlined"
                          color="success"
                          label={skill}
                          key={index}
                        />
                        {index !== filarray.length - 1 && (
                          <Box width={4} height={4} borderRadius={10}></Box>
                        )}
                      </Fragment>
                    );
                  }
                })}
              </Stack>
              <Dialog
                open={dialogOpen}
                keepMounted
                onClose={HandleFilterClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                  <Box display={"flex"} alignItems={"center"}>
                    <IconButton
                      aria-label="close"
                      onClick={HandleFilterClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Stack
                    component={Paper}
                    paddingInline={2}
                    paddingBlock={1}
                    spacing={1}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <FilterAltIcon sx={{ display: "inline" }} />{" "}
                      <Typography variant="h4" fontWeight="800">
                        Filters
                      </Typography>
                    </Box>
                    <Divider />
                    <Box>
                      <FormControl>
                        <FormControlLabel
                          label="My Preferences"
                          control={<Checkbox />}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl required={false}>
                        <FormLabel style={{ fontWeight: "bold" }}>
                          Date Posted
                        </FormLabel>
                        <RadioGroup
                          value={selectedValue}
                          onChange={handleRadioChange}
                          style={{ marginLeft: theme.spacing(2) }}
                        >
                          <FormControlLabel
                            value="1"
                            name="Today"
                            control={<Radio />}
                            label="Today"
                          />
                          <FormControlLabel
                            value="3"
                            name="Last 3 Days"
                            control={<Radio />}
                            label="Last 3 Days"
                          />
                          <FormControlLabel
                            value="7"
                            name="This week"
                            control={<Radio />}
                            label="This week"
                          />
                          <FormControlLabel
                            value="30"
                            name="This month"
                            control={<Radio />}
                            label="This Month"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl required={false}>
                        <FormLabel style={{ fontWeight: "bold" }}>
                          Work Mode
                        </FormLabel>
                        <FormGroup style={{ marginLeft: theme.spacing(2) }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="Work from home"
                                name="wfh"
                                checked={workMode.wfh}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Work From Home"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="Work from office"
                                name="wfo"
                                checked={workMode.wfo}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Work From Office"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="Hybrid"
                                name="hybrid"
                                checked={workMode.hybrid}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label="Hybrid"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ width: "auto" }}>
                      <FormLabel style={{ fontWeight: "bold" }}>
                        Annual salary (in lakhs)
                      </FormLabel>

                      <Slider
                        aria-label="Temperature"
                        defaultValue={30}
                        onChange={handleYearOfsalaryChange}
                        // getAriaValueText={valuetext}
                        valueLabelDisplay="0"
                        shiftStep={30}
                        step={2}
                        marks
                        min={0}
                        max={10}
                      />
                    </Box>

                    <Box sx={{ width: "auto" }}>
                      <FormLabel style={{ fontWeight: "bold" }}>
                        Year of Experince
                      </FormLabel>

                      <Autocomplete
                        value={null} // Set initial value to null or omit this prop
                        onChange={handleYearOfExpreChange}
                        options={[
                          "Freasher",
                          "1 Year",
                          "2 Year",
                          "3 Year",
                          "4 Year",
                          "5 Year",
                        ]}
                        getOptionLabel={(option) => option}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              size="small"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Minimum year of Experience"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon color="blue" />
                                </InputAdornment>
                              ),
                            }}
                            required={false}
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </DialogContent>
              </Dialog>
            </>
          )}
        </Grid>

        <Grid item md={7} xs={12} sm={!isTabletOrMobile ? 4 : 8}>
          {/* backgroundImage: 'linear-gradient(45deg, #eafbff ,#f2fdff ,white,white,white */}

          {/* <div  style={{ marginBottom : "15px" , borderRadius : "10px" , backgroundImage : "linear-gradient(#0A79C9 , #24282A)", boxShadow:"0px 0px 10px #cdcdcd" }} > */}

          <Box
            sx={{
              marginBottom: "15px",
              borderRadius: "10px",
              backgroundImage:
                "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
              boxShadow: "0px 0px 10px #cdcdcd",
            }}
          >
            <Box>
              <Typography
                fontWeight={"bold"}
                sx={{
                  paddingLeft: 3,
                  paddingTop: 3,
                  paddingBottom: 1,
                  fontSize: { xs: 20, sm: 25 },
                  fontFamily: "Satoshi",
                  color: "black",
                }}
              >
                Find <span style={{ color: "white" }}> your dream </span>Job
                Here!
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{ paddingLeft: 3, paddingBottom: 3 }}
                variant="body2"
                color="white"
              >
              Discover Thousands of Opportunities Worldwide. Start Your Career Journey with Us Today!.
              
              </Typography>
            </Box>

            <Box
              sx={{
                paddingLeft: { xs: "16px", md: "30px" },
                paddingRight: { xs: "16px", md: "40px" },
                paddingBottom: "30px",
              }}
            >
              <Paper
                sx={{
                  borderRadius: "10px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: { xs: "row", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "3px",
                }}
              >

                
<Autocomplete
      options={option}
      value={selectedOption}
      onChange={handleOptionChange}
      sx={{ width: { xs: "100%", sm: "55%" } }}
      renderInput={(params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: matches ? "160%" : "100%",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <SearchIcon
            sx={{ color: "#0A79C9" }}
            fontSize="large"
          />
          <TextField
            {...params}
            variant="standard"
            helperText={false}
            placeholder="Search Jobs"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true, // Disable the underline
              style: {
                fontSize: 13,
                fontFamily: "Vollkorn",
                border: "none",
                outline: "none",
                width: "100%",
                color: "black",
                paddingLeft: "10px",
              },
              onChange: (event) => {
                handleChanges(event); // Ensure handleChanges is correctly passed
              },
              onKeyDown: handleKeyPress,
            }}
          />
        </Box>
      )}
    />
                {/* <Autocomplete
                  options={option}
                  value={selectedOption} // Set the selected option
                  onChange={handleOptionChange} // Handle option change
                  sx={{ width: { xs: "100%", sm: "55%" } }}
                  renderInput={(params) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <SearchIcon
                        sx={{ paddingLeft: "10px", color: "#0A79C9" }}
                        fontSize="large"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                        ref={params.InputProps.ref}
                      >
                        <input
                          type="text"
                          {...params.inputProps}
                          name="skills"
                          onChange={handleChanges}
                          onKeyDown={handleKeyPress}
                          style={{
                            marginLeft: "10px",
                            fontSize: 13,
                            fontFamily: "Vollkorn",
                            border: "none",
                            outline: "none",
                            width: "100%",
                          }}
                          placeholder="Search Jobs"
                        />
                      </Box>
                    </Box>
                  )}
                /> */}

                <Box
                  sx={{
                    color:
                      "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                  }}
                >
                  <Button
                    onClick={handleSearch}
                    sx={{
                      width: { xs: "100%", sm: "100px" },
                      padding: "8px",
                      margin: "2px",
                      borderRadius: "10px",
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Box>

          {/* <Paper sx={{marginTop : 2.5,marginBottom : 2.5, overflow:"hidden", backgroundImage: 'linear-gradient(45deg, #eafbff ,#f2fdff ,white,white,white)' , boxShadow : "0px 0px 10px #cdcdcd" }} > 
   
 
  </Paper> */}

          {/* <Grid sx={{ display :"flex" , borderRadius : "10px" , backgroundColor:"white" }} container spacing={3} >


            <Grid item md={10} sm={10} xs={10}>

              <Autocomplete
                options={option}
                fullWidth
                value={selectedOption} // Set the selected option
                onChange={handleOptionChange} // Handle option change
                
                renderInput={(params) => (

                  <TextField
                    {...params}
                    onChange={handleChanges}
                    // onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                    // onClick={handleOptionChange} // Handle option change
                   
                    variant="standard"
                    size="medium"

                    placeholder="Search using the job title or by location. Eg UI Designer"

                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    className="search-bar"
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props}>{option}</MenuItem>
                )}
              />
            </Grid>

            <Grid item md={2} sm={2} xs={2}>
              <Button

                variant="contained"
                fullWidth
                onClick={handleSearch}
                sx={{ width: "100%", mt: 1 }}
              >
                Search
              </Button>
           
            </Grid>

          </Grid> */}

          {/* </Paper> */}

          {/* {matches === true? 
          <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between">
            <Box>
              <ToggleButtonGroup
                value={itemCountSelection}
                exclusive
                size="small"
                color="primary"
                onChange={(_, value) => setItemCountSelection(value)}
                aria-label="items per page"
              >
                <ToggleButton value="25" aria-label="25">
                  25
                </ToggleButton>
                <ToggleButton value="50" aria-label="50">
                  50
                </ToggleButton>
                <ToggleButton value="75" aria-label="75">
                  75
                </ToggleButton>
                <ToggleButton value="all" aria-label="all">
                  All
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box className="align-icon">
              <IconButton>
                <ChevronLeftOutlined />
              </IconButton>
              <Typography
                fontWeight={800}
                style={{ verticalAlign: "middle", display: "inline" }}
              >
                1
              </Typography>
              &nbsp;
              <Typography
                color="GrayText"
                style={{ verticalAlign: "middle", display: "inline" }}
              >
                of 100
              </Typography>
              <IconButton>
                <ChevronRightOutlined />
              </IconButton>
            </Box>
          </Box> 
          : null} */}
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <CircularProgress /> */}

              <lottie-player
                src="https://lottie.host/992bd503-aaa0-40e2-ac55-1ddfe95cbc47/zCjjDTPV5f.json"
                background="##FFFFFF"
                speed="1"
                loop
                autoplay
                direction="1"
                mode="normal"
                style={{ width: 300, height: 300 }}
              ></lottie-player>
            </Box>
          ) : (
            <Box sx={{ colour: "#cdcdcd" }}>
              {filteredJobs.length > 0 ? (
                filteredJobs.map((job) => (
                  <Box
                    key={job.id}
                    component={Paper}
                    paddingInline={2}
                    paddingBlock={2}
                    onClick={() =>{window.scrollTo(0,0); navigate("/view-job-details", { state: { ...job } })}}
                    sx={{
                      // ":hover": { boxShadow: "none" },
                      ":hover": {
                        boxShadow: "0px 0px 10px #cdcdcd",
                        transform: "scale(1.010)",
                      },
                      cursor: "pointer",
                      mb: 2,
                      transition: "0.2s ease-in-out",
                      // width : "650px",
                      borderRadius: "30px",
                      border: "0.5px solid #cdcdcd",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <img
                        width={50}
                        // height = {50}

                        src={job.company_logo}
                        alt="dummy"
                      />

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Box>
                        <Typography
                          className="align-icon"
                          fontWeight={500}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {/* {job.job_title ? job.job_title.toUpperCase() : null} */}
                          {job.job_title}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            fontSize={12}
                            sx={{
                              textTransform: "capitalize",
                              color: "#00000090",
                            }}
                          >
                            {job.company_name}
                          </Typography>

                          <a
                            href={job.company_website_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <LaunchIcon
                              color="primary"
                              sx={{ fontSize: 17, marginLeft: 1 }}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                    {/* <br /> */}

                    <Divider />
                    <br />

                    <Grid container rowSpacing={1}>
                      <Grid item sm={12} xs={12} md={12}>
                        {matches === true ? (
                          <Stack
                            sx={{ mt: 1 }}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <img
                              style={{ width: 16, height: 16 }}
                              src={Location}
                            />
                            {job.post_city ? (
                              <Typography variant="overline" fontSize={12}>
                                {job.post_city
                                  .map((location) => location.toUpperCase())
                                  .join(", ")}
                              </Typography>
                            ) : null}
                          </Stack>
                        ) : (
                          <Stack
                            sx={{ mt: 1 }}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <img
                              style={{ width: 16, height: 16 }}
                              src={Location}
                            />
                            {job.post_city ? (
                              <Typography variant="overline" fontSize={12}>
                                {job.post_city
                                  .map((location) => location.toUpperCase())
                                  .join(", ")}
                              </Typography>
                            ) : null}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item sm={6} xs={12} md={3}>
                        {matches === true ? (
                          <Stack direction={"column"}>
                            <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 16, height: 16 }}
                                src={experience}
                              />
                              <Typography color={"#000000"} fontSize={12}>
                                EXPERIENCE
                              </Typography>
                            </Stack>
                            <Typography variant="overline" fontSize={12}>
                              {job.minimum_experience} -{" "}
                              {job.maximum_experience} YEARS
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={experience}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              {job.minimum_experience} -{" "}
                              {job.maximum_experience} Years experience
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                      <Grid item sm={6} xs={12} md={4}>
                        {matches === true ? (
                          <Stack direction={"column"}>
                            <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 16, height: 16 }}
                                src={salary}
                              />
                              {/* <Typography color={'#000000'} fontSize={12}>CTC (ANNUAL)</Typography> */}
                              <Typography color={"#000000"} fontSize={12}>
                                CTC{" "}
                              </Typography>
                            </Stack>
                            <Typography variant="overline" fontSize={12}>
                              {job.minimum_salary.toLocaleString("en-IN")} -{" "}
                              {job.maximum_salary.toLocaleString("en-IN")}{" "}
                              {job.salary_per}{" "}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={salary}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              CTC {job.minimum_salary.toLocaleString("en-IN")} -{" "}
                              {job.maximum_salary.toLocaleString("en-IN")}{" "}
                              {job.salary_per}
                            </Typography>
                          </Stack>
                        )}
                      </Grid>

                      <Grid item sm={3} xs={12} md={3}>
                        {matches === true ? (
                          <Stack direction={"column"}>
                            <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 20, height: 20 }}
                                src={worker}
                              />
                              <Typography color={"#000000"} fontSize={12}>
                                WORK MODE{" "}
                              </Typography>
                            </Stack>
                            <Typography variant="overline" fontSize={12}>
                              {job.work_mode}{" "}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack sx={{ mt: 2 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 20, height: 20 }}
                              src={worker}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              WORK MODE {job.work_mode}{" "}
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                    </Grid>

                    {/* <Grid container sx={{mt:1}}> */}
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        {matches === true ? (
                          // <Stack direction={'column'}>
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 20, height: 20 }}
                              src={openings}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              {" "}
                              {job.vacancy} vacancies
                            </Typography>
                          </Stack>
                        ) : (
                          // </Stack>

                          <Stack sx={{ mt: 2 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 20, height: 20 }}
                              src={openings}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              {" "}
                              {job.vacancy} vacancies
                            </Typography>
                          </Stack>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                          <img
                            style={{ width: 20, height: 20 }}
                            src={applicants}
                          />
                          {/* <Typography color={'#000000'} fontSize={14}> 2 Applicants</Typography>					 */}
                          <Typography color={"#000000"} fontSize={14}>
                            {" "}
                            {job.count} Applicants
                          </Typography>
                        </Stack>
                      </Grid>

                      {/* <Grid item xs={12} sm={6} md={3}>
              <Stack sx={{mt:1}} direction={'row'} spacing={1}>
            <img style={{width:16,height:16}} src={duration}/>
            <Typography color={'#000000'} fontSize={13}>{job.post_date === 0 ? 'Posted Today ' : 
              job.post_date === 1 ? 'Posted yesterday' :
                  job.post_date === 7 ? 'Posted One week ago' :
                    `Posted ${job.post_date} days ago`}</Typography>
            </Stack>

            </Grid> */}
                    </Grid>

                    <br />
                    <Divider />
                    <br />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                          <img
                            style={{ width: 16, height: 16 }}
                            src={duration}
                          />
                          <Typography color={"#000000"} fontSize={13}>
                            {job.post_date === 0
                              ? "Posted Today "
                              : job.post_date === 1
                              ? "Posted yesterday"
                              : job.post_date === 7
                              ? "Posted One week ago"
                              : `Posted ${job.post_date} days ago`}
                          </Typography>
                        </Stack>
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            navigate("/view-job-details", {
                              state: { ...job },
                            });
                          }}
                        >
                          {" "}
                          View details{" "}
                        </Button>
                      </div>
                    </Box>

                    {/* <Box>
                      <Button variant="outlined" onClick={() => handleViewDetailsClick(job)}> View details </Button>
                    </Box> */}
                  </Box>
                ))
              ) : (
                <p
                  style={{
                    size: "bold",
                    color: "rgba(0, 0, 0, 0.6)",
                    fontStyle: "italic",
                    textAlign: "center",
                    paddingTop: "20vh",
                  }}
                >
                  No jobs available
                </p>
              )}
            </Box>
          )}
        </Grid>

        <Grid item md={2.5} xs={12} sm={4}>
          {/* <Paper elevation={3} sx={{ padding:"4px", minWidth: 100, backgroundImage: 'linear-gradient(45deg, #eafbff ,#f2fdff ,white,white,white)',  borderRadius:"10px", maxWidth: 300 }}>

  <Typography gutterBottom  fontWeight={500} textAlign={"center"} variant="h4" component="div">
    Suggested
  </Typography>

  </Paper> */}

          <Box>
            <Card
              sx={{
                maxWidth: 345,
                backgroundImage: "white",
                boxShadow: "0px 0px 10px #cdcdcd",
                borderRadius: "10px",
              }}
            >
              <Swiper
                modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                  delay: 2500, // Delay between transitions in milliseconds
                  disableOnInteraction: false, // Allow autoplay to continue after user interactions
                }}
              >
                <SwiperSlide>
                  <LazyLoad >
                    <img src={tcsbanner} alt="Hlo" style={{ width: "100%" }} />
                  </LazyLoad>
                </SwiperSlide>

                <SwiperSlide>
                  <LazyLoad >
                    <img
                      src={sutherlandbanner}
                      alt="Hlo"
                      style={{ width: "100%" }}
                    />
                  </LazyLoad>
                </SwiperSlide>
                <SwiperSlide>
                  <LazyLoad>
                    <img src={sidebanner} alt="Hlo" style={{ width: "100%" }} />
                  </LazyLoad>
                </SwiperSlide>

                {/* Add more slides as needed */}
              </Swiper>

              {/* <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Please login/register to
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, 
          </Typography>
        </CardContent> */}
            </Card>
          </Box>

          {/* <Paper elevation={3} sx={{ padding: 2, marginTop : 1, maxHeight:400, minWidth: 100, backgroundImage: 'linear-gradient(45deg, #eafbff ,#f2fdff ,white,white,white)', boxShadow:"0px 0px 10px #cdcdcd", borderRadius:"10px", maxWidth: 300 }}>

<Typography gutterBottom  fontWeight={500} textAlign={"center"} variant="h4" component="div">
Please login/register to
</Typography>


<Typography variant="body2" color="text.secondary">
  Lizards are a widespread group of squamate reptiles, with over 6,000
  species, ranging across all continents except Antarctica

</Typography>


</Paper> */}

          {matches && !isTabletOrMobile ?  (
            <Box mt={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  backgroundImage: "white",
                  boxShadow: "0px 0px 10px #cdcdcd",
                  borderRadius: "10px",
                }}
              >
                <img src={sidebanner2} alt="Hlo" style={{ width: "100%" }} />

                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Placement
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>

                <Divider />

                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button size="small">Buy</Button>
                  <Button size="small"> View Details</Button>
                </CardActions>
              </Card>
            </Box>
          ) : null}


          {matches && !isTabletOrMobile  ? (
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                marginTop: 3,
                minWidth: 100,
                backgroundImage:
                  "linear-gradient(45deg, #eafbff ,#f2fdff ,white,white,white)",
                boxShadow: "0px 0px 10px #cdcdcd",
                borderRadius: "10px",
                maxWidth: 300,
              }}
            >
              <Typography
                gutterBottom
                fontWeight={500}
                textAlign={"center"}
                variant="h4"
                component="div"
              >
                Top Companies trust us
              </Typography>

              <div
                style={{ width: "100%", height: 1, backgroundColor: "#cdcdcd" }}
              ></div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <img
                    src={jobsidebanner2}
                    alt="Hlo"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      height: "200px",
                      overflow: "hidden",
                      "& > div": {
                        animation: "marquee-up 10s linear infinite",
                        "@keyframes marquee-up": {
                          "0%": { transform: "translateY(100%)" },
                          "100%": { transform: "translateY(-550%)" },
                        },
                      },
                    }}
                  >
                    <Box mr={2}>
                      <img
                        src={tcslogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={sutherlandlogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={concentrixlogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={hexawarelogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>

                    <Box mr={2}>
                      <img
                        src={seltamlogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      height: "200px",
                      overflow: "hidden",
                      "& > div": {
                        animation: "marquee-down 10s linear infinite",
                        "@keyframes marquee-down": {
                          "0%": { transform: "translateY(-100%)" },
                          "100%": { transform: "translateY(550%)" },
                        },
                      },
                    }}
                  >
                    <Box mr={2}>
                      <img
                        src={metalogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>

                    <Box mr={2}>
                      <img
                        src={tcslogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={sutherlandlogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={concentrixlogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                    <Box mr={2}>
                      <img
                        src={hexawarelogo}
                        alt="Hlo"
                        style={{ width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </>
  );

  //   return (
  //     <>
  //       <Grid sx={{ padding:3 }} container spacing={3}>
  //         <Grid item md={3} xs={12} sm={4}>
  //           {matches === true ? (
  //             <Stack
  //             position = "sticky"  top= "12%"
  //               component={Paper}
  //               paddingInline={2}
  //               paddingBlock={1}
  //               spacing={1}
  //             >
  //               <Box display="flex" alignItems="center" gap={1}>
  //                 <FilterAltIcon sx={{ display: "inline" }} />{" "}
  //                 <Typography variant="h4" fontWeight="800">
  //                   Filters
  //                 </Typography>
  //               </Box>
  //               <Divider />

  //               {token ? <Box>
  //                 <FormControl>
  //                   <FormControlLabel
  //                     label="My Preferences"
  //                     // control={<Checkbox />}
  //                     // onChange={handleCheckboxChanges}
  //                     control={
  //                       <Checkbox
  //                           checked={checked}
  //                           onChange={handleCheckboxChanges}
  //                       />
  //                     }

  //                   />
  //                 </FormControl>
  //               </Box> : null }
  //               <Box>
  //                 <FormControl required={false}>
  //                   <FormLabel style={{ fontWeight: "bold" }}>
  //                     Date posted
  //                   </FormLabel>
  //                   <RadioGroup
  //                     value={selectedValue}
  //                     onChange={handleRadioChange}
  //                     style={{ marginLeft: theme.spacing(2) }}
  //                   >
  //                     <FormControlLabel
  //                       value="1"
  //                       control={<Radio />}
  //                       label="Today"
  //                     />
  //                     <FormControlLabel
  //                       value="3"
  //                       control={<Radio />}
  //                       label="Last 3 Days"
  //                     />
  //                     <FormControlLabel
  //                       value="7"
  //                       control={<Radio />}
  //                       label="This Week"
  //                     />
  //                     <FormControlLabel
  //                       value="31"
  //                       control={<Radio />}
  //                       label="This Month"
  //                     />
  //                   </RadioGroup>
  //                 </FormControl>
  //               </Box>
  //               <Box>
  //                 <FormControl required={false}>
  //                   <FormLabel style={{ fontWeight: "bold" }}>
  //                     Work mode
  //                   </FormLabel>
  //                   <FormGroup style={{ marginLeft: theme.spacing(2) }}>
  //                     <FormControlLabel
  //                       control={
  //                         <Checkbox
  //                           name="wfh"
  //                           checked={workMode.wfh}
  //                           onChange={handleCheckboxChange}
  //                         />
  //                       }
  //                       label="Work from Home"
  //                     />
  //                     <FormControlLabel
  //                       control={
  //                         <Checkbox
  //                           name="wfo"
  //                           checked={workMode.wfo}
  //                           onChange={handleCheckboxChange}
  //                         />
  //                       }
  //                       label="Work from Office"
  //                     />
  //                     <FormControlLabel
  //                       control={
  //                         <Checkbox
  //                           name="hybrid"
  //                           checked={workMode.hybrid}
  //                           onChange={handleCheckboxChange}
  //                         />
  //                       }
  //                       label="Hybrid"
  //                     />
  //                   </FormGroup>
  //                 </FormControl>
  //               </Box>
  //               <Box sx={{ width: "auto" }}>
  //               <FormLabel style={{ fontWeight: "bold" }}>
  //                   Annual salary (in lakhs)
  //                   </FormLabel>
  //                 <br/>
  //                 <br/>
  //                 <br/>

  //               <Slider
  //                   aria-label="Always visible"
  //                   defaultValue={0}
  //                   valueLabelDisplay="on"
  //                   onChange={handleYearOfsalaryChange}
  //                   // getAriaValueText={valuetext}

  //                   shiftStep={30}
  //                   step={2}
  //                   marks
  //                   min={0}
  //                   max={10}
  //                 />

  //               </Box>

  //             <Box sx={{ width: "auto" }}>

  //               <FormLabel style={{ fontWeight: "bold" }}>
  //                     Year of Experince
  //                   </FormLabel>

  //                   <Autocomplete
  //                       value={null} // Set initial value to null or omit this prop
  //                       onChange={handleYearOfExpreChange}
  //                       options={["Freasher","1 Year", "2 Year","3 Year","4 Year","5 Year"]}
  //                       getOptionLabel={(option) => option}
  //                       freeSolo
  //                       renderTags={(value, getTagProps) =>
  //                         value.map((option, index) => (
  //                           <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
  //                         ))
  //                       }
  //                       renderInput={(params) => (
  //                         <TextField
  //                           {...params}
  //                           placeholder="Minimum year of Experience"
  //                           InputProps={{
  //                             ...params.InputProps,
  //                             startAdornment: (
  //                               <InputAdornment position="start">
  //                                 <SearchIcon />
  //                               </InputAdornment>
  //                             ),
  //                           }}
  //                           required={false}
  //                         />

  //             	// <Autocomplete
  // 							// 	// multiple
  // 							// 	value={}
  // 							// 	onChange={handle_year_of_expre}
  // 							// 	options={["1 year","2 year"]}
  // 							// 	getOptionLabel={(option) => option}
  // 							// 	freeSolo
  // 							// 	renderTags={(value, getTagProps) =>
  // 							// 		value.map((option, index) => (
  // 							// 			<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
  // 							// 		))
  // 							// 	}

  // 							// 	renderInput={(params) => (
  // 							// 		<TextField
  // 							// 			{...params}
  // 							// 			placeholder="Year of Experince"
  // 							// 			// label="Year"
  //               //       InputProps={{
  //               //         ...params.InputProps,
  //               //         startAdornment: (
  //               //           <InputAdornment position="start">
  //               //             <SearchIcon />
  //               //           </InputAdornment>
  //               //         ),
  //               //       }}
  // 							// 			required = {false}

  // 							// 		/>
  // 								)}
  // 							/>
  //             </Box>
  //             </Stack>
  //           ) : (
  //             <>
  //               <Button
  //                 color="inherit"
  //                 variant="outlined"
  //                 onClick={HandleFilterOpen}
  //               >
  //                 <FilterAltIcon />
  //                 Filter
  //               </Button>
  //               <Stack
  //                 direction={"row"}
  //                 alignItems={"center"}
  //                 flexWrap={"wrap"}
  //               >
  //                 {[...filarray, selectedValue1].map((skill, index) => {
  //                   if (skill == "all") {
  //                     return;
  //                   } else {
  //                     return (
  //                       <Fragment key={index}>
  //                         <Chip sx={{mb:.5}}
  //                           onDelete={() => handleDelete(skill)}
  //                           varient="outlined"
  //                           color="success"
  //                           label={skill}
  //                           key={index}
  //                         />
  //                         {index !== filarray.length - 1 && (
  //                           <Box width={4} height={4} borderRadius={10}></Box>
  //                         )}
  //                       </Fragment>
  //                     );
  //                   }
  //                 })}
  //               </Stack>
  //               <Dialog
  //                 open={dialogOpen}
  //                 keepMounted
  //                 onClose={HandleFilterClose}
  //                 aria-describedby="alert-dialog-slide-description"
  //               >
  //                 <DialogTitle>
  //                   <Box display={"flex"} alignItems={"center"}>
  //                     <IconButton
  //                       aria-label="close"
  //                       onClick={HandleFilterClose}
  //                       sx={{
  //                         position: "absolute",
  //                         right: 8,
  //                         top: 8,
  //                         color: (theme) => theme.palette.grey[500],
  //                       }}
  //                     >
  //                       <CloseIcon />
  //                     </IconButton>
  //                   </Box>
  //                 </DialogTitle>
  //                 <DialogContent>
  //                   <Stack
  //                     component={Paper}
  //                     paddingInline={2}
  //                     paddingBlock={1}
  //                     spacing={1}
  //                   >
  //                     <Box display="flex" alignItems="center" gap={1}>
  //                       <FilterAltIcon sx={{ display: "inline" }} />{" "}
  //                       <Typography variant="h4" fontWeight="800">
  //                         Filters
  //                       </Typography>
  //                     </Box>
  //                     <Divider />
  //                     <Box>
  //                       <FormControl>
  //                         <FormControlLabel
  //                           label="My Preferences"
  //                           control={<Checkbox />}
  //                         />
  //                       </FormControl>
  //                     </Box>
  //                     <Box>
  //                       <FormControl required={false}>
  //                         <FormLabel style={{ fontWeight: "bold" }}>
  //                           Date posted
  //                         </FormLabel>
  //                         <RadioGroup
  //                           value={selectedValue}
  //                           onChange={handleRadioChange}
  //                           style={{ marginLeft: theme.spacing(2) }}
  //                         >
  //                           <FormControlLabel
  //                             value="1"
  //                             name="Today"
  //                             control={<Radio />}
  //                             label="Today"
  //                           />
  //                           <FormControlLabel
  //                             value="3"
  //                             name="Last 3 days"
  //                             control={<Radio />}
  //                             label="Last 3 Days"
  //                           />
  //                           <FormControlLabel
  //                             value="7"
  //                             name="This week"
  //                             control={<Radio />}
  //                             label="This Week"
  //                           />
  //                           <FormControlLabel
  //                             value="30"
  //                             name="This month"
  //                             control={<Radio />}
  //                             label="This Month"
  //                           />
  //                         </RadioGroup>
  //                       </FormControl>
  //                     </Box>
  //                     <Box>
  //                       <FormControl required={false}>
  //                         <FormLabel style={{ fontWeight: "bold" }}>
  //                           Work mode
  //                         </FormLabel>
  //                         <FormGroup style={{ marginLeft: theme.spacing(2) }}>
  //                           <FormControlLabel
  //                             control={
  //                               <Checkbox
  //                                 value="Work from home"
  //                                 name="wfh"
  //                                 checked={workMode.wfh}
  //                                 onChange={handleCheckboxChange}
  //                               />
  //                             }
  //                             label="Work From Home"
  //                           />
  //                           <FormControlLabel
  //                             control={
  //                               <Checkbox
  //                                 value="Work from office"
  //                                 name="wfo"
  //                                 checked={workMode.wfo}
  //                                 onChange={handleCheckboxChange}
  //                               />
  //                             }
  //                             label="Work From Office"
  //                           />
  //                           <FormControlLabel
  //                             control={
  //                               <Checkbox
  //                                 value="Hybrid"
  //                                 name="hybrid"
  //                                 checked={workMode.hybrid}
  //                                 onChange={handleCheckboxChange}
  //                               />
  //                             }
  //                             label="Hybrid"
  //                           />
  //                         </FormGroup>
  //                       </FormControl>
  //                     </Box>
  //                   </Stack>
  //                 </DialogContent>
  //               </Dialog>
  //             </>
  //           )}
  //         </Grid>
  //         <Grid item md={9} xs={12} sm={8}>
  //           <Grid sx={{ padding: 1 }} spacing={2} container>
  //             <Grid item md={10} sm={10} xs={10}>
  //               <Autocomplete
  //                 options={option}
  //                 fullWidth
  //                 value={selectedOption} // Set the selected option
  //                 onChange={handleOptionChange} // Handle option change

  //                 renderInput={(params) => (

  //                   <TextField
  //                     {...params}

  //                     onChange={handleChanges}
  //                     // onChange={(e) => setSearchInput(e.target.value)}
  //                     onKeyDown={handleKeyPress}
  //                     // onClick={handleOptionChange} // Handle option change

  //                     variant="standard"
  //                     size="medium"
  //                     placeholder="Search using the job title or by location. Eg UI Designer"
  //                     InputProps={{
  //                       ...params.InputProps,
  //                       startAdornment: (
  //                         <InputAdornment position="start">
  //                           <SearchIcon />
  //                         </InputAdornment>
  //                       ),
  //                     }}
  //                     className="search-bar"
  //                   />
  //                 )}
  //                 renderOption={(props, option) => (
  //                   <MenuItem {...props}>{option}</MenuItem>
  //                 )}
  //               />
  //             </Grid>

  //             <Grid item md={2} sm={2} xs={2}>
  //               <Button
  //                 variant="contained"
  //                 fullWidth
  //                 onClick={handleSearch}
  //                 sx={{ width: "100%", mt: 1 }}
  //               >
  //                 Search
  //               </Button>

  //             </Grid>

  //           </Grid>

  //           {/* {matches === true?
  //           <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between">
  //             <Box>
  //               <ToggleButtonGroup
  //                 value={itemCountSelection}
  //                 exclusive
  //                 size="small"
  //                 color="primary"
  //                 onChange={(_, value) => setItemCountSelection(value)}
  //                 aria-label="items per page"
  //               >
  //                 <ToggleButton value="25" aria-label="25">
  //                   25
  //                 </ToggleButton>
  //                 <ToggleButton value="50" aria-label="50">
  //                   50
  //                 </ToggleButton>
  //                 <ToggleButton value="75" aria-label="75">
  //                   75
  //                 </ToggleButton>
  //                 <ToggleButton value="all" aria-label="all">
  //                   All
  //                 </ToggleButton>
  //               </ToggleButtonGroup>
  //             </Box>
  //             <Box className="align-icon">
  //               <IconButton>
  //                 <ChevronLeftOutlined />
  //               </IconButton>
  //               <Typography
  //                 fontWeight={800}
  //                 style={{ verticalAlign: "middle", display: "inline" }}
  //               >
  //                 1
  //               </Typography>
  //               &nbsp;
  //               <Typography
  //                 color="GrayText"
  //                 style={{ verticalAlign: "middle", display: "inline" }}
  //               >
  //                 of 100
  //               </Typography>
  //               <IconButton>
  //                 <ChevronRightOutlined />
  //               </IconButton>
  //             </Box>
  //           </Box>
  //           : null} */}
  //           {loading ?

  //           <Box sx={{width : "100%",display : "flex", alignItems : "center", justifyContent : "center"}}>

  //   {/* <CircularProgress /> */}

  //             <lottie-player src="https://lottie.host/992bd503-aaa0-40e2-ac55-1ddfe95cbc47/zCjjDTPV5f.json" background="##FFFFFF" speed="1" loop  autoplay direction="1" mode="normal" style={{width : 300 , height :300}}></lottie-player>

  //           </Box>

  //           :
  //           <Box>

  //             {filteredJobs.length > 0 ? (
  //               filteredJobs.map((job) => (
  //                 <Box
  //                   key={job.id}
  //                   component={Paper}
  //                   paddingInline={2}
  //                   paddingBlock={2}
  //                   sx={{
  //                     ":hover": { boxShadow: "none" },
  //                     cursor: "pointer",
  //                     mb: 2,
  //                   }}

  //                 >
  //                   <Box display="flex" alignItems="center" gap={2}>
  //                     <img
  //                       width={48}
  //                       src={job.company_logo}
  //                       alt="dummy"
  //                     />

  //                     <Divider orientation="vertical" variant="middle" flexItem />
  //                     <Box>
  //                       <Typography
  //                         className="align-icon"
  //                         fontWeight={500}
  //                         variant="h4"
  //                         sx={{textTransform:"capitalize"}}
  //                       >

  //                         {/* {job.job_title ? job.job_title.toUpperCase() : null} */}
  //                         {job.job_title}
  //                       </Typography>

  //                          <Box display="flex" flexDirection="row" alignItems="center">
  //                       <Typography fontSize={12} sx={{ textTransform: "capitalize", color: "#00000090" }}>
  //                         {job.company_name}
  //                       </Typography>

  //                       <Link to={job.company_website_link} target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: 'inherit' }}>
  //                         <LaunchIcon color="primary" sx={{ fontSize: 17, marginLeft: 1 }} />
  //                       </Link>

  //                          </Box>
  //                     </Box>
  //                   </Box>
  //                   <br />

  //                   <Grid container rowSpacing={1}>

  //                     <Grid  item sm={12} xs={12} md={12}>
  //                       {matches === true ?
  //                                     <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
  //                                     <img style={{width:16,height:16}} src={Location}/>
  //                                     {job.post_city ? (
  //                                       <Typography variant="overline" fontSize={12}>
  //                                         {job.post_city.map(location => location.toUpperCase()).join(', ')}
  //                                       </Typography>
  //                                     ) : null}
  //                                   </Stack>:

  //                                 <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
  //                                   <img style={{width:16,height:16}} src={Location}/>
  //                                 {job.post_city ? (
  //                                       <Typography variant="overline" fontSize={12}>
  //                                         {job.post_city.map(location => location.toUpperCase()).join(', ')}
  //                                       </Typography>
  //                                     ) : null}

  //                               </Stack>}
  //                     </Grid>

  //                   </Grid>

  //                 <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

  //             <Grid item sm={6} xs={12} md={3}>
  //             {matches === true ?<Stack direction={'column'}>
  //             <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //             <img style={{width:16,height:16}} src={experience}/>
  //             <Typography color={'#000000'} fontSize={12}>EXPERIENCE</Typography>
  //           </Stack>
  //           <Typography variant="overline" fontSize={12}>{job.minimum_experience} - {job.maximum_experience} YEARS</Typography>
  //           </Stack>:<Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //             <img style={{width:16,height:16}} src={experience}/>
  //             <Typography color={'#000000'} fontSize={12}>{job.minimum_experience} - {job.maximum_experience} Years experience</Typography>
  //           </Stack>}
  //             </Grid>
  //             <Grid item sm={6} xs={12} md={3}>
  //             {matches === true ?<Stack direction={'column'}>
  //               <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //               <img style={{width:16,height:16}} src={salary}/>
  //               {/* <Typography color={'#000000'} fontSize={12}>CTC (ANNUAL)</Typography> */}
  //               <Typography color={'#000000'} fontSize={12}>CTC </Typography>
  //               </Stack>
  //             <Typography variant="overline" fontSize={12}>{job.minimum_salary.toLocaleString("en-IN")} - {job.maximum_salary.toLocaleString("en-IN")} {job.salary_per} </Typography>
  //             </Stack>:<Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //               <img style={{width:16,height:16}} src={salary}/>
  //               <Typography color={'#000000'} fontSize={12}>CTC  {job.minimum_salary.toLocaleString("en-IN")} - {job.maximum_salary.toLocaleString("en-IN")} {job.salary_per}</Typography>
  //               </Stack>}
  //             </Grid>

  //             <Grid item sm={3} xs={12} md={3}>
  //             {matches === true ?<Stack direction={'column'}>
  //               <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //               <img style={{width:20,height:20}} src={worker}/>
  //               <Typography color={'#000000'} fontSize={12}>WORK MODE </Typography>
  //               </Stack>
  //             <Typography variant="overline" fontSize={12}>{job.work_mode} </Typography>
  //             </Stack>:<Stack sx={{mt:2}} direction={'row'} spacing={1}>
  //               <img style={{width:20,height:20}} src={worker}/>
  //               <Typography color={'#000000'} fontSize={12}>WORK MODE {job.work_mode} </Typography>
  //               </Stack>}
  //           </Grid>

  //           </Grid>

  //           <Grid container sx={{mt:1}}>

  //           <Grid item xs={12}sm={6}md={3}>

  // {matches === true ?

  // // <Stack direction={'column'}>
  // <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  // <img style={{width:20,height:20}} src={openings}/>
  // <Typography color={'#000000'} fontSize={12}> {job.vacancy} vacancies</Typography>

  // </Stack>

  // // </Stack>

  // :

  // <Stack sx={{mt:2}} direction={'row'} spacing={1}>
  // <img style={{width:20,height:20}} src={openings}/>
  // <Typography color={'#000000'} fontSize={12}> {job.vacancy} vacancies</Typography>
  // </Stack>

  // }

  // </Grid>

  //             <Grid item  xs={12}sm={6}md={3}>
  //                 <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //                 <img style={{width:20,height:20}} src={applicants}/>
  //                   {/* <Typography color={'#000000'} fontSize={14}> 2 Applicants</Typography>					 */}
  //                   <Typography color={'#000000'} fontSize={14}> {job.count} Applicants</Typography>
  //               </Stack>
  //             </Grid>

  //             <Grid item xs={12} sm={6} md={3}>
  //               <Stack sx={{mt:1}} direction={'row'} spacing={1}>
  //             <img style={{width:16,height:16}} src={duration}/>
  //             <Typography color={'#000000'} fontSize={13}>{job.post_date === 0 ? 'Posted Today ' :
  //               job.post_date === 1 ? 'Posted yesterday' :
  //                   job.post_date === 7 ? 'Posted One week ago' :
  //                     `Posted ${job.post_date} days ago`}</Typography>
  //             </Stack>

  //             </Grid>
  //           </Grid>

  //                 <br />
  //                   <Divider />
  //                   <br />
  //                   <Box>
  //                     <Button variant="outlined" onClick={() =>{window.scrollTo(0,0); navigate("/view-job-details", { state: { ...job } })}}> View details </Button>
  //                   </Box>
  //                     {/* <Box>
  //                       <Button variant="outlined" onClick={() => handleViewDetailsClick(job)}> View details </Button>
  //                     </Box> */}
  //                 </Box>
  //               ))
  //             ) : (
  //               <p
  //                 style={{
  //                   size: "bold",
  //                   color: "rgba(0, 0, 0, 0.6)",
  //                   fontStyle: "italic",
  //                   textAlign: "center",
  //                   paddingTop: "20vh",
  //                 }}
  //               >
  //                 No jobs available
  //               </p>
  //             )}
  //           </Box> }
  //         </Grid>

  //         {/* <Grid item md={3} xs={12} sm={0}>
  // <Typography color="GrayText" variant="h4" className="align-icon">
  //   <History style={{ verticalAlign: "middle" }} /> Recent searches
  // </Typography>
  // <Stack>
  //   {jobs.map((job, index) => (
  //     <Typography
  //       key={index}
  //       variant="body1"
  //       style={{
  //         fontSize: "16px",
  //         color: "rgba(0, 0, 0, 0.6)",
  //         textAlign: "center",
  //       }}
  //     >
  //       {job.Recent_search}{" "}
  //     </Typography>
  //   ))}
  // </Stack>
  // </Grid> */}

  //       </Grid>
  //     </>
  //   );
}

// <div  style={{ marginBottom : "15px" , borderRadius : "10px" , backgroundImage : "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))", boxShadow:"0px 0px 10px #cdcdcd" }} >

// <div>

// {/* <NumberCount /> */}
// {/* <Typography sx={{ padding: 3 }} gutterBottom variant="h5"  color="white" component="div">
//    Find Your Dream Jobs Here
//     </Typography> */}

//     <Typography className="animated-heading-wrapper" fontWeight={'bold'} sx={{paddingLeft: 3 ,paddingTop: 3 , paddingBottom: 1, fontSize: 25 ,fontFamily:'Satoshi',color:"black" }}>
//     Find <span className="animated-heading"  style ={{color:"white" }}> your dream  </span>Job Here!
//   </Typography>

//   {/* <AnimatedHeading  /> */}

// </div>

// <div>
// <Typography sx={{ paddingLeft: 3 , paddingBottom: 3 }} variant="body2" color="white">
//       Lizards are a widespread group of squamate reptiles, with over 6,000
//       species, ranging across all continents except Antarctica
//     </Typography>

// </div>

// <div  style={{paddingLeft:"30px"  ,paddingRight:"40px", paddingBottom:"30px" }} >
// <Paper sx={{  borderRadius : "10px" , textAlign:'center', display : "flex",  justifyContent:"space-between", alignItems:"center"}} >

//   <Autocomplete
//     options={option}
//     value={selectedOption} // Set the selected option
//     onChange={handleOptionChange} // Handle option change
//     sx = {{ width : "55%" }}

//     renderInput={(params) => (
//       <div style={{display : "flex"}}>

//     <SearchIcon sx = {{paddingLeft : "10px" , color : "blue"}} fontSize="large"/>

//       <div  style={{ display : "flex", justifyContent:"center", alignContent:"center"}}  ref={params.InputProps.ref}>
//         <input type="text" {...params.inputProps}
//         name="skills"

//         onChange={handleChanges}

//         onKeyDown={handleKeyPress}

//         style={{ marginLeft:"10px", fontSize: 13, fontFamily: 'Vollkorn', border:"none",  outline: 'none' }} // Adjust width here
//         // placeholder="Search using the job title or by location. Eg UI Designer"
//         placeholder="Search Jobs"

//         // FormHelperTextProps={{ sx: { fontSize: 0 } }} // Adjust size of helping text here
//         />

//     </div>

//     </div>
//     )}
//   />

// <Box sx={{color:"linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))"}}>

// <Button onClick={handleSearch}   sx={{width : "100px", padding:"8px", margin : "2px"  ,  borderRadius:"10px"}}>
//   Search

//   </Button>
//   </Box>

// {/* </Stack>  */}
// </Paper>
// </div>
// {/*
// <Stack
// direction="row"
// spacing={2}

// sx ={{ backgroundColor:"white",width : '100%', alignItems : "center", justifyContent : "center"}}>

//             <Autocomplete
//               options={option}
//               value={selectedOption} // Set the selected option
//               onChange={handleOptionChange} // Handle option change
//               sx = {{ width : "55%" }}

//               renderInput={(params) => (

//                 <TextField
//                   {...params}
//                   onChange={handleChanges}
//                   // onChange={(e) => setSearchInput(e.target.value)}
//                   onKeyDown={handleKeyPress}
//                   // onClick={handleOptionChange} // Handle option change

//                   variant="standard"
//                   size="medium"

//                   placeholder="Search using the job title or by location. Eg UI Designer"

//                   InputProps={{
//                     ...params.InputProps,
//                     startAdornment: (
//                       <InputAdornment position="start">
//                         <SearchIcon />
//                       </InputAdornment>
//                     ),
//                   }}
//                   className="search-bar"
//                 />
//               )}
//               renderOption={(props, option) => (
//                 <MenuItem {...props}>{option}</MenuItem>
//               )}
//             />

// <Button

// variant="contained"
// onClick={handleSearch}
// sx={{ mt: 1 }}
// >
// Search
// </Button>

// </Stack>     */}

// </div>
