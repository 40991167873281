import { useState , useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { changepassword } from './Axiosfetch/Axiosfetch';
import { AlertToast, FullScreenContext,alertState } from "../divine-constants";


const defaultTheme = createTheme();
export default function ResetPassword() {
  const navigate = useNavigate();

  const [passwordValue, setPasswordValue] = useState("");
  const { setAlert } = useContext(AlertToast);

  const funs = (e) => {
    setPasswordValue(e.target.value)
    console.log(passwordValue,"11111111111111111")

  };

  const handlesubmit = async(event) => {
    event.preventDefault();
    console.log(event.preventDefault(),"##########")
    const datas = new FormData(event.currentTarget);

    try {
      const response = await changepassword(datas)

      if (response.status === 200) {
        localStorage.setItem("accesstoken",response.data.token)

        setAlert(alertState({
          message: "Login successfuly",
          severity: "success",
          show: true
        }));
        navigate("/jobs")
       
      }else {
        setAlert(alertState({
          message: "Something went wrong",
          severity: "error",
          show: true
        }));


      }
      
    }
    catch (err){

    }
  };
  

  return (
    <Card>
      <CardContent>
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <RestartAltIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{fontFamily:'Vollkorn'}}>
            RESET PASSWORD
          </Typography>
          <Box component="form"  onSubmit={handlesubmit}   sx={{ mt: 1}}>
          <TextField
              margin="normal"
              size='small'
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              size='small'
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
            
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
      </CardContent>
    </Card>
      
  );
}
