import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';



// axios.defaults.baseURL = "http://127.0.0.1:8000"; // Your Django backend URL
// axios.defaults.headers.common["Content-Type"] = "application/json";

// axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.headers.common["Content-Type"] = "application/json";

// // Function to refresh access token
// async function refreshAccessToken() {
//     try {
//         const refreshToken = localStorage.getItem('refresh_token');
//         const response = await axios.post('/token/refresh/', {
//             refresh: refreshToken,
//         });
//         const newAccessToken = response.data.access;
//         localStorage.setItem('access_token', newAccessToken);
//         return newAccessToken;
//     } catch (error) {
//         console.error('Error refreshing access token:', error);
//         // Handle token refresh failure (e.g., redirect to login)
//     }
// }

// // Add a request interceptor to include the access token in headers
// axios.interceptors.request.use(async (config) => {
//     const token = await localStorage.getItem("access_token");
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor to handle token expiry
// axios.interceptors.response.use((response) => {
//     return response;
// }, async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const newAccessToken = await refreshAccessToken();
//         if (newAccessToken) {
//             axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
//             return axios(originalRequest);
//         }
//     }
//     return Promise.reject(error);
// });

// export default axios;


// axios.defaults.baseURL = "http://192.168.29.12:8000";

// axios.defaults.baseURL = "http://127.0.0.1:8000";

axios.defaults.baseURL = "https://job.seltam.co.in";

// axios.defaults.baseURL = "http://3.108.25.162:8000";

// axios.defaults.baseURL = "http://3.108.148.125:9000";

// axios.defaults.timeout = 5000;

axios.defaults.headers.common["Content-Type"] = "application/json";


axios.interceptors.request.use(async (config) => {
  const Token =  await localStorage.getItem("accesstoken")
  return {
    ...config,
     headers: {
      ...config.headers,
      Authorization:
      `Bearer ${Token}`,

      // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YWRmY2NmY2FiOGY5MDFjYTQxNTZiYyIsImVtYWlsIjoidmFzYW50aGFubmEuc2VsdGFtQGdtYWlsLmNvbSIsImhhc2giOiI2YmRmYjg0YzYyY2I3ZTU1Y2NjODU4MzIyZGY1MjY5NmNkZmJmZjBkOWVmZDUxYzMwYTRjNjdhMjI1MzEyMzY1In0.wmLGi_1jIXOSq_lnwlMpaJuSrXnovv1EC3bIIywqd4w",
    },
  };
});
// `Bearer ${localStorage.getItem("accestoken")}`,

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

