import {
  Box,
  Paper,
  Grid,
  Stack,
  Typography,
  Divider,
  Button,
  ListItem,
  List,
  ListItemText,
  IconButton,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useContext, useState, useEffect } from "react";
import { AlertToast, alertState } from "../divine-constants";
import {
  addbookmark,
  get_details,
  get_profile,
  particular_partner,
  purchase_partner,
  purchsed_placement,
} from "./Axiosfetch/Axiosfetch";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import bookmarkadd from "./../images/bookmarkadd.png";
import bookmarkadded from "./../images/bookmarkadded.png";
import openings from "./../images/openings.png";
import worker from "./../images/worker.png";
import { useTheme } from "@emotion/react";
import LaunchIcon from "@mui/icons-material/Launch";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import LottieAnimation from "../Popup"




export default function ViewJobDetail(control) {
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const [dialogOpen, setDialogOpen] = useState();
  const [jobs, setJobs] = useState([]);

  const [company, setCompany] = useState([]);
  // const [post_date, setPost_date] = useState();
  const [applied_candidates_counts, setApplied_candidates_count] = useState();
  const [matching_skills_percentage, setMatching_skills_percentage] =
    useState();
  const [matching_skills, setMatching_skills] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const { setAlert } = useContext(AlertToast);
  const [save, setSave] = useState(false);
  const [applystatus, setApplystatus] = useState(false);
  const navigate = useNavigate();
  const [institutes, setInstitutes] = useState({});
  const [animi, setAnimi] = useState(false);
  const [applied, setApplied] = useState(false);
  const [login, setLogin] = useState(false);

  const location = useLocation();
  const data = location.state;

  const token = localStorage.getItem("accesstoken");



  const fetchJobs = async () => {

    const id = data.id;

    try {

      const response = await particular_partner(id);
      if (response.status === 200) {

        setInstitutes(response.data);
       
      } else {
        throw new Error(`Something went wrong ${response.data.message}`);
      }
    } catch (error) {
      // console.log(error)`
    }
  };

  const fetchJobss = async () => {

    const id = data.id;

    try {
      
      const response = await purchase_partner(id);

      if (response.status === 200) {

        setApplied(response.data.applied_status);
    
       
      } else if(response.status === 401){
        
        setLogin(true);


      }else {
        throw new Error(`Something went wrong ${response.data.message}`);
      }
    } catch (error) {
      // console.log(error)`
    }
  };


  useEffect(() => {
    scrollToTop()
    fetchJobs()
    fetchJobss()
    window.scrollTo(0, 0);
  }, [animi]);


  function scrollToTop() {

    window.scrollTo(0, 0);
  }
  

  const handleClick = async() => {


    const id = { "id" : data.id };


    const response = await purchsed_placement(id);
   
    if (response.status === 200) {
    
      setAnimi(true)
      
  };

}


  return (
    <>
    {Object.keys(institutes).length > 0 ? <Grid container sx={{ padding: 2 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            component={Paper}
            paddingInline={2}
            paddingBlock={2}
            sx={{ cursor: "pointer" , boxShadow : "0px 0px 10px #cdcdcd", }}
            margin={"auto"}
            borderRadius={2}
          >



          <Box >


              <Box display="flex" alignItems="center" gap={2}>
                <img width={48} src={institutes.institute_logo} alt="dummy" />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box>
                  <Typography
                    className="align-icon"
                    fontWeight={500}
                    variant="h4"
                    sx={{textTransform:"capitalize"}}
                  >
                
                    {institutes.institute_name}
                  
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">  
                      <Typography fontSize={12} sx={{ textTransform: "capitalize", color: "#00000090" }}>
                        {institutes.partner_name}
                      </Typography>
                  
                      <a href={institutes.company_website_link}  target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: 'inherit' }}>
                        <LaunchIcon color="primary" sx={{ fontSize: 17, marginLeft: 1 }} />
                      </a>
                                  
                         </Box>

  

                </Box>
          
                {/* <Typography marginLeft="auto" color="GrayText" style={{alignSelf: "flex-start"}}>{jobs.post_date === 0 ? 'Today' : `${jobs.post_date} days ago`}</Typography> */}
              </Box>

          


      

      
      
          </Box>
      
			<br/>

      <Grid container sx={{mt:1}}>

                    <Grid  item sm={6} xs={12} md={2.5}>
                  
                          <Stack sx={{mt:1 , display:"flex" , alignItems:"flex-start" }} direction={'column'} alignItems={'center'} >

                         <Stack direction="row" sx={{display:"flex" , justifyContent:'center' , alignItems:"center" }} >
                         <img style={{width:16,height:16}} src={Location}/>
                         <Typography sx={{fontFamily: "Poppins" , marginLeft:"10px"}} fontSize={14} fontWeight={"bold"}>
          
  Address
</Typography>

                         </Stack>
                    
                        
                   
                   
                            <Typography variant="overline" fontSize={12}>

                            {institutes.address}
                            </Typography>
                    
                                        
                    </Stack>
                    </Grid>

                  </Grid>


            <Divider />


{applied ? 
            <Button
                   
                    
                    sx={{
                      
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      marginTop: "15px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      // backgroundColor: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                      // backgroundColor: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                      backgroundImage : "linear-gradient(#24282A97 , #0A79C9)" 
                    
                    

                    }}
                  >
                    
                  Purchased
                  </Button> :

            <Button
                    onClick={handleClick}
                    
                    sx={{
              
                      padding: "8px",
                      marginTop: "15px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      backgroundImage : "linear-gradient(#24282A97 , #0A79C9)" 

                    }}
                  >

{login ? "Login to Buy" : `₹ ${Number(institutes.price).toLocaleString("en-IN")}`}

                  </Button>} 

      

                    



          
        
          
      
          </Grid>
        
        
          <Grid
            item
            sm={12}
            xs={12}
            md={10}
            // key={data.id}
            component={Paper}
            paddingInline={2}
            paddingBlock={2}
            sx={{ cursor: "pointer" , display:"flex" , justifyContent:'center' , alignItems : "center" , boxShadow : "0px 0px 10px #cdcdcd",  }}
            margin={"auto"}
            width={"40%"}
            borderRadius={2}
            marginTop={2}
            flexDirection={"column"}
            
          
          >
            <Stack
              direction="column"
              marginTop={2}
              spacing={2}
              height={"100%"}
              position={"relative"}
            >

            <List>
              {institutes.about ? 
                <ListItem>
                <Stack direction={'column'}>
                <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                    About Us
                  </Typography>
                  <ListItemText sx={{ whiteSpace : "pre-line"}}>{institutes.about}</ListItemText>
                </Stack>
             
                </ListItem>  

                : null}
              
              {institutes.our_services ? 
                <ListItem>
                <Stack direction={'column'}>
                <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                Our services
                  </Typography>
                  <ListItemText sx={{ whiteSpace : "pre-line"}}>{institutes.our_services}</ListItemText>
                </Stack>
             
                </ListItem>  

                : null}

              {institutes.conditions ? 
                <ListItem>
                <Stack direction={'column'}>
                <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                Terms & conditions
                  </Typography>
                  <ListItemText sx={{ whiteSpace : "pre-line"}}>{institutes.conditions}</ListItemText>
                </Stack>
             
                </ListItem>  

                : null}
              
 
              </List>


            </Stack>



          </Grid>

    
          

        </Grid>: null}





        
    

{animi ? 

<LottieAnimation />

     : null}



    </>
  );
}






      

// <lottie-player
// src="https://lottie.host/992bd503-aaa0-40e2-ac55-1ddfe95cbc47/zCjjDTPV5f.json"
// background="##FFFFFF"
// speed="1"
// loop
// autoplay
// direction="1"
// mode="normal"
// style={{ width: 300, height: 300 }}
// ></lottie-player>

