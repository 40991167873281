import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Noticeapi } from "./pages/Axiosfetch/Axiosfetch";
import Calendar_img from "../src/images/Calendar_img.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid ',
  p: 4,
};

const options = [
  "Immediate Joiner",
  "1+ Week",
  "2+ Weeks",
  "3+ Weeks",
  "1+ Month",
  "2+ Months",
  "3+ Months",
];

export default function Noticepopup() {
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [animi, setAnimi] = React.useState(true);

  const handleClose = () => {
    setAnimi(false);
  };

  const handleSubmit = () => {
    sendSelectedValue(value);
    setAnimi(false);
  };

  const sendSelectedValue = async (selectedValue) => {
    const data = { notice_period: selectedValue };

    try {
      // Replace with your backend API endpoint
      const response = await Noticeapi(data);
    } catch (error) {
      console.error("Error sending selected value:", error);
    }
  };

  return (
    <>
      {animi && (
        <Modal
          open={animi}
          // onClose={() => setAnimi(false)}

          onClose={false}
          aria-labelledby="lottie-modal"
          aria-describedby="lottie-animation-modal"
          // data-aos="fade-down"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              // border: '2px solid #cdcdcd',
              padding: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "50%" }} src={Calendar_img} />
            </div>

            <Typography id="modal-modal-title" variant="h6" component="h2">
              Notice period
            </Typography>

            <div>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} helperText={false} />
                )}
              />
            </div>

            <Box mt={2} display="flex" justifyContent="end">
              <Button
               
                sx={{
                  
                    backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
                    color: "white",
                
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
}
