import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addbookmark, bookmark_history } from "./Axiosfetch/Axiosfetch";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import openings from "./../images/openings.png";
import bookmark from "./../images/bookmark.png";
import worker from "./../images/worker.png";
import { AlertToast, alertState } from "../divine-constants";
import LaunchIcon from "@mui/icons-material/Launch";

export default function BookMarks() {
  const { setAlert } = useContext(AlertToast);
  const matches = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [logo, setLogo] = useState("");
  const [save, setSave] = useState(false);

  const token = localStorage.getItem("accesstoken");

  




  useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await bookmark_history();
        if (response.status === 200) {
          setJobs(response.data);
        } else {
          console.error("Invalid data structure:", response);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, []);

  

  const HandleSave = async (data) => {
    if (save == false) {
      const datas = { id: data.id, job_save: "true" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(true);
        setAlert(
          alertState({
            message: "You have saved this job",
            severity: "success",
            show: true,
          })
        );
      }
    } else if (save == true) {
      const datas = { id: data.id, job_save: "false" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(false);
        setAlert(
          alertState({
            message: "This job is no longer saved",
            severity: "success",
            show: true,
          })
        );
      }
    }
  };

  return (
    <>
      <Grid sx={{ padding: 5 }} container spacing={2}>
        <Grid item xs={12} sm={12} md={2}></Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography
            sx={{
              mb: 2,
              font: "Vollkorn",
              fontSize: 25,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            My Bookmarks
          </Typography>
          <Box>
            {filteredJobs.length > 0 ? (
              filteredJobs.map((job) => (
                <Box
                  key={job.id}
                  component={Paper}
                  paddingInline={2}
                  paddingBlock={2}
                  sx={{
                    // ":hover": { boxShadow: "none" },
                    ":hover": {
                      boxShadow: "0px 0px 10px #cdcdcd",
                      transform: "scale(1.010)",
                    },
                    cursor: "pointer",
                    mb: 2,
                    transition: "0.2s ease-in-out",
                    // width : "650px",
                    borderRadius: "30px",
                    border: "0.5px solid #cdcdcd",
			
					
                  }}
                >
                  {/* <Box component={Paper} paddingInline={2} paddingBlock={2} sx={{":hover": {boxShadow: "none"}, cursor: "pointer"}}> */}
                  <Box display="flex" alignItems="center" gap={2}>
                    <img
                      width={48}
                      //   src="/dummy-image-square.webp"
                      src={job.company_logo}
                      alt="dummy"
                    />
					
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box>
                      <Typography
                        className="align-icon"
                        fontWeight={500}
                        variant="h4"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {job.job_title}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          fontSize={12}
                          sx={{
                            textTransform: "capitalize",
                            color: "#00000090",
                          }}
                        >
                          {job.company_name}
                        </Typography>

                        <Link
                          to={job.company_website_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <LaunchIcon
                            color="primary"
                            sx={{ fontSize: 17, marginLeft: 1 }}
                          />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
               
				  <Divider />
				  {/* <br /> */}

                  <Grid container rowSpacing={1}>
                    <Grid item sm={12} xs={12} md={12}>
                      {matches === true ? (
                        <Stack
                          sx={{ mt: 1 }}
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <img
                            style={{ width: 16, height: 16 }}
                            src={Location}
                          />
                          {job.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {job.post_city
                                .map((location) => location.toUpperCase())
                                .join(", ")}
                            </Typography>
                          ) : null}
                        </Stack>
                      ) : (
                        <Stack
                          sx={{ mt: 1 }}
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <img
                            style={{ width: 16, height: 16 }}
                            src={Location}
                          />
                          {job.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {job.post_city
                                .map((location) => location.toUpperCase())
                                .join(", ")}
                            </Typography>
                          ) : null}
                        </Stack>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item sm={6} xs={12} md={3}>
                      {matches === true ? (
                        <Stack direction={"column"}>
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={experience}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              EXPERIENCE
                            </Typography>
                          </Stack>
                          <Typography variant="overline" fontSize={12}>
                            {job.minimum_experience} - {job.maximum_experience}{" "}
                            YEARS
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                          <img
                            style={{ width: 16, height: 16 }}
                            src={experience}
                          />
                          <Typography color={"#000000"} fontSize={12}>
                            {job.minimum_experience} - {job.maximum_experience}{" "}
                            Years experience
                          </Typography>
                        </Stack>
                      )}
                    </Grid>

                    <Grid item sm={6} xs={12} md={3}>
                      {matches === true ? (
                        <Stack direction={"column"}>
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={salary}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              CTC{" "}
                            </Typography>
                          </Stack>
                          <Typography variant="overline" fontSize={12}>
                            {job.minimum_salary} - {job.maximum_salary} LPA{" "}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack sx={{ mt: 2 }} direction={"row"} spacing={1}>
                          <img style={{ width: 16, height: 16 }} src={salary} />
                          <Typography color={"#000000"} fontSize={12}>
                            CTC {job.minimum_salary} - {job.maximum_salary} LPA
                          </Typography>
                        </Stack>
                      )}
                    </Grid>

                    <Grid item sm={6} xs={12} md={3}>
                      {matches === true ? (
                        <Stack direction={"column"}>
                          <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={worker}
                            />
                            <Typography color={"#000000"} fontSize={12}>
                              WORK MODE
                            </Typography>
                          </Stack>
                          <Typography variant="overline" fontSize={12}>
                            {job.work_mode}{" "}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack sx={{ mt: 2 }} direction={"row"} spacing={1}>
                          <img style={{ width: 16, height: 16 }} src={worker} />
                          <Typography color={"#000000"} fontSize={12}>
                            {" "}
                            WORK MODE {job.work_mode}
                          </Typography>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                        <img style={{ width: 18, height: 18 }} src={openings} />
                        <Typography color={"#000000"} fontSize={14}>
                          {job.vacancy} vacancies
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                        <img
                          style={{ width: 18, height: 18 }}
                          src={applicants}
                        />
                        <Typography color={"#000000"} fontSize={14}>
                          {" "}
                          {job.count} Applicants
                        </Typography>
                      </Stack>
                    </Grid>

            

                  </Grid>

                  <br />
                  <Divider />
                  <br />

				  <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
				
					<Stack
						direction={{ xs: "row", sm: "row", md: "row" }}
						spacing={{ xs: 2, md: 2, sm: 2 }}
						
					>

							<img style={{ width: 16, height: 16 }} src={duration} />
							<Typography color={"#000000"} fontSize={13}>
							{job.post_date === 0
								? "Posted Today "
								: job.post_date === 1
								? "Posted yesterday"
								: job.post_date === 7
								? "Posted One week ago"
								: `Posted ${job.post_date} days ago`}
							</Typography>
							{/* <Typography color={'#000000'} fontSize={13}>Posted {job.post_date}  ago</Typography> */}
						</Stack>

									

					<Stack
						direction={{ xs: "row", sm: "row", md: "row" }}
						spacing={{ xs: 2, md: 2, sm: 2 }}
					>


						<Button
						
						onClick={() => {
							navigate(
							"/view-job-details",
							{ state: { ...job } },
							window.scrollTo(0, 0)
							);
						}}
						>
						{" "}
						View details{" "}
						</Button>
						{token ? (
						<Box>
							{save === false ? (
							<IconButton
								color="primary"
								onClick={() => HandleSave(jobs)}
							>
								{/* <img style={{width:16,height:16}} src={bookmarkadd}/> */}
							</IconButton>
							) : (
							<IconButton
								color="primary"
								onClick={() => HandleSave(jobs)}
							>
								{/* <img style={{width:16,height:16}} src={bookmarkadded}/> */}
							</IconButton>
							)}
						</Box>
						) : null}
					</Stack>

				</Box>

				
	
                </Box>
              ))
            ) : (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <img
                  style={{
                    width: "150x",
                    height: "150px",
                    alignContent: "center",
                  }}
                  src={bookmark}
                ></img>
                <Typography
                  style={{
                    font: "Vollkorn",
                    fontWeight: "bold",
                    fontSize: 20,
                    textAlign: "center",
                    paddingTop: "5vh",
                  }}
                >
                  No Bookmarks
                </Typography>
                <Typography
                  style={{
                    font: "Vollkorn",
                    textAlign: "center",
                    fontSize: 15,
                    paddingTop: "3vh",
                  }}
                >
                  Your list of Bookmarked internships and jobs is currently
                  empty
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}></Grid>
      </Grid>
    </>
  );
}
