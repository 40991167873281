import {
  CurrencyRupeeOutlined,
  HomeOutlined,
  PinDropOutlined,
  StarsOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  addbookmark,
  applied_history,
  get_details,
} from "./Axiosfetch/Axiosfetch";
import JobApply from "../../src/images/JobApply.png";
import { AlertToast, alertState } from "../divine-constants";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import openings from "./../images/openings.png";
import bookmark from "./../images/bookmark.png";
import worker from "./../images/worker.png";
import LaunchIcon from "@mui/icons-material/Launch";

import appliedjobs from "./../images/appliedjobs.png";
import appliedjobs2 from "./../images/appliedjobs2.png";
import profile from "./../images/hrprofile.png";
import bookmarkadd from "./../images/bookmarkadd.png";
import bookmarkadded from "./../images/bookmarkadded.png";
import forborder from "./../images/forborder.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import CircularProgressWithLabel from "./Cricual";

const JobBox = ({ job, isActive, onClick }) => {
  const navigate = useNavigate();

  return (
    <Box
      key={job.id}
      component={Paper}
      paddingInline={2}
      paddingBlock={2}
      onClick={onClick}
      sx={{
        cursor: "pointer",
        mb: 2,
        background: isActive ? "#D1E8FF" : "#ECF5FF",
        border: isActive ? "1px solid #49A3F1" : "none",
        marginRight: "15px",

        position: "relative",

        borderRadius: "20px",
        "::before": isActive
          ? {
              content: 'url("./../images/forborder.png")', // Path to your image file
              //   content: '"hello"', // Path to your image file
              width: "100%", // Adjust to your image dimensions
              height: "20px", // Adjust to your image dimensions
              position: "absolute",
              top: 0,
              right: 0,
              width: 0,
              height: 0,
              // borderTop: "10px solid #49A3F1",
              // borderRight: "10px solid transparent"
            }
          : {},
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
        <img width={48} src={job.company_logo} alt="dummy" />

        <Divider orientation="vertical" variant="middle" flexItem />

        <Box>
          <Typography
            className="align-icon"
            fontWeight={500}
            variant="h4"
            sx={{ textTransform: "capitalize" }}
          >
            {job.job_title}
          </Typography>

          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              fontSize={12}
              sx={{ textTransform: "capitalize", color: "#00000090" }}
            >
              {job.company_name}
            </Typography>

            <Link
              to={job.company_website_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <LaunchIcon
                color="primary"
                sx={{ fontSize: 17, marginLeft: 1 }}
              />
            </Link>

            {/* <Box  display="flex" flexDirection="row" >
					
					<TaskAltIcon  sx={{marginRight : "5px" , color : "green"}}/>
					<Typography
								className="align-icon"
								fontWeight={500}
								variant="h4"
								sx={{textTransform:"capitalize" , color:"green"}}
							>
							Applied 
							</Typography>
				</Box> */}
          </Box>
        </Box>
      </Box>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} sx={{ display: "flex" }}>
          <div
            style={{
              background: "white",
              paddingRight: "10px",
              borderRadius: "15px",
              display: "flex",
              marginTop: "15px",
              border: "1px solid #cdcdcd",
            }}
          >
            <CheckCircleIcon sx={{ marginRight: "2px", color: "green" }} />
            <Typography
              sx={{
                color: "#00000090",
                fontSize: 13,
                fontWeight: "medium",
                textAlign: "center",
                marginTop: "2px",
                borderRadius: "5px",
              }}
            >
              {" "}
              Applied{" "}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Typography
            sx={{
              color: "#00000090",
              fontSize: 11,
              fontWeight: "medium",
              textAlign: "center",
              marginTop: "2px",
              borderRadius: "5px",
            }}
          >
            {job.Applied_date === 0
              ? "Today "
              : job.Applied_date === 1
              ? "Yesterday"
              : job.Applied_date === 7
              ? "One week ago"
              : `${job.Applied_date} days ago`}
          </Typography>

          {/* 				
					<Typography > {job.Applied_date}  </Typography>  */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default function AppliedJobHistory() {
  const { setAlert } = useContext(AlertToast);
  const navigate = useNavigate();
 
  const [jobs, setJobs] = useState([]);
  const [company, setCompany] = useState([]);
  const [save, setSave] = useState(false);
  const matches = useMediaQuery("(min-width:768px)");
  const token = localStorage.getItem("accesstoken");
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [filteredJobs, setFilteredJobs] = useState([]);
  
  const [activeJobId, setActiveJobId] = useState(null);

  const location = useLocation();

  // const data = location.state;

  const [data, setData] = useState([]);
  const [apply_job, setApply_job] = useState([]);
  const [matching_skills_percentage, setMatching_skills_percentage] = useState(
    []
  );

  const [applied_candidates_counts, setApplied_candidates_count] = useState();
  const [applystatus, setApplystatus] = useState(false);

  useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {

    const fetchJobs = async () => {
      try {
        const response = await applied_history();

        if (response && response.data && Array.isArray(response.data)) {
          setJobs(response.data);
          await handleBoxClick(response.data[0].id);
          
        } else {
          console.error("Invalid data structure:", response);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, []);


  const handleBoxClick = async (jobId) => {
    // Your function logic here
    console.log("Box clicked!", jobId);
    setActiveJobId(jobId);

    try {
      const response = await get_details(jobId);
      if (response.status === 200) {
        setApplied_candidates_count(response.data.applied_candidates_count);
        setData(response.data.job_details);
        setCompany(response.data.company_details);
        setApply_job(response.data.apply_job);
        setMatching_skills_percentage(response.data.matching_skills_percentage);

        //   setMatching_skills_percentage(response.data.matching_skills_percentage);

        //   setMatching_skills(response.data.matching_skills);

        //   setSave(response.data.save_status);

        //   setApplystatus(response.data.Applied_state);

        //   setApplied_candidates_count(response.data.applied_candidates_count);
      } else {
        throw new Error(`Something went wrong ${response.data.message}`);
      }
    } catch (error) {
      // console.log(error)`
      // setAlert(
      //   alertState({
      //     message: error.message,
      //     severity: "error",
      //     show: true,
      //   })
      // );
    }
  };

  function HandleNavigation() {
    if (data.job_type[0] === "job") {
      navigate("/jobs");
    } else if (data.job_type[0] === "internships") {
      navigate("/internships");
    }
  }

  const handleClick = async () => {
    const id = data.id;
    const response = await get_details(id);
    console.log("testing1", response.data.job_details.post_status);
    if (response.status === 200) {
      setJobs(response.data.job_details);
      if (response.data.job_details.post_status === false) {
        setAlert(
          alertState({
            message: "job closed",
            severity: "error",
            show: true,
          })
        );
      } else {
        scrollToTop(); // Call the external function

        navigate("/apply-job", { state: { ...jobs } });
      }
    }
  };

  const HandleSave = async (data) => {
    if (save == false) {
      const datas = { id: data.id, job_save: "true" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(true);
        setAlert(
          alertState({
            message: "You have saved this job",
            severity: "success",
            show: true,
          })
        );
      }
    } else if (save == true) {
      const datas = { id: data.id, job_save: "false" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(false);
        setAlert(
          alertState({
            message: "This job is no longer saved",
            severity: "success",
            show: true,
          })
        );
      }
    }
  };

  return (
	
    <>
      {filteredJobs.length > 0 ? (
        
        <Grid sx={{ paddingLeft: "30px",  paddingRight: "15px",  paddingTop: "50px", paddingBottom: "50px", background: "#F5F5F5" }} container spacing={2}>

          <Grid item xs={12} sm={12} md={12}>
            <Typography
              sx={{
                mb: 2,
                font: "Vollkorn",
                fontSize: 25,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              My Job Applications
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={9.5} sx={{ background: "#F5F5F5" }}>
            {/* <Grid item xs={12} sm={12} md={12} sx={{background:"#F5F5F5" }}   > */}

            <Grid
              sx={{
                background: "white",
                border: "0.5px solid #cdcdcd",
                borderRadius: "30px",
                display: "flex",
                justifyContent: "flexStart",
              }}
              container
              spacing={2}
            >
              {/* <Grid item xs={12} sm={12} md={5} sx={{  borderRight : matches ? "1px solid #cdcdcd" : "0px"  }} > */}
              {/* <Grid item xs={12} sm={12} md={5} sx={{  borderRight : matches ? "1px solid #cdcdcd" : "0px"  }} > */}
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                sx={{ borderRight: { md: "1px solid #cdcdcd", xs: "0px" } }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "9px",
                    marginTop: "10px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        font: "Vollkorn",
                        fontSize: 15,
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {" "}
                      {apply_job}{" "}
                    </Typography>

                    <Typography
                      sx={{
                        font: "Vollkorn",
                        fontSize: 11,
                        fontWeight: "medium",
                        color: "#017BFD",
                        marginTop: "4px",
                        marginBottom: "6px",
                        letterSpacing: "1px",
                      }}
                    >
                      Total Applies{" "}
                    </Typography>
                  </Box>

                  {/* <Divider sx={{marginBottom:"10px" , }}orientation="vertical" variant="Bold" /> */}

                  <div
                    style={{
                      width: "1px",
                      marginLeft: "8px",
                      backgroundColor: "#cdcdcd",
                    }}
                  >
                    {" "}
                  </div>

                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        font: "Vollkorn",
                        fontSize: 15,
                        fontWeight: "bold",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      00
                    </Typography>

                    <Typography
                      sx={{
                        font: "Vollkorn",
                        fontSize: 11,
                        fontWeight: "medium",
                        color: "#017BFD",
                        marginTop: "4px",
                        marginBottom: "8px",
                        letterSpacing: "1px",
                      }}
                    >
                      Application Update{" "}
                    </Typography>
                  </Box>
                </Box>
                {/* <Divider sx={{marginBottom:"10px"}}orientation="Horizontal" variant="middle" flexItem /> */}
                <div
                  style={{
                    width: "97%",
                    height: "1px",
                    backgroundColor: "#cdcdcd",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                </div>

                <Box sx={{ overflowY: "scroll", height: "400px" }}>

                  {filteredJobs.length > 0
                    ? filteredJobs.map((job) => (
                      
                        <JobBox
                          key={job.id}
                          job={job}
                          isActive={job.id === activeJobId}
                          onClick={
                            matches
                              ? () => handleBoxClick(job.id)
                              : () => {
                                  window.scrollTo(0, 0);
                                  navigate("/view-job-details", {
                                    state: { ...job },
                                  });
                                }
                          }
                        />
                      ))
                    : null}
                </Box>
              </Grid>

              {matches && !isTabletOrMobile ? (
                <Grid item xs={12} sm={12} md={7}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                    gap={2}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{ borderBottom: "1px solid #cdcdcd" }}
                    >
                      {/* <Grid container spacing={2} > */}

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "10px",
                          marginBottom: "1px",
                        }}
                      >
                        <Grid item xs={12} sm={12} md={5}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              className="align-icon"
                              fontWeight={600}
                              variant="h4"
                              sx={{ textTransform: "capitalize" }}
                            >
                              Job Profile
                            </Typography>

                            <Typography
                              className="align-icon"
                              fontWeight={300}
                              variant="small"
                              sx={{
                                textTransform: "capitalize",
                                letterSpacing: "1px",
                                fontSize: 12,
                                fontWeight: "small",
                              }}
                            >
                              Matching Skills
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <CircularProgressWithLabel
                            value={matching_skills_percentage}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          paddingRight: "20px",
                        }}
                      >
                        <TaskAltIcon
                          sx={{ marginRight: "5px", color: "green" }}
                        />

                        <Typography
                          className="align-icon"
                          fontWeight={500}
                          variant="h4"
                          sx={{ textTransform: "capitalize", color: "green" }}
                        >
                          Applied
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" gap={2}>
                      <img width={55} src={data.company_logo} alt="dummy" />

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />

                      <Box>
                        <Typography
                          className="align-icon"
                          fontWeight={500}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {data.job_title}
                        </Typography>

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            fontSize={12}
                            sx={{
                              textTransform: "capitalize",
                              color: "#00000090",
                            }}
                          >
                            {data.company_name}
                          </Typography>

                          <Link
                            to={data.company_website_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <LaunchIcon
                              color="primary"
                              sx={{ fontSize: 17, marginLeft: 1 }}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Box>

                    {/* <Divider orientation="Horizontal" variant="middle" flexItem /> */}
                    <div
                      style={{
                        width: "97%",
                        height: "1px",
                        backgroundColor: "#cdcdcd",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          className="align-icon"
                          fontWeight={500}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        >
                          Activity on this job
                        </Typography>
                      </Grid>

                      <Divider
                        orientation="Horizontal"
                        variant="middle"
                        flexItem
                      />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ display: "flex" }}
                      >
                        <Grid item xs={12} sm={12} md={4}>
                          <Typography
                            className="align-icon"
                            fontWeight={500}
                            variant="h4"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {applied_candidates_counts}
                          </Typography>

                          <Typography variant="body2" color="text.secondary">
                            Total applications
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5}>
                          <Typography
                            className="align-icon"
                            fontWeight={500}
                            variant="h4"
                            sx={{ textTransform: "capitalize" }}
                          >
                            1
                          </Typography>

                          <Typography variant="body2" color="text.secondary">
                            Applications Viewed by recrutier
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider
                    sx={{ marginBottom: "10px" }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <Box
                    // key={job.id}
                    component={Paper}
                    paddingInline={2}
                    paddingBlock={2}
                    sx={{
                      // ":hover": { boxShadow: "none" },
                      ":hover": { transform: "scale(1.010)" },
                      cursor: "pointer",
                      mb: 2,
                      marginRight: "10px",
                      // transition : "0.2s ease-in-out",

                      // background : "white",

                      // width : "650px",
                      borderRadius: "20px",
                      border: "0.5px solid #cdcdcd",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <img
                        width={48}
                        // src={job.company_logo}
                        src={profile}
                        alt="dummy"
                      />

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />

                      <Box>
                        <Typography
                          className="align-icon"
                          fontWeight={500}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        >
                          HR {data.posted_email}
                        </Typography>

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            fontSize={12}
                            sx={{
                              textTransform: "capitalize",
                              color: "#00000090",
                            }}
                          >
                            {data.company_name}
                          </Typography>

                          <Link
                            to={"http:\\link.com"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <LaunchIcon
                              color="primary"
                              sx={{ fontSize: 17, marginLeft: 1 }}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {matches && !isTabletOrMobile ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={2.5}
              sx={{ paddingTop: "0px !important" }}
            >
              <img src={appliedjobs} alt="Hlo" style={{ width: "100%" }} />
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ margin: "50px" }}
        >
          <Typography
            sx={{
              mb: 2,
              font: "Vollkorn",
              fontSize: 25,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            My Job Applications
          </Typography>

          <img
            style={{ width: "150px", height: "150px", alignContent: "center" }}
            src={JobApply}
          ></img>
          <Typography
            style={{
              font: "Vollkorn",
              fontWeight: "bold",
              fontSize: 20,
              textAlign: "center",
              paddingTop: "5vh",
              
            }}
          >
            No Applications Found
          </Typography>
          <Typography
            style={{
              font: "Vollkorn",
              textAlign: "center",
              fontSize: 15,
              paddingTop: "3vh",
            }}
          >
            It appears that you have not applied for any internships or
            employment
          </Typography>
        </Box>
      )}
    </>

    // 	<Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
    // 	<img style={{width:"150px", height:'150px',alignContent:"center"}} src={JobApply}></img>
    // 			<Typography style={{ font:"Vollkorn", fontWeight:'bold', fontSize:20, textAlign: 'center', paddingTop: '5vh' }}>No Applications Found</Typography>
    // 	<Typography style={{font:"Vollkorn",textAlign: 'center', fontSize:15 , paddingTop: '3vh'}}>It appears that you have not applied for any internships or employment</Typography>
    // 	</Box>
    // )}
  );
}

{
  /* <Typography sx={{mb:2, font :"Vollkorn", fontSize:25, fontWeight:"500",textAlign : "center"}}>My Job Applications</Typography>

<Box>
{filteredJobs.length > 0 ? (
  filteredJobs.map((job) => (
	<Box
	  key={job.id}
	  component={Paper}
	  paddingInline={2}
	  paddingBlock={2}
	  sx={{
		// ":hover": { boxShadow: "none" },
		":hover": { boxShadow:"0px 0px 10px #cdcdcd", transform : "scale(1.010)"},
		cursor: "pointer",
		mb: 2,
		transition : "0.2s ease-in-out",
		// width : "650px",
		borderRadius : "30px",
		border : "0.5px solid #cdcdcd"
	  }}
	  
	>
	  {/* <Box component={Paper} paddingInline={2} paddingBlock={2} sx={{":hover": {boxShadow: "none"}, cursor: "pointer"}}> */
}
//   <Box display="flex" alignItems="center" gap={2}>
// 	<img
// 	  width={48}
// 	//   src="/dummy-image-square.webp"
// 	  src={job.company_logo}
// 	  alt="dummy"
// 	/>

// 	<Divider orientation="vertical" variant="middle" flexItem />

// 	<Box>

// 	  <Typography
// 		className="align-icon"
// 		fontWeight={500}
// 		variant="h4"
// 		sx={{textTransform:"capitalize"}}
// 	  >
// 		{job.job_title}
// 	  </Typography>

// 	  <Box display="flex" flexDirection="row" alignItems="center">
// 	  <Typography fontSize={12} sx={{ textTransform: "capitalize", color: "#00000090" }}>
// 		{job.company_name}
// 	  </Typography>

// 	  <Link  to={job.company_website_link} target="_blank" rel="noopener noreferrer"   style={{ textDecoration: 'none', color: 'inherit' }}>
// 		<LaunchIcon color="primary" sx={{ fontSize: 17, marginLeft: 1 }} />
// 	  </Link>

// 		 </Box>
// 	</Box>
//   </Box>
//   <br />

{
  /* <Grid container rowSpacing={1}>

	  <Grid  item sm={6} xs={12} md={3}>
			{matches === true ? 
			  <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
			  <img style={{width:16,height:16}} src={Location}/>
			  {job.post_city ? (
				<Typography variant="overline" fontSize={12}>
				  {job.post_city.map(location => location.toUpperCase()).join(', ')}
				</Typography>
			  ) : null}
			</Stack>:
		  <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
			<img style={{width:16,height:16}} src={Location}/>
		  {job.post_city ? (
				<Typography variant="overline" fontSize={12}>
				  {job.post_city.map(location => location.toUpperCase()).join(', ')}
				</Typography>
			  ) : null}
		</Stack>}
			</Grid>

	  </Grid>
	   */
}

//   <Grid container rowSpacing={1}
// 		columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
// 		<Grid item sm={6} xs={12} md={3}>
// 		{matches === true ?<Stack direction={'column'}>
// 		<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 		<img style={{width:16,height:16}} src={experience}/>
// 		<Typography color={'#000000'} fontSize={12}>EXPERIENCE</Typography>

// 	</Stack>
// 	<Typography variant="overline" fontSize={12}>{job.minimum_experience} - {job.maximum_experience} YEARS</Typography>
// 	</Stack>:<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 		<img style={{width:16,height:16}} src={experience}/>
// 		<Typography color={'#000000'} fontSize={12}>{job.minimum_experience} - {job.maximum_experience} Years experience</Typography>
// 	</Stack>}
// 		</Grid>
// 		<Grid item sm={6} xs={12} md={3}>
// 		{matches === true ?<Stack direction={'column'}>
// 			<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 			<img style={{width:16,height:16}} src={salary}/>
// 			<Typography color={'#000000'} fontSize={12}>CTC </Typography>
// 			</Stack>
// 		<Typography variant="overline" fontSize={12}>{job.minimum_salary} - {job.maximum_salary} LPA </Typography>
// 		</Stack>:<Stack sx={{mt:2}} direction={'row'} spacing={1}>
// 			<img style={{width:16,height:16}} src={salary}/>
// 			<Typography color={'#000000'} fontSize={12}>CTC  {job.minimum_salary} - {job.maximum_salary} LPA</Typography>
// 			</Stack>}
// 		</Grid>

// 		<Grid item sm={6} xs={12} md={3}>
// 		{matches === true ?<Stack direction={'column'}>
// 			<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 			<img style={{width:16,height:16}} src={worker}/>
// 			<Typography color={'#000000'} fontSize={12}>WORK MODE</Typography>
// 			</Stack>
// 		<Typography variant="overline" fontSize={12}>{job.work_mode} </Typography>
// 		</Stack>:<Stack sx={{mt:2}} direction={'row'} spacing={1}>
// 			<img style={{width:16,height:16}} src={worker}/>
// 			<Typography color={'#000000'} fontSize={12}> WORK MODE {job.work_mode}</Typography>
// 			</Stack>}
// 		</Grid>

// 	</Grid>

// 		<Grid container sx={{mt:2}}>
// 		<Grid item xs={12}sm={6}md={3}>
// 					<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 						<img style={{width:18,height:18}} src={openings}/>
// 						<Typography color={'#000000'} fontSize={14}>{job.vacancy} vacancies</Typography>
// 				</Stack>
// 			</Grid>
// 			<Grid item xs={12}sm={6}md={3}>
// 					<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 					<img style={{width:18,height:18}} src={applicants}/>

// 						<Typography color={'#000000'} fontSize={14}> {job.count} Applicants</Typography>
// 				</Stack>
// 			</Grid>
// 			<Grid item xs={12} sm={6} md={3}>
// 				<Stack sx={{mt:1}} direction={'row'} spacing={1}>
// 			<img style={{width:16,height:16}} src={duration}/>
// 			<Typography color={'#000000'} fontSize={13}>{job.post_date === 0 ? 'Posted Today ' :
// 				job.post_date === 1 ? 'Posted yesterday' :
// 					job.post_date === 7 ? 'Posted One week ago' :
// 					`Posted ${job.post_date} days ago`}</Typography>
// 			{/* <Typography color={'#000000'} fontSize={13}>Posted 1 week ago</Typography> */}
// 			</Stack>
// 			</Grid>
// 		</Grid>
// 	<br />

// 	  <Divider />
// 	  <br />

// 	  <Stack
// 	  direction={{ xs: "row", sm: "row", md: "row" }}
// 		  spacing={{ xs: 2, md: 2, sm: 2 }}>
// 			<Button variant="outlined" onClick={() => navigate("/view-job-details", { state: { ...job } })}> View details </Button>
// 		{token ?
// 		<Box>
// 		{save === false ? (
// 	  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
// 		{/* <img style={{width:16,height:16}} src={bookmarkadd}/> */}
// 	  </IconButton>
// 	) : (
// 	  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
// 		{/* <img style={{width:16,height:16}} src={bookmarkadded}/> */}
// 	  </IconButton>
// 	)}
// 		  </Box> : null}

// 	</Stack>

// 	</Box>
//   ))

// ) : (
// 	<Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
// 	<img style={{width:"150px", height:'150px',alignContent:"center"}} src={JobApply}></img>
// 			<Typography style={{ font:"Vollkorn", fontWeight:'bold', fontSize:20, textAlign: 'center', paddingTop: '5vh' }}>No Applications Found</Typography>
// 	<Typography style={{font:"Vollkorn",textAlign: 'center', fontSize:15 , paddingTop: '3vh'}}>It appears that you have not applied for any internships or employment</Typography>
// 	</Box>
// )}
// </Box> */}

// return (
//     <>
//      <Grid sx={{padding:5}} container spacing={2}>
// 		<Grid item xs={12} sm={12} md={2}>
// 		</Grid>
//         <Grid item xs={12} sm={12} md={8} >
// 		<Typography sx={{mb:2, font :"Vollkorn", fontSize:25, fontWeight:'bold',textAlign : "center"}}>My Job Applications</Typography>
//             <Box>
// 				{filteredJobs.length > 0 ?
// 				(filteredJobs.map((job) => (
// 					<Box
// 					key={job.id}
// 					component={Paper}
// 					paddingInline={2}
// 					paddingBlock={2}
// 					sx={{ ":hover": { boxShadow: "none" }, cursor: "pointer", mb:2,}}
// 					>
// 						<Box display="flex" alignItems="center" gap={2}>
// 							<img width={48} src="/dummy-image-square.webp" alt="dummy" />
// 							<Divider orientation="vertical" variant="middle" flexItem />
// 							<Box>
// 								<Typography className="align-icon" fontWeight={500} variant="h4">
// 									{job.job_title}
// 									<Tooltip arrow title="Actively hiring" placement="right" style={{verticalAlign: "middle"}}>
// 										<TrendingUpOutlined color="info" />
// 									</Tooltip>
// 								</Typography>
// 								<Typography fontWeight={100} color={"GrayText"}>{job.company_name}</Typography>
// 							</Box>
// 							{/* <Typography marginLeft="auto" color="GrayText" style={{alignSelf: "flex-start"}}>{job.post_date === 0 ? 'Today' : `${job.post_date} days ago`}</Typography> */}
// 						</Box>
// 						<br />
// 						<Stack flexWrap="wrap" direction="row" gap={1}>
// 							<Chip icon={<CurrencyRupeeOutlined color="disabled" />} variant="outlined" label={<>{job.maximum_salary} - {job.minimum_salary} LPA</>} />
// 							<Chip icon={<HomeOutlined color="disabled" />} variant="outlined" label={<>{job.work_mode}</>} />
// 							<Chip icon={<PinDropOutlined color="disabled" />} variant="outlined" label={<>{job.job_location}</>} />
// 							<Chip icon={<StarsOutlined color="disabled" />} variant="outlined" label={<>{job.minimum_experience} - {job.maximum_experience} yrs</>} />
// 						</Stack>
// 						<br />
// 						<Box >
// 							<Typography fontWeight="bold">Skills</Typography>
// 						<Typography style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>  {Array.isArray(job.key_skills) && job.key_skills.length > 0 ? (job.key_skills.map((skill, index) => (<Typography fontWeight="small" key={index} style={{ marginRight: '5px' ,  fontSize: '12px' , color: 'rgba(0, 0, 0, 0.6)' }}>{skill}  </Typography>))) :
// 						(<Typography>No skills specified</Typography>)}</Typography></Box>
// 						<br />
// 						<Divider />
// 						<br />
// 						{/* <Box><Button onClick={()=>  navigate('/view-job-details',{ state: {...job } })}>View details</Button></Box> */}
// 						<Box><Button color="success">Applied</Button></Box>
// 					</Box>))) :
// 					(
// 					<Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
// 					<img style={{width:"150px", height:'150px',alignContent:"center"}} src={JobApply}></img>
// 					<Typography style={{ font:"Vollkorn", fontWeight:'bold', fontSize:20, textAlign: 'center', paddingTop: '5vh' }}>No Applications Found</Typography>
// 					<Typography style={{font:"Vollkorn",textAlign: 'center', fontSize:15 , paddingTop: '3vh'}}>It appears that you have not applied for any internships or employment</Typography>
// 					{/* <Box sx={{justifyContent: 'center', alignContent:'center'}}>
// 					<Button>Search</Button>
// 					</Box> */}
// 					</Box>
// 					)}
// 			</Box>
//         </Grid>
// 		<Grid item xs={12} sm={12} md={2}></Grid>
//     </Grid>
//     </>

// )
