import React, { useState , useEffect  } from "react";
import { Modal, Box } from "@mui/material";

const LottieAnimation = () => {
  
  const [animi, setAnimi] = useState(true); // Example state, replace with your actual state logic
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimi(false);
    }, 3000); // 5 seconds

    // Cleanup the timer if the component is unmounted or if animi changes
    return () => clearTimeout(timer);
  }, [animi]);

  return (
    <>
      {animi && (

        <Modal
          open={animi}
          onClose={() => setAnimi(false)}
          aria-labelledby="lottie-modal"
          aria-describedby="lottie-animation-modal"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              outline: "none",
            }}
          >
            <lottie-player

              src="https://lottie.host/94a0edf6-eec4-4bd5-98ed-950073b660c1/6ICkdXBNqQ.json"
              background="#fff"
              speed="1"
              style={{ width: "300px", height: "300px" }}
            
              autoplay
              direction="1"
              mode="normal"
            ></lottie-player>

        
          </Box>
        </Modal>
      )}
    </>
  );
};



export default LottieAnimation;
