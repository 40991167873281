import { Box, Button, Chip, Divider, Grid, IconButton, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Recommend_jobs, addbookmark, } from "./Axiosfetch/Axiosfetch";
import recomend from "../../src/images/recomendations.png";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import openings from "./../images/openings.png";
import bookmarkadd from "./../images/bookmarkadd.png";
import bookmarkadded from "./../images/bookmarkadded.png";
import { AlertToast, alertState } from "../divine-constants";

export default function RecomendedJobs(){
	const { setAlert } = useContext(AlertToast);
    const navigate = useNavigate();
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [save, setSave] = useState(false);
	const matches = useMediaQuery('(min-width:768px)');
	const token = localStorage.getItem('accesstoken');

	useEffect(() => {
	  setFilteredJobs(jobs);
	}, [jobs]);

	useEffect(() => {
		const fetchJobs = async () => {
		try {
			const response = await Recommend_jobs();
			if (response && response.data && Array.isArray(response.data)) {
			setJobs(response.data);
			} else {
			console.error("Invalid data structure:", response);
			}
		} catch (error) {
			console.error("Error fetching jobs:", error);
		}
		};
		fetchJobs();
	}, []);

	const HandleSave = async (data) => {
		if (save == false) {
		  const datas = { id: data.id, job_save: "true" };
		  const response = await addbookmark(datas);
		  if (response.status === 200) {
			setSave(true);
			setAlert(
			  alertState({
				message: "You have saved this job",
				severity: "success",
				show: true,
			  })
			);
		  }
		} else if (save == true) {
		  const datas = { id: data.id, job_save: "false" };
		  const response = await addbookmark(datas);
		  if (response.status === 200) {
			setSave(false);
			setAlert(
			  alertState({
				message: "This job is no longer saved",
				severity: "success",
				show: true,
			  })
			);
		  }
		}
	  };

	return (
        <>
         <Grid sx={{padding:5}} container spacing={2}>
			<Grid item xs={12} sm={12} md={2}>
			</Grid>
            <Grid item xs={12} sm={12} md={8} >
			<Typography sx={{mb:2, font :"Vollkorn", fontSize:25, fontWeight:'bold',textAlign : "center"}}>Recommendations</Typography>
			<Box>
            {filteredJobs.length > 0 ? (
              filteredJobs.map((job) => (
                <Box
                  key={job.id}
                  component={Paper}
                  paddingInline={2}
                  paddingBlock={2}
                  sx={{
                    ":hover": { boxShadow: "none" },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <img
                      width={48}
                    //   src="/dummy-image-square.webp"
                      src={job.company_logo}
                      alt="dummy"
                    />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box>
                      <Typography
                        className="align-icon"
                        fontWeight={500}
                        variant="h4"
                      >
                        {job.job_title ? job.job_title.toUpperCase() : null}
                      </Typography>
                      <Typography fontSize={12} color={"#00000090"}>
                        {job.company_name ? job.company_name.toUpperCase() : null}
                      </Typography>
                    </Box>
                  </Box>
                  <br />

				  <Grid container rowSpacing={1}>
				  <Grid  item sm={6} xs={12} md={3}>
						{matches === true ? 
                          <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
                          <img style={{width:16,height:16}} src={Location}/>
                          {job.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {job.post_city.map(location => location.toUpperCase()).join(', ')}
                            </Typography>
                          ) : null}
                        </Stack>:
                      <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
                        <img style={{width:16,height:16}} src={Location}/>
                      {job.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {job.post_city.map(location => location.toUpperCase()).join(', ')}
                            </Typography>
                          ) : null}
                    </Stack>}
						</Grid>
				  </Grid>
				  
                  <Grid container rowSpacing={1}
                		columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item sm={6} xs={12} md={3}>
						{matches === true ?<Stack direction={'column'}>
						<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={experience}/>
						<Typography color={'#000000'} fontSize={12}>EXPERIENCE</Typography>
					</Stack>
					<Typography variant="overline" fontSize={12}>{job.minimum_experience} - {job.maximum_experience} YEARS</Typography>
					</Stack>:<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={experience}/>
						<Typography color={'#000000'} fontSize={12}>{job.minimum_experience} - {job.maximum_experience} Years experience</Typography>
					</Stack>}
						</Grid>
						<Grid item sm={6} xs={12} md={3}>
						{matches === true ?<Stack direction={'column'}>
							<Stack sx={{mt:1}} direction={'row'} spacing={1}>
							<img style={{width:16,height:16}} src={salary}/>
							<Typography color={'#000000'} fontSize={12}>CTC (ANNUAL)</Typography>
							</Stack>
						<Typography variant="overline" fontSize={12}>{job.minimum_salary} - {job.maximum_salary} LPA </Typography>
						</Stack>:
						<Stack sx={{mt:2}} direction={'row'} spacing={1}>
							<img style={{width:16,height:16}} src={salary}/>
							<Typography color={'#000000'} fontSize={12}>CTC (ANNUAL) {job.minimum_salary} - {job.maximum_salary} LPA</Typography>
							</Stack>}
						</Grid>
					</Grid>

					<Grid container sx={{mt:2}}>
					<Grid item xs={12}sm={6}md={3}>
								<Stack sx={{mt:1}} direction={'row'} spacing={1}>
									<img style={{width:18,height:18}} src={openings}/>
									<Typography color={'#000000'} fontSize={14}>10 vacancies</Typography>						
							</Stack>
						</Grid>
						<Grid item xs={12}sm={6}md={3}>
								<Stack sx={{mt:1}} direction={'row'} spacing={1}>
								<img style={{width:18,height:18}} src={applicants}/>
									<Typography color={'#000000'} fontSize={14}>2 Applicants</Typography>					
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={duration}/>
						<Typography color={'#000000'} fontSize={13}>Posted 1 week ago</Typography>
						</Stack>
						</Grid>
					</Grid>
                <br />
                  <Divider />
                  <br />

				  <Stack 
				  direction={{ xs: "row", sm: "row", md: "row" }}
              		spacing={{ xs: 2, md: 2, sm: 2 }}>
						<Button variant="outlined" onClick={() => navigate("/view-job-details", { state: { ...job } })}> View details </Button>
					{/* {token ? 
					<Box>

                	{save === false ? (
                  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
					<img style={{width:16,height:16}} src={bookmarkadd}/>
                  </IconButton>
                ) : (
                  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
					<img style={{width:16,height:16}} src={bookmarkadded}/>
                  </IconButton>
                )}
              		</Box> : null} */}

				</Stack>

                </Box>
              ))
            ) : ( 
				<Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
					<img style={{width:"150x", height:'150px',alignContent:"center"}} src={recomend}></img>
					<Typography style={{ font:"Vollkorn", fontWeight:'bold', fontSize:20, textAlign: 'center', paddingTop: '5vh' }}>No Recommendations</Typography>
					<Typography style={{font:"Vollkorn",textAlign: 'center', fontSize:15 , paddingTop: '3vh'}}>Examine individualized employment and internship opportunities</Typography>
					</Box>
					)}
          </Box>
            </Grid>
			<Grid item xs={12} sm={12} md={2}></Grid>
        </Grid>
        </>
       
    )
}


    // return (
    //     <>
    //      <Grid sx={{padding:5}} container spacing={2}>
	// 		<Grid item xs={12} sm={12} md={2}>
	// 		</Grid>
    //         <Grid item xs={12} sm={12} md={8} >
	// 		<Typography sx={{mb:2, font :"Vollkorn", fontSize:25, fontWeight:'bold',textAlign : "center"}}>Recommendations</Typography>
    //             <Box>
	// 				{filteredJobs.length > 0 ? 
	// 				(filteredJobs.map((job) => ( 
	// 					<Box
	// 					key={job.id}
	// 					component={Paper}
	// 					paddingInline={2}
	// 					paddingBlock={2}
	// 					sx={{ ":hover": { boxShadow: "none" }, cursor: "pointer", mb:2,}}
	// 					> 
	// 						<Box display="flex" alignItems="center" gap={2}>
	// 							<img width={48} src="/dummy-image-square.webp" alt="dummy" />
	// 							<Divider orientation="vertical" variant="middle" flexItem />
	// 							<Box>
	// 								<Typography className="align-icon" fontWeight={500} variant="h4">
	// 									{job.job_title}
	// 									<Tooltip arrow title="Actively hiring" placement="right" style={{verticalAlign: "middle"}}>
	// 										<TrendingUpOutlined color="info" />
	// 									</Tooltip>
	// 								</Typography>
	// 								<Typography fontWeight={100} color={"GrayText"}>{job.company_name}</Typography>
	// 							</Box>
	// 							<Typography marginLeft="auto" color="GrayText" style={{alignSelf: "flex-start"}}>{job.post_date === 0 ? 'Today' : `${job.post_date} days ago`}</Typography>
	// 						</Box>
	// 						<br />
	// 						<Stack flexWrap="wrap" direction="row" gap={1}>
	// 							<Chip icon={<CurrencyRupeeOutlined color="disabled" />} variant="outlined" label={<>{job.maximum_salary} - {job.minimum_salary} LPA</>} />
	// 							<Chip icon={<HomeOutlined color="disabled" />} variant="outlined" label={<>{job.work_mode}</>} />
	// 							<Chip icon={<PinDropOutlined color="disabled" />} variant="outlined" label={<>{job.job_location}</>} />
	// 							<Chip icon={<StarsOutlined color="disabled" />} variant="outlined" label={<>{job.minimum_experience} - {job.maximum_experience} yrs</>} />
	// 						</Stack>
	// 						<br />	
	// 						<Box >
	// 							<Typography fontWeight="bold">Skills</Typography>
	// 						<Typography style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>  {Array.isArray(job.key_skills) && job.key_skills.length > 0 ? (job.key_skills.map((skill, index) => (<Typography fontWeight="small" key={index} style={{ marginRight: '5px' ,  fontSize: '12px' , color: 'rgba(0, 0, 0, 0.6)' }}>{skill}  </Typography>))) : 
	// 						(<Typography>No skills specified</Typography>)}</Typography></Box>
	// 						<br />
	// 						<Divider />
	// 						<br />
	// 						{/* <Box><Button onClick={()=>  navigate('/view-job-details',{ state: {...job } })}>View details</Button></Box> */}
	// 						<Box><Button color="success">Applied</Button></Box>
	// 					</Box>))) : 
	// 					( 
	// 					<Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
	// 					<img style={{width:"150x", height:'150px',alignContent:"center"}} src={recomend}></img>
						
	// 					<Typography style={{ font:"Vollkorn", fontWeight:'bold', fontSize:20, textAlign: 'center', paddingTop: '5vh' }}>No Recommendations</Typography>
	// 					<Typography style={{font:"Vollkorn",textAlign: 'center', fontSize:15 , paddingTop: '3vh'}}>Examine individualized employment and internship opportunities</Typography>
	// 					{/* <Box sx={{justifyContent: 'center', alignContent:'center'}}>
	// 					<Button>Search</Button>
	// 					</Box> */}
	// 					</Box>
	// 					)}
	// 			</Box> 
    //         </Grid>
	// 		<Grid item xs={12} sm={12} md={2}></Grid>
    //     </Grid>
    //     </>
       
    // )