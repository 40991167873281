import {
  Alert,
  Box,
  Button,
  Container,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import form_img from "../../../images/form_img.png";
import name from "../../../images/name.png";
import email from "../../../images/email.png";
import file from "../../../images/file.png";
import cross from "../../../images/cross.png";
import phone from "../../../images/phone.png";
import { AlertToast } from "../../../divine-constants";
import FormDialog from "../../OTPverify";
import {
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Autocomplete from "@mui/material/Autocomplete";
import {
  get_details,
  get_profile,
  GetAllCountry,
  GetAllState,
  GetAllStatecity,
  partner_list,
  Submit_application,
} from "../../Axiosfetch/Axiosfetch";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";

import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const options = ["male", "female", "others"];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ViewForm = () => {
  // const matches = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [ShowSuccessPopup, setShowSuccessPopup] = useState(false);

  const [user, setUser] = useState();
  const [data, setData] = useState({
    id: "",
    company_id: "",
    sub_company_id: "",
    company_name: "",
    company_website_link: "",
    about_company: "",
    company_logo: "",
    hr_id: "",
    posted_by: "",
    role: null,
    job_title: "",
    domine: "",
    job_type: [],
    type: "",
    post_active_status: null,
    job_nature: "",
    time_line: "",
    job_location: [],
    vacancy: 2,
    minimum_experience: 1,
    maximum_experience: 4,
    job_description: "",
    key_skills: [],
    minimum_salary: 0,
    maximum_salary: 0,
    salary_per: "",
    benefits: "",
    job_responsibilities: "",
    contact_no: 918968571256,
    contact_email: "",
    cv_require_status: true,
    audio_require_status: false,
    video_require_status: false,
    posted_mobile_num: 918968571256,
    posted_email: "",
    experience: [],
    language: [],
    certificate: [],
    CustomQuestions: [],
    date: "",
    post_expre_date: "",
    show_email: true,
    show_contact: true,
    active_status: true,
    post_status: true,
    draft_post: true,
    status: true,
    Applied_status: true,
    post_close_job: true,
    job_bookmark_status: true,
    work_mode: "",
    post_country: "",
    post_state: "",
    post_city: [],
    advertise_country: "",
    advertise_state: "",
    advertise_city: [],
    stateCode: "",
    cityCode: "",
    AdvertisestateCode: "",
    AdvertisecityCode: "",
    show_questions: {
      one: true,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
    },
    relocate: {
      required: false,
      Showdata: [],
      question: "",
      type: "",
    },
    minEducation: {
      required: false,
      Showdata: null,
      question: null,
      type: null,
      dropdown: [],
    },
    ShiftTime: {
      required: false,
      Showdata: [],
      question: null,
      type: null,
      dropdown: [],
    },
    post_date: 9,
    Status: true,
    count: 0,
  });

  // const data = location.state;

  useEffect(() => {
    const handleClick = async () => {
      // const id = data.id;
      const id = "66bca87414f22cf73c12b787";
      const response = await get_details(id);

      if (response.status === 200) {
        setData(response.data.job_details);
      }
    };
    handleClick();
  }, []);

  const [resumename, setResumename] = useState("");

  const [coverLetter, setCoverLetter] = useState("");

  const [resume, setResume] = useState();
  const [warringstatus, setWarringstatus] = useState(false);
  const [isvisiablestatus, setIsvisiablestatus] = useState(false);

  // const [value, setValue] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");

  const [experience, setExperience] = useState(data?.experience);
  const [certificate, setCertificate] = useState(data?.certificate);
  const [CustomQuestion, setCustomQuestion] = useState(data?.CustomQuestions);

  const [StartTime, setStartTime] = useState(null);
  const [EndTime, setEndTime] = useState(null);

  const [for_radio_value, setFor_radio_value] = useState("female");

  const handleChange = (event) => {
    setFor_radio_value(event.target.value);
    setFormData({ ...formData, Avaliable_time: event.target.value });
  };

  const dataAdds = (item) => {
    let newArr = {}; // Initialize newArr outside of the if-else block

    if (item.question != null) {
      newArr = { ...item, Answer: "yes" }; // Assign the modified object to newArr
    } else {
      newArr = { ...item }; // Assign the original object to newArr
    }

    return newArr;
  };

  const [relocate, setRelocate] = useState(data?.relocate);
  const [minEducation, setMinEducation] = useState(data?.minEducation);

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    setEndDate(end);
    console.log(start, "datesdatesdates");
    console.log(end, "datesdatesdates2");

    setFormData({ ...formData, startDate: start, endDate: end });
  };

  const dataAdd = (item) => {
    const newArr = [];

    item.forEach((item) => {
      newArr.push({ ...item, Answer: "yes" });
    });

    return newArr;
  };

  const [language, setLanguage] = useState(dataAdd(data?.language));
  const [shiftTime, setShiftTime] = useState(data?.ShiftTime);
  const [notice_period, setNotice_period] = useState(false);

  const { setAlert } = useContext(AlertToast);

  const [formData, setFormData] = useState({
    // Initialize form data with default values
    relocated: dataAdds(data.relocate),
    minEducations: {},
    languages: dataAdd(data.language),
    ShiftTimes: {},
    CustomQuestions: [],
    certificates: [],
    experiences: [],
    CoverLetter: "",
    resumename: "",
    resumes: "",
    startDate: "",
    endDate: "",
    starttime: "",
    endtime: "",
    Avaliable_time: "",
    day: "",
    id: data.id,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const days_of_week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleCustomQuestion = (e, id) => {
    const { value } = e.target;

    setCustomQuestion((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handlelanguageChange = (e, id) => {
    const { value } = e.target;

    setLanguage((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const handleExperience = (e, id) => {
    const { value } = e.target;

    setExperience((prevData) => {
      const newData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Answer: value };
        } else {
          return item;
        }
      });
      return newData;
    });
  };

  const handleCertificate = (e, id) => {
    const { value } = e.target;

    setCertificate((prevData) => {
      return prevData.map((item) =>
        item.id === id ? { ...item, Answer: value } : item
      );
    });
  };

  useEffect(() => {
    setFormData({ ...formData, CustomQuestions: CustomQuestion });
  }, [CustomQuestion]);

  useEffect(() => {
    setFormData({ ...formData, certificates: certificate });
  }, [certificate]);

  useEffect(() => {
    setFormData({ ...formData, experiences: experience });
  }, [experience]);

  useEffect(() => {
    setFormData({ ...formData, relocated: relocate });
  }, [relocate]);

  useEffect(() => {
    setFormData({ ...formData, minEducations: minEducation });
  }, [minEducation]);

  useEffect(() => {
    console.log("languagelanguage", language);
    setFormData({ ...formData, languages: language });
  }, [language]);

  useEffect(() => {
    setFormData({ ...formData, ShiftTimes: shiftTime });
  }, [shiftTime]);

  useEffect(() => {
    setFormData({ ...formData, CoverLetter: coverLetter });
  }, [coverLetter]);

  useEffect(() => {
    setFormData({ ...formData, resumes: resume });
    // console.log()
  }, []);

  useEffect(() => {
    console.log(coverLetter, "coverLetter");
  }, [coverLetter]);

  const handleRelocateChange = (event) => {
    setRelocate({ ...relocate, Answer: event.target.value });
    // setFormData({ ...formData, relocate: event.target.value });
  };

  const handleEducationChange = (event) => {
    setMinEducation({ ...minEducation, Answer: event.target.value });
  };

  const handleShiftTimeChange = (event) => {
    setShiftTime({ ...shiftTime, Answer: event.target.value });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleSkillsChange = (event, newValue) => {
    setFormData({
      ...formData,
      day: newValue,
    });
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await get_profile(); // Call your API function to fetch profile data
        if (response.status === 200) {
          setUser(response.data);

          // setResume(response.data.resume)
          setResumename(response.data.resumename);
          if (response.data.notice_period) {
            setNotice_period(false);
          } else {
            setNotice_period(true);
          }

          setFormData({
            ...formData,
            resumes: response.data.resume,
            resumename: response.data.resumename,
          });
        }
      } catch (err) {}
    };
    fetchProfileData();
  }, []);

  console.log(warringstatus, "warringstatus");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWarringstatus(true);
  };

  const handleSubmits = async (e) => {
    e.preventDefault();

    setIsvisiablestatus(!isvisiablestatus);
    // setShowSuccessPopup(true);
  };

  {
    isvisiablestatus &&
      window.scrollTo({
        top: 900,
        left: 0,
        behavior: "smooth",
      });
  }

  const submitapi = async () => {
    try {
      const data = new FormData();

      for (const [key, value] of Object.entries(formData)) {
        if (key == "resumes") {
          if (value) {
            data.append(key, value);
          } else {
            throw new Error();
          }
        } else {
          data.append(key, JSON.stringify(value));
        }
      }

      const response = await Submit_application(data);

      if (response.status === 200) {
        navigate("/jobs");

        // console.log('Cover letter submitted successfully!');
      } else {
        // Handle error, maybe show an error message
        console.error("Failed to submit cover letter");
      }
    } catch (error) {
      setAlert({
        message: "Upload Resume",
        severity: "error",
        show: true,
      });
    }
  };

  const handleresumeChange = async (event) => {
    const selectedFile = event.target.files[0];
    setResumename(selectedFile.name);
    setFormData({
      ...formData,
      resumes: selectedFile,
      resumename: selectedFile.name,
    });
  };

  const [valuess, setValuess] = useState(options[0]);
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const matches = useMediaQuery("(min-width:600px)");

  const [country, setCountry] = useState([]);
  const [stateCode, setStateCode] = useState();
  const [countryapi, setCountryapi] = useState();
  const [stateapi, setStateapi] = useState();
  const [State, setStates] = useState([]);

  const [citydata, setCities] = useState([]);
  const [citycode, setCityCode] = useState();
  const [error, setError] = useState(false);

  const [stateerror, setStateError] = useState(false);

  const [jobPost, setJobPost] = useState({
    post_country: "",
    post_state: "",
    post_city: "",
  });

  const [formDatas, setFormDatas] = useState({
    firstName: "",
    mobile: "",
    email: "",
    password: "",
    key_skills: "",
    year_of_expriance: 0,
    month_of_expriance: 0,

    country: "",
    state: "",
    city: "",
    experience_status: "",
    notice: "",
  });

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    // Fetch countrie
    setValue("country", jobPost.post_country);
    setValue("state", jobPost.post_state);
    setValue("city", jobPost.post_city);
  }, [jobPost]);

  useEffect(() => {
    // Fetch countries
    if (stateCode) {
      fetchStates(stateCode);
    }
  }, [stateCode]);

  useEffect(() => {
    // Fetch countries
    if (stateCode && citycode) {
      fetchCities(stateCode, citycode);
    }
  }, [citycode]);

  const fetchCountries = async () => {
    try {
      const { status, data } = await GetAllCountry();
      console.log(data, "datadatadata");
      if (status === 200) {
        setCountry(data);
        // setValue('country', jobPost.post_country);
      } else if (status === 403) {
      } else {
        throw new Error(`Something went wrong ${data.message}`);
      }
    } catch (error) {
      // setAlert(alertState({
      // message: error.message,
      // severity: "error",
      // show: true
      // }));
    }
  };

  const fetchStates = async (country) => {
    console.log(country, "countrycountry");
    try {
      const { status, data } = await GetAllState(country);
      console.log(data, "datadatadata");
      if (status === 200) {
        setStates(data);
        // setValue('state', jobPost.post_state);
      } else {
        throw new Error(`Something went wrong 1 ${data.message}`);
      }
    } catch (error) {
      // setAlert(alertState({
      // message: error.message,
      // severity: "error",
      // show: true
      // }));
    }
  };

  const fetchCities = async (state, city) => {
    try {
      const { status, data } = await GetAllStatecity(state, city);
      console.log(data, "datadatadata");
      if (status === 200) {
        setCities(data);
        // setValue('city', jobPost.post_city);
      } else {
        throw new Error(`Something went wrong ${data.message}`);
      }
    } catch (error) {
      // setAlert(alertState({
      // message: error.message,
      // severity: "error",
      // show: true
      // }));
    }
  };

  const handleInputChange = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
  };

  const handlechange = (e) => {
    setFormDatas({ ...formDatas, experience_status: e.target.value });
  };

  // const handleSubmit = async () => {
  //   try {
  //     const response = await partner_list();
  //     console.log("Response:", response.data);
  //     // Handle success, show a message, etc.
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //     // Handle error, show a message, etc.
  //   }
  // };

  // const handleresumeChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   // setName(selectedFile.name)

  //   // const pic = {"photos" : selectedFile }
  //   // setFilename(pic)
  // };

  return (
    <Container maxWidth="md">
      <Paper elevation={3}>
        <Stack direction={"column"} sx={{ borderRadius: "10px" }}>
          <Box
            sx={{
              display: "flex",
              background: "#C5DCFF50",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <div
              style={{
                // width: {md :"50%" , xs:"100%"},
                width: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "70%" }} src={form_img} />
            </div>

            <div
              style={{
                // width: {md :"50%" , xs:"100%"},
                width: "70%",
                padding: "15px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: { xs: 20, sm: 35, md: 38 },
                }}
              >
                Apply Form
              </Typography>
              <Typography>
                Please fill out the form below to submit your application.
                Ensure all required fields are completed accurately. We look
                forward to reviewing your submission and will get back to you as
                soon as possible.
              </Typography>
            </div>
          </Box>

          {/* <Grid  container> */}
          <Box sx={{ padding: "30px" }}>
            <Grid item xs={12} md={2} sm={2}></Grid>

            <Grid justifyContent={"center"} item xs={12} md={8} sm={8}>
              <Typography
                color={"#000000"}
                variant="h2"
                fontWeight={"bold"}
                sx={{ textTransform: "capitalize" }}
              >
                {data.job_title} ({data.work_mode ? data.work_mode : null})
              </Typography>
              <Typography color={"#00000090"} fontSize={16} variant="overline">
                {data.company_name ? data.company_name : null}
              </Typography>

              <Divider />

              <br />

              {/* <form autoComplete="off" onSubmit={handleSubmit}> */}
              <form onSubmit={handleSubmit}>
                {data ? (
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      fontSize={15}
                      color={"#000000"}
                      fontFamily={"Poppins"}
                    >
                      Application Questionnaire
                    </Typography>
                    {data?.relocate?.question ? (
                      <Stack
                        sx={{ mt: 2 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <FormControl>
                          <Typography color={"#000000"} fontSize={13}>
                            Are you willing to relocate to any of the following
                            locations -
                            {data.relocate.Showdata.map((city, index) => (
                              <span key={index}>
                                {index > 0 && ","} {city}
                              </span>
                            ))}{" "}
                            ?{" "}
                          </Typography>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            defaultValue={"yes"}
                            // value={value}
                            // onChange={handleRadio}
                            // value={"yes"}

                            onChange={handleRelocateChange}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes, I am available to relocate"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    ) : null}

                    {data.minEducation.question ? (
                      <FormControl sx={{ mt: 2, maxWidth: 500 }}>
                        {/* <Stack sx={{mt:2, maxWidth:300}} direction={{ xs: 'column', sm: 'column', md:'column'}} spacing={{ xs: 1, sm: 1, md: 1 }}> */}
                        <Typography color={"#000000"} fontSize={13}>
                          {" "}
                          {data.minEducation.question} (
                          {data.minEducation.Showdata})
                        </Typography>
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={formData.education}
                            onChange={handleEducationChange}
                            // required
                          >
                            {data.minEducation.dropdown.map(
                              (education, index) => (
                                <MenuItem key={index} value={education}>
                                  {education}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {/* </Stack> */}
                      </FormControl>
                    ) : null}

                    {data.experience.length > 0 ? (
                      <Stack
                        sx={{ mt: 2 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <div>
                          {data.experience.map((exp, index) => (
                            <div key={index}>
                              <Typography color={"#000000"} fontSize={13}>
                                {exp.question} ?
                              </Typography>
                              <TextField
                                id={`experience-${index}`}
                                // value={exp.Showdata}
                                // required

                                onChange={(e) => handleExperience(e, exp.id)}
                                type="number"
                              />
                            </div>
                          ))}
                        </div>
                      </Stack>
                    ) : null}

                    {data.language.length > 0 ? (
                      <Stack
                        sx={{ mt: 2 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <div>
                          {data.language.map((lang, index) => (
                            <div key={index}>
                              <Typography color={"#000000"} fontSize={13}>
                                {lang.question}({lang.Showdata}){" "}
                              </Typography>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label={lang.question}
                                  name={lang.question}
                                  defaultValue={"Yes"}
                                  // value={lang.Showdata}
                                  // onChange={handleRadioLanguage}
                                  // value={formData.language}
                                  onChange={(e) =>
                                    handlelanguageChange(e, lang.id)
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          ))}
                        </div>
                      </Stack>
                    ) : null}

                    {data.certificate.length > 0 ? (
                      <Stack
                        sx={{ mt: 2 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <div>
                          {data.certificate.map((cer, index) => (
                            <div key={index}>
                              <Typography color={"#000000"} fontSize={13}>
                                {cer.question} ({cer.Showdata})
                              </Typography>
                              <TextField
                                id={`experience-${index}`}
                                // value={cer.Showdata}
                                onChange={(e) => handleCertificate(e, cer.id)}
                                // required
                                type="text"
                              />
                            </div>
                          ))}
                        </div>
                      </Stack>
                    ) : null}

                    {data.ShiftTime.question ? (
                      <Stack
                        sx={{ mt: 2, maxWidth: 300 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <Typography color={"#000000"} fontSize={13}>
                          {data.ShiftTime.question}
                        </Typography>
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            // value={formData.ShiftTimes}
                            onChange={handleShiftTimeChange}
                            // required
                          >
                            {data.ShiftTime.dropdown.map((shift, index) => (
                              <MenuItem key={index} value={shift}>
                                {shift}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    ) : null}

                    {data.CustomQuestions.length > 0 ? (
                      <Stack
                        sx={{ mt: 2 }}
                        direction={{ xs: "column", sm: "column", md: "column" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <div>
                          {data.CustomQuestions.map((cus, index) => (
                            <div key={index}>
                              <Typography color={"#000000"} fontSize={13}>
                                {cus.question}
                              </Typography>
                              <TextField
                                id={`experience-${index}`}
                                // name = {cus.question}
                                // value = {cus.Showdata}
                                // onChange={(e) => handleCustomQuestion(cus, e.target.value)}
                                onChange={(e) =>
                                  handleCustomQuestion(e, cus.id)
                                }
                                type="text"
                                // required
                              />
                            </div>
                          ))}
                        </div>
                      </Stack>
                    ) : null}
                  </Box>
                ) : null}

                <Box sx={{ mt: 2 }}>
                  <Typography variant="h3">Cover Letter</Typography>
                  <Typography fontSize={13} color={"#00000090"}>
                    Why should we hire you for this position
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    value={coverLetter}
                    required={false}
                    onChange={(e) => setCoverLetter(e.target.value)}
                  />
                </Box>

                <Box>
                  <Typography variant="h3">Resume</Typography>
                  <Typography fontSize={13} color={"#00000090"}>
                    The employer is able to view and download this CV.
                  </Typography>
                  {resumename ? (
                    <Stack
                      sx={{ mt: 2 }}
                      direction={"row"}
                      spacing={2}
                      alignItems="center"
                    >
                      <img style={{ width: 21, height: 21 }} src={file} />
                      <Typography fontSize={16}> {resumename}</Typography>
                      <Box
                        display={"flex"}
                        alignItems={"flex-end"}
                        justifyContent={"flex-end"}
                      >
                        {/* <IconButton >
                            <img style={{width:14, height:14}} src={cross}/>
                        </IconButton> */}
                      </Box>
                    </Stack>
                  ) : (
                    <Stack
                      sx={{ mt: 2 }}
                      direction={"row"}
                      spacing={2}
                      alignItems="center"
                    >
                      <img style={{ width: 21, height: 21 }} src={file} />
                      <Typography fontSize={16}>
                        {" "}
                        Please Upload Resume
                      </Typography>
                      <Box
                        display={"flex"}
                        alignItems={"flex-end"}
                        justifyContent={"flex-end"}
                      >
                        {/*                             
                        <IconButton >
                            <img style={{width:14, height:14}} src={cross}/>
                        </IconButton> */}
                      </Box>
                    </Stack>
                  )}

                  <Paper sx={{ p: 2, mt: 2 }} elevation={3}>
                    <Stack alignContent={"center"} alignItems={"center"}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleresumeChange}
                          accept=".docx,.pdf"
                        />
                      </Button>
                      <Typography
                        sx={{ mt: 1 }}
                        fontSize={12}
                        color={"#00000090"}
                      >
                        File size limit: 10 MB. File type: DOC, PDF, DOCX
                      </Typography>
                    </Stack>
                  </Paper>
                </Box>

                <Box textAlign={"center"} sx={{ mt: 2 }}>
                  {/* {isvisiablestatus &&

              <Button
                type="submit"
                value="submit"
                sx={{
                  backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
                  color: "white",
                }}
              >
               Register & Apply
              </Button> } */}
                </Box>
              </form>
            </Grid>

            <Grid item xs={12} md={2} sm={2}></Grid>

            {isvisiablestatus && (
              <Box component="form" sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h2"
                      fontWeight={"bold"}
                      // sx={{ mt: "25px", mb:"20" , padding:"5px"  ,  borderRadius:"10px",color:"white", backgroundImage:"linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))" ,  fontSize: { xs: 20, sm: 35, md: 38 } }}
                      sx={{
                        mt: "25px",
                        mb: "35px",
                        fontSize: { xs: 20, sm: 35, md: 38 },
                      }}
                    >
                      Create your{" "}
                      <span style={{ color: "#1976d2" }}> profile </span>
                    </Typography>
                    {/* backgroundImage:"linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))" */}
                    {/* <Typography
                      sx={{  fontSize: { xs: 10, sm: 15, md: 13 } }}
                    >Already registered? Login
                    </Typography> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      sx={{
                        mt: "45px",
                        mb: "10",
                        textAlign: "end",
                        fontSize: { xs: 10, sm: 15, md: 13 },
                      }}
                    >
                      Already registered?{" "}
                      <span
                        onClick={() => {
                          navigate("/userlogin");
                          window.scrollTo(0, 0);
                        }}
                        style={{ color: "blue" }}
                      >
                        {" "}
                        Login{" "}
                      </span>
                      here
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Name"
                      variant="standard"
                      sx={{ width: "100%" }}
                      name="firstName"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      variant="standard"
                      sx={{ width: "100%" }}
                      name="email"
                      type="email"
                      autoComplete="off"
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Mobile"
                      variant="standard"
                      sx={{ width: "100%" }}
                      // sx={{border: 'none',"& fieldset": { border: 'none' },}}
                      name="mobile"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="standard"
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              handleInputChange={handleInputChange}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      label="Password"
                      variant="standard"
                      sx={{ width: "100%" }}
                      name="password"
                      type="password"
                      onChange={handleInputChange}
                    />
                  </Grid> */}

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={valuess}
                      onChange={(event, newValue) => {
                        setValuess(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      options={options}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Key Skills"
                      variant="standard"
                      sx={{ width: "100%" }}
                      name="key_skills"
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: "none" }}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Country"
                          size="small"
                          //   MenuProps={MenuProps}
                          error={error}
                          value={jobPost.post_country}
                          name="post_country"
                          //   onChange={handleCountryChange}
                          onChange={(event, newValue) => {
                            setJobPost({
                              ...jobPost,
                              post_country: event.target.value,
                            });
                            event.target.value === ""
                              ? setError(true)
                              : setError(false);
                          }}
                          //   required
                        >
                          <MenuItem value={""}>Select</MenuItem>
                          {country.map((e, i) => {
                            return (
                              <MenuItem
                                onClick={() => setStateCode(e.iso2)}
                                key={i}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      {jobPost?.post_country?.length > 0 && (
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            State
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="State"
                            size="small"
                            //   MenuProps={MenuProps}
                            // rules={textValidation("Gender")}
                            error={stateerror}
                            value={jobPost.post_state}
                            name="post_state"
                            onChange={(event, newValue) => {
                              setJobPost({
                                ...jobPost,
                                post_state: event.target.value,
                              });
                              event.target.value === ""
                                ? setStateError(true)
                                : setStateError(false);
                            }}
                            required
                          >
                            <MenuItem value={""}>Select</MenuItem>
                            {State?.map((e, i) => {
                              return (
                                <MenuItem
                                  onClick={() => setCityCode(e.iso2)}
                                  key={i}
                                  value={e.name}
                                >
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {/* <FormHelperText>Please provide valid state</FormHelperText> */}
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {matches ? (
                      <Box sx={{ my: 3 }}>
                        {jobPost?.post_state?.length > 0 && (
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="City"
                              size="small"
                              // rules={textValidation("Gender")}
                              //   MenuProps={MenuProps}
                              error={stateerror}
                              value={jobPost.post_city}
                              name="post_city"
                              onChange={(event, newValue) => {
                                setJobPost({
                                  ...jobPost,
                                  post_city: event.target.value,
                                });
                              }}
                            >
                              <MenuItem value={""}>Select</MenuItem>
                              {citydata.map((e, i) => {
                                return (
                                  <MenuItem key={i} value={e.name}>
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {/* <FormHelperText>Please provide valid City</FormHelperText> */}
                          </FormControl>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{ mt: 3 }}>
                        {jobPost?.post_state?.length > 0 && (
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">
                              City
                            </InputLabel>

                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="City"
                              size="small"
                              // rules={textValidation("Gender")}
                              //   MenuProps={MenuProps}
                              error={stateerror}
                              value={jobPost.post_city}
                              name="post_city"
                              onChange={(event, newValue) => {
                                setJobPost({
                                  ...jobPost,
                                  post_city: event.target.value,
                                });
                              }}
                            >
                              <MenuItem value={""}>Select</MenuItem>
                              {citydata.map((e, i) => {
                                return (
                                  <MenuItem key={i} value={e.name}>
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {/* <FormHelperText>Please provide valid City</FormHelperText> */}
                          </FormControl>
                        )}
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}></Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <RadioGroup
                        row
                        value={formDatas.experience_status}
                        name="radio-buttons-group"
                        onChange={handlechange}
                      >
                        <FormControlLabel
                          value="Experience"
                          control={<Radio />}
                          label="Experience"
                        />
                        <FormControlLabel
                          value="Fresher"
                          control={<Radio />}
                          label="Fresher"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}></Grid>

                  {formDatas.experience_status === "Experience" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 1 }}>
                          <TextField
                            name="year_of_expriance"
                            value={formDatas.year_of_expriance}
                            onChange={handleInputChange}
                            label="Year Of Experience"
                            fullWidth
                            type="number"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box sx={{ mt: 1 }}>
                          <TextField
                            name="month_of_expriance"
                            value={formDatas.month_of_expriance}
                            onChange={handleInputChange}
                            label="Month Of Experience"
                            fullWidth
                            type="number"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  {/* 
              <Grid item xs={12} md={6}>
                <h4>Upload Resume</h4>
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12} md={12}>
                <Box sx={{ border: "1px dotted" }}>
                  <Stack
                    alignContent={"center"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ my: "50px" }}
                  >
                    <Button
                      sx={{ width: "150px" }}
                      component="label"
                      startIcon={<CloudUploadIcon />}
                    >
                      Update file
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          // Handle file upload
                        }}
                        accept=".docx,.pdf"
                      />
                    </Button>
                    <Typography fontSize={13} color={"black"}>
                      File size limit: 2MB. File type: DOCX, PDF, DOC
                    </Typography>
                  </Stack>
                </Box>
              </Grid> */}
                </Grid>
              </Box>
            )}
          </Box>

          <Divider sx={{ height: 28, m: 0.5 }} />

          <Box sx={{ margin: "20px", textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage: "linear-gradient(#24282A97 , #0A79C9)",
                color: "white",
              }}
              onClick={handleSubmits}
            >
              Register & Apply
            </Button>
          </Box>
        </Stack>
      </Paper>

      {/* {ShowSuccessPopup && <FormDialog onClose={closeSuccessPopup} open={ShowSuccessPopup}/>} */}
    </Container>
  );
};

export default ViewForm;

// <Box sx={{ width: "100%", padding: "30px" }}>
// <Grid
//   container
//   rowSpacing={1}
//   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
// >
//   <Grid item xs={12} md={6}>
//     <TextField
//       id="standard-basic"
//       label="Name"
//       variant="standard"
//       sx={{ width: "100%" }}
//     />
//   </Grid>
//   <Grid item xs={12} md={6}>
//     <TextField
//       id=""
//       label="Mobile"
//       variant="standard"
//       sx={{ width: "100%" }}
//     />
//   </Grid>
//   <Grid item xs={12} md={6}>
//     <TextField
//       id=""
//       label="Email"
//       variant="standard"
//       sx={{ width: "100%" }}
//     />
//   </Grid>
//   <Grid item xs={12} md={6}>
//     <TextField
//       id=""
//       label="Password"
//       variant="standard"
//       sx={{ width: "100%" }}
//     />
//   </Grid>
//   <Grid item xs={12} md={6}>
//     <Autocomplete
//       value={value}
//       onChange={(event, newValue) => {
//         setValue(newValue);
//       }}
//       inputValue={inputValue}
//       onInputChange={(event, newInputValue) => {
//         setInputValue(newInputValue);
//       }}
//       id="controllable-states-demo"
//       options={options}
//       sx={{ width: "100%" }}
//       renderInput={(params) => (
//         <TextField {...params} label="Gender" variant="standard" />
//       )}
//     />
//   </Grid>

//   <Grid item xs={12} md={6}>
//     <TextField
//       id=""
//       label="Key Skills"
//       variant="standard"
//       sx={{ width: "100%" }}
//     />
//   </Grid>

//   <Grid item xs={12} md={6}>

//     <FormControl>
//       {/* <FormLabel id="demo-radio-buttons-group-label">Total E</FormLabel> */}
//       <RadioGroup
//         row
//         aria-labelledby="demo-radio-buttons-group-label"
//         defaultValue="Fresher"
//         name="radio-buttons-group"
//         value={formDatas.experience_status} // Set the value prop to control the selected option
//         onChange={handlechange}
//         // onChange={handlechange}
//       >
//         <FormControlLabel
//           value="Experience"
//           control={<Radio />}
//           label="Experience"
//         />
//         <FormControlLabel
//           value="Fresher"
//           control={<Radio />}
//           label="Fresher"
//         />
//       </RadioGroup>
//     </FormControl>
//     {/* <Box></Box> */}

//     {formDatas.experience_status === "Experience" ? (  <Box display="flex" sx={{ mt: 2 }}>
//     <h4>Total Work Experience</h4>
//   </Box> ): null}

//     </Grid>

//     <Grid item xs={12} md={6}>
//     </Grid>

//     {formDatas.experience_status === "Experience" ? (
//       <>
//         {/* <h4 >Total Work Experience</h4> */}

//         <Grid item xs={12} md={6}>

//         <Box sx={{ mt: 2 }}>
//           <TextField
//             name="year_of_expriance"
//             value={formDatas.year_of_expriance}
//             onChange={handleInputChange}
//             label="Year Of Experience"
//             fullWidth
//             type="number"
//           />
//         </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             name="month_of_expriance"
//             value={formDatas.month_of_expriance}
//             onChange={handleInputChange}
//             label="Month Of Experience"
//             fullWidth
//             type="number"
//           />
//         </Box>
//         </Grid>

//         <Box></Box>
//       </>
//     ) : null}

//   <Grid item xs={12} md={6}>
//   <h4>Upload Resume</h4>
//   </Grid>
//   <Grid item xs={12} md={6}>
//   </Grid>

//   <Grid   item xs={12} md={12}>
//     {/* <div style={{border:"1px solid blue" , borderRadius:"5px" }}> */}

//     {/* <Paper elevation={4}> */}

//         {/* <Alert severity="info" icon={false} variant="outlined"> */}

//           <Stack
//             alignContent={"center"}
//             alignItems={"center"}
//             spacing={2}
//             sx={{padding: "30px 250px" , border: "1px dotted"}}
//           >

//             <Button
//               sx={{ width: "150px" }}
//               component="label"
//               // variant="outlined"

//               startIcon={<CloudUploadIcon />}
//             >
//               {/* {resumename? 'Upload file' :'Update file'} */}
//               {"Update file"}
//               <VisuallyHiddenInput
//                 type="file"
//                 style={{ display: "none" }}
//                 onChange={handleresumeChange}
//                 accept=".docx,.pdf"
//               />

//             </Button>
//             <Typography noWrap fontSize={13} color={"black"} >
//               File size limit: 2MB. File type: DOCX, PDF, DOC
//             </Typography>

//           </Stack>
//         {/* </Alert> */}

//     {/* </Paper> */}
//   </Grid>

// </Grid>

// </Box>

// <Divider sx={{ height: 28, m: 0.5 }}  />

// <Box sx={{margin: "20px" , textAlign: "center"}}>

// <Button variant="contained" sx={{backgroundImage : "linear-gradient(#24282A97 , #0A79C9)" , color : "white" }}  >Submit</Button>

// </Box>

// <Grid item xs={12} md={6}>
// <Box sx={{ mt: 3 }}>
//   <FormControl fullWidth>
//     <InputLabel id="demo-simple-select-label">
//       Country
//     </InputLabel>
//     <Select
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       label="Country"
//       size="small"
//       //   MenuProps={MenuProps}
//       error={error}
//       value={jobPost.post_country}
//       name="post_country"
//       //   onChange={handleCountryChange}
//       onChange={(event, newValue) => {
//         setJobPost({
//           ...jobPost,
//           post_country: event.target.value,
//         });
//         event.target.value === ""
//           ? setError(true)
//           : setError(false);
//       }}
//       //   required
//     >
//       <MenuItem value={""}>Select</MenuItem>
//       {country.map((e, i) => {
//         return (
//           <MenuItem
//             onClick={() => setStateCode(e.iso2)}
//             key={i}
//             value={e.name}
//           >
//             {e.name}
//           </MenuItem>
//         );
//       })}
//     </Select>
//   </FormControl>
// </Box>
// <Box sx={{ mt: 3 }}>
//   <FormControl fullWidth>
//     <InputLabel id="demo-simple-select-label">State</InputLabel>
//     <Select
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       label="State"
//       size="small"
//       //   MenuProps={MenuProps}
//       // rules={textValidation("Gender")}
//       error={stateerror}
//       value={jobPost.post_state}
//       name="post_state"
//       onChange={(event, newValue) => {
//         setJobPost({
//           ...jobPost,
//           post_state: event.target.value,
//         });
//         event.target.value === ""
//           ? setStateError(true)
//           : setStateError(false);
//       }}
//       required
//     >
//       <MenuItem value={""}>Select</MenuItem>
//       {State?.map((e, i) => {
//         return (
//           <MenuItem
//             onClick={() => setCityCode(e.iso2)}
//             key={i}
//             value={e.name}
//           >
//             {e.name}
//           </MenuItem>
//         );
//       })}
//     </Select>
//     {/* <FormHelperText>Please provide valid state</FormHelperText> */}
//   </FormControl>
// </Box>

// {matches ? (
//   <Box sx={{ mt: 3 }}>
//     {jobPost?.post_state?.length > 0 && (
//       <FormControl fullWidth>
//         <InputLabel id="demo-simple-select-label">
//           City
//         </InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           label="City"
//           size="small"
//           // rules={textValidation("Gender")}
//           //   MenuProps={MenuProps}
//           error={stateerror}
//           value={jobPost.post_city}
//           name="post_city"
//           onChange={(event, newValue) => {
//             setJobPost({
//               ...jobPost,
//               post_city: event.target.value,
//             });
//           }}
//         >
//           <MenuItem value={""}>Select</MenuItem>
//           {citydata.map((e, i) => {
//             return (
//               <MenuItem key={i} value={e.name}>
//                 {e.name}
//               </MenuItem>
//             );
//           })}
//         </Select>
//         {/* <FormHelperText>Please provide valid City</FormHelperText> */}
//       </FormControl>
//     )}
//   </Box>
// ) : (
//   <Box sx={{ mt: 3 }}>
//     {jobPost?.post_state?.length > 0 && (
//       <FormControl fullWidth>
//         <InputLabel id="demo-simple-select-label">
//           City
//         </InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           label="City"
//           size="small"
//           // rules={textValidation("Gender")}
//           //   MenuProps={MenuProps}
//           error={stateerror}
//           value={jobPost.post_city}
//           name="post_city"
//           onChange={(event, newValue) => {
//             setJobPost({
//               ...jobPost,
//               post_city: event.target.value,
//             });
//           }}
//         >
//           <MenuItem value={""}>Select</MenuItem>
//           {citydata.map((e, i) => {
//             return (
//               <MenuItem key={i} value={e.name}>
//                 {e.name}
//               </MenuItem>
//             );
//           })}
//         </Select>
//         {/* <FormHelperText>Please provide valid City</FormHelperText> */}
//       </FormControl>
//     )}
//   </Box>
// )}
// </Grid>

// <Box sx={{mt:2}}>

// <FormControl  >

//   <FormLabel id="demo-row-radio-buttons-group-label">
//     Confirm your availability{" "}
//   </FormLabel>

//   <RadioGroup
//     row ={true}
//     aria-labelledby="demo-row-radio-buttons-group-label"
//     name="row-radio-buttons-group"
//     value={for_radio_value}
//     onChange={handleChange}
//     sx={{mt:1}}
//   >
//    <Stack direction="row" sx={{marginLeft:"5px"}}>

//       <FormControlLabel
//         value="female"
//         control={<Radio />}
//         label="Flexible Schedule"
//       />
//       <FormControlLabel
//         value="male"
//         control={<Radio />}
//         label="other"
//       />
//          </Stack>

//   </RadioGroup>
// </FormControl>

// </Box>

// {for_radio_value === "male" ? (

//   <Grid container spacing={2} sx={{mt:2}}>
//     <Grid item xs={12} md={12}>
//       <Box sx={{ width: { md: "40%", xs: "100%" } }}>

//         <DatePicker
//           selected={startDate}
//           onChange={onChange}
//           startDate={startDate}
//           endDate={endDate}
//           selectsRange
//           dateFormat="dd/MM/yyyy"
//           isClearable={true}
//           withPortal
//           required
//           placeholderText="  From dd/mm/yyyy To dd/mm/yyyy "
//           className="dateRange"
//         />
//       </Box>
//     </Grid>
//     <Grid item xs={6} md={3}>

//       <Box sx={{width:"60%"}} >
//         {" "}
//         <LocalizationProvider dateFormats={"fulltime12h"} dateAdapter={AdapterDayjs}>
//           {" "}
//           {/* Use AdapterMoment */}

//           <MobileTimePicker
//             sx={{
//               "& .css-uu33k-MuiInputBase-root-MuiOutlinedInput-root":
//                 {
//                   height: "40px",

//                 },
//             }}
//             label="Start Time"
//             value={formData.starttime || dayjs()}

//             onChange={(newValue) =>
//               setFormData({ ...formData, starttime: newValue } , console.log(newValue,"newValue"))
//             }
//             // maxTime={dayjs(EndTime?.$d) || ""}
//           />
//         </LocalizationProvider>
//       </Box>
//     </Grid>
//     <Grid item xs={6} md={3}>
//       <Box sx={{width:"60%"}}>
//         {" "}
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           {" "}
//           {/* Use AdapterMoment */}

//           <MobileTimePicker
//             sx={{
//               "& .css-uu33k-MuiInputBase-root-MuiOutlinedInput-root":
//                 {
//                   height: "40px",

//                 },
//             }}
//             label="End Time"
//             value={formData.endtime || dayjs()}
//             onChange={(newValue) =>
//               setFormData({ ...formData, endtime: newValue } , console.log(newValue,"newValue"))
//             }

//             // onChange={(newValue) => { console.log("newValuesd32", new Date(newValue).toLocaleTimeString(undefined, {hour: "numeric", minute: "numeric"}))}
//             // onChange={(newValue) => { console.log("newValuesd32", new Date(newValue).toLocaleTimeString(undefined, {hour: "numeric", minute: "numeric"}))}

//             //   setFormData({ ...formData, endtime: dayjs(newValue).format("DD MM YYYY, hh:mm A") })
//             // }
//             // maxTime={dayjs(EndTime?.$d) || ""}

//           />
//         </LocalizationProvider>
//       </Box>
//     </Grid>
//   </Grid>
// ) : null}
