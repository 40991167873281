import { useTheme, Box, Typography, Paper, Stack, Button, Chip, Radio, Grid, FormControlLabel, InputAdornment, FormGroup, FormLabel } from "@mui/material";
import { useContext, useEffect, useMemo , useState } from "react";
import { FullScreenContext } from "../divine-constants";
import { useForm , Controller } from "react-hook-form";
import { LibAutocomplete, LibCheckbox, LibRadioGroup, LibTextField } from "../components/LibComponents";
import { CurrencyRupeeOutlined } from "@mui/icons-material";
import { userprefer } from "./Axiosfetch/Axiosfetch";
import {useNavigate} from "react-router-dom";
import { check_box_valid, textValidation } from "../utils";

export default function MyPreferences() {
	const theme = useTheme();
	const navigate = useNavigate();
	

	const {setIsFullScreen} = useContext(FullScreenContext);
	const preferences = useMemo(() => [{title: "dentist"}, {title: "psychologist"}, {title: "engineering"}, {title: "therapist"}])
	const jobTitles = useMemo(() =>  ['Software Engineer',
		'Web Developer',
		'Data Scientist',
		'Product Manager',
		'UX/UI Designer',
		'Network Administrator',
		'Marketing Specialist',
		'Financial Analyst',
		'Human Resources Manager',
		'Customer Support Representative',
		
	  ])
	  
	const java_skills = useMemo(() =>  [
		"Python programming",
		"Data structures and algorithms",
		"Web development with frameworks (Django, Flask, FastAPI)",
		"Database management (SQL, NoSQL)",
		"Git version control",
		"Unit testing and TDD",
		"RESTful API development",
		"Asynchronous programming (asyncio)",
		"Front-end frameworks (React, Vue.js)",
		"Data analysis with Pandas and NumPy",
		"Task automation and scripting",
		"Software development methodologies (Agile, Scrum)",
		"Containerization (Docker)",
		"Orchestration (Kubernetes)",
		"Cloud services (AWS, Azure, Google Cloud)",
		"Cybersecurity best practices",
		"Problem-solving and debugging",
		"Continuous integration and continuous deployment (CI/CD)",
		"Software design patterns",
		"Communication and collaboration",
		"Continuous learning",
		"Java Programming",
		"Object-Oriented Programming (OOP)",
		"Java Standard Edition (Java SE)",
		"Java Enterprise Edition (Java EE)",
		"JavaServer Faces (JSF)",
		"Spring Framework",
		"Hibernate",
		"Servlets",
		"JavaServer Pages (JSP)",
		"RESTful Web Services",
		"Microservices Architecture",
		"Spring Boot",
		"Maven",
		"Gradle",
		"JUnit",
		"TestNG",
		"Version Control (Git, SVN)",
		"Database Management (SQL, JDBC)",
		"HTML, CSS, JavaScript",
		"Threading and Concurrency",
		"Design Patterns",
		"Dependency Injection",
		"Logging (SLF4J, Log4j)",
		"Build Tools (Ant)",
		"Continuous Integration/Continuous Deployment (CI/CD)",
		"Web Application Security",
		"Agile/Scrum Methodology",
		"Problem-Solving Skills",
		"Debugging and Profiling Tools",
		"Eclipse IDE or IntelliJ IDEA",
		"Understanding of JVM Internals",
		"Knowledge of Containers (Docker)",
		"Understanding of REST and SOAP",
		"Web Development Frameworks (Spring MVC)",
		"Web Application Servers (Tomcat, Jetty)",
		"JSON and XML Processing",
		"Unit Testing and Integration Testing",
		"Performance Optimization",
		"Cloud Technologies (AWS, Azure, GCP)",
		"Linux/Unix Basics"])

	  const locations = [
		'Mumbai, Maharashtra',
		'Delhi',
		'Bangalore, Karnataka',
		'Chennai, Tamil Nadu',
		'Hyderabad, Telangana',
		'Kolkata, West Bengal',
		'Pune, Maharashtra',
		'Ahmedabad, Gujarat',
		'Jaipur, Rajasthan',
		'Lucknow, Uttar Pradesh',
		
	  ];



	const [error, setError] = useState('');
	const [message, setMessage] = useState('');
	
	const {
		control,
		handleSubmit,
		getValues
	} = useForm({
		defaultValues: {
			emailUpdates: false,
			work_mode: '',
			job_titles: [],
			location: '',
			max_salary: '',
			Skills: "",
			Preferred_shift: ""
			// Preferred_shift: {day_shift: false, night_shift: false, us_shift: false, uk_shift: false}
		}
	});
	useEffect(() => {
		setIsFullScreen(true);
	}, []);

	// const onSubmit = data => {
	// 	//api logic will go here
	// 	console.log(data);
	// }

	const onSubmit = async (resdata) => {
		try {
		  const { status , data  } = await userprefer(resdata);
		  if (status === 200) {
			navigate("/user_profile");
			
			
		  } else {
			// throw new Error(`Something went wrong ${status}`);
			setMessage(data.message);
		  }
		} catch (error) {
		  console.error("Error:", error);
		  setError("An unexpected error occurred.");
		}
	  };
	  
	return (
		<Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{
			width: "80%",
			margin: "auto",
			marginTop: "50px",
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(3),
			[theme.breakpoints.down("sm")]: {
				width: "95%"
			}
		}}>
			<Typography variant="h4" sx={{marginTop: theme.spacing(4)}} color="primary">This is the last step!</Typography>
			<Paper elevation={4} sx={{paddingBlock: theme.spacing(3)}}>
				<Typography variant="h3" margin={`0 auto ${theme.spacing(1)}`} width="90%">Customize Your Experience</Typography>
				<Typography margin={`${theme.spacing(1)} auto`} width="90%">Your job recommendations will be based on the details you provide here.</Typography>
				<br />
				<Box maxWidth="90%" marginInline="auto" sx={{[theme.breakpoints.up("sm")]: {maxWidth:"80%"}	}}>
					
					<br />
					{/* <br /> */}
					<LibAutocomplete 
						control={control}
						// options={preferences}
						options= {jobTitles}
						rules={textValidation("Job position")}
						
						name={"job_titles"}
						autocompleteProps={{
							multiple: true,
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							placeholder: "Enter your preffered job titles",
							label: "Job position"
						}}
					/>

					<LibAutocomplete 
						control={control}
						options={java_skills}
						name={"Skills"}
						rules={textValidation("Skills")}

						autocompleteProps={{
							multiple: true,
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							placeholder: "Add your skills",
							label: "Skills"
						}}
					/>
					<Grid container columnSpacing={2}>
						<Grid item md={6} xs={12}>
							<LibAutocomplete
								control={control}
								options={locations}
								name={"location"}
								rules={textValidation("Skills")}
								
								autocompleteProps={{
									multiple: true,
									getOptionLabel: option => option,
									freeSolo: true,
									renderTags: (value, getTagProps) =>
									value.map((option, index) => (
								  		<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
									)),
								}}
								textfieldProps={{
									label: "Preffered work location",
									fullWidth: true
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}  >
						<Controller
							name="max_salary"
							control={control}
							// rules={{ required: 'Expected Salary is required' }} // Add the required rule
							// rules={textValidation("Expected Salary")} // Add the required rule
							render={({ field }) => (
							<LibTextField 
								control={control}
								name={"max_salary"}
								rules={textValidation("Expected Salary")}
								textfieldProps={{
									label: "Expected Salary",
									fullWidth: true,
									type: "number",
									InputProps: {startAdornment: <InputAdornment position="start"> <CurrencyRupeeOutlined /> </InputAdornment>, endAdornment: <InputAdornment position="end"> Monthly </InputAdornment> }
								}}
							/>
							)}
						/>
						</Grid>
					</Grid>
					<Stack direction={'column'}>
					<LibRadioGroup control={control} rules={check_box_valid()} label={<h4>Preffered Shift</h4>} name={"Preferred_shift"} >
							<FormControlLabel  value= "Day Shift" control={<Radio />} label="Day Shift" />
							<FormControlLabel  value="Night Shift" control={<Radio />} label="Night Shift" />
							<FormControlLabel  value="Flexible" control={<Radio />} label="Flexible" />
						</LibRadioGroup>

						<LibRadioGroup control={control} rules={check_box_valid()} label={<h4>Work mode</h4>} name={"work_mode"}>
							<FormControlLabel value="Work From Home " control={<Radio />} label="Work from home" />
							<FormControlLabel value="Work From Office" control={<Radio />} label="Work from office" />
							<FormControlLabel value="Hybrid" control={<Radio />} label="Hybrid" />
						</LibRadioGroup>
					</Stack>
				</Box>
			</Paper>
			<Stack marginBottom={theme.spacing(4)} direction="row" columnGap={theme.spacing(2)} marginLeft={"auto"}>
				{/* <Button type="submit" variant="text">Skip for now</Button> */}
				<Button type="submit">Submit</Button>
			</Stack>
		</Box>
	)
}


