import { createTheme, formHelperTextClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
export const theme = createTheme({
	shadows: [
		"none",
		"rgba(150, 150, 150, 0.1) 0 0 20px 0",
		"rgba(0, 0, 0, 0.1) 0 20px 60px -10px",
		"rgba(0, 0, 0, 0.09) 0 6px 9px 0",
		"rgba(0, 0, 0, 0.1) 0 5px 20px 0",
		"rgba(0, 0, 0, 0.12) 0 3px 13px 1px",
		"rgba(0, 0, 0, 0.1) 0 15px 35px 0, rgba(0, 0, 0, 0.07) 0 3px 10px 0",
		"rgba(57, 70, 78, 0.15) 0 0 1px 0, rgba(57, 70, 78, 0.25) 0 20px 55px -8px",
		"rgba(0, 0, 0, 0.25) 0 15px 45px -9px","","","","","","","","","","","","","","","",""
	],
	typography: {
		fontWeightBold: 600,
		allVariants: {
			color: grey[800]
		},
		fontFamily: [
			'"Open Sans"',
			'Roboto',
			'"Helvetica"',
			'Arial',
			'sans-serif',
	  	].join(','),
		body1: {
			fontSize: "0.75rem"
		},
		body2: {
			fontSize: "0.8rem"
		},
		h2: {
			fontSize: "1.5rem"
		},
		h3: {
			fontSize: "1.27rem"
		},
		h4: {
			fontSize: "1rem",
			fontWeight: 800,
		},
		h5: {
			fontSize: "0.75rem"
		},
		button: {
			textTransform: "capitalize"
		}
	},
	components: {
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
					fontSize: "1rem"
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: "outlined",
				color: "primary",
				size: "small",
				required: true,
				margin: "dense",
				helperText: " ",
				autoComplete: "off",
				minRows: 5,
				maxRows: 12,
				multiline: false,
				InputLabelProps: {
					shrink: true
				},
				FormHelperTextProps: {
					classes: {
						root: formHelperTextClasses
					}
				}
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: "small"
			}
		},
		MuiTabs: {
			defaultProps: {
				variant: "scrollable",
				scrollButtons: true,
				allowScrollButtonsMobile: true
			}
		},
		MuiButton: {
			defaultProps: {
				size: 'small',
				variant: "contained"
			}
		},
		MuiModal: {
			defaultProps: {
				keepMounted: false,
			}
		},
		MuiCheckbox: {
			defaultProps: {
				size: "small",
				checked: false,
				style: {
					padding: 4
				},
				color: "primary"
			}
		},
		MuiChip: {
			defaultProps: {
				size: "small",
				variant: "outlined",
				style: {
					color: "GrayText"
				}
			}
		},
		MuiRadio: {
			defaultProps: {
				size: "small",
				disableRipple: true,
				style: {
					padding: 4
				}
			}
		},
		MuiSwitch: {
			defaultProps: {
				disableRipple: true
			}
		},
		MuiFormControl: {
			defaultProps: {
				size: "small",
				required: true
			}
		},
		MuiMenu: {
			defaultProps: {
				anchorPosition: undefined,
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right"
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "right"
				}
			}
		},
		MuiListItemIcon: {
			defaultProps: {
				style: {
					scale: "0.8",
					minWidth: 40
				}
			}
		},
		MuiListItem: {
			defaultProps: {
				disablePadding: true
			}
		},
		MuiListItemButton: {
			defaultProps: {
				disablePadding: true
			}
		},
		MuiMenuItem: {
			defaultProps: {
				style: {
					paddingBlock: 4
				}
			}
		},
		MuiDrawer: {
			defaultProps: {
				variant: "permanent",
				anchor: "left"
			}
		},
		MuiTableCell: {
			defaultProps: {
				padding: "normal",
				size: "small"
			},
			styleOverrides: {
				root: {
					maxWidth: "250px",
					whiteSpace: "nowrap"
				}
			}
		},
		MuiAlert: {
			defaultProps: {
				style: {
					width: "fit-content",
					marginInline: "auto",
					marginTop: 16
				},
				elevation: 5
			}
		}
	}
})