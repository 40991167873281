import { Fragment, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react"
import { FullScreenContext } from "../../divine-constants"
import { Box, Container, Grid, Paper, CircularProgress, Typography ,Stack, Chip, ListItem, List, ListItemIcon, ListItemText, Tabs, Tab, IconButton, Tooltip, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Slide, InputAdornment, Alert, useMediaQuery, TextField , Checkbox,  FormControlLabel ,FormControl, RadioGroup, Autocomplete} from '@mui/material';
import {   MenuItem , FormGroup,  ToggleButton, ToggleButtonGroup, FormHelperText, FormLabel, Select, InputLabel } from "@mui/material";
import { CallOutlined, EmailOutlined, LocationOnOutlined  } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import { LibAutocomplete, LibTextField, TabPanel } from "../../components/LibComponents";
import { useForm } from "react-hook-form";
import WorkExperience from "./WorkExperienceSection";
import Education from "./EducationSection";
import Projects from "./ProjectsSection";
import { GetAllCountry, GetAllState, GetAllStatecity, add_profile_summary, addprofile, addresume, audiodelete, edit_audio, edit_details, get_profile, resume_delete } from "../Axiosfetch/Axiosfetch";
import { emailValidation, mobileValidation, textValidation } from "../../utils";
import EditPreference from "./EditPreference";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import edit from "../../images/edit.png";
import file from "../../images/file.png";
import cross from "../../images/cross.png";
import email from "../../images/email.png";
import location from "../../images/location.png";
import grad from "../../images/grad.png";
import phone from "../../images/phone.png";
import job from "../../images/job.png";
import audio from "../../images/audio.png";
import { AlertToast,alertState } from "../../divine-constants";
import PersonalDetails from "./PersonalDetails";
import Certifications from "./Certifications";
import Certification from "./Certifications";
import user from "../../images/user.png"
import WorkExperiences from "./WorkExperienceSection";
import { useNavigate } from "react-router-dom";
import Noticepopup from "../../Noticepopup";



function TabIconLabel({icon, label}) {
	return (
		<Stack>
			{icon}
			<Typography variant="caption">
				{label}
			</Typography>
		</Stack>
	)
}


const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
  });

export default function UserProfile() {
	const navigate = useNavigate();
	const [imageSrc,setImageSrc] = useState();
	const [fullWidth, setFullWidth] = useState(true);
	const matches = useMediaQuery('(min-width:600px)');

  const [maxWidth, setMaxWidth] = useState('sm');
	const {setIsFullScreen} = useContext(FullScreenContext);
	const profileRadius = useRef(120);
	const skills = useMemo(() => ["UI/UX", "Responsive design", "TypeScript", "JavaScript", "Angular", "ReactJS", "Adobe XD", "Sass"], []);
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState(0);
	const [jobs, setJobs] = useState([]);
	const [open1, setOpen1] = useState(false);
	const [dialog, setDialog] = useState(false);
	const [chummatry, setTrychumma] = useState(false);
	const [filename, setFilename] = useState('');
	const [name, setName] = useState('');
	const [resumename, setResumename] = useState('');
	const { setAlert } = useContext(AlertToast);
	const [audioFile, setAudioFile] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const token = localStorage.getItem('accesstoken')
	const [loading,setLoading] = useState(false)
	const [loading1,setLoading1] = useState(false)
	const [isnotice,setIsnotice] = useState(true)

	


	const {
		watch,
		control,
		handleSubmit,
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			Skills: [],
			candidate_percentage :  0,
			profile_pic : "",
			resumeName : "",
			candidates_qualification : {
			candidates_qualifications : []
			},
			profile_summary : "",

			personalDetails: {
				resume: 'file',
				audioFile: '',
				audioFile_name: '',

				// highest_qualification: 'Postgraduate',
				// languages: [{name: "English", speak: true, write: true, read: true}, {name: "Tamil", speak: true, write: true, read: true}],
				firstName: '',
				middleName: '',
				lastName: '',
				password: '',
				email: '',
				mobile: '',
				Date_of_birth : "",			
				Gender : "" ,
				Have_you_taken_career_break : "",
				district : "",
				Permanent_address : "",
				Home_town : "",
				Languages : [],
			},

			Certifications : {
				Certification_name: "",
                Certificate_Provider: "",
                certification_url: "",
                certification_id: "",
                start_date: "",
                end_date: "",
			},
	


			workExperience: {
				isEmployed: false,
				recent_company: '',
				recent_salary: '',
				dates: {
					joinDate: '2019-12-23',
					leavingDate: '2022-04-08'
				},
				notice_period: '',
			},

			projectdetails : {
				project_title: '',
				Details_of_project: '',
				Skills_used: [],
				Worked_from: '',
				Worked_till: '',
				link: '',
				Client: '',
			},
			user_prefers: [{
				id : "",
				Preferred_job_titles: [],
				Employment_type: '',
				Currently_looking: "",
				work_mode: '',
				location: [],
				Preferred_shift: '',
				mini_salary: '',
				max_salary: '',
				
			}],
		}
	});

	useEffect( () => {

		if ( !localStorage.getItem('accesstoken')) {
		navigate("/jobs");
		}

	},[])

	const fetchProfileData = async () => {

		try {
			const response = await get_profile(); // Call your API function to fetch profile data
			if (response.status === 200) {

				setIsnotice(response.data.notice_period)
				
				// Set the fetched data as default values for the form fields
				setValue('personalDetails', response.data);
				

				setValue('workExperience', response.data.work_experience);
				setValue('projectdetails', response.data.candidate_projects);
				setValue("Skills",response.data.key_skills)
				setValue("candidate_percentage",response.data.candidate_percentage)
				setValue("candidates_qualification.candidates_qualifications",response.data.candidates_qualification)
				setValue("Certifications",response.data.certification)
				setValue("profile_summary",response.data.profile_summary)

				setValue("profile_pic",response.data.profile_pic)
				
				setValue("resumeName",response.data.resumename)
				setValue("personalDetails.audioFile_name",response.data.audioFile_name)
				setResumename(response.data.resumename)

				
				setJobs(response.data);
				if (response.data.user_prefer.length !== 0) {
					setValue('user_prefers', response.data.user_prefer);
				}
				profileImage()
			
			} else {
				console.error('Invalid data structure:', response);
			}
		} catch (error) {
			console.error('Error fetching profile data:', error);
		}
	};


	


	useEffect(() => {
		fetchProfileData();
		profileImage() // Call the function to fetch profile data
	}, [getValues])

			
    const onSubmit = (data) => {
        // Handle form submission
    };
		
	const handleTabsChange = (_, newValue) => {
		setActiveTab(newValue);

	};
	useEffect(() => {
		setIsFullScreen(false);
	}, []);
	

	const fileInputRef = useRef()
	const handleImageClick = () => {
		fileInputRef.current.click();
	  };


  const handleFileChange = async(event)  => {

	const selectedFile = event.target.files[0];
	setName(selectedFile.name)


	const pic = {"photos" : selectedFile }
	setFilename(pic)
};

  const resumedelete = async(event)  => {


	const response = await resume_delete();
	if (response.status === 200){
		fetchProfileData();
		
		setAlert(alertState({
			message: "successfully delete",
			severity: "success",
			show: true
			  }));
		
			}
  	}

	const [audioFiles, setAudioFiles] = useState(null);
	const [audioFile_name, SetAudioFile_name] = useState(null);

  const handleFileChanges = async(e)  => {

	setAudioFiles(e.target.files[0]);
	const filenames = e.target.files[0]
	console.log(filenames.name,"audioFileaudioFile")
	SetAudioFile_name(filenames.name)

  }

  const handleUploadaudio = async(e) => {

	// const formData = new FormData();
	const audio = {"audio" : audioFiles , "audioname" : audioFile_name }

    // formData.append({'audio': audioFiles , "audioFile_name" : audioFile_name});

	if (audio.audio) {
		const response = await edit_audio(audio);

		if (response.status === 200){
			fetchProfileData();
			
			setAlert(alertState({
				message: "successfully Added",
				severity: "success",
				show: true
				  }));
			
				}

	}else {

		console.log("erorr")
		setAlert(alertState({
			message: "Select Audio File",
			severity: "error",
			show: true
			  }));
		
			}

	}



	
	

  const profileImage = () => {
	const imageSource = getValues("profile_pic");
	console.log("testing 23",imageSource)
	setImageSrc(imageSource)
  }


  const handleupdate = async() => {
	setLoading1(true)
	try {
	const response = await addprofile(filename);
	if (response.status === 200){
		fetchProfileData();
        setLoading1(false)
		setDialog(false)
		setAlert(alertState({
			message: "successfully Added",
			severity: "success",
			show: true
			  }));
		// window.location.reload()
		
	} else {
		throw new Error('Upload your profile');
		
	}
	} catch (error) {
		// console.log(error)
		setAlert(alertState({
			message: error.message,
			severity: "error",
			show: true
		}));
	}
}

  const handleupdates = () => {

	setDialog(false)

}



  const handleresumeChange = async(event) => {
	setLoading(true)
	const selectedFile = event.target.files[0];
	const resume = {"resume" : selectedFile , "resumename" : selectedFile.name }

	

	const response = await addresume(resume);
	if (response.status === 200){
		setLoading(false)
		setResumename(selectedFile.name)
		fetchProfileData();
		setAlert(alertState({

			message: "successfully Added",
			severity: "success",
			show: true
			  }));
		setDialog(false)
		
	}
  };

  const handleClickOpen = (data) => {
	if(data === "edit"){
		setOpen1(true);
	}
	else if (data === "profile"){
		setDialog(true);
	}
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
	setDialogOpen(true)
    
  };

  const dialogClose = () => {
	setDialogOpen(false);
}


  const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
  });

  const [open, setOpen] = useState(false);
	
  
	const handleOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

  
	return (
	
		
		<Container onSubmit={handleSubmit(onSubmit)} sx={{px: {md: 12, sm: 6}}}>
			<EducationDialogsss
					dialogClose={dialogClose}
					dialogOpen={dialogOpen}
					control ={control}
					fetchProfileData = {fetchProfileData}
					data ={jobs}
					// handleSubmit={handleSubmit}
				
				/>

			<CheckBoxDialog 
			open = {open}
			handleClose = {handleClose}
			jobs = {jobs}
			fetchProfileData= {fetchProfileData}

			 />
			
			

			{jobs && (
				<Grid container spacing={{xs: 2, md: 3}} py={{xs: 2, md: 4}}>
					<Grid item xs={12} sm={4} md={3}>
						<Paper p={2} component={Stack} direction="column" alignItems={"center"} spacing={2} height={"100%"}>
							<Typography fontSize={18} textAlign={"center"} color={"black"}>{getValues("candidate_percentage")} %</Typography>

							{/* <Box
								sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								}}
							> */}
								<CircularWithLabel

								value={getValues("candidate_percentage")}

								img={imageSrc}

								percentage={getValues("candidate_percentage")}

								handleClickOpen={handleClickOpen}								
								/>

								{getValues("profile_pic") ? 
										<Dialog
										fullWidth={fullWidth}
										maxWidth={maxWidth}
										open={dialog}
										TransitionComponent={Transition}
										keepMounted
										aria-describedby="alert-dialog-slide-description"
									  >
		
										<DialogTitle>{"Profile Photo"}</DialogTitle>
									{loading1 ?

										<Box sx={{width : "100%",display : "flex", alignItems : "center", justifyContent : "center"}}>
											<lottie-player src="https://lottie.host/098484a6-7191-4a4d-a8d5-8efb82a8b780/1sOopI0mjW.json" background="##FFFFFF" speed="1" loop  autoplay direction="1" mode="normal" style={{width : 150 , height :100}} ></lottie-player>
										</Box> : <>

										<Box sx={{
											display: 'flex',
											flexDirection: 'column',
											m: 'auto',
											width: 'fit-content',
											}}>

											<DialogContent sx={{textAlign:"center"}}>
											<Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
											Upload file
											<VisuallyHiddenInput type="file"   style={{ display: 'none' }} onChange={handleFileChange}  accept=".jpg,.jpeg,.png"  />
											</Button>
										</DialogContent>

										{filename ? <DialogContentText >File name: {name} Supported file format: png, jpg, jpeg - upto 2MB</DialogContentText>:<DialogContentText >Supported file format: png, jpg, jpeg - upto 2MB</DialogContentText>}
										
										</Box>
										</>
								}
									
										
										<DialogActions>
											{/* {loading1 ?  <Button onClick={() => { handleupdate(setValue)}}  >Upload</Button> :  <Button onClick={() => { handleupdate(setValue)}} disabled >Upload</Button> } */}
										  <Button onClick={() => { handleupdate(setValue)}}  >Upload</Button>
										  <Button onClick={handleupdates} >Cancel</Button>
										</DialogActions>
								
									
									  </Dialog> : <Dialog
										fullWidth={fullWidth}
										maxWidth={maxWidth}
										open={dialog}
										TransitionComponent={Transition}
										keepMounted
										aria-describedby="alert-dialog-slide-description">
										<DialogTitle>{"Profile Photo"}</DialogTitle>
									


										<Box sx={{
											display: 'flex',
											flexDirection: 'column',
											m: 'auto',
											width: 'fit-content',
											}}>
											<DialogContent sx={{textAlign:"center"}}>
											<Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
											Upload file
											<VisuallyHiddenInput type="file"  style={{ display: 'none' }} onChange={handleFileChange}  accept=".jpg, .jpeg, .png" />
											</Button>
										</DialogContent>
								
									
						
										<DialogContentText > File name: {name} Supported file format: png, jpg, jpeg - upto 2MB</DialogContentText>
										</Box>
										<DialogActions>
										  <Button onClick={() => {handleupdate(setValue)}}>Upload</Button>
										  <Button  onClick={handleupdates}>Cancel</Button>
										</DialogActions>
									  </Dialog> }


								<Box>
									{jobs.middleName ? <Typography textAlign={"center"} sx={{textTransform:"capitalize"}} variant="h4">{jobs.firstName} {jobs.middleName} {jobs.lastName} </Typography> 
								:<Typography textAlign={"center"} sx={{textTransform:"capitalize"}} variant="h4">{jobs.firstName}.{jobs.lastName}</Typography>}
									{/* <Typography textAlign={"center"} variant="caption">{jobs.user_prefer[0].Preferred_job_titles[0][0]}</Typography> */}
								</Box>

					
						</Paper>
					</Grid>	

					<Grid item xs={12} sm={8} md={9}>
						<Paper p={2} component={Stack} direction="column" spacing={2} height={"100%"} position={"relative"}>

							<Tooltip title="Edit this section">
								{/* <IconButton onClick={()=>{handleClickOpen("edit");}} sx={{ */}
								<IconButton onClick={handleOpen} sx={{
									position: "absolute",
									top: theme.spacing(1), right: theme.spacing(1)}}>
									 <img style={{width:15, height:15}} src={edit}/>
								</IconButton>
							</Tooltip>

							<List>
								<ListItem>
									<ListItemIcon>
										<img style={{width:18,height:18}} src={email}/>
									</ListItemIcon>
									{jobs.email ? <ListItemText>{jobs.email}</ListItemText> : <ListItemText>No Email specified</ListItemText> }
								</ListItem>
								
								<ListItem>
									<ListItemIcon>
									<img style={{width:18,height:18}} src={phone}/>
									</ListItemIcon>
									{jobs.mobile ? <ListItemText>{jobs.mobile}</ListItemText> : <ListItemText>No mobile specified</ListItemText> }
								</ListItem>

								<ListItem>
									<ListItemIcon>
									<img style={{width:18,height:18}} src={location}/>
									</ListItemIcon>
									{jobs.city? (<ListItemText>{jobs.city}</ListItemText>
									) : (
										<ListItemText>No location specified</ListItemText>
									)}
									
								</ListItem>

								{/* <ListItem>
									<ListItemIcon>
									<img style={{width:18,height:18}} src={grad}/>
									</ListItemIcon>
									{jobs.experience_status === "true1" ? (<ListItemText> Fresher </ListItemText>
									)  : jobs.month_of_expriance > 0 || jobs.year_of_expriance > 0 ? (<ListItemText> Experience </ListItemText>)  : (
										<ListItemText>No location specified</ListItemText>
									)}
									
								</ListItem> */}

								{/* {jobs.experience_status === "true1" || getValues("workExperience.recent_company","") ? (<ListItem> */}
								{ jobs.experience_status === "Fresher" ? (<ListItem>

									<ListItemIcon>
									<img style={{width:18,height:18}} src={grad}/>
									</ListItemIcon>
									<ListItemText> Fresher </ListItemText>
									
								</ListItem>) : jobs.experience_status === "Experience" || jobs.month_of_expriance > 0 || jobs.year_of_expriance > 0 ? (
								<ListItem>
									<ListItemIcon>
											<img style={{width:18,height:18}} src={job}/>
									</ListItemIcon>
									
									 <ListItemText> Experience </ListItemText>
										
								
									
								</ListItem>) : 
								<ListItem>
									<ListItemIcon>
									<img style={{width:18,height:18}} src={grad}/>
									</ListItemIcon>
							
									<ListItemText>No specified</ListItemText>
								
								</ListItem> }

							</List>
							<section>
								<Typography fontSize={15} color={"black"}  mb={1}>Skills</Typography>
								<Stack direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={1} rowGap={.5}>
									{Array.isArray(getValues("Skills") ) && getValues("Skills") .length > 0 ? (
										getValues("Skills") .map((skill, index) => (
											<Fragment key={index}>
											<Chip label={skill} key={index} />
											{index !== getValues("Skills") .length - 1 && (
												<Box width={4} height={4} bgcolor={"text.disabled"} borderRadius={10}></Box>
											)}
											</Fragment>
										))
										) : (
										<Typography>No skills specified</Typography>
									)}
								</Stack>
							</section>
							<section>
								<Typography fontSize={15} color={"black"}  mb={1}>Self Intro</Typography>
								
								{getValues("personalDetails.audioFile") ? 
									<Stack direction="row" spacing={2} >
										{/* <img style={{width:22, height:22}} src={audio}/>
										<Typography fontSize={16}> {getValues("personalDetails.audioFile_name")} </Typography>
										<Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
										<IconButton onClick={handleaudiodelete}>
											<img style={{width:14, height:14}} src={cross}/>
										</IconButton>
								</Box> */}

								<audio controls>
									<source  src={getValues("personalDetails.audioFile")} />
								</audio>

									</Stack>

								: <Typography>No Audio specified</Typography> }
								
							</section>
						
						</Paper>
					</Grid>

					<Grid item xs={12} sm={12} md={12}>
					<Paper sx={{p:2}} height={"100%"}>
						<Stack direction={'column'} textAlign={'start'} alignItems={"flex-start"} justifyContent={"flex-start"}>

							<Typography fontSize={18}  color={"black"}>Resume<span style={{ color: 'green' }}> 20%</span></Typography>

							{getValues("resumeName") ? 
								<Stack sx={{mt:2}} direction={'row'} spacing={2} alignItems="center">
								<img style={{width:22, height:22}} src={file}/>
								<Typography fontSize={16}> {getValues("resumeName")} </Typography>
								<Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
								<IconButton color="red" onClick={resumedelete}>
									<img style={{  width:14, height:14}} src={cross}/>
								</IconButton>
								</Box>
								</Stack>:


							<Typography fontSize={13} variant="caption">
									This is the resume that an employer will see when you apply for a job or internship. Make sure it is current at all times.
								</Typography>
								
								}
							
							{loading ?
					<Box sx={{width : "100%",display : "flex", alignItems : "center", justifyContent : "center"}}>

{/* <CircularProgress /> */}

<lottie-player src="https://lottie.host/098484a6-7191-4a4d-a8d5-8efb82a8b780/1sOopI0mjW.json" background="##FFFFFF" speed="1" loop  autoplay direction="1" mode="normal" style={{width : 150 , height :100}} ></lottie-player>

		  {/* <lottie-player src="https://lottie.host/992bd503-aaa0-40e2-ac55-1ddfe95cbc47/zCjjDTPV5f.json" background="##FFFFFF" speed="1" loop  autoplay direction="1" mode="normal" style={{width : 300 , height :300}}></lottie-player> */}

		</Box> :

							<Alert severity="info" icon={false} variant="outlined">

								<Stack alignContent={'center'} alignItems={'center'} spacing={2}>
									<Button 
									sx={{width:'150px'}} 
									component="label" 
									variant="outlined" 
									startIcon={<CloudUploadIcon />}>
										{resumename? 'Upload file' :'Update file'}
										<VisuallyHiddenInput type="file" style={{ display: 'none' }} onChange={handleresumeChange} accept=".docx,.pdf" />
									</Button>
								<Typography fontSize={13}  color={"black"}>File size limit: 2MB. File type: DOCX, PDF, DOC</Typography>
								</Stack>
							</Alert>	
}

						</Stack>		
						</Paper>
					</Grid> 
					
					<Grid item xs={12} sm={12} md={12}>
					<Paper sx={{p:2}} height={"100%"}>
						<Stack direction={'column'} textAlign={'start'} alignItems={"flex-start"} justifyContent={"flex-start"}>
							<Stack direction = {"row"} sx={ {width : "100%" }} justifyContent={"space-between"}>
							<Typography fontSize={20}  color={"black"}>Profile summary<span style={{ color: 'green' }}> 20%</span></Typography>
						{/* <Button type="submit" onClick={handleSubmit()} variant="text">Add Profile summary</Button> */}
							<Button   variant="text" onClick={handleClose2}>Add Profile summary</Button>
							</Stack>
							{getValues("profile_summary") ? 

								<Stack sx={{mt:2}} direction={'row'} spacing={2} alignItems="center">
								
								<Typography fontSize={13}> {getValues("profile_summary")} </Typography>
								
								</Stack>:
							<Typography fontSize={13} sx ={{lineHeight : 2}}variant="caption">
								Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.
								</Typography>}
							
							
						</Stack>		
						</Paper>


					</Grid>

					<Grid item xs={12} sm={12} md={12}>
					<Paper sx={{p:2}} height={"100%"}>

						<Box sx={{ gridColumn: "1 / -1", mt: 3, mb: 3 }}>
					
						<Typography fontSize={20}  color={"black"}>Self Intro<span style={{ color: 'green' }}> 20%</span></Typography>

						{jobs.audioFile_name ? (
							<>
						
							<Stack sx={{ mt: 2 }} direction="row" spacing={2} >

								<img style={{ width: 22, height: 22 }} src={file} alt="audio icon" />
								<Typography variant="body1">{jobs.audioFile_name}</Typography>
							</Stack>
							<Stack sx={{ mt: 1 }}  direction="row" spacing={2} >
							<Box 
							
							sx={{  display: 'flex', border : "1px solid #007aff" , padding : 0.5, boxShadow :"rgb(10 10 10 / 10%) 0 0 20px 0" , borderRadius: '5px', cursor: 'pointer' ,
							alignItems: 'center' ,
							[theme.breakpoints.down("sm")]: {
								width : '90%'
							}

						}}
							
							>

								<input type="file"   accept="audio/*"  Value={jobs.audioFile_name} onChange={handleFileChanges}
								 style={{ padding : "8px" ,  border: 'none' , width:"100%"}} />
								<Button onClick={handleUploadaudio} variant="outlined" style={{
									
									 borderTopRightRadius: '5px',   borderBottomRightRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }}>Change Audio</Button>
							</Box>
							
							</Stack>
							</>
						) : (
							<>
						
							<Stack sx={{ mt: 2 }} direction="row" spacing={2} >
							<Box 
							sx={{  display: 'flex', border : "1px solid #007aff" , padding : 0.5, boxShadow :"rgb(10 10 10 / 10%) 0 0 20px 0" , borderRadius: '5px', cursor: 'pointer' ,
							alignItems: 'center' ,
							[theme.breakpoints.down("sm")]: {
								width : '90%'
							}

						}}>

								<input type="file"   accept="audio/*"  Value={jobs.audioFile_name} onChange={handleFileChanges}
								 style={{ padding : "8px" ,  border: 'none' , width:"100%"}} />
								<Button onClick={handleUploadaudio} variant="outlined" style={{
									
									 borderTopRightRadius: '5px',   borderBottomRightRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }}>Upload Audio</Button>
							</Box>
							
							</Stack>
							</>
						)}
						</Box>
						</Paper>


					</Grid>

					<Grid item xs={12}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
							<Tabs value={activeTab} onChange={handleTabsChange}>
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Personal details"} />
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Preferences"} />
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Work experience"} />
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Education"} />
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Projects"} />
								<Tab aria-controls={'simple-tabpanel-' + activeTab} itemID={"simple-tabpanel-" + activeTab} label={"Certfications"} />
							</Tabs>
						</Box>


						<TabPanel index={0} value={activeTab} children={ getValues("personalDetails").id ? 
						 <PersonalDetails setValue={setValue} formData={getValues("personalDetails") } control={control} /> :
						  <PersonalDetails setValue={setValue} formData={getValues("personalDetails") } control={control} /> }/>


						<TabPanel index={1} value={activeTab} children= {  getValues("user_prefers")[0].id && getValues("Skills")  ? 

						 <EditPreference setValue={setValue} formData={getValues("user_prefers")} key_skills={getValues("Skills")}
						 candidate_persen ={getValues("candidate_percentage")} control={control} /> :  

						  <EditPreference setValue={setValue} formData={getValues("user_prefers")} key_skills={getValues("Skills")}
						 candidate_percentage ={getValues("candidate_percentage")} control={control} />  }/>

						<TabPanel index={2} value={activeTab} children={<WorkExperiences setValue={setValue} formData={getValues("workExperience") } control={control} />} />
						<TabPanel index={3} value={activeTab} children={<Education setValue={setValue} fetchProfileDatas = {fetchProfileData} formData={getValues("candidates_qualification.candidates_qualifications") } control={control} />  } />
						<TabPanel index={4} value={activeTab} children={<Projects 
						setValue={setValue} 
						handleSubmit={handleSubmit}
						getValues={getValues}
						formData={getValues("projectdetails")}
						candidate_persen ={getValues("candidate_percentage")}
						control={control} />  } />
						<TabPanel index={5} value={activeTab} children={<Certification setValue={setValue} formData={getValues("Certifications") } control={control} />  } />
						

					</Grid>
				</Grid>
			)}

{ isnotice ?(null):

<Noticepopup />

}
		</Container>


	


	
		)
	
}



function EducationDialogsss({  data ,dialogClose , dialogOpen , fetchProfileData }) {
	const { setAlert } = useContext(AlertToast);

    const [formData, setFormData] = useState({
        summary: '', // Assuming this is the field where project details are entered
    });

	useEffect(() =>{
		setFormData({
			...formData,
			summary: data.profile_summary, // Assuming API response has a property detailsOfProject
		});


	},[data])



    // Function to handle form submission
    const handleSubmit = async () => {

		if (formData.summary.length >= 50) {

			try {
				// Make API call here using fetch or axios
				const response = await add_profile_summary(formData)
	
				if (response.status === 200 ) {
					fetchProfileData()
					setAlert(alertState({
						message: "Profile summary added successfully ",
						severity: "success",
						show: true
					  }));
					  setFormData({
						...formData,
						summary: data.detailsOfProject, // Assuming API response has a property detailsOfProject
					});
					  
					
					 // const data = await response.json();
					 console.log(formData,"formData"); // Log API response for testing
					 // Handle API response accordingly
	
	
				}
	
			   
			} catch (error) {
				console.error('Error:', error);
				// Handle error condition
			}
			dialogClose()

		} else{
			setAlert(alertState({
				message: "Write a Profile summary of more than 50 characters",
				severity: "error",
				show: true
			  }));

		}
        
    };

    // Function to handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Dialog maxWidth={'md'}  open={dialogOpen} onClose={dialogClose}>
            <DialogTitle>
                <Box fontSize={18} color={"black"} display="flex" alignItems="center">
                    Profile summary
                </Box>
                <Typography fontSize={12} sx={{ lineHeight: 2  }} variant="text">
                    Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid component={"form"} noValidate container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="summary"
							value={formData.summary}
							onChange={handleInputChange}
							label="Summary"
							fullWidth
							multiline
							
                        />
                    </Grid>
                </Grid>
            </DialogContent>
		

            <DialogActions>
                <Button onClick={dialogClose} variant="text">Cancel</Button>
                <Button type="submit"  onClick={handleSubmit} variant="text">Add</Button>
            </DialogActions>
			
        </Dialog>
    );
}




function CheckBoxDialog( {open  , handleClose , jobs , fetchProfileData  }  ) {

	const [freshersChecked, setFreshersChecked] = useState(false);
	const [experiencedChecked, setExperiencedChecked] = useState(false);
	const [candidateType, setCandidateType] = useState('');

	
	
	
	const [pre_value, setPre_value] = useState();
	const { setAlert } = useContext(AlertToast);
	const theme = useTheme();
	
	
	const options = ["Immediate Joiner", "1+ Week", "2+ Weeks", "3+ Weeks", "1+ Month", "2+ Months", "3+ Months"];


	const [country, setCountry] = useState([]);
	const [stateCode, setStateCode] = useState();
	const [countryapi, setCountryapi] = useState();
	const [stateapi, setStateapi] = useState();
    const [State, setStates] = useState([]);

    const [citydata, setCities] = useState([]);
    const [citycode, setCityCode] = useState();
    const [error, setError] = useState(false);

	const [value, setValue] = useState(options[0]);
  	const [inputValue, setInputValue] = useState('');
	
    const [stateerror, setStateError] = useState(false);

    const [jobPost, setJobPost] = useState({
        post_country: '',
        post_state: '',
        post_city: ''
    });

	const [formDatas, setFormDatas] = useState({

		firstName : "",
		middleName : "",
		lastName : "",
		year_of_expriance : 0,
		month_of_expriance : 0,
		country : "",
		state :"",
		city : "",
		experience_status:"",
		notice:"",
		
	});

	

	const [isChecked, setIsChecked] = useState(false);
	const matches = useMediaQuery('(min-width:600px)');
	console.log("CheckBoxDialog",jobs)

	

	useEffect(() => {
    
        
		if (jobs) {
			setFormDatas({
				id : jobs.id || '',
				firstName: jobs.firstName || '',
				middleName: jobs.middleName || '',
				lastName: jobs.lastName || '',
				notice : jobs.notice_period || '',
				year_of_expriance: jobs.year_of_expriance || '',
				month_of_expriance: jobs.month_of_expriance || [],
				experience_status: jobs.experience_status || '',
				
			});
		fetchCountries(jobs.country);
		setCountryapi(jobs.country)
		setStateapi(jobs.state)
		}

    }, [jobs]);

	



    useEffect(() => {
        // Fetch countries
		if (stateCode) {
			fetchStates(stateCode)
		}
		

    }, [stateCode]);

    useEffect(() => {
        // Fetch countries
		if (stateCode && citycode) {
			fetchCities(stateCode , citycode)
		}
		


    }, [citycode]);

	console.log(jobPost,"postt")


    const fetchCountries = async() => {
		try {
			const { status , data  } = await GetAllCountry();
			console.log(data,"datadatadata")	
			if (status === 200) {
				setCountry(data)
				const filteredData = data.filter(item => item.name === jobs.country);
				setJobPost({...jobPost,post_country: filteredData[0]?.name});
				setStateCode(filteredData[0]?.iso2)
				

		
					
			}else if (status === 403) { 
		
			} else {
				throw new Error(`Something went wrong ${data.message}`);
			}
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
	}

    const fetchStates = async(country) => {

		console.log(country,"countrycountry")
		try {
			const { status , data  } = await GetAllState(country);
			console.log(data,"datadatadata")	
			if (status === 200) {
				setStates(data);
				const filteredData = data.filter(item => item.name === jobs.state);
				setJobPost({...jobPost,post_state: filteredData[0]?.name});
				setCityCode(filteredData[0]?.iso2)

				// setValue('state', jobPost.post_state);
				
				
			}else {
				throw new Error(`Something went wrong 1 ${data.message}`);
			}
			
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
	}


    const fetchCities = async(state , city) => {
        
		try {
			const { status , data  } = await GetAllStatecity(state , city);
			console.log(data,"datadatadata")	
			if (status === 200) {
				setCities(data);
				const filteredData = data.filter(item => item.name === jobs.city);
				setJobPost({...jobPost,post_city: filteredData[0]?.name});
				
				// setValue('city', jobPost.post_city);
				
			}else {
				throw new Error(`Something went wrong ${data.message}`);
			}
			
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
    };

	
    const handleInputChange = (e) => {
        const { name, value } = e.target;
		
		console.log(pre_value,"pre_value")
		if (name == "year_of_expriance" && value < 1 && value ){
			setPre_value(value)
			

		}else if(name == "month_of_expriance" && value < 1 && value && pre_value < 1  ) {

			setAlert(alertState({
				message: "Experience should be at least one.",
				severity: "error",
				show: true
				}));

		}

	
	
        setFormDatas({
            ...formDatas,
            [name]: value
        });
    };


	const handleSubmit = async () => {
		

		const formData = {
			"firstName" : formDatas.firstName,
			"middleName" : formDatas.middleName,
			"lastName" : formDatas.lastName,
			"notice_period" : formDatas.notice,
			"year_of_expriance" : formDatas.year_of_expriance,
			"month_of_expriance" : formDatas.month_of_expriance,
			"country": jobPost.post_country,
			"state" : jobPost.post_state,
			"city" : jobPost.post_city,
			"experience_status" : formDatas.experience_status,
			};
		
		//   console.log("payload ",formData )

		
		try {
			if (formDatas.experience_status == "Experience" &&  formDatas.year_of_expriance < 1  && formDatas.month_of_expriance < 1 ){
				setAlert(alertState({
					message: "Experience should be at least one.",
					severity: "error",
					show: true
					}));

			}else{
			const profileResponse = await edit_details(formData);
			if (profileResponse.status === 200) {
				await fetchProfileData()
		
				setAlert({
					message: "Added successfully",
					severity: "success",
					show: true
				});
				handleClose()
			}
		}
			
				
		} catch (error) {
			setAlert({
				message: error.message,
				severity: "error",
				show: true
			});
		}
		  
	  };



	

	const handlechange = (event) => {
		
		setFormDatas({...formDatas,experience_status : event.target.value})
		
	  };

	const handlechange2 = (event) => {
		console.log(event,'event.target.value')
		
		setFormDatas({...formDatas,notice : event.target.value})
		
	  };

	
	
  
	return (
		<>
	  <div>
		{/* <Button onClick={handleOpen} variant="contained">Open Dialog</Button> */}
		<Dialog maxWidth={"sm"} fullWidth={true}  open={open} onClose={handleClose}>
		  <DialogTitle>Profile Edit</DialogTitle>
	
		  <DialogContent>

		

		  <form onSubmit={handleSubmit}  > 
		
		
		 <Box display="grid" gridTemplateColumns="1fr" marginInline="auto" width="100%" gridColumn="1 / -1"
                  gap={0 + ' ' + theme.spacing(3)} sx={{ 
                    
                      [theme.breakpoints.up("sm")]: {
                          gridTemplateColumns: "repeat(2, 1fr)",
                      } , mt:3 

                  }}>

			<TextField
					name="firstName"
					value={formDatas.firstName}
					onChange={handleInputChange}
					label="First Name"
					fullWidth
				/>

				<TextField
					name="middleName"
					value={formDatas.middleName}
					onChange={handleInputChange}
					label="Middle Name"
					fullWidth
					required = {false}
				/>

				<TextField
					name="lastName"
					value={formDatas.lastName}
					onChange={handleInputChange}
					label="Last Name"
					fullWidth
					
				/>


				<Autocomplete
				name="notice"
				value={formDatas.notice}
			
				inputValue={formDatas.notice}
				onInputChange={(event, newInputValue) => {
				//   setInputValue(newInputValue);
				  setFormDatas({...formDatas, notice: newInputValue})

				}}
				id="controllable-states-demo"
				options={options}
				sx={{ width: 300 }}
				renderInput={(params) => <TextField {...params} label="Notice Period" />}

						
					/>



{/* 
				<Autocomplete

					value={formDatas.}

					onChange={(event, newValue) => {

					setValue(newValue);

					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					}}
					id="controllable-states-demo"
					options={options}
					sx={{ width: 300 }}
					renderInput={(params) => <TextField {...params} label="Controllable" />}
					/> */}

				{/* <Box> </Box> */}
				

			
			<FormControl >
				{/* <FormLabel id="demo-radio-buttons-group-label">Total E</FormLabel> */}
				<RadioGroup
					row
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="Fresher"
					name="radio-buttons-group"
					value={formDatas.experience_status}  // Set the value prop to control the selected option
        			onChange={handlechange}
					// onChange={handlechange}
				>

					<FormControlLabel   value="Experience"  control={<Radio />} label="Experience" />
					<FormControlLabel  value="Fresher"   control={<Radio />} label="Fresher" />
				
				</RadioGroup>
				</FormControl>
				{/* <Box></Box> */}

			{formDatas.experience_status === "Experience" ?
			
			<>
			{/* <h4 >Total Work Experience</h4> */}
			  <Box> </Box>

				<Box display="flex" sx={{mt:2}}>
				<h4 >Total Work Experience</h4>
				</Box>  
				 <Box> </Box>

				<Box sx={{mt:2}}> 

				<TextField
					name="year_of_expriance"
					value={formDatas.year_of_expriance}
					onChange={handleInputChange}
					label="Year Of Experience"
					fullWidth
					type ="number"
				/>
				</Box>
	
		
		<Box sx={{mt:2}}> 
				<TextField
					name="month_of_expriance"
					value={formDatas.month_of_expriance}
					onChange={handleInputChange}
					label="Month Of Experience"
					fullWidth
					type ="number"
				/>
			</Box>
			<Box></Box>	
		</>
		
		:null}
		
							
		<Box></Box>
		<Box sx={{mt:3}}>
		  <FormControl fullWidth >

			<InputLabel id="demo-simple-select-label">
				Country
			</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				label="Country"
				size="small"
			//   MenuProps={MenuProps}
				error={error}
				value={jobPost.post_country}
				
				name="post_country"
			//   onChange={handleCountryChange}
				onChange={(event, newValue) =>{
				setJobPost({
					...jobPost,
					post_country: event.target.value,
				});
				event.target.value === "" ?setError(true):setError(false);
				
				}}
			//   required
			>
				<MenuItem value={""}>Select</MenuItem>
				{country.map((e,i)=>{
				return (
				<MenuItem onClick={()=>setStateCode(e.iso2)} key={i} value={e.name}>{e.name}</MenuItem>
					)
				})
				}
			</Select>
	
			</FormControl>
			</Box>
			<Box sx={{mt:3}}>

		
				<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">
				State
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					label="State"
					size="small"
				//   MenuProps={MenuProps}
					rules={textValidation("Gender")}
					error={stateerror}
					value={jobPost.post_state}
					name="post_state"
			
					onChange={(event, newValue) =>{
					setJobPost({
						...jobPost,
						post_state: event.target.value,
					});
					event.target.value === "" ?setStateError(true):setStateError(false)
					}
					
					}
					required
				>
					<MenuItem value={""}>Select</MenuItem>
					{State?.map((e,i)=>{
						return (
					<MenuItem  onClick={() => setCityCode(e.iso2)} key={i} value={e.name}>{e.name}</MenuItem>
						)
					})
					}
				</Select>
				{/* <FormHelperText>Please provide valid state</FormHelperText> */}
				</FormControl>
				</Box>

						{matches ?
						   <Box sx={{mt:3}} >

						   {jobPost?.post_state?.length >0 &&
						   
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							City
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="City"
							  size="small"
							  rules={textValidation("Gender")}
							//   MenuProps={MenuProps}
							  error={stateerror}
							  value={jobPost.post_city}
							  name="post_city"
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_city: event.target.value,
								});
							  }}
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {
								citydata.map((e,i)=>{
								  return (

							  <MenuItem key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid City</FormHelperText> */}
						  </FormControl>}

						  </Box>:
						<Box sx={{mt:3}} >
						   {jobPost?.post_state?.length >0 &&
						   
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							City
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="City"
							  size="small"
							  rules={textValidation("Gender")}
							//   MenuProps={MenuProps}
							  error={stateerror}
							  value={jobPost.post_city}
							  name="post_city"
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_city: event.target.value,
								});
							  }}
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {
								citydata.map((e,i)=>{
								  return (

							  <MenuItem key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid City</FormHelperText> */}
						  </FormControl>}

						  </Box>}
		
			</Box>

			<DialogActions>
		
		<Button onClick={handleClose} color="primary">Cancel</Button>
		{/* <Button onClick={handleSubmit} color="primary">Submit</Button>
	  </DialogActions> */}
		<Button type="submit" color="primary" >Update</Button>
	</DialogActions>
</form>


        </DialogContent>
		
		
		</Dialog>
	  </div>




</>
	);
  }
  











function CircularWithLabel(props) {
	console.log(props.img , " props.img")



	const [imgSrc,setImgSrc] = useState()

	useEffect(()=>{
		setImgSrc(props.img)
	},[props])

	return (
	  <Box sx={{ position: "relative", display: "inline-flex" }}>
		<CircularProgress
		  variant="determinate"
		  size={160}
		  thickness={2}
		  {...props}
		/>
		{/* <Box sx={{display: "flex" , justifyContent: "center",}} > */}


		<Box
		onClick={()=>{props.handleClickOpen("profile")}}
		  sx={{
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: "absolute",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			// flexDirection :"column"

		  }}
		>

		  <img
			src={imgSrc ? imgSrc : "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0=" }
			loading="lazy"
			alt="Profile"
			style={{
			  borderRadius: "50%",
			  width: "92%",
			  height: "92%",
			}}
			
		  />
		</Box>
		
		{/* {props.jobs.middleName ? <Typography textAlign={"center"} variant="h4">{props.jobs.firstName} {props.jobs.middleName} {props.jobs.lastName} </Typography> 
									:<Typography textAlign={"center"} variant="h4">{props.jobs.firstName}{props.jobs.lastName}</Typography>} */}

		{/* </Box> */}


		{props.type !== "resumesearch" && (
		  <Box
			sx={{
			  top: 180,
			  left: 0,
			  bottom: 0,
			  right: 0,
			  position: "absolute",
			  display: "flex",
			  alignItems: "center",
			  justifyContent: "center",
			}}
		  >
			{/* <Button variant="contained" size="small" disableElevation>
			  <Typography variant="caption" component="div">
				{`${Math.round(props.percentage)}% Matching`}
			  </Typography>
			</Button> */}

		  </Box>
		)}
	  </Box>


 


	

	);

  }