import styled from "@emotion/styled";
import { createContext } from "react";
import { Link as ReactAnchor } from "react-router-dom";

export const AlertToast = createContext();

export function alertState({ title = "", message = "", severity = "", show = false }) {
	return { title, message, severity, show };
  }

  export function modalReducer(state, action) {
	switch (action.type) {
	  case "view": return { ...state, view: action.to === "open" };
	  default: return { ...Object.keys(state).map(key => ({ [key]: false, to: "close" })) };
	}
  }

  
export const links = [
	{name: "Link 1", path: "/"},
	{name: "Link 1", path: "/"},
	{name: "Link 1", path: "/"},
	{name: "Link 1", path: "/"},
]

export const Link = styled(ReactAnchor)(({theme}) => ({
	color: theme.typography.body1.color,
	textDecoration: "none"
}));

export const FullScreenContext = createContext({
	// isFullScreen: false,
	// setIsFullScreen: () => null
});

export const TokenContext = createContext();

export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
