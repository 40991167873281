import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Typography, CardActions, CardMedia } from '@mui/material';
import { resend } from './Axiosfetch/Axiosfetch';
import FormDialog from './OTPverify';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


function PopupExample({open,onClose}) {
    // const [open, setOpen] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
        };

    const onSubmit = async() => {
        // onClose()
        setLoading(true)
        try {
          const { status , data  } =  await resend();
          if (status === 201) {
            // localStorage.setItem("accesstoken",data.token)
            setShowSuccessPopup(true)
            
                        
            // setTimeout(() => {
                
            //     console.log("setTimeout setShowSuccessPopup")
            // }, 2000)
          
          }else {
            // throw new Error(`Something went wrong ${status}`);
            
    
          }
        } catch (error) {
          console.error("Error:", error);
  
        }finally{
            setLoading(false);
          }
    };
    return (
        <div >
            <Dialog open={open} onClose={onClose}>
            <Card sx={{ maxWidth: 345 ,margin: "auto"}}>
            {/* <CardMedia
                sx={{ height: 100 , maxWidth: 100,justifyContent: "center"}}
                image={email1}
                title="green iguana"
            /> */}
                <CardContent>
                    <Typography gutterBottom variant="h3" component="div">
                    Email Already Registered
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    This email address is already registered, but the verification stage has not been completed. If you wish to complete it.
                    </Typography>
                </CardContent>
                <CardActions sx={{marginLeft:10}}>
                    
          <LoadingButton
      
      onClick={onSubmit}
   
      loading={loading}
      type="submit" 
      loadingPosition="start"
      variant="contained"
      color="primary"

      // startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
      startIcon={loading ? <SaveIcon size={20}  /> : null}
    >
     Send OTP
    </LoadingButton> 

                    {/* <Button  onClick={onSubmit} size="small">Send OTP</Button> */}
                    <Button  onClick={onClose} size="small">Close</Button>
                </CardActions>
                </Card>
            </Dialog>
            {showSuccessPopup && <FormDialog onClose={closeSuccessPopup} open={showSuccessPopup}/>}
        </div> 
    );
}

export default PopupExample;
