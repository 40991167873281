import {
  Button,
  Stack,
  InputBase,
  Paper,
  Divider,
  Grid,
  Typography,
  IconButton,
  Box,
  Autocomplete,
  TextField,

} from "@mui/material";
import LazyLoad from "react-lazyload";

import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useMediaQuery from "@mui/material/useMediaQuery";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useTheme } from "@emotion/react";
import { useContext, useEffect, useRef, useState } from "react";

import Location from "../src/images/location.png";
// import Banner from "../src/images/Desktops.png";
import Banner from "../src/images/background.png";
import experience from "../src/images/experience.png";
import salary from "../src/images/salary.png";
import right from "../src/images/right.png";
import sutherland from "../src/images/sutherland.png";
import tcs from "../src/images/tcs.png";
import concentrix from "../src/images/concentrix.png";
import hexaware from "../src/images/hexaware.png";
import banner2 from "../src/images/banner2.png";
import homepagebanner from "../src/images/homepagebanner.png";
import mobileresponce from "../src/images/mobileresponce.png";
// import backgroundimg from "../src/images/Desktop";
import Container from "@mui/material/Container";
import DoneIcon from "@mui/icons-material/Done";
import Notificationfire from "./components/Firebase/Notificationfire";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import CircularProgress from "@mui/material/CircularProgress";

import { NumberCount, NumberCount2 } from "../src/pages/Countup";

import { Virtual } from "swiper/modules";
import "swiper/css/virtual";
import {
  Job_title_dropdown,
  Job_title_skills_dropdown,
  Recommend_jobs,
  get_jobs,
  location_dropdown,
  searchbar,
} from "./pages/Axiosfetch/Axiosfetch";
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { AlertToast, alertState } from "./divine-constants";
import LaunchIcon from "@mui/icons-material/Launch";
import Typed from "typed.js";
import Marquee from "react-fast-marquee";
import Noticepopup from "./Noticepopup";

export default function HomePage() {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:599px)");

  const token = localStorage.getItem("accesstoken");

  const { setAlert } = useContext(AlertToast);

  const [searchInput, setSearchInput] = useState({
    skills: "",
    location: "",
  });
  const [isdata, setIsdata] = useState();
  const [searchdata, setSearchdata] = useState(false);

  const handleInputChange = (e) => {
    setSearchInput({
      ...searchInput,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSearch = async () => {
    try {
      const response = await searchbar(searchInput);

      if (response.status === 200) {
        // {window.scrollTo(0,0); navigate("/jobs", { state: { ...response.data } })}

        setJobs(response.data);

        window.scrollTo({
          top: 600,
          left: 0,
          behavior: "smooth",
        });
      }
      if (response && response.data && Array.isArray(response.data)) {
        setSearchdata(true);
        // setJobs(response.data);
        // setPost_date(response.post_date);
      } else {
        console.error("Invalid data structure:", response);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await Recommend_jobs();
        // if (response && response.data && Array.isArray(response.data)) {
        if (response.status == 200) {
          setJobs(response.data);
          setIsdata(true);
        } else if (response.status == 204) {
          setIsdata(false);
        } else {
          const jobtypes = "jobs";
          const response = await get_jobs(jobtypes);
          if (response.status == 200) {
            setJobs(response.data);
          }
        }

        // } else {
        //     throw new Error(`Something went wrong ${response.data.message}`);
        //   }
      } catch (error) {
        // setAlert(alertState({
        //   message: error.message,
        //   severity: "error",
        //   show: true
        // }));
      } finally {
        setIsLoading(false);
      }
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);

  console.log(jobs);

  const defaultProps = {
    // options: top100Films,
    getOptionLabel: (option) => option.title,
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const [options, setOptions] = useState([]); // State to hold the fetched options
  const [location, setLocation] = useState([]); // State to hold the fetched options

  const fetchOptions = async () => {
    // Execute API call to fetch options

    try {
      const responce = await Job_title_skills_dropdown();
      setOptions(responce.data);
    } catch {}
  };

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchOption = async () => {
    // Execute API call to fetch options

    try {
      const responce = await location_dropdown();
      setLocation(responce.data);
    } catch {}
  };

  const handleKeyPress = (event) => {
   
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // const flatProps = {
  //   options: top100Films.map((option) => option.title),
  // };

  const typedElementRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ["ou can look at over 5 lakh jobs"],
      typeSpeed: 60,
      startDelay: 500, // Optional delay before typing starts
      showCursor: false, // Optional: hide cursor
    };

    // Function to initialize Typed.js
    const initializeTyped = () => {
      // Destroy the previous Typed instance if it exists
      if (typedElementRef?.current && typedElementRef.current?.typed) {
        typedElementRef.current.typed.destroy();
      }

      // Initialize new Typed instance
      typedElementRef.current.typed = new Typed(
        typedElementRef?.current,
        options
      );
    };

    // Initialize Typed.js immediately
    initializeTyped();

    // Set interval to re-initialize Typed.js every 2 seconds
    const intervalId = setInterval(() => {
      initializeTyped();
    }, 10000);

    // Cleanup function to clear interval
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleViewDetailsClick = (job) => {
    const queryParams = new URLSearchParams();
    Object.entries(job).forEach(([key, value]) => {
      queryParams.append(key, value);
    });
    const queryString = queryParams.toString();
    window.open(`/view-job-details?${queryString}`, "_blank");
  };

  // const typedElementRef = useRef(null);

  //   useEffect(() => {
  //     const options = {
  //       strings: ["You can look at over 5 lakh jobs"],
  //       typeSpeed: 30,
  //       startDelay: 200, // Optional delay before typing starts
  //       showCursor: false, // Optional: hide cursor
  //     };

  //     const typed = new Typed(typedElementRef.current, options);

  //     // Cleanup function
  //     return () => {
  //       typed.destroy();
  //     };
  //   }, []);

  // useEffect(() => {
  //   console.log(drawerOpen, "drawerOpen");
  // }, [drawerOpen]);

  return (
    <>
 
      {matches ? (
        <div
          style={{
            position: "relative",
            width: isTabletOrMobile ? "100%" : "100%",
          }}
        >

          <div style={{ position: "relative", width: "100%" }}>
            {isTabletOrMobile ? (
              <div
              
                style={{
                  backgroundColor: "#C5DCFF",
                  height: 400,
                  marginBottom: "50px",
                }}
              >
                {/* <LazyLoad height={400} once scrollContainer="body">
        <img src={} alt="Banner" style={{ width: '100%' }} />
        </LazyLoad>  */}
              </div>
            ) : (
              <LazyLoad>
                <img
                  src={banner2}
                  alt="Banner"
                  style={{ width: "100%", height: "95%" }}
                />
              </LazyLoad>
            )}

            <Grid
              item
              xs={12}
              sx={{
                top: 100,
                left: isTabletOrMobile ? "5%" : 55,
                position: "absolute",
                textAlign: "center",
                width: isTabletOrMobile ? "90%" : "auto",
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: matches ? 30 : 18,
                  fontSize: isTabletOrMobile ? 30 : 30,

                  fontFamily: "Satoshi",
                  color: "black",
                }}
              >
                Get the job of{" "}
                <span style={{ color: "blue" }}>your dreams</span> right away
              </Typography>

              <Typography
                sx={{
                  fontSize: matches ? 22 : 14,
                  fontSize: isTabletOrMobile ? 20 : 22,
                  fontFamily: "Satoshi",
                  color: "black",
                }}
              >
                Y<span ref={typedElementRef}></span>
              </Typography>
            </Grid>
          </div>

          <div
            style={{
              position: "absolute",
              top: 180,
              left: isTabletOrMobile ? "10%" : 50,
              width: isTabletOrMobile ? "80%" : "55%",
            }}
          >
            <Paper
              sx={{
                p: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: isTabletOrMobile ? "row" : "row",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Autocomplete
                value={searchInput.skills}
                onChange={(event, value) =>
                  handleInputChange({ target: { name: "skills", value } })
                }
                options={options}
                onFocus={fetchOptions}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: isTabletOrMobile ? "100%" : "35%",
                }}
                renderInput={(params) => (
                  <>
                    <SearchIcon sx={{ color: "blue" }} fontSize="large" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                      ref={params.InputProps.ref}
                    >
                      <InputBase
                        type="text"
                        {...params.inputProps}
                        name="skills"
                        onKeyDown={handleKeyPress}
                        sx={{
                          fontSize: 15,
                          fontFamily: "Vollkorn",
                          border: "none",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Skills/Designation/Companies"
                      />
                    </div>
                  </>
                )}
              />

              {/* <div style={{ width: isTabletOrMobile ? "100%" : 1, height: isTabletOrMobile ? 1 : 25, backgroundColor: "#cdcdcd", margin: isTabletOrMobile ? "10px 0" : "0 10px" }}></div> */}

              <div
                style={{
                  width: "1px",
                  height: "30px",
                  marginLeft: "10px",
                  backgroundColor: "#cdcdcd",
                }}
              >
                {" "}
              </div>

              <Autocomplete
                value={searchInput.location}
                onChange={(event, value) =>
                  handleInputChange({ target: { name: "location", value } })
                }
                options={location}
                onFocus={fetchOption}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: isTabletOrMobile ? "100%" : "35%",
                }}
                renderInput={(params) => (
                  <>
                    <LocationOnIcon
                      sx={{ paddingLeft: "5px", color: "blue" }}
                      fontSize="large"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: "10px",
                        width: "100%",
                      }}
                      ref={params.InputProps.ref}
                    >
                      <InputBase
                        type="text"
                        {...params.inputProps}
                        name="location"
                        onKeyDown={handleKeyPress}
                        sx={{
                          fontSize: 18,
                          fontFamily: "Vollkorn",
                          border: "none",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Location"
                      />
                    </div>
                  </>
                )}
              />

              <Button
                onClick={handleSearch}
                variant="contained"
                sx={{
                  width: isTabletOrMobile ? "100%" : "25%",
                  mt: isTabletOrMobile ? 0 : 0,
                }}
              >
                Search
              </Button>
            </Paper>
          </div>
        </div>
      ) : (
        //  <Grid
        //     item
        //     xs={12}
        //     sx={{textAlign: "center"  ,backgroundImage : `url(${mobileresponce})`,  paddingTop : "80px" , paddingRight : "20px" ,paddingLeft : "20px",
        //     backgroundSize: "cover",
        //     backgroundPosition: "center",
        //     height:"80vh"

        //   }}

        //   >

        <div style={{ position: "relative", width: "100%" }}>
          <LazyLoad height={400} once scrollContainer="body">
            <img src={mobileresponce} alt="Banner" style={{ width: "100%" }} />
          </LazyLoad>

          <Grid
            item
            xs={12}
            sx={{
              top: 50,
              padding: "10px",
              position: "absolute",
              textAlign: "center",
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: matches ? 35 : 18,
                fontFamily: "Satoshi",
                color: "black",
              }}
            >
              Get the job of{" "}
              <span style={{ color: "#017BFD" }}>your dreams</span> right away
            </Typography>

            <Typography
              sx={{
                fontSize: matches ? 25 : 14,
                fontFamily: "Satoshi",
                color: "black",
              }}
            >
              Y<span ref={typedElementRef}></span>
            </Typography>

            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
                width: "100%",
                borderRadius: 8,
              }}
            >
              <InputBase
                name="skills"
                value={searchInput.skills}
                onChange={handleInputChange}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Skills/Companies"
                inputProps={{ "aria-label": "search skills" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              <InputBase
                name="location"
                value={searchInput.location}
                onChange={handleInputChange}
                sx={{ flex: 1, ml: 1 }}
                placeholder="Location"
                inputProps={{ "aria-label": "search location" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                onClick={handleSearch}
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </Grid>
        </div>
      )}

      {/* 
) : (
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 5,
          width: '100%',
          borderRadius: 8
        }}
      >
        <InputBase
          name="skills"
          value={searchInput.skills}
          onChange={handleInputChange}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Skills/Designation/Companies"
          inputProps={{ 'aria-label': 'search skills' }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <InputBase
          name="location"
          value={searchInput.location}
          onChange={handleInputChange}
          sx={{ flex: 1, ml: 1 }}
          placeholder="Location"
          inputProps={{ 'aria-label': 'search location' }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          onClick={handleSearch}
          color="primary"
          sx={{ p: '10px' }}
          aria-label="directions"
        >
          <DirectionsIcon />
        </IconButton>
      </Paper>
    )} */}

      {matches ? (
        <Container maxWidth="lg">
          <div>
            <Typography variant="h3" fontWeight="bold" textAlign="center">
              Top companies trust Us
            </Typography>

            <Marquee gradient={true}>
              <img
                src={concentrix}
                alt="concentrix"
                style={{ width: 300, height: 100 }}
              />
              <img
                src={sutherland}
                alt="sutherland"
                style={{ width: 300, height: 100 }}
              />
              <img
                src={hexaware}
                alt="hexaware"
                style={{ width: 300, height: 100 }}
              />
              <img src={tcs} alt="tcs" style={{ width: 300, height: 100 }} />
            </Marquee>
          </div>
        </Container>
      ) : (
        <Container smallWidth="sm" sx={{ paddingTop: "10px" }}>
          <div>
            <Typography variant="h3" fontWeight="bold" textAlign="center">
              Top companies trust Us
            </Typography>

            <Marquee gradient={true}>
              <img
                src={concentrix}
                alt="concentrix"
                style={{ width: 250, height: 80 }}
              />
              <img
                src={sutherland}
                alt="sutherland"
                style={{ width: 250, height: 80 }}
              />
              <img
                src={hexaware}
                alt="hexaware"
                style={{ width: 250, height: 80 }}
              />
              <img src={tcs} alt="tcs" style={{ width: 250, height: 80 }} />
            </Marquee>
          </div>
        </Container>
      )}

      <Grid
        alignContent={"center"}
        alignItems={"center"}
        container
        sx={{ p: matches ? 0 : 1 }}
      >
        <Grid sx={{ mt: 10 }} item xs={12} sm={12} md={12}>
          <Paper sx={{ p: 2, background: "rgb(227 238 255)" }}>
            {!token && searchdata == false ? (
              <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
                Latest Opportunities
              </Typography>
            ) : null}

            {searchdata == true ? (
              <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
                Search Results
              </Typography>
            ) : null}
            {token && isdata == true && searchdata == false ? (
              <div>
                <Typography
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  variant="h3"
                  fontWeight={"bold"}
                  sx={{ mt: "50px" }}
                  textAlign={"center"}
                >
                  suggested to you
                </Typography>

                <Typography
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  fontWeight={"small"}
                  sx={{ mt: "10px" }}
                  color={"#000000"}
                  textAlign={"center"}
                >
                  based on your Skills
                </Typography>
              </div>
            ) : isdata == false && searchdata == false ? (
              <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                <Typography
                  variant="h3"
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  No jobs found
                </Typography>
                <Typography variant="subtitle2" textAlign={"center"}>
                  We could not find any jobs matching your desired Skills
                </Typography>
                <Button
                  variant="text"
                  sx={{width:"100%"}}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/user_profile");
                  }}
                >
                  {" "}
                  Edit Skills{" "}
                </Button>
              </div>
            ) : null}

            {/* <Typography variant='subtitle2' color={'#000000'} textAlign={'center'}>based on your preferences</Typography> */}

            <Container
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              sx={{ mt: "30px", marginBottom: "60px" }}
              maxWidth="lg"
            >
              <Swiper
                mousewheel={false}
                Navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={
                  matches
                    ? [Pagination, Navigation, Virtual, Mousewheel]
                    : [Autoplay, Pagination, Navigation, Virtual]
                }
                className="mySwiper"
                spaceBetween={30}
                slidesPerView={matches && !isTabletOrMobile ? 4 : 1}
                virtual
              >
                {filteredJobs.length != 0 ? (
                  filteredJobs.map((job, index) => (
                    <SwiperSlide
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate("/view-job-details", { state: { ...job } });
                      }}
                      // onClick={() =>

                      //   handleViewDetailsClick(job)}

                      key={job}
                      virtualIndex={index}
                    >
                      <Box
                        key={job.id}
                        component={Paper}
                        paddingInline={2}
                        paddingBlock={2}
                        sx={{
                          // ":hover": { boxShadow: "none" },
                          // borderRadius:1,
                          // cursor: "pointer",
                          // mb: 2,
                          // backgroundColor:'green',
                          // mt:2,
                          // boxShadow: "0px 0px 10px #cdcdcd;"
                          ":hover": {
                            boxShadow: "0px 0px 10px #cdcdcd",
                            transform: "scale(1.010)",
                          },
                          cursor: "pointer",
                          mb: 2,
                          mt: 2,
                          transition: "0.2s ease-in-out",
                          // width : "650px",
                          borderRadius: "30px",
                          border: "0.5px solid #cdcdcd",
                          height:"100%",
                     
                       
                        }}
                        onClick={() => {
                          if (!matches) {
                            navigate("/view-job-details", {
                              state: { ...job },
                            });
                            scrollToTop();
                          }
                        }}
                      >
                        <img
                          width={35}
                          height={35}
                          style={{ objectFit: "contain" }}
                          src={job.company_logo}
                          alt="dummy"
                        />
                        <Divider />

                        <Box
                          sx={{ mt: 2 }}
                          display="flex"
                          alignItems="center"
                          gap={2}
                        >
                          <Box>
                            <Typography
                              className="align-icon"
                              fontWeight={500}
                              variant="h4"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {/* {job.job_title ? job.job_title.toUpperCase() : null} */}
                              {job.job_title}
                            </Typography>

                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              <Typography
                                fontSize={12}
                                sx={{
                                  textTransform: "capitalize",
                                  color: "#00000090",
                                }}
                              >
                                {job.company_name}
                              </Typography>

                              <Link
                                to={job.company_website_link}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <LaunchIcon
                                  color="primary"
                                  sx={{ fontSize: 17, marginLeft: 1 }}
                                />
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        <br />
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item sm={12} xs={12} md={12}>
                            <Stack sx={{ mt: 1  }} alignItems={"center"} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 16, height: 16 }}
                                src={Location}
                              />
                              {job.post_city ? (
                                <Typography variant="overline" fontSize={12}>
                                  {job.post_city
                                    .map((location) => location.toUpperCase())
                                    .join(", ")}
                                </Typography>
                              ) : null}
                              {/* <Typography color={'#000000'} fontSize={12}>{job.job_location ? job.job_location.toUpperCase() : null}</Typography> */}
                            </Stack>
                          </Grid>

                          <Grid item sm={12} xs={12} md={12}>
                            <Stack sx={{ mt: 1 }} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 16, height: 16 }}
                                src={experience}
                              />
                              <Typography color={"#000000"} fontSize={12}>
                                {job.minimum_experience} -{" "}
                                {job.maximum_experience} Years experience
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item sm={12} xs={12} md={12}>
                            <Stack sx={{ mt: 2 }} direction={"row"} spacing={1}>
                              <img
                                style={{ width: 16, height: 16 }}
                                src={salary}
                              />
                              <Typography color={"#000000"} fontSize={12}>
                                CTC (ANNUAL) {job.minimum_salary} -{" "}
                                {job.maximum_salary} LPA
                              </Typography>
                            </Stack>
                          </Grid>

                          {matches ? (
                            // <Button  sx={{ mt:2,marginLeft:'auto'}}   variant="outlined" onClick={() => handleViewDetailsClick(job)}> View details </Button>

                            <Button
                              onClick={() => {
                                if (matches) {
                                  navigate("/view-job-details", {
                                    state: { ...job },
                                  });
                                }
                              }}
                              sx={{ mt: 2, marginLeft: "auto" }}
                              variant="text"
                            >
                              View Details{" "}
                              <img
                                style={{ width: 16, height: 16 }}
                                src={right}
                              />{" "}
                            </Button>
                          ) : null}
                        </Grid>
                      </Box>

                    </SwiperSlide>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <lottie-player
                      src="https://lottie.host/f0f8761e-4a5b-43ef-9f80-c764c531a3d2/c5dT17Mbid.json"
                      background="##FFFFFF"
                      speed="1"
                      style={{ width: 250, height: 250 }}
                      loop
                      autoplay
                      direction="1"
                      mode="normal"
                    ></lottie-player>
                  </Box>
                )}

                {/* // <Typography variant='subtitle2' color={'#000000'} textAlign={'center'}> NO Jobs  </Typography> } */}
              </Swiper>
            </Container>
          </Paper>

          <Container
            sx={{ mt: "50px", mb: "50px" }}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            maxWidth="lg"
          >
            {/* <Grid container sx={{display : "flex" , justifyContent:"center" , alignItems:"center" , }}  data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"  maxWidth="lg" spacing={3}> */}

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                p: matches ? 8 : 0,
              }}
              xs={4}
              md={4}
            >
              <div>
                <NumberCount2 value={8} />
                {matches ? (
                  <Typography color={"#000000"} fontSize={15}>
                    {" "}
                    8k daily active users{" "}
                  </Typography>
                ) : (
                  <Typography color={"#000000"} fontSize={8}>
                    {" "}
                    8k daily active users{" "}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                padding: "60px",
                p: matches ? 8 : 0,
              }}
              xs={4}
              md={4}
            >
              <div>
                <NumberCount value={12} />

                {matches ? (
                  <Typography color={"#000000"} fontSize={15}>
                    {" "}
                    Over 12M open jobs
                  </Typography>
                ) : (
                  <Typography color={"#000000"} fontSize={8}>
                    {" "}
                    Over 12M open jobs{" "}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                p: matches ? 8 : 0,
              }}
              xs={4}
              md={4}
            >
              <div>
                <NumberCount value={5} />

                {matches ? (
                  <Typography color={"#000000"} fontSize={15}>
                    {" "}
                    Over 5 million stories shared{" "}
                  </Typography>
                ) : (
                  <Typography color={"#000000"} fontSize={8}>
                    {" "}
                    Over 5 million stories shared{" "}
                  </Typography>
                )}
              </div>

              {/* </Grid> */}
            </Grid>
          </Container>
        </Grid>

        {/* <Paper sx={{p:2 , mt :20 , background : "rgb(227 238 255)" }}> */}

        {matches && !isTabletOrMobile ? (
          <Container sx={{ mt: "10px", width: "100%" }} maxWidth="lg">
            <Grid
              sx={{ width: "100%", overflow: "hidden" }}
              container
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                md={6}
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img
                  src={homepagebanner}
                  alt="Hlo"
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                // sx={{ pl: { xs: 0, md: 2 } }}
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <Typography variant="h4" component="h1">
                  Millions of Jobs. Find the one that suits you.
                </Typography>
                <Typography variant="body1" component="p">
                  Search all the open positions on the web. Get your own
                  personalized salary estimate. Read reviews on over 600,000
                  companies worldwide.
                </Typography>
                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <DoneIcon sx={{ marginRight: "10px" }} />
                  <span>Bring to the table win-win survival</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <DoneIcon sx={{ marginRight: "10px" }} />
                  <span>Capitalize on low hanging fruit to identify</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <DoneIcon sx={{ marginRight: "10px" }} />
                  <span>But I must explain to you how all this</span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button variant="contained">Get Started</Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        ) : null}

        {/* </Paper> */}
        {/* <Paper sx={{p:2 , mt :20 , background : "rgb(227 238 255)" }}>

  
<Container   sx={{mt :20}}    style={{ display : "flex" ,justifyContent:"center" , alignItems:"center"}} 
   data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" maxWidth="lg"> 





     </Container>





</Paper> */}
      </Grid>
     

      

    </>
  );
}
