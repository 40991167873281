import { Button, IconButton, MenuItem, Paper,useMediaQuery , Typography, useTheme, FormGroup, Tooltip, Stack, ToggleButton, ToggleButtonGroup, InputAdornment, FormControl, FormHelperText, FormLabel, FormControlLabel, Radio, Grid, Select, InputLabel, DialogTitle } from "@mui/material";
import { Box , Chip } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AlertToast, FullScreenContext, alertState } from "../divine-constants";
import { AddOutlined, BorderLeft, CurrencyRupeeOutlined, RemoveOutlined, Watch } from "@mui/icons-material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Percentages, dummyLanguages, textValidation, Workexpre,  } from "../utils";
import { LibAutocomplete, LibCheckbox, LibDateField, LibRadioGroup, LibSelect, LibTextField } from "../components/LibComponents";
import { GetAllCountry, GetAllState, GetAllStatecity, basicdetails, edit_audio, get_country_list, get_state } from "./Axiosfetch/Axiosfetch";
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import CircularProgress from '@mui/material/CircularProgress';

export default function Details() {
	const theme = useTheme();
	const [selectedQualification, setSelectedQualification] = useState();
	const [country, setCountry] = useState([]);
	const [stateCode, setStateCode] = useState();
	const [freasher, setFreasher] = useState(false);
	const matches = useMediaQuery('(max-width:375px)');
	
	
    const [State, setStates] = useState([]);

    const [citydata, setCities] = useState([]);
    const [citycode, setCityCode] = useState();
    const [error, setError] = useState(false);
    const [stateerror, setStateError] = useState(false);
    const [jobPost, setJobPost] = useState({
        post_country: '',
        post_state: '',
        post_city: ''
    });
	const navigate = useNavigate();
	const {setIsFullScreen} = useContext(FullScreenContext);
	const { setAlert } = useContext(AlertToast);

	const [isChecked, setIsChecked] = useState(false);
	const [audioFiles, setAudioFiles] = useState(null);
	const [audioFile_name, SetAudioFile_name] = useState(null);
	const [loading, setLoading] = useState(false);


 

	const {
		watch,
		control,
		handleSubmit,
		getValues,
		setValue,
	} = useForm(
		{
		defaultValues: {
			resume: '',
			highest_qualification: '',
			audioFiles : '',
			audioFilename : '',
			Gender: '',
			country : "",
			state : "",
			city : "",
	
			languages: [{id: 1, lang: null, speak: false, write: false, read: false}],
			primary_graducation : false,

			candidates_qualification : {
				candidates_qualifications : []
				},
		
			
			workExperience: {
				isEmployed: false,
				recent_company: '',
				designation: '',
				recent_salary: '',
				dates: {
					joinDate: '',
					leavingDate: ''
				},
				months: "",
				years: "",
				notice_period: ''
			}
		}
	});

	

	
	useEffect(() => {
    
        fetchCountries();

    }, []);

	


	useEffect(() => {
        // Fetch countrie
		setValue('country', jobPost.post_country);
		setValue('state', jobPost.post_state);
		setValue('city', jobPost.post_city);
    }, [jobPost]);

    useEffect(() => {
        // Fetch countries
		if (stateCode) {
			fetchStates(stateCode)
		}
		

    }, [stateCode]);

    useEffect(() => {
        // Fetch countries
		if (stateCode && citycode) {
			fetchCities(stateCode , citycode)
		}
		


    }, [citycode]);


    const fetchCountries = async() => {
		try {
			const { status , data  } = await GetAllCountry();
			console.log(data,"datadatadata")	
			if (status === 200) {
				setCountry(data)
				// setValue('country', jobPost.post_country);
		
					
			}else if (status === 403) { 
		
			} else {
				throw new Error(`Something went wrong ${data.message}`);
			}
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
	}

    const fetchStates = async(country) => {

		console.log(country,"countrycountry")
		try {
			const { status , data  } = await GetAllState(country);
			console.log(data,"datadatadata")	
			if (status === 200) {
				setStates(data);
				// setValue('state', jobPost.post_state);
				
				
			}else {
				throw new Error(`Something went wrong 1 ${data.message}`);
			}
			
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
	}


    const fetchCities = async(state , city) => {
        
		try {
			const { status , data  } = await GetAllStatecity(state , city);
			console.log(data,"datadatadata")	
			if (status === 200) {
				setCities(data);
				// setValue('city', jobPost.post_city);
				
			}else {
				throw new Error(`Something went wrong ${data.message}`);
			}
			
			} catch (error) {
			
				setAlert(alertState({
				message: error.message,
				severity: "error",
				show: true
				}));
			}
    };






const handleFileChanges = async(e)  => {

setAudioFiles(e.target.files[0]);
const filenames = e.target.files[0]
console.log(filenames.name,"audioFileaudioFile")
SetAudioFile_name(filenames.name)

}


const handleUploadaudio = async(e) => {

// const formData = new FormData();
const audio = {"audio" : audioFiles , "audioname" : audioFile_name }

// formData.append({'audio': audioFiles , "audioFile_name" : audioFile_name});
setLoading(true);

if (audio.audio) {
	const response = await edit_audio(audio);

	if (response.status === 200){
		setLoading(false);
		
		setAlert(alertState({
			message: "successfully Added",
			severity: "success",
			show: true
			  }));
		
			}

}else {

	console.log("erorr")
	setLoading(false);
	setAlert(alertState({
		message: "Select Audio File",
		severity: "error",
		show: true
		  }));
	
		}
}



    

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setJobPost({
            ...jobPost,
            post_state: selectedState,
            post_city: ''
        });
        setStateError(selectedState === '');
        if (selectedState !== '') {
            fetchCities(selectedState);
        }
    };

    const handleCityChange = (event) => {
        const selectedCity = event.target.value;
        setJobPost({
            ...jobPost,
            post_city: selectedCity
        });
    };


	
	const w_qualification = watch("highest_qualification");

	const work_experience = watch(["workExperience.months", "workExperience.years"]); 

	const handleCheckboxChange = () => {

		setIsChecked(!isChecked); // Toggles the state value
	
	  }
	
	  const handleCheckboxclose = () => {
		setIsChecked(false); // Toggles the state value
		setValue("workExperience.months",0)
		setValue("workExperience.years",0)
		

	
	  }

	
	function handleAddLanguage() {
		append({lang: null, read: false, speak: false, write: false , id : getValues("languages").length + 1});
	}

	function handleRemoveLanguage(index) {
		remove(index)
	}

	const {fields, append, remove} = useFieldArray({name: "languages",control: control});
	console.table(fields,"989")
	console.log(getValues("workExperience.months"),"onSubmitDetailsoutside")

	const onSubmit = async (resdata) => {
		const myJSON = JSON.stringify(resdata);

		
		if (!audioFiles ){ 
			setAlert(alertState({
				message: "please Upload Your Self Intro ",
				severity: "error",
				show: true
			  }));

		}else if (!jobPost.post_country ){
			setAlert(alertState({
				message: "please Enter Country",
				severity: "error",
				show: true
			  }));

		}else if (!jobPost.post_state ) {
			setAlert(alertState({
				message: "please Enter State",
				severity: "error",
				show: true
			  }));

		}else if (!jobPost.post_city ){ 

			setAlert(alertState({
				message: "please Enter City",
				severity: "error",
				show: true
			  }));

		
		}else{
		try {
		  const { status , data  } = await basicdetails(resdata);	
		  if (status === 200) {
			navigate("/my-preferences");

		  }else if (status === 403) { 
	
		  } else {
			throw new Error(`Something went wrong ${data.message}`);
		  }
		  } catch (error) {
		 
			setAlert(alertState({
			  message: error.message,
			  severity: "error",
			  show: true
			}));
		  }
		}
		
	  };

	useEffect(() => {
		switch(w_qualification) {
			case "10th": setSelectedQualification(<Tenth control={control} getValues= {getValues} name="tenth" />); break;
			case "12th": setSelectedQualification(<Twelfth control={control}  getValues= {getValues} name="twelfth" />); break;
			case "undergraduate": setSelectedQualification(<Undergraduate control={control} getValues= {getValues}  name="undergraduate" />); break;
			case "postgraduate": setSelectedQualification(<Postgraduate control={control} getValues= {getValues} name="postgraduate" />); break;
			case "doctorate": setSelectedQualification(<Doctorate control={control} getValues= {getValues} name="doctorate" />); break;
			default: setSelectedQualification(null);
		}
	}, [w_qualification]);

	useEffect(() => {
		setIsFullScreen(true);
	}, []);





	return (
		<Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{
			width: "80%",
			margin: "auto",
			marginTop: "50px",
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(3),
			[theme.breakpoints.down("sm")]: {
				width: "95%"
			}
		}}>
			
			{/* <Typography variant="h4" sx={{marginTop: theme.spacing(4)}} color="primary">Welcome John Doe!</Typography> */}
			<Paper elevation={4} sx={{paddingBlock: theme.spacing(3)}}>
				<Typography variant="h3" margin={`0 auto ${theme.spacing(1)}`} width="90%">Customize Your Experience</Typography>
				<Typography margin={`${theme.spacing(1)} auto`} width="90%">Your recruiter will see the details you provide here.</Typography>
				
				<br />
				

		
				<Box
					display="grid" 
					gridTemplateColumns="1fr"
					maxWidth="90%"
					marginInline="auto"
					gap={0 + ' ' + theme.spacing(3)} sx={{
					[theme.breakpoints.up("sm")]: {
						gridTemplateColumns: "repeat(2, 1fr)",
						maxWidth:"80%"
					}
				}}>


			
				<Box> 
				<Typography variant="h3">
					Self Intro
				</Typography>		
				<Typography  width="100%">Helps recruiters understanding your communication.</Typography>	
			
				
					 <Box sx={{display: 'flex', justifyContent : "space-between" , width : "330px"  , border : "0.5px solid #007aff" , boxShadow :"rgb(10 10 10 / 10%) 0 0 20px 0" , borderRadius: '5px', cursor: 'pointer' , mt:1  , mb:3}}>
					 
								<input type="file"   accept="audio/*"  required  onChange={handleFileChanges}
								 style={{ padding : "10px" , width : "219px" ,border: 'none' }} />
{/* paddingLeft : "15px" */}
					<LoadingButton
						// onClick={handleClick}
						onClick={handleUploadaudio} 
						variant="outlined" 
						style={{ borderTopRightRadius: '5px',  border: "none" , borderBottomRightRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }}

						loading={loading}
						// type="submit" 
						loadingPosition="start"
						// variant="contained"
						// color="primary"

						// startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
						startIcon={loading ? <SaveIcon size={20}  /> : null}
						>
						Upload Audio
						</LoadingButton> 

							{/* <Button 
							onClick={handleUploadaudio} variant="outlined" style={{ borderTopRightRadius: '5px',  border: "none" , borderBottomRightRadius: '5px', backgroundColor: '#f0f0f0', cursor: 'pointer' }}>
							Upload Audio
							</Button> */}

							</Box>
				</Box>
							
						<Box> </Box>
							
		
					{/* <LibTextField 
						control={control}
						name={"resume"}
						rules={textValidation("Resume")}
						textfieldProps={{
							type: "file",
							label: "Resume"
						}}
					/> */}

					{/* <Box sx={{gridColumn: "1 / -1" , mt:3 , mb:3}}>
                   
				   <h4>Self Intro</h4>
				   <div style={{ display: 'inline-flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '5px' }}>
					 <input type="file" accept="audio/*"   style={{ border: 'none', padding: '8px', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} />
					 {/* <button onClick={handleUpload} style={{ border: 'none', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', padding: '8px 16px', backgroundColor: '#f0f0f0', cursor: 'pointer' }}>Upload Audio</button> */}
				   {/* </div> */}

				
					<LibTextField
						control={control}
						name={"Gender"}
						rules={textValidation("Gender")}
						textfieldProps={{
							label: "Gender",
							select: true
						}}
					>

						<MenuItem value="male">Male</MenuItem>
						<MenuItem value="female">Female</MenuItem>
						<MenuItem value="others">Others</MenuItem>
					</LibTextField>


					<LibTextField 
						control={control}
						name={"highest_qualification"}
						rules={textValidation("Highest qualification")}
						textfieldProps={{
							label: "Highest qualification",
							select: true
						}}
					>
						<MenuItem value="10th">10th pass</MenuItem>
						<MenuItem value="12th">12th pass</MenuItem>
						<MenuItem value="undergraduate">Undergraduate</MenuItem>
						<MenuItem value="postgraduate">Postgraduate</MenuItem>
						<MenuItem value="doctorate">Doctorate</MenuItem>
					</LibTextField>

					{selectedQualification}
					{/* <Box></Box> */}

					<Box sx={{mt:3}}>

			
					<FormControl fullWidth >
	
							<InputLabel id="demo-simple-select-label">
							  Country
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="Country"
							  size="small"
							//   MenuProps={MenuProps}
							  error={error}
							  value={jobPost.post_country}
							  
							  name="post_country"
							//   onChange={handleCountryChange}
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_country: event.target.value,
								});
								event.target.value === "" ?setError(true):setError(false);
								
							  }}
							//   required
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {country.map((e,i)=>{
								return (
							  <MenuItem onClick={()=>setStateCode(e.iso2)} key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid country</FormHelperText> */}
							
						  </FormControl>
				</Box>
				
						{matches ?
						//    <Box sx={{mt:3}} >
						   <Box  >

						   {jobPost?.post_country.length >0 && 
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							State
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="State"
							  size="small"
							//   MenuProps={MenuProps}
							
							  error={stateerror}
							  value={jobPost.post_state}
							  name="post_state"
						
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_state: event.target.value,
								});
								event.target.value === "" ?setStateError(true):setStateError(false)
							  }
							   
							  }
							  required
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {State?.map((e,i)=>{
								  return (
							  <MenuItem  onClick={() => setCityCode(e.iso2)} key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid state</FormHelperText> */}
						  </FormControl>}
						   </Box>:

						   <Box sx={{mt:3}} >

						   {/* <Box  > */}

						   {jobPost?.post_country.length >0 && 
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							State
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="State"
							  size="small"
							//   MenuProps={MenuProps}
						
							  error={stateerror}
							  value={jobPost.post_state}
							  name="post_state"
						
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_state: event.target.value,
								});
								event.target.value === "" ?setStateError(true):setStateError(false)
							  }
							   
							  }
							  required
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {State?.map((e,i)=>{
								  return (
							  <MenuItem  onClick={() => setCityCode(e.iso2)} key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid state</FormHelperText> */}
						  </FormControl>}
						   </Box>}


						{matches ?

						   <Box sx={{mt:3}} >

						   {jobPost?.post_state?.length >0 &&
						   
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							City
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="City"
							  size="small"
						
							//   MenuProps={MenuProps}
							  error={stateerror}
							  value={jobPost.post_city}
							  name="post_city"
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_city: event.target.value,
								});
							  }}
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {
								citydata.map((e,i)=>{
								  return (

							  <MenuItem key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid City</FormHelperText> */}
						  </FormControl>}

						  </Box>:
						<Box sx={{mt:3}} >
						   {jobPost?.post_state?.length >0 &&
						   
						  <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
							City
							</InputLabel>
							<Select
							  labelId="demo-simple-select-label"
							  id="demo-simple-select"
							  label="City"
							  size="small"
							
							//   MenuProps={MenuProps}
							  error={stateerror}
							  value={jobPost.post_city}
							  name="post_city"
							  onChange={(event, newValue) =>{
								setJobPost({
								  ...jobPost,
								  post_city: event.target.value,
								});
							  }}
							>
							  <MenuItem value={""}>Select</MenuItem>
							  {
								citydata.map((e,i)=>{
								  return (

							  <MenuItem key={i} value={e.name}>{e.name}</MenuItem>
								  )
								})
							  }
							</Select>
							{/* <FormHelperText>Please provide valid City</FormHelperText> */}
						  </FormControl>}

						  </Box>}
						  <Box></Box>
				
			
				<Box sx={{mt:3}}>
				<section > 
				<h4 style = {{paddingLeft: "5px"}}>Work Status</h4>
				{/* <br /> */}
				<LibRadioGroup row control={control} onChange={handleCheckboxChange}  name={"Work"} >
					<FormControlLabel name={"Experience"} sx={{marginLeft:"1px",marginTop:"2px"}}  value= "Experience" onChange={handleCheckboxChange} control={<Radio />} label="Experience" />
					<FormControlLabel name={"Fresher"} value= "Fresher" onChange={handleCheckboxclose} control={<Radio />} label="Fresher" />
				</LibRadioGroup>
				</section>
				</Box> 
				
				<Box></Box>

			{isChecked ? ( 
				<Box sx={{mt:2}}>
					<section >
						<h4 >Total Work Experience</h4>
						<br />
						<Stack direction="row" spacing={2}>
							<LibTextField
								control={control}
								name={"workExperience.years"}
								rules={Workexpre("Years")}
								textfieldProps={{
									label: "Years",
									type: "number",
									style: {flexGrow: '1'}
								}}
							/>
							<LibTextField
								control={control}
								rules={Workexpre("Months")}
								name={"workExperience.months"}
								textfieldProps={{
									label: "Months",
									type: "number",
									style: {flexGrow: '1'}
								}}
							/>
						</Stack>
					</section>

				</Box>
				) : null } 
				
					{(parseInt(work_experience[0]) > 0 || parseInt(work_experience[1]) > 0) && <WorkExperience control={control} name="workExperience" isEmployed={watch("workExperience.isEmployed")} />}



			

				<Box sx={{gridColumn: "1 / -1"}}>
					<h4>Languages</h4>
						{/* <br /> */}
						{fields.map((field, index) => {
							
							return(
								// <Paper  key={field.id} style={{display: "flex", paddingBlock: theme.spacing(0.5), paddingInline: theme.spacing(1), marginBottom: theme.spacing(1), alignItems: "center"}}>
								<Paper  key={field.id} style={{display: "flex", paddingBlock: theme.spacing(0.5), marginBottom: theme.spacing(1), alignItems: "center"}}>
								<Stack flexWrap="wrap" direction="row" flexGrow={1}>
				
									<LibAutocomplete 
										control={control}
										name={`languages.${index}.name`}
										rules={textValidation("Language")}
										
										options={[...dummyLanguages]}
										textfieldProps={{
											label: "Language",
											helperText: "",
											fullWidth: true,
											field: field


										}}

										autocompleteProps={{
											style: {flexGrow: '1'}
										}}
									/>

									
									<FormGroup style={{display: "flex", marginLeft: theme.spacing(4), columnGap: theme.spacing(2), flexDirection: "row"}}>
										<LibCheckbox 
											control={control}
											name={`languages.${index}.speak`}
											checked={getValues("languages")[index].speak}
											FormControlLabelProps={{
												label: "Speak"
											}}
										/>
										<LibCheckbox 
											control={control}
											name={`languages.${index}.read`}
											checked={getValues("languages")[index].read}
											FormControlLabelProps={{
												label: "Read"
											}}
										/>
										<LibCheckbox 
											control={control}
											name={`languages.${index}.write`}
											checked={getValues("languages")[index].write}
											FormControlLabelProps={{
												label: "Write"
											}}
										/>
									</FormGroup>
								</Stack>

								{index > 0 && 	<Tooltip title="Remove language">
									<IconButton variant="contained" size="small" onClick={handleRemoveLanguage.bind(this, index)}><RemoveOutlined /> </IconButton>
								</Tooltip>}

							
							</Paper>
							)
						
					})}
						<Button variant="text" size="small" onClick={handleAddLanguage}><AddOutlined /> Add language</Button>
					</Box> 

					<br />
				</Box>
			
			</Paper>
			<Button sx={{marginBottom: theme.spacing(4), width: "fit-content", ml: "auto"}} type="submit">Submit</Button>
		</Box>
	)
}


/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues, any>,
 * 	name: import("react-hook-form").FieldValues
 * 	formDatas: import("react-hook-form").FieldValues
 * }} props
 * }} props
 * @returns {JSX.Element}
 */
export function Tenth(props) {
	// console.log(props.formDatas.board,"lplp")
	



	const theme = useTheme();
	const boardOptions = [
		'Tamil Nadu Board',
		'State Board',
		'CBSE Board',
		'ICSE Board',
		'UP Board',
		'Maharashtra State Board',
		'Rajasthan Board',
		'Karnataka State Board',
		'West Bengal Board',
		'Gujarat Board',
		'Bihar Board',
		'Madhya Pradesh Board',
		'Punjab State Board',
		'Haryana Board',
		'Kerala State Board',
		'Telangana State Board',
		'Odisha Board',
		'Assam Board',
		'Jharkhand Board',
		'Chhattisgarh Board',
		'Uttarakhand Board',
		'Himachal Pradesh Board',
		'Goa Board',
		'Nagaland Board',
		'Manipur Board',
		'Tripura Board',
		'Meghalaya Board',
		'Arunachal Pradesh Board',
		'Mizoram Board',
		'Sikkim Board',
		// Add more board options as needed
	  ];
	
	const chennaiSchoolTypes = [
		"Government Schools",
		"Private Schools",
		"Public Schools",
		"International Schools",
		"CBSE Schools",
		"ICSE Schools",
		"State Board Schools",
		"Matriculation Schools",
		"Montessori Schools",
		"Waldorf Schools",
		"Religious Schools",
		"Special Education Schools",
		"Boarding Schools",
		"Day Schools",
		"Co-educational Schools",
		"Girls' Schools",
		"Boys' Schools",
		"Military Schools",
		"Art Schools",
		"Language Schools"
	];
	// board
	// passOutYear
	// School_medium
	// percentage



	return (
		<section style={{gridColumn:"1 / -1"}}> 
			<h4>Class X</h4>
			<br />
			
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gridColumn="1 / -1"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>
				

					<LibAutocomplete 
						control={props.control}
						options={boardOptions}
						name={props.name + ".Board"}
						rules={textValidation("Board")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Board"
						}}
					/>
				<LibTextField 
					control={props.control}
					name={props.name + ".School_name"}
					rules={textValidation("School Name")}
					textfieldProps={{
						label: "School Name"
					}}
				/>

				<LibTextField 
					control={props.control}
					rules={textValidation("Passing out year")}
					name={props.name + ".passOutYear"}
					// defaultValue={props.formDatas.item?.passOutYear || ''}
					textfieldProps={{
						label: "Passing out year",
						type: "number",
					
						
					}}
				/>
				<LibTextField
				control={props.control}
				rules={textValidation("School Medium")}
					name={props.name + ".School_medium"}
					// defaultValue={props.formDatas.item?.School_medium || ''}
					textfieldProps={{
						label: "School Medium",
						select: true
					}}
				>
					<MenuItem value="tamil">Tamil</MenuItem>
					<MenuItem value="english">English</MenuItem>
					<MenuItem value="hindhi">Hindhi</MenuItem>
				</LibTextField>

				<LibTextField 
					control={props.control}
					name={props.name + ".percentage"}
					// defaultValue={props.formDatas.item?.persentage || ''}
					// rules={textValidation("Percentage %")}
					rules={Percentages()}
					textfieldProps={{
						label: "Percentage %",
						type: "number",
					}}
				/>
			</Box>


			
		</section>	
	)}

/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues, any>,
 * 	name: import("react-hook-form").FieldValues
 * }} props
 * @returns {JSX.Element}
 */
export function Twelfth(props) {
	const theme = useTheme();
	const boardOptions = [
		'Tamil Nadu Board',
		'State Board',
		'CBSE Board',
		'ICSE Board',
		'UP Board',
		'Maharashtra State Board',
		'Rajasthan Board',
		'Karnataka State Board',
		'West Bengal Board',
		'Gujarat Board',
		'Bihar Board',
		'Madhya Pradesh Board',
		'Punjab State Board',
		'Haryana Board',
		'Kerala State Board',
		'Telangana State Board',
		'Odisha Board',
		'Assam Board',
		'Jharkhand Board',
		'Chhattisgarh Board',
		'Uttarakhand Board',
		'Himachal Pradesh Board',
		'Goa Board',
		'Nagaland Board',
		'Manipur Board',
		'Tripura Board',
		'Meghalaya Board',
		'Arunachal Pradesh Board',
		'Mizoram Board',
		'Sikkim Board',
		// Add more board options as needed
	  ];
	
	return (
		<section style={{gridColumn:"1 / -1"}}>
			<h4>Class XII</h4>
			<br />
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gridColumn="1 / -1"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>

				<LibAutocomplete 
						control={props.control}
						options={boardOptions}
						name={props.name + ".Board"}
						rules={textValidation("Board")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Board"
						}}
					/>

				<LibTextField 
					control={props.control}
					name={props.name + ".School_name"}
					rules={textValidation("School Name")}
					textfieldProps={{
						label: "School Name"
					}}
				/>


				<LibTextField 
					control={props.control}
					name={props.name + ".passOutYear"}
					rules={textValidation("Passing out year")}
					textfieldProps={{
						label: "Passing out year",
						type: "number",
					}}
				/>
				<LibTextField
				control={props.control}
					name={props.name +  ".School_medium"}
					rules={textValidation("School Medium")}
					textfieldProps={{
						label: "School Medium",
						select: true
					}}
				>
					<MenuItem value="tamil">Tamil</MenuItem>
					<MenuItem value="english">English</MenuItem>
					<MenuItem value="hindhi">Hindi</MenuItem>
				</LibTextField>

				<LibTextField 
					control={props.control}
					name={props.name + ".percentage"}
					// rules={textValidation("Percentage %")}
					rules={Percentages()}
					textfieldProps={{
						label: "Percentage %",
						
					}}
					
				/>
			</Box>
		</section>
	)}


export function Undergraduate(props) {
	const [checked, setChecked] = useState(true);
	const theme = useTheme();
	const { setAlert } = useContext(AlertToast);
	const chennaiUniversitiesAndColleges = [
		"Anna University",
		"Indian Institute of Technology Madras (IIT Madras)",
		"Madras Institute of Technology (MIT)",
		"Madras Christian College (MCC)",
		"Stella Maris College",
		"Loyola College, Chennai",
		"SRM Institute of Science and Technology (SRMIST)",
		"Vellore Institute of Technology (VIT)",
		"Hindustan Institute of Technology and Science (HITS)",
		"Satyabhama Institute of Science and Technology",
		"Sathyabama Institute of Science and Technology",
		"Chennai Mathematical Institute",
		"Vel Tech Rangarajan Dr. Sagunthala R & D Institute of Science and Technology",
		"MOP Vaishnav College for Women",
		"Ethiraj College for Women",
		"Pachaiyappa's College",
		"Presidency College, Chennai",
		"Queen Mary's College",
		"Madras School of Economics",
		"National Institute of Fashion Technology (NIFT) Chennai",
		"Central Institute of Plastics Engineering & Technology (CIPET), Chennai",
		"Tamil Nadu Dr. Ambedkar Law University",
		"University of Madras",
		'St. Joseph\'s College, Chennai',
		// Add more colleges as needed
		
	];

 const Years = [1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
	1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
	2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
	2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
	2020, 2021, 2022, 2023, 2024]


	const [selectedStartingYear, setSelectedValue] = useState("");
	const [endingYear, setEndingValue] = useState("");
	const [endingValuess, setEndingValues] = useState("");

	// Define a function to handle value changes
	const handleAutocompleteChange = (value) => {
		setSelectedValue(value);

	}


	const {
	
		getValues

	} = useForm(
		
	)

	console.log(getValues("candidates_qualification.candidates_qualifications"),"getValues12345")
	
	 
	

const handleAutocompleteChanges = (value) => {	
    // const selectedStartingYear = parseInt(selectedStartingYear);
    const endingYear = parseInt(value);
    setEndingValue(value)
	console.log("Starting year must be greater than the ending year.")

    // Check if the selected starting year is greater than the ending year
    if (selectedStartingYear >= endingYear) {
		console.log("Starting year must be greater than the ending yuuuuuuuuear.")
		setAlert(alertState({
			message: "Starting year must be greater than the ending year.",
			severity: "error",
			show: true
			}));
        
		
    }

}

const [clearValue, setClearValue] = useState(false);

  const handleClearValue = () => {
    setClearValue(true); // Update clearValue state to trigger clearing of selected values
  };



	return (
		<section style={{gridColumn:"1 / -1"}}>
			<h4>Under-Graduate/Diploma</h4>
			<br />
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gridColumn="1 / -1"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>
				<LibAutocomplete 
						control={props.control}
						options={chennaiUniversitiesAndColleges}
						name={props.name + ".University"}
						rules={textValidation("University")}
					
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "University/College"
						}}
					/>


				<LibTextField 
					control={props.control}
					name={props.name + ".Course"}
					rules={textValidation("Course")}
					textfieldProps={{
						label: "Course"
					}}
				/>
				<LibTextField 
					control={props.control}
					name={props.name + ".stream"}
					rules={textValidation("Stream")}
					textfieldProps={{
						label: "Stream"
					}}
				/>
					<LibTextField 
					control={props.control}
					name={props.name + ".percentage"}
					// defaultValue={props.formDatas.item?.persentage || ''}
					// rules={textValidation("Percentage %")}
					rules={Percentages()}
					textfieldProps={{
						label: "Percentage %",
						type: "number",
					}}
				/>
				{/* <Box></Box> */}
				<Typography variant="body2">Course Duration</Typography>
				<Box></Box>
				<LibAutocomplete 
					control={props.control}
					options={Years.reverse()}
					name={props.name + ".Starting_Year"}
					rules={textValidation("Ending Year")}
					// onChange={(value) => handleAutocompleteChange(value)}
					autocompleteProps={{
						getOptionLabel: option => option,
						freeSolo: true,
						renderTags: (value, getTagProps) =>
							value.map((option, index) => (
								<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
							)),
					}}
					textfieldProps={{
						label: "Starting Year"
					}}
				/>

				{/* <LibAutocomplete 
						control={props.control}
						options={Years.reverse()}
						name={props.name + ".Starting_Year"}
						// onChange={handleAutocompleteChange} // Pass the callback function
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						
						}}
						textfieldProps={{
							label: "Starting Year"
							
						}}
						

					/> */}
{/* 
				<LibAutocomplete 
						control={props.control}
						options={Years.reverse()}
						name={props.name + ".Ending_Year"}
						rules={textValidation("Ending Year")}
						onAutocompleteChange={handleAutocompleteChanges} // Pass the callback function
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
								
								clearButtonProps: ( // Define clearButtonProps
								<IconButton onClick={() => props.onClearInput()} edge="end">
									<ClearIcon />
								</IconButton>
							),

						}}
							
					
						textfieldProps={{
							label: "Ending Year",
					
						}}
					
			
					/>  */}

<LibAutocomplete
  control={props.control}
  options={Years}
  name={props.name + ".Ending_Year"}
  rules={textValidation("Ending Year")}
//   onChange={handleAutocompleteChanges}
// onChange={(value) => handleAutocompleteChanges(value)}
  autocompleteProps={{
    getOptionLabel: option => option,
    freeSolo: true,
    renderTags: (value, getTagProps) =>
      value.map((option, index) => (
        <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
      )),
  }}
  textfieldProps={{
    label: "Ending Year",
  }}
  selectedStartingYear={selectedStartingYear}
  endingYear={endingYear}
  clearOnBlur={true} 
/>
{/* 

<LibAutocomplete 

		control={props.control}
		options={Years}
		name={props.name + ".Ending_Year"}
		rules={textValidation("Ending Year")}
		onAutocompleteChange={handleAutocompleteChanges}
		autocompleteProps={{
			getOptionLabel: option => option,
			freeSolo: true,
			renderTags: (value, getTagProps) =>
				value.map((option, index) => (
					<Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
				)),
		}}
		textfieldProps={{
			label: "Ending Year",
		}}
		
	/> */}


				<LibRadioGroup row control={props.control}   label={'Course Type'} name={props.name + ".course_type"} >
					<FormControlLabel  value= "Full Time" control={<Radio />} label="Full time" />
					<FormControlLabel  value="Part Time" control={<Radio />} label="Part time" />
					<FormControlLabel  value="Corres" control={<Radio />} label="Distance Learning" /> 
				</LibRadioGroup>

				{/* <LibRadioGroup row control={props.control}   name={props.name + ".primary_graducation"} >
					<FormControlLabel  value= 'true' control={<Radio />} label="Consider this to be my main master" />
				</LibRadioGroup> */}



				<LibCheckbox 
					control={props.control}
					name={props.name + ".primary_graducation"}
					defaultValue={checked}
					setChecked={setChecked}
					rules={textValidation("primary_graducation")}

					// checked={}
					// checked={getValues("primary_graducation")}
					FormControlLabelProps={{
						label: "Consider this to be my main master's"
					}}
				/> 
			</Box>
		</section>
	)
}
/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues, any>,
 * 	name: import("react-hook-form").FieldValues
 * }} props
 * @returns {JSX.Element}
 */
export function Postgraduate(props) {
	const theme = useTheme();
	const chennaiUniversitiesAndColleges = [
		"Anna University",
		"Indian Institute of Technology Madras (IIT Madras)",
		"Madras Institute of Technology (MIT)",
		"Madras Christian College (MCC)",
		"Stella Maris College",
		"Loyola College, Chennai",
		"SRM Institute of Science and Technology (SRMIST)",
		"Vellore Institute of Technology (VIT)",
		"Hindustan Institute of Technology and Science (HITS)",
		"Satyabhama Institute of Science and Technology",
		"Sathyabama Institute of Science and Technology",
		"Chennai Mathematical Institute",
		"Vel Tech Rangarajan Dr. Sagunthala R & D Institute of Science and Technology",
		"MOP Vaishnav College for Women",
		"Ethiraj College for Women",
		"Pachaiyappa's College",
		"Presidency College, Chennai",
		"Queen Mary's College",
		"Madras School of Economics",
		"National Institute of Fashion Technology (NIFT) Chennai",
		"Central Institute of Plastics Engineering & Technology (CIPET), Chennai",
		"Tamil Nadu Dr. Ambedkar Law University",
		"University of Madras"
	];
	const Years = [1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
		1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
		2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
		2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
		2020, 2021, 2022, 2023, 2024]
	// uni_name
	// course
	// stream
	// passOutYear
	return (
		<section style={{gridColumn:"1 / -1"}}>
			<h4>Masters/Post-Graduation</h4>
			<br />
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>
				<LibAutocomplete 
						control={props.control}
						options={chennaiUniversitiesAndColleges}
						name={props.name + ".University"}
						rules={textValidation("School Medium")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "University/College"
						}}
					/>

				<LibTextField 
					control={props.control}
					name={props.name + ".Course"}
					rules={textValidation("School Medium")}
					textfieldProps={{
						label: "Course"
					}}
				/>
				<LibTextField 
					control={props.control}
					name={props.name + ".stream"}
					rules={textValidation("Stream")}
					textfieldProps={{
						label: "Stream"
					}}
				/>
				<LibTextField 
					control={props.control}
					name={props.name + ".percentage"}
					// defaultValue={props.formDatas.item?.persentage || ''}
					// rules={textValidation("Percentage %")}
					rules={Percentages()}
					textfieldProps={{
						label: "Percentage %",
						type: "number",
					}}
				/>
				{/* <Box></Box> */}
				<Typography>Course Duration</Typography>
				<Box></Box>
				<LibAutocomplete 
						control={props.control}
						options={Years}
						name={props.name + ".Starting_Year"}
						rules={textValidation("School Medium")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Starting Year"
						}}
					/>
				<LibAutocomplete 
						control={props.control}
						options={Years.reverse()}
						name={props.name + ".Ending_Year"}
						rules={textValidation("Ending Year")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Ending Year"
						}}
					/>
					<LibRadioGroup row control={props.control} label={'Course Type'} name={props.name + ".course_type"} >
					<FormControlLabel  value= "Full Time" control={<Radio />} label="Full time" />
					<FormControlLabel  value="Part Time" control={<Radio />} label="Part time" />
					<FormControlLabel  value="Corres" control={<Radio />} label="Distance Learning" /> 
				</LibRadioGroup>

				{/* <LibRadioGroup row control={props.control}   name={props.name + ".primary_graducation"} >
					<FormControlLabel  value= 'true' control={<Radio />} label="Consider this to be my main master" />
				</LibRadioGroup> */}

				
				<LibCheckbox 
					control={props.control}
					name={props.name + ".primary_graducation"}
					// defaultValue={checked}
					// setChecked={setChecked}
					rules={textValidation("primary_graducation")}

					// checked={}
					// checked={getValues("primary_graducation")}
					FormControlLabelProps={{
						label: "Consider this to be my main master's"
					}}
				/> 


				{/* <LibCheckbox 
						control={props.control}
						name={"emailUpdates"}
						FormControlLabelProps={{
							label: "Consider this to be my main master's"
						}}
					/> */}
			</Box>
			
		</section>
	)
}

/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues, any>,
 * 	name: import("react-hook-form").FieldValues
 * }} props
 * @returns {JSX.Element}
 */
export function Doctorate(props) {
	const theme = useTheme();
	const chennaiUniversitiesAndColleges = [
		"Anna University",
		"Indian Institute of Technology Madras (IIT Madras)",
		"Madras Institute of Technology (MIT)",
		"Madras Christian College (MCC)",
		"Stella Maris College",
		"Loyola College, Chennai",
		"SRM Institute of Science and Technology (SRMIST)",
		"Vellore Institute of Technology (VIT)",
		"Hindustan Institute of Technology and Science (HITS)",
		"Satyabhama Institute of Science and Technology",
		"Sathyabama Institute of Science and Technology",
		"Chennai Mathematical Institute",
		"Vel Tech Rangarajan Dr. Sagunthala R & D Institute of Science and Technology",
		"MOP Vaishnav College for Women",
		"Ethiraj College for Women",
		"Pachaiyappa's College",
		"Presidency College, Chennai",
		"Queen Mary's College",
		"Madras School of Economics",
		"National Institute of Fashion Technology (NIFT) Chennai",
		"Central Institute of Plastics Engineering & Technology (CIPET), Chennai",
		"Tamil Nadu Dr. Ambedkar Law University",
		"University of Madras"
	];
	const Years = [1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
		1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
		2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
		2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
		2020, 2021, 2022, 2023, 2024]
	return (
		<section style={{gridColumn:"1 / -1"}}>
			<h4>Doctorate/PhD</h4>
			<br />
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gridColumn="1 / -1"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>
				<LibAutocomplete 
						control={props.control}
						options={chennaiUniversitiesAndColleges}
						name={props.name + ".University"}
						rules={textValidation("University/College")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "University/College"
						}}
					/> 
				<LibTextField 
					control={props.control}
					name={props.name + ".Course"}
					rules={textValidation("Course")}
					textfieldProps={{
						label: "Course"
					}}
				/>
				<LibTextField 
					control={props.control}
					name={props.name + ".stream"}
					rules={textValidation("Stream")}
					textfieldProps={{
						label: "Stream"
					}}
				/>
				<LibTextField 
					control={props.control}
					name={props.name + ".percentage"}
					// defaultValue={props.formDatas.item?.persentage || ''}
					// rules={textValidation("Percentage %")}
					rules={Percentages()}
					textfieldProps={{
						label: "Percentage %",
						type: "number",
					}}
				/>
				{/* <Box></Box> */}
				<Typography>Course Duration</Typography>
				<Typography></Typography>
				<LibAutocomplete 
						control={props.control}
						options={Years.reverse()}
						name={props.name + ".Starting_Year"}
						rules={textValidation("Starting_Year")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Starting Year"
						}}
					/>
				<LibAutocomplete 
						control={props.control}
						options={Years.reverse()}
						name={props.name + ".Ending_Year"}
						rules={textValidation("Ending_Year")}
						autocompleteProps={{
							getOptionLabel: option => option,
							freeSolo: true,
							renderTags: (value, getTagProps) =>
								value.map((option, index) => (
								  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
								)),
						}}
						textfieldProps={{
							label: "Ending Year"
						}}
					/>

			
			<LibRadioGroup row control={props.control} label={'Course Type'} name={props.name + ".course_type"} >
					<FormControlLabel  value= "Full Time" control={<Radio />} label="Full time" />
					<FormControlLabel  value="Part Time" control={<Radio />} label="Part time" />
					<FormControlLabel  value="Distance Learning" control={<Radio />} label="Distance Learning" /> 
				</LibRadioGroup>

				{/* <LibCheckbox 
						control={props.control}
						name={"emailUpdates"}
						FormControlLabelProps={{
							label: "Consider this to be my main master's"
						}}
					/> */}

				
				<LibCheckbox 
					control={props.control}
					name={props.name + ".primary_graducation"}
					// defaultValue={checked}
					// setChecked={setChecked}
					rules={textValidation("primary_graducation")}

					// checked={}
					// checked={getValues("primary_graducation")}
					FormControlLabelProps={{
						label: "Consider this to be my main master's"
					}}
				/> 
			</Box>
		</section>
	)}

/**
 * 
 * @param {{
 * 	control: import("react-hook-form").Control<import("react-hook-form").FieldValues, any>,
 * 	name: import("react-hook-form").FieldValues,
 * 	isEmployed: boolean
 * }} props
 * @returns {JSX.Element}
 */


export function WorkExperience	(props) {
	const theme = useTheme();

	const [showButton, setShowButton] = useState(false);


	console.log(showButton,"showButton")
	


	useEffect(() => {

		if (props.data ) {
			const hasTrue = props?.data?.some(item => item.current_job_status);

	  props.setFormValue("workExperience.isEmployed",!hasTrue);

	  
	  // Set state based on the condition
	  setShowButton(hasTrue);


		}
	  // Check if both objects have current_job_status as false
	  
	}, [props.data]); //



	console.log(props.isEmployed,"isEmployedeeeeee")
	console.log(props.name + ".isEmployed","isEmployedeeeeeesssssssss")
	// isEmployed,
	// recent_company
	// recent_salary
	// join_date
	// leaving_date
	// notice_period
	return (
		<section style={{gridColumn:"1 / -1"}}>
	

			
			<Typography sx={{marginTop : 1 , fontWeight : 500 }} variant="h3" >

			 Work experience  
				{/* <AddOutlined style={{ marginLeft: "auto" }} /> */}
			</Typography>

			<Typography sx={{marginBottom :1}}  color={'#00000080'} >Details like job title, company name, etc, help employers understand your work.</Typography>

			
       
			{/* <h4>Work details</h4> */}
			{/* <br /> */}
			<Box display="grid" 
			gridTemplateColumns="1fr"
			marginInline="auto"
			width="100%"
			gap={0 + ' ' + theme.spacing(3)} sx={{
				[theme.breakpoints.up("sm")]: {
					gridTemplateColumns: "repeat(2, 1fr)",
				}}
			}>
				<Box>
					<Controller 
						control={props.control}
						name={props.name + ".isEmployed"}
						
						render={({field, fieldState}) => (
							<FormControl fullWidth>
								<ToggleButtonGroup
									size="small"
									style={{marginTop: theme.spacing(0.5)}}
									fullWidth
									exclusive
									{...field}
									value={field.value}
								onChange={(_, value) =>{console.log("isNull",value); if ( value !== null ){if (showButton) {
										return null
									}  else {
										return field.onChange(value);
									}} }}
								>
								{showButton ? null : <ToggleButton value={true}>Employed</ToggleButton>}

								{/* {props.data.map((item, index) => (
									<div key={index}>
										{item.current_job_status ? null : <ToggleButton value={true}>Employed</ToggleButton>}
									</div>
									))} */}
									<ToggleButton value={false}>Unemployed</ToggleButton>
								</ToggleButtonGroup>
								<FormHelperText> </FormHelperText>
							</FormControl>
						)}
					/>
				</Box>

				<LibTextField 
					control={props.control}
					name={props.name + ".recent_company"}
					rules={textValidation("company")}
					textfieldProps={{
						label: props.isEmployed ? "Current company" : "Previous company"
					}}
				/>

				<LibTextField 
				control={props.control}
				name={props.name + ".designation"}
				rules={textValidation("designation")}
				textfieldProps={{
					label: props.isEmployed ? "Current designation" : "Previous desiganation"
				}}
				/>

				<LibTextField 
				control={props.control}
				name={props.name + ".Skills_used"}
				rules={textValidation("Skills")}
				textfieldProps={{
					label: props.isEmployed ? "Current Skills" : "Used Skills"
				}}
				/>

				

				<LibTextField 
					control={props.control}
					name={props.name + ".recent_salary"}
					rules={textValidation("Current (CTC)")}
					textfieldProps={{
						label: props.isEmployed ? "Current (CTC)" : "Previous (CTC)",
						type: "number",
						InputProps: {
							startAdornment: (
								<InputAdornment position="start">
									<CurrencyRupeeOutlined />
								</InputAdornment>
							)
						}
					}}
				/>

				{/* <Stack direction="row" spacing={2} mt={theme.spacing(1)}>	 */}
					<LibTextField
						control={props.control}
						name={props.name + '.dates.joinDate'}
						rules={textValidation("Join date")}
						textfieldProps={{
							label: "Join date",
							type: "date",
							style: {flexGrow: '1'}
						}}
					/>

			
				

				{/* </Stack> */}

				{props.isEmployed === false && (
					<LibTextField
						control={props.control}
						name={props.name + '.dates.leavingDate'}
						rules={textValidation("Leaving date")}
						textfieldProps={{
							label: "Leaving date",
							type: "date",
							style: {flexGrow: '1'}
						}}
					/>
				)}

				{props.isEmployed  && (
				<LibSelect 
					control={props.control}
					name={props.name + ".notice_period"}
					rules={textValidation("Notice period")}
					label="Notice period"
				>
					<MenuItem value="immediate">Immediate joiner</MenuItem>
					<MenuItem value="1+ weeks">1+ weeks</MenuItem>
					<MenuItem value="2+ weeks">2+ weeks</MenuItem>
					<MenuItem value="3+ weeks">3+ weeks</MenuItem>
					<MenuItem value="1+ months">1+ months</MenuItem>
					<MenuItem value="3+ months">3+ months</MenuItem>
				</LibSelect>
				)}
			</Box>
		</section>
	)
}













				
{/* <LibAutocomplete 
control={control}
// options={preferences}
options= {countries_in_world}

name={"country"}
autocompleteProps={{
	multiple: false,
	getOptionLabel: option => option,
	freeSolo: true,
	renderTags: (value, getTagProps) =>
		value.map((option, index) => (
		  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
		)),
}}
textfieldProps={{
	// placeholder: "Enter your preffered job titles",
	label: "Countries"
}}
/>


<LibAutocomplete 
control={control}
// options={preferences}
options= {states_in_india}

name={"state"}
autocompleteProps={{
	multiple: false,
	getOptionLabel: option => option,
	freeSolo: true,
	renderTags: (value, getTagProps) =>
		value.map((option, index) => (
		  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
		)),
}}
textfieldProps={{
	// placeholder: "Enter your preffered job titles",
	label: "State"
}}
/>
<LibAutocomplete 
control={control}
// options={preferences}
options= {states_in_india}

name={"city"}
autocompleteProps={{
	multiple: false,
	getOptionLabel: option => option,
	freeSolo: true,
	renderTags: (value, getTagProps) =>
		value.map((option, index) => (
		  <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
		)),
}}
textfieldProps={{
	// placeholder: "Enter your preffered job titles",
	label: "City"
}}
/>  */}
