import axios from 'axios';


export const register = async (formData) => {
    try {
      const response = await axios.post("/candidate/register/", formData);
      return response;
    } catch (err) {
      return err.response;
    }
  };

export const basicdetails = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/basic_details/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };
export const add_school_qualification = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_school_qualification/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };

export const add_certificate = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_certificate/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };
export const Add_Graduate_qualification = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_Graduate_qualification/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };

export const add_post_graduate_qualification = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_post_Graduate_qualification/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };

export const add_doctorate_qualification = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_doctorate_qualification/", formData);
      console.log(response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return response;
    } catch (err) {
     
      console.log(err.response,"eeeeeeeeeeeeeeeeeeeeeeee")
      return err.response;
    }
  };

export const userprefer = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/your_preferrence/", formData);
      console.log(response)
      return response;
    } catch (err) {
      
  
      return err.response;
    }
  };

// export const userprefers = async (formData) => {
//     console.log(formData);
//     try {
//       const response = await axios.post("/candidate/user_pre_second/", formData);
//       console.log(response)
//       return response;
//     } catch (err) {
      
  
//       return err.response;
//     }
//   };
  


export const resend = async () => {
  
    try {
      const response = await axios.post("/candidate/resend/");
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

export const otp_verify = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/verify/", formData);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

export const Submit_application = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Submit_application/", formData , {headers : {"Content-Type" : "multipart/form-data"}} );
      // const response = await axios.post("/candidate/Submit_application/", formData);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };


  export const get_jobs = async (data) => {
    try {
      const response = await axios.get(`/candidate/get_all_jobs/?types=${data}`);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const notification_count_api = async (data) => {
    try {
      const response = await axios.get(`/candidate/notification_count_api/`);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const view_notification_details = async () => {
    try {
      const response = await axios.get(`/candidate/view_notification_details/`);
      return response;
    } catch (err) {
      return err.response;
    }
  };


  export const partner_list = async () => {
    try {
      const response = await axios.get('/partner/for_seeker_partner_list');
      return response;
    } catch (err) {
      return err.response;
    }
  };


  export const get_country_list = async () => {
    try {
      const response = await axios.get("/api/get_country_list/");
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const get_state = async () => {
    try {
      const response = await axios.get("/api/get_state/");
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const get_internships = async (data) => {
    try {
      const response = await axios.get(`/candidate/get_all_jobs/?types=${data}`);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const get_profile = async () => {
    try {
      const response = await axios.get("/candidate/get_profile/");
      return response;
    } catch (err) {

      localStorage.removeItem("accesstoken");
    
     

      return err.response;
    }
  };

  export const get_details = async (data) => {
    try {
      const response = await axios.get(`/candidate/get_details/?id=${data}`);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const particular_partner = async (data) => {
    try {
      const response = await axios.get(`/partner/particular_partner?id=${data}`);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const purchase_partner = async (data) => {
    try {
      const response = await axios.get(`/partner/purchase_partner?id=${data}`);
      return response;
    } catch (err) {
      return err.response;
    }
  };


  export const applied_history = async () => {
    try {
      const response = await axios.get("/candidate/Applied_history/");
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const addbookmark = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/candidate/Add_bookmarks/", formData);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const Noticeapi = async (formData) => {
 
    try {
      const response = await axios.post("/candidate/noticeapi", formData);
      
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };


  export const purchsed_placement = async (formData) => {
    console.log(formData);
    try {
      const response = await axios.post("/partner/purchsed_placement", formData);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const resume_delete = async () => {
  
    try {
      const response = await axios.post("/candidate/resume_delete/");
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };
  export const audiodelete = async () => {
  
    try {
      const response = await axios.post("/candidate/audiodelete/");
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };
  export const edit_audio = async (pic) => {
  
    try {
      const response = await axios.post("/candidate/edit_audio/",pic,{headers : {"Content-Type" : "multipart/form-data"}});
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };


  export const addprofile = async (pic) => {
    console.log(pic);
    try {
      const response = await axios.post("/candidate/profile_photo/",pic,{headers : {"Content-Type" : "multipart/form-data"}});
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const addresume = async (pic) => {
    console.log(pic);
    try {
      const response = await axios.post("/candidate/resume_upload/",pic,{headers : {"Content-Type" : "multipart/form-data"}});
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const getbookmark = async (id) => {
    console.log(id);
    try {
      const response = await axios.get(`/candidate/bookmark_status/?id=${id}`);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const Recommend_jobs = async () => {
 
    try {
      const response = await axios.get('/candidate/Recommend_jobs/');
      console.log(response)
      return response;
    } catch (err) {
      localStorage.removeItem("accesstoken");
      
      return err.response;
    }
  };

  
  export const bookmark_history = async () => {
    try {
      const response = await axios.get("/candidate/bookmark_history/");

      return response;
    } catch (err) {
      return err.response;
    }
  };
  
  export const datefilter = async (data) => {
    try {
      const response = await axios.get(`/candidate/date_filter?date_types=${data}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const user_preferred_jobs = async () => {
    try {
      const response = await axios.get('/candidate/auto_filter/');
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const Job_title_dropdown = async () => {
    try {
      const response = await axios.get('/api/Job_title_dropdown');
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };
  export const Job_title_skills_dropdown = async () => {
    try {
      const response = await axios.get('/api/Job_title_skills_dropdown');
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const location_dropdown = async () => {
    try {
      const response = await axios.get('/api/location_dropdown');
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };
  

  
  export const searchbar = async (data) => {
    try {
      const queryParams = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
      const response = await axios.get(`/candidate/search?${queryParams}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const salary_filter = async (data) => {
    try {
    //   const queryParams = Object.entries(data)
    // .map(([key, value]) => `${key}=${value}`)
    // .join('&');
      const response = await axios.get(`/candidate/salary_filter?data=${data}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };
  export const year_of_filter = async (data) => {
    try {
    //   const queryParams = Object.entries(data)
    // .map(([key, value]) => `${key}=${value}`)
    // .join('&');
      const response = await axios.get(`/candidate/year_of_filter?data=${data}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };


  export const work_mode = async (data,date_type,salary_type , y_of_e) => {
    try {
      
    const queryParams = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    console.log(queryParams,"queryParamstest")
      const response = await axios.get(`/candidate/work_mode?${queryParams}&date_types=${date_type}&salary=${salary_type}&year=${y_of_e}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const intern_work_mode = async (data,date_type,salary_type , y_of_e) => {
    try {
      
    const queryParams = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
      const response = await axios.get(`/candidate/intern_work_mode?${queryParams}&date_types=${date_type}&salary=${salary_type}&year=${y_of_e}`);
      console.log(response);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  export const login = async (data) => {

    try {
      const response = await axios.post("/candidate/login/",data)
      return response;
    }
    catch (err) {
      return err.response;
    }
  } 
  export const add_profile_summary = async (data) => {

    try {
      const response = await axios.post("/candidate/add_profile_summary/",data)
      return response;
    }
    catch (err) {
      return err.response;
    }
  } 

  export const add_notification = async (data) => {

    try {
      const response = await axios.post("/candidate/add_notification/",data)
      return response;
    }
    catch (err) {
      return err.response;
    }
  } 

  export const changepassword = async (data) => {

    try {
      const response = await axios.post("/candidate/password_change/",data)
      return response;
      
    }
    catch (err) {
      return err.response;

    }
  }
  export const edit_projects = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_projects/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }
  export const edit_user_prefer = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_user_prefer/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const edit_profile = async (data) => {
    try {
      const response = await axios.patch("/candidate/edit_profile/",data , {headers : {"Content-Type" : "multipart/form-data"}})
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }
  export const edit_details = async (data) => {
    try {
      const response = await axios.patch("/candidate/edit_details/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  
  export const Edit_employment_details = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_employment_details/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const Edit_Graduate_qualification = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_Graduate_qualification/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const Edit_post_Graduate_qualification = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_post_Graduate_qualification/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const Edit_doctorate_qualification = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_doctorate_qualification/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const edit_certificate = async (data) => {
    try {
      const response = await axios.patch("/candidate/edit_certificate/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }
  export const Edit_School_qualification = async (data) => {
    try {
      const response = await axios.patch("/candidate/Edit_School_qualification/",data)
      console.log(response,"edit")
      return response;
    }
    catch (err) {
      console.log(err,"error")
      return err.response;

    }
  }

  export const forgotpass = async (data) => {
    try {
      const response = await axios.post("/candidate/generate_otp/",data);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };

  export const verify = async (data) => {
    try {
      const response = await axios.post("/candidate/password_otp_verify/",data);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };
  export const addworkExperience = async (data) => {
    try {

      const response = await axios.post("/candidate/Add_employment_details/",data);
      console.log(response)
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };
  export const addproject = async (data) => {
    try {

      const response = await axios.post("/candidate/Add_projects/",data);
      console.log(response,"ooo")
      return response;
    } catch (err) {
      console.log(err);
      return err.response;
    }
  };





  export const GetAllCountry = async () => {
		var config = {
		  method: 'get',
		  url: 'https://api.countrystatecity.in/v1/countries',
		  headers: {
			'X-CSCAPI-KEY': 'RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA=='
		  }
		};
		try {
		  const response = await axios(config);
		  console.log(response);
		  return response;
		} catch (err) {
		  console.log(err);
		  return err.response;
		}
	  };

	  export const GetAllState = async (state) => {
		var config = {
		  method: 'get',
		  url: `https://api.countrystatecity.in/v1/countries/${state}/states`,
		  headers: {
			'X-CSCAPI-KEY': 'RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA=='
		  }
		};
		try {
		  const response = await axios(config);
		  console.log(response);
		  return response;
		} catch (err) {
		  console.log(err);
		  return err.response;
		}
	  };
	  export const GetAllStatecity = async (state,cities) => {
		var config = {
		  method: 'get',
		  url: `https://api.countrystatecity.in/v1/countries/${state}/states/${cities}/cities`,
		  headers: {
			'X-CSCAPI-KEY': 'RWxveU1QMHpXU3ZhUlo2cVRWUHMwSER5WWJ0czBzTmpMeGZoSFdwTA=='
		  }
		};
		try {
		  const response = await axios(config);
		  console.log(response);
		  return response;
		} catch (err) {
		  console.log(err);
		  return err.response;
		}
	  };
