import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { forwardRef, useState, useContext } from "react";
// import { alertState, AlertToast } from "../divine-constants";
import axios from 'axios';
import {alertState ,  AlertToast } from "./divine-constants";
import { get_profile } from "./pages/Axiosfetch/Axiosfetch";

function DeleteModal({url, setDialogDisplay, dialogDisplay , data ,fetchProfileData, setValue, type="others" }) {
	const [progress, setProgress] = useState(false);
	console.log(data,"i_d")
	const {setAlert} = useContext(AlertToast);
	
	// console.log(setFormdata,"setFormdatas")
    async function fetchSync  () {
		const response = await get_profile(); // Call your API function to fetch profile data
		if (response.status === 200) {
		
			setValue('projectdetails', response.data.candidate_projects);
			setValue('candidate_percentage', response.data.candidate_percentage);			
            setValue('workExperience', response.data.work_experience);
			setValue("Certifications",response.data.certification);
			setValue("personalDetails.Languages",response.data.Languages);
	
		}
		
	}	
	
    function handleDelete() {
        setProgress(true);
        try {
            axios.post(url, data)
                .then(async(response)  => {
                    if (response.status === 200) {
				
						if (type == "education"){
							fetchProfileData()
						}
						await fetchSync()
						setDialogDisplay(false);
					
						// const response = await get_profile(); // Call your API function to fetch profile data
						// if (response.status === 200) {
						
						// 	setValue('projectdetails', response.data.candidate_projects);
						// 	setValue('candidate_percentage', response.data.candidate_percentage);			
						// 	setValue('workExperience', response.data.work_experience);
						// 	setValue("Certifications",response.data.certification);
					
						// }
					
						
                  
                        
                        setAlert(alertState({
                            title: "",
                            message: "Record deleted successfully",
                            severity: "success",
                            show: true
                        }));
                    }
                })
                .catch(err => {
					console.log("Unable to delete")
                    return err.response;
                })
                .finally(() => {
                    setProgress(false);
                });
        } catch (err) {
            console.error("Error: ", err);
        }
    }
    

	// function handleDelete() {
	// 	setProgress(true);
	// 	api().patch(url, data).then(res => {
	// 		if(res.status === 200) {
	// 			setDialogDisplay(false);
	// 			setAlert(alertState({
	// 				title: "",
	// 				message: "Record deleted successfully",
	// 				severity: "success",
	// 				show: true	
	// 			}))
	// 			fetchData();
	// 		}
	// 	}).catch(() => {
	// 		setAlert(alertState({
	// 			title: "",
	// 			message: "Unable to delete",
	// 			severity: "error",
	// 			show: true	
	// 		}));
	// 	}).finally(() => setProgress(false));
	// }
	return <Dialog open={dialogDisplay} disableEnforceFocus keepMounted>
		<DialogTitle color="red">Warning !</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Are you sure you want to delete this record ?<br />
				<br />
				<b>This action is not reversible.</b>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button variant="text" disabled={progress} onClick={handleDelete} color="error"> {progress && <><CircularProgress sx={{float: "right"}} size="16px" />&nbsp;&nbsp;</>} Delete</Button>
			<Button variant="text" disabled={progress} onClick={() => setDialogDisplay(false)} color="warning">Cancel</Button>
		</DialogActions>
	</Dialog>
}

export default forwardRef((props, _ref) => <DeleteModal {...props} />)