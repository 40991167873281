import React, { useEffect, useState } from "react";
import { onMessageListener, requestPermission } from "./Firebase";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Margin } from "@mui/icons-material";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  useEffect(() => {
    requestPermission();
    console.log("Entered twice");
    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });

      //   `${payload?.notification?.title} : ${payload?.notification?.body}`
      toast(
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1">
              {payload?.notification?.title}
            </Typography>

            <Typography variant="subtitle2">
              {payload?.notification?.body}
            </Typography>
          </Stack>

          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

          {/* <Divider  flexItem/> */}

          <img
            width={55}
            src={payload?.data?.logo}
            // src={profile}
            sx={{ border: "1px solid #cdcdcd" }}
            alt="dummy"
          />

          {/* <Avatar
        alt="Remy Sharp"
        src={payload?.data?.logo}
        sx={{ width: 56, height: 56 , border:"1px solid #cdcdcd"}}
      /> */}
        </Stack>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    });

    return () => {
      unsubscribe.catch((err) => console.log("failed", err));
    };
  }, [onMessageListener()]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
};

export default Notification;
