import { Drawer, List } from "@mui/material";

export default function Sidenav({state}) {
	return (
		<Drawer open={state[0]}>
			<List>

			</List>
		</Drawer>
	)
}