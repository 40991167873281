
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { otp_verify, resend } from './Axiosfetch/Axiosfetch';
import { Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Registration from './Registration';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


const SuccessPopup = ({ onClose, open }) => {
  const theme = useTheme();
  const [otpValue, setOtpValue] = useState('');
  const navigate = useNavigate();

  const [verificationResult, setVerificationResult] = useState(null);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [remainingSeconds, setRemainingSeconds] = useState(60);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event) => {
    setOtpValue(event.target.value);
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
   
    try {
      const resdata = { otp: otpValue }; // Assuming you want to send the OTP as part of the email field

      const { status, data } = await otp_verify(resdata);
      if (status === 200) {
        localStorage.setItem('accesstoken', data.token);
    
        navigate("/details");
   
      } else {
        // throw new Error(`Something went wrong ${status}`);
        setVerificationResult('error');
        setMessage(data.error);
        // setOtpValue("")
        setMessageColor('red');
      }

    } catch (error) {
      setVerificationResult('error');
      setMessage('An error occurred. Please try again later.');
      setMessageColor('red');
      // Handle error or show a message to the user
    }
  };

  const handleResendOTP = () => {
    setRemainingSeconds(60);
    setIsTimerRunning(true);
    setVerificationResult(null);
    setOtpValue("")
    // setLoading(true)
  // Assuming you want to send the OTP as part of the email field
    const { status, data } = resend();
    if (status === 200) {
      // setLoading(false)
    }
  };
  

  useEffect(() => {
    let countdownTimer;
    if (isTimerRunning && remainingSeconds > 0) {
      countdownTimer = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      
    } else {
      // Timeout reached, handle as needed (e.g., disable the "Resend OTP" button)
      setIsTimerRunning(false);
      clearInterval(countdownTimer);
      setVerificationResult('error');
      setMessage("OTP Expried");
      setMessageColor('red');
    }

    // Cleanup the timer when the component unmounts or when remainingSeconds becomes 0
    return () => {
      clearInterval(countdownTimer);
    };
  }, [isTimerRunning, remainingSeconds]);


  return (
    <React.Fragment>
      <div style={{maxwidth:"lg",height:"100%",backgroundColor:"#fff"}}>
      <Dialog open={open} onClose={onClose} fullWidth={true} >
       
        {verificationResult && (
          <div style={{ backgroundColor: messageColor, color: 'white', padding: '10px', textAlign: 'center' }}>
            {message}
          </div>
        )}
        
        <DialogTitle>OTP Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your OTP of email address.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="otp"
            name="otp"
            label="OTP"
            type="number"
            fullWidth
            variant="standard"
            value={otpValue}
            onChange={handleInputChange}
          />
          
        <p>Remaining Seconds: {remainingSeconds}</p>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Back</Button>
    

          {/* <LoadingButton
      
      onClick={handleResendOTP}
      disabled={isTimerRunning}
      loading={loading}
      type="submit" 
      loadingPosition="start"
      variant="contained"
      color="primary"

      // startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
      startIcon={loading ? <SaveIcon size={20}  /> : null}
    >
      Resend OTP
    </LoadingButton>  */}

          <Button onClick={handleResendOTP} disabled={isTimerRunning}>
            Resend OTP
          </Button>
          <Button onClick={handleSubmit} color="primary" >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </React.Fragment>
  );
};

export default SuccessPopup;

