import { Button, IconButton, InputAdornment, Paper, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AlertToast, FullScreenContext,alertState } from "../divine-constants";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { CallOutlined, Visibility, VisibilityOffOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emailValidation, mobileValidation, passwordValidation, textValidation } from "../utils";
import { LibTextField } from "../components/LibComponents";
import { register } from "./Axiosfetch/Axiosfetch";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';



import FormDialog from './OTPverify';

import React from 'react';
import PopupExample from "./resend";

export default function Registration() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setIsFullScreen } = useContext(FullScreenContext);
  const [error, setError] = useState('');
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [ShowSuccessPopup, setShowSuccessPopup] = useState(false);
  const [ShowErrorPopup, setShowErrorPopup] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [loading, setLoading] = React.useState(false);

	const { setAlert } = useContext(AlertToast);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      password: '',
      email: '',
      mobile: '',
    },
  });
  const [success, setSuccess] = useState(false);
  const onSubmit = async (resdata) => {
    setLoading(true);
    try {
      const { status , data  } = await register(resdata);
      if (status === 200) {
		  localStorage.setItem("accesstoken",data.token)
      setAlert(alertState({
        message: "OTP sent successfully",
        severity: "success",
        show: true
      }));
        setShowSuccessPopup(true);
        setVerificationResult(null);
      }else if (status === 403) { 
        setAlert(alertState({
					message: "Email already Registered, Complete the Verification Process",
					severity: "success",
					show: true
				}));
        setShowErrorPopup(true);
        localStorage.setItem("accesstoken",data.token)


      }else if (status === 400) { 
        setAlert(alertState({
					message: "Already registered with this email address.",
					severity: "error",
					show: true
				}));
     
      }else {
        setAlert(alertState({
					message: "Something went wrong! ",
					severity: "error",
					show: true
				}));
      }
    } catch (error) {
      setAlert(alertState({
        message: "Something went wrong! Please try again",
        severity: "error",
        show: true
      }));      
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
  }, []);

  function func() {
    navigate("/");
  }
  const handleClickOpen = () => {
    // setOpen(true);
  };
  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const closeerrorPopup = () => {
    setShowErrorPopup(false);
  };

  // const handleClick = () => {
   
  //   // Simulate an asynchronous action
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 2000);
  // };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "80%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
      }}
    >
      <Button
        variant="text"
        sx={{ width: "fit-content", marginTop: theme.spacing(4) }}
        onClick={func}
      >
        <ArrowBackOutlinedIcon /> Back to home
      </Button>
      {verificationResult && (
            <div style={{ backgroundColor: messageColor, color: 'white', padding: '10px', textAlign: 'center' , borderRadius:'5px'}}>
            {message}
            </div>
        )}
      <Paper elevation={4}>
        <Typography
          variant="h3"
          sx={{ margin: `${theme.spacing(3)} auto`, width: "90%" }}
        >
          Create your profile
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          maxWidth="90%"
          marginInline="auto"
          gap={0 + " " + theme.spacing(3)}
          sx={{
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          }}
        >
          <LibTextField
            control={control}
            name="firstName"
            rules={textValidation("First Name")}
            textfieldProps={{
              label: "First Name",
            }}
          />

          <LibTextField
            control={control}
            name={"middleName"}
            rules={{ required: false }}
            textfieldProps={{
              label: "Middle Name",
              required: false,
            }}
          />

          <LibTextField
            control={control}
            name={"lastName"}
            rules={textValidation("Last name")}
            textfieldProps={{
              label: "Last Name",
            }}
          />

          <LibTextField
            control={control}
            name={"password"}
            rules={passwordValidation()}
            textfieldProps={{
              label: "Password",
              type: passwordIsVisible ? "text" : "password",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setPasswordIsVisible((curr) => !curr)
                      }
                    >
                      {passwordIsVisible ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <LibTextField
            control={control}
            name="email"
            rules={emailValidation()}
            textfieldProps={{
              label: "Email",
              type: "email",
            }}
          />
          <LibTextField
            name="mobile"
            control={control}
            rules={mobileValidation()}
            textfieldProps={{
              label: "Mobile No",
              InputProps: {
                startAdornment: (
                  <InputAdornment
                    sx={{ p: 0 }}
                    position="start"
                  >
                    <CallOutlined />
                  </InputAdornment>
                ),
              },
              type: "tel",
            }}
          />
        </Box>
      </Paper>
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {error}
        </Typography>
      )}
      {success && (
        <Typography
          variant="body2"
          color="success"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          Registration successful! Redirecting...
        </Typography>
      
      )}

 <LoadingButton
      // onClick={handleClick}
      sx={{ marginBottom: theme.spacing(4), width: "fit-content", ml: "auto" }}

      loading={loading}
      type="submit" 
      loadingPosition="start"
      variant="contained"
      color="primary"

      // startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
      startIcon={loading ? <SaveIcon size={20}  /> : null}
    >
      Submit 
    </LoadingButton> 
      

{/* 
      <Button sx={{ marginBottom: theme.spacing(4), width: "fit-content", ml: "auto" }}
        type="submit" 
      >
        Submit
      </Button> */}

      {/* <LoadingButton
        loading
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
      >
        Submit
      </LoadingButton> */}
   

      {ShowSuccessPopup && <FormDialog onClose={closeSuccessPopup} open={ShowSuccessPopup}/>}
  

      {ShowErrorPopup && <PopupExample onClose={closeerrorPopup} open={ShowErrorPopup}/>}
    </Box>
    

  );
}





