import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from "@mui/icons-material/Close";
import { ClickAwayListener, Stack, Typography  } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "../notification/notification.style.css";
import CardItems from "./CardItems";
import NotificationHeading from "./NotificationHeading";
import { view_notification_details } from "../../pages/Axiosfetch/Axiosfetch";


export default function NotificationDrawer({ drawerOpen, setDrawerOpen , }) {

  const [jobs, setJobs] = useState([]);

  const [filteredJobs, setFilteredJobs] = useState([]);

  const [loading, setLoading] = useState(false);

  const [activeJobId, setActiveJobId] = useState(null);

  const [isVisiable, setIsVisible] = useState(true);

  const [deleteid, setDeleteid] = useState(null);

  const [counts, setCounts] = useState(null);

  // Number of items to show initially
  const initialDisplayCount = 3;

  // State to manage the number of items to display
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  // Function to toggle between showing more or fewer items

  const handleToggle = () => {

    if (displayCount === initialDisplayCount) {
      setDisplayCount(filteredJobs.length); // Show all items

      setCounts(filteredJobs.length);
    } else {
      setDisplayCount(initialDisplayCount); // Show only initial items
    }
  };

  const handleRemoveMessage = (id) => {
    console.log(" handleRemoveMessage checks", id);
    setIsVisible(false);
    setDeleteid(id);
    setActiveJobId(id)

    const filteredJobNew  = JSON.parse(JSON.stringify(filteredJobs))

   setTimeout(()=>{

    const arr = filteredJobNew.filter((item) => item.id !== id);

    setFilteredJobs(arr);}, 500);

    

    // setFilteredJobs((prevMessages) => prevMessages.filter((filteredJobs) => filteredJobs.id !== id));

    
  };




  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await view_notification_details();

        if (response && response.data && Array.isArray(response.data)) {
          setFilteredJobs(response.data);
          setLoading(true);
        } else {
          console.error("Invalid data structure:", response);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  
  useEffect(() => {
    console.log("filteredJobs:s", filteredJobs);
  }, [filteredJobs]);
  useEffect(() => {
    console.log("NotificationDrawer drawerOpen", drawerOpen);
  }, [drawerOpen]);

  return (

        <div>
          <ClickAwayListener
            onClickAway={() => {
              setDrawerOpen(!drawerOpen);
              console.log("Click Away Listener");
            }}
          >
            <Drawer
              sx={{
                display: { xs: "block", sm: "block", md: "block" },
                "& .MuiDrawer-paper": {
                  backdropFilter: "blur(20px)",

                  background: "#00000080",
                  borderRadius:{xs:"0px" , sm: "20px 0px 0px 20px", md: "20px 0px 0px 20px"},
                  // height: "88vh",
                  height: {xs:"100vh" , sm:"70vh" , md : "88vh" },
                  
                  //   filter: "brightness(80%)",
                  marginTop: { xs: "58px", sm: "83px", md: "68px" },
                  // marginTop: "55px",
                  boxShadow: "0px -1px 15px #1e1e1e5c",
                  scrollbarWidth: "none",
                  width:{xs:"100%" , sm:340 , md : 340 }
                
    
                },
              }}
              anchor="right"
              open={drawerOpen}
              onClose={() => {
                setDrawerOpen(false);
              }}
            >
              <NotificationHeading
               
                counts={counts}
                loading={loading}
                handleToggle={handleToggle}
                setFilteredJobs={setFilteredJobs}
                handleRemoveMessage={handleRemoveMessage}
                filteredJobs={filteredJobs}
                displayCount={displayCount}
                initialDisplayCount={initialDisplayCount}
                setActiveJobId={setActiveJobId}
                deleteid={deleteid}
                setDeleteid={setDeleteid}
                activeJobId={activeJobId}
                isVisiable={isVisiable}
              />
            </Drawer>
          </ClickAwayListener>
        </div>
      
 
  );
}
