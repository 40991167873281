import { LogoutOutlined, Person } from "@mui/icons-material";
import React, { useEffect } from "react";
import { Link, Grid, ClickAwayListener } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import RecommendIcon from "@mui/icons-material/Recommend";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import LoginIcon from "@mui/icons-material/Login";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import Notificationfire from "./components/Firebase/Notificationfire";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import "./App.css";
import Notificationfire from "./components/Firebase/Notificationfire";

import {
  Button,
  Menu,
  Stack,
  useTheme,
  CircularProgress,
  Container,
  ButtonGroup,
} from "@mui/material";
import { borders } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { get_profile, notification_count_api } from "./pages/Axiosfetch/Axiosfetch";
import useMediaQuery from "@mui/material/useMediaQuery";
import NotificationDrawer from "./components/notification/NotificationDrawer";
// import jwt_decode from 'jwt-decode';

export default function Header() {
  
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [datas, setDatas] = useState([]);
  const [notifyCount, setNotifyCount] = useState([]);
  const drawerWidth = 220;

  const matches = useMediaQuery("(min-width:599px)");

  const token_data = localStorage.getItem("accesstoken");
  const token = localStorage.getItem("accesstoken");

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  // const decodedToken = jwt_decode(token);

  // const userId = decodedToken.id;
  // const userEmail = decodedToken.email;

  // console.log(userId , userEmail , "userEmailuserEmailuserEmail" )
  console.log(drawerOpen,"drawerOpen" )

  const fetchJobs = async () => {
    try {
      const response = await get_profile();
      if (response.status === 200) {
        setDatas(response.data);
      } else {
      }
    } catch (error) {}
  };

  const notification_count_fun_call = async () => {

    try {
      const response = await notification_count_api();
      if (response.status === 200) {
        setNotifyCount(response.data);
      } else {
      }
    } catch (error) {}
  };
  notification_count_fun_call()

  React.useEffect(() => {

    fetchJobs();

    
  }, []);

  useEffect(()=> {
    
  },[])

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    await fetchJobs();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {

    setIsClosing(true);
    setMobileOpen(false);

  };

  const handleDrawerTransitionEnd = async () => {
    await fetchJobs();

    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handlesignout = () => {
    localStorage.clear();
    navigate("/jobs", { replace: true });
  };


  const links = [
    ...(token
      ? [{ name: "Profile", icon: <Person />, links: "/user_profile" }]
      : []),
    { name: "Jobs", icon: <WorkOutlineIcon />, links: "/jobs" },
    { name: "Internships", icon: <ModelTrainingIcon />, links: "/internships" },
    {
      name: "Applied Jobs",
      icon: <FactCheckIcon />,
      links: "/applied-job-history",
    },
    { name: "BookMarks", icon: <BookmarkBorderIcon />, links: "/bookmarks" },
    // { name: "BookMarks", icon: <BookmarkBorderIcon />, links: "/bookmarks" },
    {
      name: "Recomended Jobs",
      icon: <RecommendIcon />,
      links: "/recomended-job",
    },

    ...(token
      ? [{ name: "Logout", icon: <LogoutOutlined />, links: "#" }]
      : [{ name: "Sign In", icon: <LoginIcon />, links: "/UserLogin" }]),
  ];

  const drawer = (
    <div>
      {token ? (
        <Toolbar>
          <Box
            sx={{
              width: 30,
              height: 30,
              backgroundImage: datas.profile_pic
                ? `url(${datas.profile_pic})`
                : `url(https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0=)`,
              backgroundSize: "cover",
              borderRadius: 500,
            }}
          ></Box>

          {token ? (
            datas.middleName ? (
              <Typography
                sx={{ ml: 1, fontWeight: "bold", textTransform: "capitalize" }}
              >
                {datas.firstName} {datas.middleName} {datas.lastName}
              </Typography>
            ) : (
              <Typography
                sx={{ ml: 1, fontWeight: "bold", textTransform: "capitalize" }}
              >
                {datas.firstName} {datas.lastName}
              </Typography>
            )
          ) : (
            <Typography sx={{ ml: 1, textTransform: "capitalize" }}>
              {" "}
              Profile{" "}
            </Typography>
          )}
        </Toolbar>
      ) : (
        <Toolbar>
          <Box
            sx={{
              width: 30,
              height: 30,
              backgroundImage: `url(https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=20&m=1223671392&s=612x612&w=0&h=lGpj2vWAI3WUT1JeJWm1PRoHT3V15_1pdcTn2szdwQ0=)`,
              backgroundSize: "cover",
              borderRadius: 500,
            }}
          ></Box>

          <Typography sx={{ ml: 1, textTransform: "capitalize" }}>
            {" "}
            Add Profile{" "}
          </Typography>
        </Toolbar>
      )}

      <Divider />
      <List>
        {links.map((text, index) => (
          <ListItem key={text.name} disableadding>
            <ListItemButton
              onClick={() => {
                handleDrawerClose();
                text.name === "Logout" ? handlesignout() : navigate(text.links);
              }}
            >
              <ListItemIcon>{text.icon}</ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );




  return (
    <>
      <Notificationfire />
      <Box>
        {matches === true ? (
          <AppBar
            className="appbarcss"
            component="nav"
            sx={{
              // zIndex: "12000",
              backgroundColor: "thistle",
              backdropFilter: "blur(6px)",
              backgroundColor: "rgba(255,255,255,0.85)",
              borderBottom: borders({
                borderBottom: "2px solid " + theme.palette.grey[100],
                mainShade: "#fff",
              }),
              // boxShadow: "none",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Toolbar className="toolbarcss" color="inherit">
              <div
                style={{ width: isTabletOrMobile ? 5 : null }}
                onClick={() => navigate("/")}
              >
                <img loading="" src="logo.svg" alt="flower logo" />

                <img
                  style={{ marginBottom: 5, height: 25 }}
                  src="logo_text.png"
                  alt="text logo"
                />
              </div>

              <Stack spacing={1} direction="row" marginInline="auto">
                <div>
                  {/* <label color={"black"}> icon </label> */}

                  <Button
                    sx={{ color: "#000000" }}
                    size="large"
                    variant="text"
                    onClick={() => navigate("/jobs")}
                  >
                    Job
                  </Button>
                </div>

                <Button
                  sx={{ color: "#000000" }}
                  size="large"
                  variant="text"
                  onClick={() => navigate("/internships")}
                >
                  Internships
                </Button>
                {token_data ? (
                  <Button
                    sx={{ color: "#000000" }}
                    size="large"
                    variant="text"
                    onClick={() => navigate("/bookmarks")}
                  >
                    BookMarks
                  </Button>
                ) : null}

                {token_data ? (
                  <Button
                    sx={{ color: "#000000" }}
                    size="large"
                    variant="text"
                    onClick={() => navigate("/applied-job-history")}
                  >
                    Applications
                  </Button>
                ) : null}

                <div className="offer">
                  <Button
                    sx={{ color: "#000000" }}
                    size="large"
                    variant="text"
                    onClick={() => navigate("/placement")}
                  >
                    Placement
                  </Button>

                  {isTabletOrMobile ? (
                    <div className="offers">New</div>
                  ) : (
                    <div className="offers_md_size">New</div>
                  )}
                </div>
              </Stack>

              

{token_data ? (
              <div className="noti">
                <NotificationsIcon
                  onClick={() => {
                    setDrawerOpen(!drawerOpen);console.log("Notification triggered mobiles")
                  }}
                  sx={{ color: "#838383" }}
                />

                <div className="notifiCount">{notifyCount}</div>
              </div>): null}

              <Stack
                direction="row"
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >

                {token_data ? null : (
                  <Stack direction="row" spacing={1}>
                    <Button
                      color="success"
                      onClick={() => navigate("/UserLogin")}
                    >
                      Login
                    </Button>
                    <Button href="/registration">Register</Button>
                  </Stack>
                )}


                {token_data ? (
                  <div style={{ position: "relative" }}>
                    <IconButton
                      id="profile"
                      // color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleClick}
                    >
                      <Person />
                    </IconButton>
                    {Boolean(anchorEl) ? (
                      <UserMenu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        datas={datas}
                      />
                    ) : null}
                  </div>
                ) : null}
              </Stack>
            </Toolbar>
          </AppBar>
        ) : (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <AppBar
              color="inherit"
              position="fixed"
              className="appbarcss1"
              component="nav"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>

              

              <Stack direction="row" sx={{ width: "100%" , display:"flex" , justifyContent:"space-between" , alignItems:"center"}} >
              <Box onClick={() => navigate("/")}>
                  <img
                    loading=""
                    src="logo.svg"
                    alt="flower logo"
                    style={{ height: 30 }}
                  />
                  <img
                    style={{ marginBottom: 5, height: 18 }}
                    src="logo_text.png"
                    alt="text logo"
                  />
                </Box>


              {token_data ? (
                <div className="noti">


                <NotificationsIcon
                  onClick={() => {
                    setDrawerOpen(!drawerOpen);console.log("Notification triggered mobile")
                  }}
                  sx={{ color: "#838383" }}
                />

                <div className="notifiCount">{datas.notification_count}</div>
              </div>) : null}


              </Stack>
              
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

              <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>

              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
          </Box>
        )}
      </Box>

      {drawerOpen && (
        <NotificationDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
   
        />


      )}
      
    </>
  );
}

function UserMenu({ open, handleClose, anchorEl, datas }) {
  const navigate = useNavigate();

  const handlesignout = () => {
    localStorage.clear();
    navigate("/jobs", { replace: true });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={4}
        sx={{
          position: "absolute",
          top: 35,
          right: 0,
          backgroundColor: "#fff",
          width: 180,
          boxShadow: "0px 0px 20px #cdcdcd",
          paddingTop: "10px",
        }}
      >
        {datas && (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px !important",
            }}
          >
            <Box sx={{ position: "relative", marginInline: "auto" }}>
              {/* <CircularWithLabel
								value={datas.candidate_percentage}
								// img={getValues("profile_pic")}
								// percentage={}
								// handleClickOpen={handleClickOpen}								
								/> */}

              <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) => theme.palette.grey[200],
                }}
                size={100}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  animationDuration: "550ms",
                  position: "absolute",
                  left: 0,
                }}
                size={100}
                thickness={4}
                value={datas.candidate_percentage}
              />

              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography variant="h2" display={"inline"} fontWeight={800}>
                  {datas.candidate_percentage}
                </Typography>
                %
              </Box>
            </Box>

            <Typography textAlign={"center"} variant="h4">
              {datas.firstName}
            </Typography>
            <br />
            <Divider />

            <List>
              <ListItem>
                <ListItemButton href="/user_profile">
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText>Your Profile</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handlesignout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>

                  <ListItemText>Sign Out</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Container>
        )}
      </Box>
    </ClickAwayListener>
  );
}

// function AnchorTemporaryDrawer() {

//   const [state, setState] = useState({
//     right: false,
//     top:false,
//     bottom:false,
//     left:false,
//   });

//   const toggleDrawer = (anchor, open) => (event) => {

//     console.log(anchor,open , "anchor")

//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });

//   };

//   const list = (anchor) => (

//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >

//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (

//           <ListItem key={text} disablePadding>

//             <ListItemButton>

//               <ListItemIcon>

//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}

//               </ListItemIcon>

//               <ListItemText primary={text} />

//             </ListItemButton>
//           </ListItem>

//         ))}
//       </List>

//       <Divider />

//       <List>

//         {['All mail', 'Trash', 'Spam'].map((text, index) => (
//           <ListItem key={text} disablePadding>
//             <ListItemButton>

//               <ListItemIcon>

//                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}

//               </ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItemButton>
//           </ListItem>
//         ))}

//       </List>

//     </Box>
//   );

//   return (

//     <div>
//       {['right'].map((anchor) => (
//         <React.Fragment key={anchor}>

//           <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>

//           <Drawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}

//           >

//             {list(anchor)}

//           </Drawer>

//         </React.Fragment>
//       ))}
//     </div>
//   );
// }
