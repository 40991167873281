import { Box } from '@mui/material'
import React from 'react'


const PageNotFound = () => {
  return (
    <> 
    {/* <div>PageNotFound</div> */}
    <Box sx={{width : "100%",display : "flex", alignItems : "center", justifyContent : "center"}}>

{/* <CircularProgress /> */}

        <lottie-player src="https://lottie.host/a0a6f5f6-d836-4d01-857e-37e5bdcc8a2f/DZkMVASIoX.json" background="##ffffff" speed="1"  style={{width : 300 , height :300}}  loop  autoplay direction="1" mode="normal"></lottie-player>


          </Box>

          {/* <lottie-player src="https://lottie.host/992bd503-aaa0-40e2-ac55-1ddfe95cbc47/zCjjDTPV5f.json" background="##FFFFFF" speed="1" loop  autoplay direction="1" mode="normal" style={{width : 300 , height :300}}></lottie-player> */}

   
    {/* <div>Return to <Link to="">Homepage</Link> */}
    {/* </div> */}
      
    </>
  )
}

export default PageNotFound
