import {
  Box,
  Paper,
  Grid,
  Stack,
  Typography,
  Divider,
  Button,
  ListItem,
  List,
  ListItemText,
  IconButton,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useContext, useState, useEffect } from "react";
import { AlertToast, alertState } from "../divine-constants";
import { addbookmark, get_details, get_profile } from "./Axiosfetch/Axiosfetch";
import Location from "./../images/location.png";
import experience from "./../images/experience.png";
import salary from "./../images/salary.png";
import duration from "./../images/duration.png";
import applicants from "./../images/applicants.png";
import bookmarkadd from "./../images/bookmarkadd.png";
import bookmarkadded from "./../images/bookmarkadded.png";
import openings from "./../images/openings.png";
import worker from "./../images/worker.png";
import { useTheme } from "@emotion/react";
import LaunchIcon from '@mui/icons-material/Launch';

export default function ViewJobDetail(control) {
	const theme = useTheme();
	const matches = useMediaQuery('(min-width:600px)');
  const [dialogOpen, setDialogOpen] = useState();
  const [jobs, setJobs] = useState([]);
  
  const [company, setCompany] = useState([]);
  // const [post_date, setPost_date] = useState();
  const [applied_candidates_counts, setApplied_candidates_count] = useState();
  const [matching_skills_percentage, setMatching_skills_percentage] = useState();
  const [matching_skills, setMatching_skills] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const { setAlert } = useContext(AlertToast);
  const [save, setSave] = useState(false);
  const [applystatus, setApplystatus] = useState(false);
  const navigate = useNavigate();
  
  const location = useLocation();
  const data = location.state;
  const token = localStorage.getItem('accesstoken');

  // const data = new URLSearchParams(location.search);
  // console.log(data.get("company_name"),"eeeeeeeeeeeee")

  // const jobTitle = queryParams.title
  

  // Access the parameters using the get() method
  // 
  // const jobDescription = queryParams.get('description');

  // useEffect(() => {
  //   // Use jobTitle and jobDescription to render the content
  //   console.log(jobTitle, jobDescription);
  // }, [jobTitle, jobDescription]);

  const fetchJobs = async () => {
    const id = data.id;
    // const id = data.get('id');
    try {
      const response = await get_details(id);
      if (response.status === 200) {
        setJobs(response.data.job_details);
       
        setCompany(response.data.company_details)      
        setApplied_candidates_count(response.data.applied_candidates_count);

        setMatching_skills_percentage(response.data.matching_skills_percentage);

        setMatching_skills(response.data.matching_skills);

        setSave(response.data.save_status);

        setApplystatus(response.data.Applied_state);
        
        setApplied_candidates_count(response.data.applied_candidates_count);
      } else {
        throw new Error(`Something went wrong ${response.data.message}`);
      }
    } catch (error) {
      // console.log(error)`
      // setAlert(
      //   alertState({
      //     message: error.message,
      //     severity: "error",
      //     show: true,
      //   })
      // );
    }
  };




  useEffect(() => {
  
    fetchJobs();
  }, []);

  const dialogClose = async () => {
    setDialogOpen(false);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  
  // Button component


  const handleClick = async() => {
    const id = data.id;
    const response = await get_details(id);
    console.log("testing1",response.data.
    job_details.post_status)
    if (response.status === 200) {
      setJobs(response.data.job_details);
        if (response.data.job_details.post_status === false){
          setAlert(
            alertState({
              message: "job closed",
              severity: "error",
              show: true,
            })
          );
     
    }else{
    scrollToTop(); // Call the external function

    navigate("/apply-job", { state: { ...jobs } });
    }
  };}

  const DialogboxOpen = async () => {
    try {
      const response = await get_profile(); // Call your API function to fetch profile data
      if (response.status === 200) {
        setDialogOpen(true);
        setUserdata(response.data);
      } else {
        throw new Error("Invalid input: The value must be a positive number");
      }
    } catch (error) {
      // console.log(error)
      setAlert(
        alertState({
          message: error.message,
          severity: "error",
          show: true,
        })
      );
    }
  };

  function HandleNavigation() {
    if (data.job_type[0] === "job") {
      navigate("/jobs");
    } else if (data.job_type[0] === "internships") {
      navigate("/internships");
    }
  }
  const HandleSave = async (data) => {
    if (save == false) {
      const datas = { id: data.id, job_save: "true" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(true);
        setAlert(
          alertState({
            message: "You have saved this job",
            severity: "success",
            show: true,
          })
        );
      }
    } else if (save == true) {
      const datas = { id: data.id, job_save: "false" };
      const response = await addbookmark(datas);
      if (response.status === 200) {
        setSave(false);
        setAlert(
          alertState({
            message: "This job is no longer saved",
            severity: "success",
            show: true,
          })
        );
      }
    }
  };

  console.log(jobs, "22222222222222222222222222222222222data")

  return (
    <>
      {/* <ApplyJob
        state={[dialogOpen, setDialogOpen]}
        control={control}
        dialogClose={dialogClose}
        dialogOpen={dialogOpen}
        data={userdata}
      /> */}

      {data && (
        <Grid container sx={{ padding: 2}}>

          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            component={Paper}
            paddingInline={2}
            paddingBlock={2}
            sx={{ cursor: "pointer" }}
            margin={"auto"}
            borderRadius={2}
          >
            <Box onClick={HandleNavigation}>
              <Box display="flex" alignItems="center" gap={2}>
                <img width={48} src={data.company_logo} alt="dummy" />
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box>
                  <Typography
                    className="align-icon"
                    fontWeight={500}
                    variant="h4"
                    sx={{textTransform:"capitalize"}}
                  >
                
                    {data.job_title}
                  
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">  
                      <Typography fontSize={12} sx={{ textTransform: "capitalize", color: "#00000090" }}>
                        {data.company_name}
                      </Typography>
                  
                      <a href={data.company_website_link}  target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: 'inherit' }}>
                        <LaunchIcon color="primary" sx={{ fontSize: 17, marginLeft: 1 }} />
                      </a>
                                  
                         </Box>
                </Box>
                {/* <Typography marginLeft="auto" color="GrayText" style={{alignSelf: "flex-start"}}>{jobs.post_date === 0 ? 'Today' : `${jobs.post_date} days ago`}</Typography> */}
              </Box>
      
              <Grid container sx={{mt:1}}>
                    <Grid  item sm={6} xs={12} md={3}>
                        {matches === true ? 
                          <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
                          <img style={{width:16,height:16}} src={Location}/>
                          {data.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {data.post_city.map(location => location.toUpperCase()).join(', ')}
                            </Typography>
                          ) : null}

                        </Stack>:



                      <Stack sx={{mt:1}} direction={'row'} alignItems={'center'} spacing={1}>
                        <img style={{width:16,height:16}} src={Location}/>
                      {data.post_city ? (
                            <Typography variant="overline" fontSize={12}>
                              {data.post_city.map(location => location.toUpperCase()).join(', ')}
                            </Typography>

                          ) : null}
                    </Stack>}
                    </Grid>

                  </Grid>

              		<Grid container rowSpacing={1}
                		columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item sm={3} xs={12} md={3}>
						{matches === true ?<Stack direction={'column'}>
						<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={experience}/>
						<Typography color={'#000000'} fontSize={12}>EXPERIENCE</Typography>
					</Stack>
					<Typography variant="overline" fontSize={12}>{data.minimum_experience} - {data.maximum_experience} YEARS</Typography>
					</Stack>:<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={experience}/>
						<Typography color={'#000000'} fontSize={12}>{data.minimum_experience} - {data.maximum_experience} Years experience</Typography>
					</Stack>}
						</Grid>


					<Grid item sm={3} xs={12} md={3}>
						{matches === true ?<Stack direction={'column'}>
							<Stack sx={{mt:1}} direction={'row'} spacing={1}>
							<img style={{width:16,height:16}} src={salary}/>
							{/* <Typography color={'#000000'} fontSize={12}>CTC ({data.salary_per} )</Typography> */}
							<Typography color={'#000000'} fontSize={12}>CTC</Typography>
							</Stack>
						<Typography variant="overline" fontSize={12}>{data.minimum_salary.toLocaleString("en-IN")} - {data.maximum_salary.toLocaleString("en-IN")} {data.salary_per} </Typography>
						</Stack>:<Stack sx={{mt:2}} direction={'row'} spacing={1}>
							<img style={{width:16,height:16}} src={salary}/>
							{/* <Typography color={'#000000'} fontSize={12}>CTC ({data.salary_per} ) {data.minimum_salary} - {data.maximum_salary} {data.salary_per}  </Typography> */}
							<Typography color={'#000000'} fontSize={12}>CTC  {data.minimum_salary.toLocaleString("en-IN")} - {data.maximum_salary.toLocaleString("en-IN")} {data.salary_per}  </Typography>
							</Stack>}
					</Grid>

					<Grid item sm={3} xs={12} md={3}>
						{matches === true ?<Stack direction={'column'}>
							<Stack sx={{mt:1}} direction={'row'} spacing={1}>
							<img style={{width:20,height:20}} src={worker}/>
							<Typography color={'#000000'} fontSize={12}>WORK MODE </Typography>
							</Stack>
						<Typography variant="overline" fontSize={12}>{data.work_mode} </Typography>
						</Stack>:<Stack sx={{mt:2}} direction={'row'} spacing={1}>
							<img style={{width:20,height:20}} src={worker}/>
							<Typography color={'#000000'} fontSize={12}>WORK MODE {data.work_mode} </Typography>
							</Stack>}
					</Grid>

					</Grid>


					<Grid container sx={{mt:2}}>
					<Grid  item xs={12}sm={3.5}md={3}>
								<Stack sx={{mt:1}} direction={'row'} spacing={1}>
									<img  style={{width:16,height:16}} src={openings}/>
									<Typography color={'#000000'} fontSize={14}>{data.vacancy} vacancies</Typography>						
							</Stack>
						</Grid>

						<Grid item xs={12}sm={3.5}md={3}>
								<Stack sx={{mt:1}} direction={'row'} spacing={1}>
								<img style={{width:16,height:16}} src={applicants}/>
									<Typography color={'#000000'} fontSize={14}>{applied_candidates_counts} Applicants</Typography>					
							</Stack>
						</Grid>
						<Grid item xs={12} sm={3.5} md={3}>
							<Stack sx={{mt:1}} direction={'row'} spacing={1}>
						<img style={{width:16,height:16}} src={duration}/>
            <Typography color={'#000000'} fontSize={13}>{data.post_date === 0 ? 'Posted Today' : 
              data.post_date === 1 ? 'Posted yesterday' :
                  data.post_date === 7 ? 'Posted One week ago' :
                   `Posted ${data.post_date} days ago`}</Typography>
						{/* <Typography color={'#000000'} fontSize={13}>Posted {data.post_date} ago</Typography> */}
						</Stack>
						</Grid>
						<Grid item xs={12} sm={1.5} md={3}></Grid>
					</Grid>
            </Box>
			<br/>
            <Divider />
            <br />
            <Stack  direction={{ xs: "row", sm: "row", md: "row" }}
              spacing={{ xs: 2, md: 2, sm: 2 }}>
              <Box>
                {
                    token ? ( 
                      !jobs.post_status ? (
                            <Button variant="outlined" > Closed </Button> 
                        ) : (
                        applystatus ? (
                            <Button variant="outlined" > Applied </Button> 
                        ) : (
                            // <Button variant="outlined" onClick={() => {navigate("/apply-job", { state: { ...jobs } } , window.scrollTo(0,0) )}}>Apply</Button>
                            <Button variant="outlined" onClick={handleClick}>Apply</Button>
                        )
                    ) ): (
                      applystatus  ? (
                            <Button variant="outlined" > Applied </Button>
                        ) : (
                            <Button variant="outlined" onClick={() =>  {navigate("/userlogin"); window.scrollTo(0,0)}  }  >Login to apply</Button>
                        )
                    )
                }

              </Box>
              {token ? <Box>
                {save === false ? (
                  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
					        <img style={{width:16,height:16}} src={bookmarkadd}/>
                  </IconButton>
                ) : (
                  <IconButton color="primary" onClick={() => HandleSave(jobs)}>
					      <img style={{width:16,height:16}} src={bookmarkadded}/>
                  </IconButton>
                )}
              </Box> : null}
            </Stack>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={10}
            // key={data.id}
            component={Paper}
            paddingInline={2}
            paddingBlock={2}
            sx={{ cursor: "pointer" }}
            margin={"auto"}
            width={"40%"}
            borderRadius={2}
            marginTop={2}
          >
            <Stack
              direction="column"
              marginTop={2}
              spacing={2}
              height={"100%"}
              position={"relative"}
            >

            <List>
              {data.about_company ? 
                <ListItem>
                <Stack direction={'column'}>
                <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                    About Company
                  </Typography>
                  <ListItemText sx={{ whiteSpace : "pre-line"}}>{data.about_company}</ListItemText>
                </Stack>
             
                </ListItem>  

                : null}
              
 
              {data.job_description ? 

                <ListItem sx={{mt:'.5rem'}}>

                <Stack direction={'column'}>
                  
                <Typography sx={{fontFamily: "Poppins" }} fontSize={14} fontWeight={"bold"}>

                    Job Description
                  </Typography>
                  <ListItemText sx={{ whiteSpace : "pre-line"}} >{data.job_description}</ListItemText>

                </Stack>
             
                </ListItem>  
                : null}

               { data.job_responsibilities ?
                <ListItem sx={{mt:'.5rem'}}>
                  <Stack direction={'column'}>
                    <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                    Job Responsibilities
                      </Typography>
                      <ListItemText  sx={{ whiteSpace : "pre-line"}} >{data.job_responsibilities}</ListItemText>
                  </Stack>
                </ListItem> : null}

                {/* {data?.key_skills.length > 0 ? 

                <ListItemText sx={{mt:'.5rem'}}>
                  <Stack direction={'column'}>
                  <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                    Skills Required
                  </Typography>

                  <Stack  sx={{mt:'.5rem' }} direction={"row"} alignItems={"center"} flexWrap={"wrap"} spacing={1} rowGap={1}>
							      {data.key_skills.map((skill, index)=> (
                        <Fragment sx={{marginLeft: "10px"}}  key={index}>
                        <Chip size = {'small'} sx={{backgroundColor:'white'}} label={skill} key={index} />
                        </Fragment>
                      ))}	
						      </Stack>
                  </Stack>
                </ListItemText> : null
                } */}

              
                {data.benefits ? 
                <>
               

                <ListItem sx={{mt:'.5rem'}}>
                  <Typography sx={{fontFamily: "Poppins"}} fontSize={14} fontWeight={"bold"}>
                    Benefits
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText sx={{ whiteSpace : "pre-line"}} > {data.benefits}</ListItemText>
                </ListItem> 
                </>
                : null}


                {/* <ListItem>
                  <Typography fontSize={13} fontWeight={"bold"}>
                    Shift Timing :{" "}
                  </Typography>
                  <ListItemText>{data.work_mode}</ListItemText>
                </ListItem> */}


                <ListItem > 
                  <Box  display="flex" sx={{mt:1}} gap={1}> 
                  <Typography fontSize={13} fontWeight={"bold"}>
                     Work Mode : {""}
                  </Typography>

                  <Typography fontSize={13}>
                   {data.work_mode}
                  </Typography>

                  </Box>
                 

                </ListItem>

                <ListItem>
                <Box  display="flex" sx={{mt:1}} gap={1}> 

                
                <Typography fontSize={13} fontWeight={"bold"}>
                  Job Nature :{" "}
                  </Typography>
                <Typography fontSize={13} >
                {data.job_nature}
                  </Typography>
                

                </Box>
             
                </ListItem>

                <ListItem>

                <Box  display="flex" sx={{mt:1}}  gap={1}> 
                <Typography fontSize={13} fontWeight={"bold"}>
                  Time Line  :{" "}
                  </Typography>
                
                <Typography fontSize={13}>
                {data.time_line}
                  </Typography>
      
            

                </Box>
                  
                </ListItem>
                <ListItem>

                <Box  display="flex" sx={{mt:1}}  gap={1}> 
                <Typography fontSize={13} fontWeight={"bold"}>
                Job Type  :{" "}
                  </Typography>
                
                <Typography fontSize={13}>
                {data.job_type}
                  </Typography>
      
            

                </Box>
                  
                </ListItem>
                <ListItem>

                <Box  display="flex" sx={{mt:1}}  gap={1}> 
                <Typography fontSize={13} fontWeight={"bold"}>
                Type  :{" "}
                  </Typography>
                
                <Typography fontSize={13}>
                {data.type}
                  </Typography>
      
            

                </Box>
                  
                </ListItem>

                {/* <ListItem sx={{ marginTop: 1 }}>
                  <Typography fontSize={13} fontWeight={"bold"}>
                    Role :{" "}
                  </Typography>
                  <ListItemText> {data.role ? data.role.toUpperCase():null}</ListItemText>
                </ListItem> */}
      
              { data.contact_no ? <ListItem> 

                  <Box  display="flex"  sx={{mt:1}} gap={1}> 
                  <Typography fontSize={13} fontWeight={"bold"}>
                    Contact : {" "}
                  </Typography>
                  <Typography fontSize={13} >
                  {data.contact_no}
                  </Typography>
                  </Box>
                
                </ListItem> : null }

                {data.contact_email ?  <ListItem> 

                  <Box  display="flex" sx={{mt:1}}  gap={1}> 
                  <Typography fontSize={13} fontWeight={"bold"}>
                  Contact Email : {" "}
                  </Typography>
                  <Typography fontSize={13} >
                  {data.contact_email}
                  </Typography>
                 

                  </Box>
                
                </ListItem> : null }

                <ListItem>
                  
                <Box  display="flex" sx={{mt:1}}  gap={1}>  
                <Typography fontSize={13} fontWeight={"bold"}>
                    Location : {" "}
                  </Typography>

                <Typography fontSize={13}>
                    {/* {data.get("post_city").join(", ")} */}
                    {data.post_city.join(", ")}
                </Typography> 
    
                
                </Box>
             
                </ListItem>

                {matching_skills_percentage >= 0  ? <ListItem>
                  <Box  display="flex" sx={{mt:1}}  gap={1}> 

                  <Typography fontSize={13} fontWeight={"bold"}>
                    Matching Skills : {" "}
                  </Typography>
                  <Typography fontSize={13} >
                  {matching_skills_percentage}%
                  </Typography>
                
                   </Box>
                </ListItem> : null }

                <ListItem>
                  <ListItemText>
                  <Stack
                    direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  rowGap={0.5}
                  sx={{ marginLeft: "10px" }} // Corrected prop name
              >
                      {matching_skills ? (
                        matching_skills.map((skill, index) => (
                          <Fragment key={index}>
                            <Chip
                              color="success"
                              variant="outlined"
                              label={skill}
                              key={index}
                            />
                            {/* {index !== jobs.key_skills.length - 1 && (
									<Box width={4} height={4} bgcolor={"text.disabled"} borderRadius={10}></Box>
									)} */}
                          </Fragment>
                        ))
                      ) : (
                        <Typography>No matching skills </Typography>
                      )}
                    </Stack>
                  </ListItemText>
                </ListItem>
              </List>

            </Stack>
          </Grid>
          
        </Grid>
      )}
    </>
  );
}

// function ApplyJob({ dialogClose, dialogOpen, data }) {
//   const [dialogOpens, setDialogOpens] = useState(false);
//   // const [dialogCloses, setDialogCloses] = useState(false);

//   const [operation, setOperation] = useState();

//   function dialogCloses() {
//     setDialogOpens(false);
//   }

//   function HandleEdit() {
//     // dialogClose()
//     HandleEdits();
//   }
//   function HandleEdits() {
//     dialogClose();
//     setDialogOpens(true);
//   }
//   return (
//     <Dialog fullWidth="100%" open={dialogOpen} onClose={dialogClose}>
//       <DialogTitle>
//           <Typography fontWeight="bold" fontSize={20}>
//             Apply
//           </Typography>          
//       </DialogTitle>
//       <Divider />
//       <DialogContent sx={{ mt:1, p: 3 }}>
//         <Grid container spacing={2}>
//           {/* <Grid xs={12} sm={12} md={12}>
//             <Box>
//               <Typography variant="overline" fontSize={15}>
//                 Contact Info
//               </Typography>
//             </Box>
//             <Stack
//               sx={{ mt: 1 }}
//               direction={{ xs: "row", sm: "row" }}
//               spacing={{ xs: 2, sm: 2, md: 3 }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   placeContent: "center",
//                   width: 60,
//                   height: 60,
//                   backgroundImage: data.profile_pic
//                     ? `url(${data.profile_pic})`
//                     : `url(${profile})`,
//                   // 'url("https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
//                   backgroundSize: "contain",
//                   borderRadius: 500,
//                 }}
//               ></Box>

//               <List>
//                 <ListItem>
//                   <Typography varient="h3">
//                     {data.firstName} {data.lastName}
//                   </Typography>
//                 </ListItem>
//                 <ListItem>
//                   <Typography fontSize={12} color={"#000000"}>
//                     {data.key_skills ? (
//                       data.key_skills[0]
//                     ) : SignalCellularNullOutlined}
//                   </Typography>
//                 </ListItem>
//                 <ListItem>
//                   <Typography fontSize={13} color={"#000000"}>{data.location}</Typography>
//                 </ListItem>
//               </List>
//             </Stack>
//           </Grid> */}

//           <Grid xs={12} sm={12} md={12}>
//             <Stack sx={{ mt: 2 }}>
//               <TextField
//                 id="outlined-basic"
//                 label="Email"
//                 inputProps={{ style: { fontWeight: "bold", colour: "black" } }}
//                 value={data.email}
//                 disabled
//                 variant="outlined"
//               />
//               <TextField
//                 id="outlined-basic"
//                 label="Phone"
//                 value={data.mobile}
//                 inputProps={{ style: { fontWeight: "bold", colour: "black" } }}
//                 disabled
//                 variant="outlined"
//               />
//               <Typography fontWeight={"bold"} fontSize={15}>
//                 Resume
//               </Typography>
//             </Stack>
//           </Grid>
//         </Grid>
//         <DialogActions>
//           <Button onClick={dialogClose}>Sumbit</Button>
//         </DialogActions>
//       </DialogContent>
//     </Dialog>
//   );
// }


